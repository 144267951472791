<template>
    <div>

        <div class="row">
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row set_row">
                            <!-- <div class="col-xl-1">
                            </div> -->
                            <div class="col-sm-12 col-xl-12 set_table_center">
                                <table id="notOperatedRecords" class="display table-striped table-bordered select"
                                       cellspacing="0" width="100%">
                                    <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>google_locality</th>
                                        <th>caught_location</th>
                                        <th>caught_method</th>
                                        <th>ownership</th>
                                        <th>created_at</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- <div class="col-xl-1">
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data()
    {
        return {
            table: null,
        }
    },
    methods: {

        NotOperatedRecords()
        {
            let vm = this;

            if (vm.table != null) {
                vm.table.destroy();
            }
            try {
                vm.table = $('#notOperatedRecords').DataTable({
                    "serverSide": true,
                    "ajax": {
                        "url": window.DATATABLES_BASE_URL + "records/clearedForSterilization",
                        "type": "POST",
                    },
                    lengthMenu: [[20, 100, -1], [20, 100, "All (Warning: Slow)"]],
                    pageLength: 20,
                });
            } catch (e) {
                console.log(e)
            }
        },

    },
    mounted()
    {
        let vm = this;
        vm.NotOperatedRecords();
    }
}

</script>
