<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox ">
                <div class="ibox-content">
                    <div class="panel-heading">
                        <h2 class="set_font_title">
                            Report By Dates
                        </h2>
                    </div>
                </div>
                <el-row>
                    <div class="card p-3">
                        <el-row :gutter="20" class="mb-2">
                            <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                            >
                                <label>Select Range</label>
                                <multiselect
                                        v-model="selectedRange"
                                        @select="resetAllModel"
                                        :options="dateRanges"
                                        :show-labels="false"
                                        placeholder="Select Date Range"
                                ></multiselect>
                            </el-col>
                        </el-row>
                        <el-row
                                :gutter="20"
                                v-show="selectedRange === 'Yearly'"
                        >
                            <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                            >
                                <label class="w-100">
                                    Pick a year
                                </label>
                                <el-date-picker
                                        v-model="selectedDate"
                                        type="year"
                                        placeholder="Year"
                                        format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row
                                :gutter="20"
                                v-show="selectedRange === 'Monthly'"
                        >
                            <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                            >
                                <label class="w-100">
                                    Pick a month
                                </label>
                                <el-date-picker
                                        v-model="selectedDate"
                                        type="month"
                                        placeholder="Month"
                                        format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row
                                :gutter="20"
                                v-show="selectedRange === 'Weekly'"
                        >
                            <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                            >
                                <label class="w-100">
                                    Pick a week
                                </label>
                                <el-date-picker
                                        v-model="selectedDate"
                                        type="week"
                                        placeholder="Week"
                                        format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" v-show="selectedRange === 'Daily'">
                            <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                            >
                                <label class="w-100">
                                    Pick a Date
                                </label>
                                <el-date-picker
                                        v-model="selectedDate"
                                        type="date"
                                        placeholder="Day"
                                        format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row
                                :gutter="20"
                                v-show="selectedRange === 'Custom'"
                        >
                            <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                            >
                                <label class="w-100">
                                    Select a custom range of dates
                                </label>
                                <el-date-picker
                                        v-model="selectedDateCustom"
                                        type="daterange"
                                        start-placeholder="Start date"
                                        end-placeholder="End date"
                                        format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <div>
                            <el-button
                                    :disabled="!(selectedDate || selectedDateCustom)"
                                    @click="generatePDF"
                                    :loading="isGenerating"
                                    class="btn btn-primary float-right text-white mt-3 mr-1"
                            >
                                Generate PDF
                            </el-button>
                        </div>
                    </div>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '../../mixins/GlobalMixin';
import Multiselect from "vue-multiselect";
import {Row, Col, DatePicker, Button} from "element-ui";

export default {
    name: "ReportByDates",
    props: {
        projectId: {
            type: [Number, String],
            required: true
        }
    },
    data: () => ({
        selectedRange: "Custom",
        dateRanges: ["Yearly", "Monthly", "Weekly", "Daily", "Custom"],
        selectedDate: "",
        selectedDateCustom: '',
        isGenerating: false
    }),
    mixins: [mixin],
    components: {
        Multiselect,
        [Row.name]: Row,
        [Col.name]: Col,
        [DatePicker.name]: DatePicker,
        [Button.name]: Button
    },
    methods: {
        async generatePDF()
        {
            this.isGenerating = true;
            const type = this.selectedRange.toLowerCase();
            let sDate, eDate;
            if (type === 'custom') {
                sDate = new Date(this.selectedDateCustom[0]);
                eDate = new Date(this.selectedDateCustom[1])
            } else {
                sDate = new Date(this.selectedDate);
                eDate = new Date(this.selectedDate);
            }
            const URL = `/pm/reports/generate/report/${this.projectId}`;
            const requestPayload = {
                report_type: 1
            };
            switch (type) {
                case "yearly":
                    eDate.setFullYear(sDate.getFullYear() + 1);
                    break;
                case "monthly":
                    eDate.setMonth(sDate.getMonth() + 1);
                    break;
                case "weekly":
                    eDate.setDate(sDate.getDate() + 7);
                    break;
            }
            requestPayload.start_date = this.getDateFormat(sDate);
            requestPayload.end_date = this.getDateFormat(eDate);
            requestPayload.time_period = type.toLowerCase()
            console.log(
                "request payload",
                requestPayload,
                sDate,
                eDate,
                "typeof Sdate",
                typeof sDate,
                "type of eDate",
                typeof eDate
            );
            try {
                const {data} = await axios.post(URL, requestPayload);
                console.log("all Areas", data.response.data);
                const response = data.response.data;
                // this.allAreas = [
                //     ...response.assigned_survey_areas,
                //     ...response.assigned_vaccination_areas
                // ];
                // this.allAreas = response.areas;
                const downloadURL = `/pm/reports/download/pdf/${response.report_id}`
                const pdfResponse = await axios.get(downloadURL)

                this.isGenerating = false;
                console.log("pdf response", pdfResponse, data.response.data, pdfResponse.data.response.error.error_code, pdfResponse.data.response.data);
                if (pdfResponse.data.response.data === null) {
                    this.showAlert('Oops!', pdfResponse.data.response.error.msg, false)
                } else {
                    window.location.href = pdfResponse.data.response.data.src
                }
            } catch (error) {
                this.isGenerating = false;
                console.error(error.response);
            }
        },
        getDateFormat(date)
        {
            console.log('date format', date)
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()

            return `${year}-${month}-${day}`
        },
        resetAllModel()
        {
            this.selectedDate = ''
            this.selectedDateCustom = ''
        }
    },
    mounted()
    {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        //Bus.$emit("projectype", vm.project);
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__input:disabled {
    cursor: not-allowed;
}

.set_font_title {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black !important;
    margin-left: -12px !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
    width: 100%;
}
</style>
