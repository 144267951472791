<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>

        <div>
            <div>


                <div class="row set_row top-bar set_msg title-row">
                    <h5>{{ getTitle }}</h5>
                </div>
                <hr>
                <div class="row set_row">

                    <template v-if="addNewFieldTeam">
                        <div class="col-12">
                            <div class="manage-team-section">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Select Organisation</label>
                                        <br>
                                        <el-select class="select-info"
                                                   style="width: 100%"
                                                   size="large"
                                                   placeholder="Select Organisation"
                                                   @change="handleChange"
                                                   v-model="model.selectedOrganisation">
                                            <el-option v-for="org in userOrganisations"
                                                       class="select-danger"
                                                       :value="org.organisation.id"
                                                       :label="org.organisation.name"
                                                       :key="org.organisation.id">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>

                                <fieldset>
                                    <br>

                                    <div class="form-group">
                                        <fg-input
                                            type="text"
                                            ref="addFieldTeamName"
                                            label="Field Team Name*"
                                            name="Field Team Name"
                                            v-validate="fieldTeamValidation.fieldTeamName"
                                            :error="getError('Field Team Name')"
                                            v-model="model.fieldTeamName">
                                        </fg-input>
                                    </div>
                                </fieldset>

                                <fieldset>
                                    <div class="form-group">
                                        <label class="control-label">Field Team Description</label>
                                        <textarea class="form-control" v-model="model.fieldTeamDescription"
                                                  placeholder="Enter description here..." rows="3"></textarea>
                                    </div>
                                </fieldset>

                                <div class="row">
                                    <div class="col-12 text-right">
                                        <br>
                                        <button v-if="onLine" v-bind:disabled="model.isDisabled"
                                                v-on:click="validateAndSubmit"
                                                type="button"
                                                class="btn btn-round btn-info">Add Field Team
                                        </button>
                                        <button v-else v-bind:disabled="true" v-on:click="validateAndSubmit"
                                                type="button"
                                                class="btn btn-round btn-info">Add Field Team
                                        </button>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </template>

                    <template v-if="addNewTeamUser">
                        <div class="col-md-6 col-sm-12">

                            <div class="manage-team-section">
                                <label class="invite-user-label">Select Field Team</label>
                                <el-select
                                    id="feildTeams"
                                    class="mb-2"
                                    v-bind:disabled="isDisabled"
                                    @change="getInvitations"
                                    v-model="selectedTeam"
                                    placeholder="Field Teams"
                                >
                                    <el-option
                                        v-for="(team, key) in teams"
                                        :key="key"
                                        :label="team.name"
                                        :value="team.id"
                                    ></el-option>
                                </el-select>


                                <div class="row">
                                    <div class="col-12">
                                        <br>
                                        <label class="invite-user-label">Enter Email to Invite/add User on a selected team</label>
                                        <fg-input v-model="userEmail"
                                                  type="text"
                                                  ref="inviteUser"
                                                  name="User Email"
                                                  :disabled="invitingUser"
                                                  v-validate="userInviteValidation.userEmail"
                                                  :error="getError('User Email')"
                                                  placeholder="Enter user email..."></fg-input>
                                    </div>

                                    <div class="col-6">
                                        <label class="invite-user-label">First Name</label>
                                        <fg-input v-model="firstName"
                                                  type="text"
                                                  required
                                                  name="First Name"
                                                  v-validate="userInviteValidation.firstName"
                                                  :error="getError('First Name')"
                                                  :disabled="invitingUser"
                                                  placeholder="Enter First Name"></fg-input>
                                    </div>

                                    <div class="col-6">
                                        <label class="invite-user-label">Last Name</label>
                                        <fg-input v-model="lastName"
                                                  type="text"
                                                  name="Last Name"
                                                  :disabled="invitingUser"
                                                  placeholder="Enter Last Name"></fg-input>
                                    </div>
                                    <div class="col-6">
                                        <label class="invite-user-label">Password</label>
                                        <fg-input v-model="password"
                                                  type="password"
                                                  name="Password"
                                                  :disabled="invitingUser"
                                                  placeholder="Enter Password"></fg-input>
                                    </div>
                                    <div class="col-6">
                                        <label class="invite-user-label">Confirm Password</label>
                                        <fg-input v-model="passwordConfirmation"
                                                  type="password"
                                                  name="Confirm Password"
                                                  :disabled="invitingUser"
                                                  placeholder="Confirm Password"></fg-input>
                                    </div>

                                    <div class="col-12">
                                        <p>*Password will remain unchanged for any existing users</p>
                                    </div>


                                    <div class="col-12">

                                        <label class="invite-user-label">Notes</label>
                                        <fg-input v-model="userNote"
                                                  type="text"
                                                  textarea
                                                  name="Note"
                                                  :disabled="invitingUser"
                                                  placeholder="Enter note if any..."></fg-input>


                                    </div>


                                    <div class="col-12 text-right">

                                        <button v-if="onLine" :disabled="invitingUser" type="button"
                                                @click="inviteUserToProject" name="invite"
                                                class="btn btn-round btn-info">
                                            Invite User
                                        </button>
                                        <button v-else v-bind:disabled="true" type="button"
                                                @click="inviteUserToProject" name="invite"
                                                class="btn btn-round btn-info">
                                            Invite User
                                        </button>

                                    </div>


                                </div>


                            </div>

                        </div>
                        <div class="col-md-6 col-sm-12"
                             style="background-color: white; border-radius: 30px; box-shadow: 0 8px 6px -6px gray;">
                            <label class="invite-user-label invitation-label">Invitation List</label>
                            <div v-if="invitations.length>0">
                                <table class="invitation-table">
                                    <tr>
                                        <th>Name/Email</th>
                                        <th>Status</th>
                                    </tr>
                                    <template v-for="(invitation,index) in invitations">
                                        <tr style="border: 1px solid #606266; padding: 5px;">
                                            <td>
                                                <span style="font-weight: bold;">{{ getName(invitation) }}</span>
                                                <br>
                                                <a :href="'mailto:'+invitation.email">{{ invitation.email }}</a>
                                            </td>
                                            <td :style="{color:invitation.joined == 0 ?'red':'green'}">
                                                ({{ invitation.joined == 0 ? 'Pending' : 'Joined' }})
                                                <a href="#" v-if="invitation.joined==0"
                                                   v-on:click.prevent="cancelInvitation(invitation.invitation_code,index)"><i
                                                    class="nc-icon nc-simple-remove"></i></a>
                                            </td>
                                        </tr>

                                    </template>
                                </table>
                            </div>
                            <div v-else>
                                <br>
                                <p class="text-center">No Invitations found</p>
                            </div>
                        </div>
                    </template>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import mixin from '../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import {mapGetters} from 'vuex';

import {Select, Option,} from 'element-ui';
import Swal from "sweetalert2";


export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline
    },
    data() {
        return {

            addNewFieldTeam: false,

            addNewTeamUser: false,

            model: {
                fieldTeamName: '',
                fieldTeamDescription: '',
                selectedOrganisation: null,
            },
            fieldTeamValidation: {
                fieldTeamName: {
                    required: true,
                }
            },
            // Merged data of inviteFieldTeamUser.vue
            selectedTeam: null,
            password: null,
            passwordConfirmation: null,
            teams: [],
            isDisabled: false,
            userEmail: '',
            userNote: '',
            userInviteValidation: {
                userEmail: {
                    required: true,
                    email: true,
                },
                firstName: {
                    required: true,
                }
            },

            firstName: '',
            lastName: '',

            invitingUser: false,
            invitations: [],
        }
    },
    mixins: [mixin],
    methods: {
        getName(invitation) {
            if (invitation.first_name != "" || invitation.last_name != "") {
                return invitation.first_name + " " + invitation.last_name
            } else {
                return "Unknown"
            }
        },
        async init() {
            let vm = this;
            await vm.$store.dispatch('user/getUserOrganisations');
            if (this.userOrganisations.length) {
                this.model.selectedOrganisation = this.userOrganisations[0].organisation.id
            }
            this.handleChange()
        },
        validateAndSubmit: function () {
            let vm = this;
            this.isDisabled = true;
            this.$refs.addFieldTeamName.$validator.validate('Field Team Name').then((result) => {
                if (result) {
                    axios.post('/field_team/create', {
                        name: vm.model.fieldTeamName,
                        description: vm.model.fieldTeamDescription,
                        organisation_id: vm.model.selectedOrganisation,
                    }).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            vm.model.fieldTeamName = '';
                            vm.model.fieldTeamDescription = '';
                            vm.$validator.reset();
                            vm.handleChange();
                        }
                    }).catch(error => {
                        if (error.response.status === 400) {
                            vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                        } else {
                            console.log(error);
                        }

                    });
                }
                this.isDisabled = false;
                return false;
            }).catch(() => {
                this.isDisabled = false;
                return false
            });
        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        // Merged methods of InviteFieldTeamUser.vue
        getInvitations() {
            let vm = this;

            vm.invitations = [];

            axios.get('/field_team/get_invitations', {
                params: {
                    field_team_id: vm.selectedTeam,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.invitations = response.data.response.data.invitations;
                }
            }).catch(error => {

            });
        },
        cancelInvitation(invitationCode, index) {
            let vm = this;

            new Swal({
                title: 'Are you sure you want to cancel this invitation?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Cancel Invitation',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.get('/field_team/cancel/invitation/' + invitationCode)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(response.data.response.error.msg, '', true);
                                vm.invitations.splice(index, 1);
                            } else {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        })
                        .catch(error => {
                            if (error.response.data.response.error) {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        });
                }
            });
        },
        inviteUserToProject() {
            let vm = this;

            if (vm.selectedTeam == null) {
                alert('Please select field team');
                return;
            }
            this.$refs.inviteUser.$validator.validate('User Email').then((result) => {

                if (result) {
                    vm.invitingUser = true;
                    axios.post('/field_team/invite_user/platform', {
                        email: vm.userEmail,
                        on_team: vm.selectedTeam,
                        first_name: vm.firstName,
                        last_name: vm.lastName,
                        password: vm.password,
                        password_confirmation: vm.passwordConfirmation,
                        note: vm.userNote,
                    }).then(response => {
                        vm.invitingUser = false;
                        if (response.data.response.error.error_code === 0) {
                            vm.showAlert(response.data.response.error.msg, vm.userEmail, true);
                            vm.userEmail = '';
                            vm.firstName = '';
                            vm.lastName = '';
                            vm.userNote = '';
                            vm.password = ''
                            vm.passwordConfirmation = ''
                            vm.$validator.reset();
                            vm.invitations.push(response.data.response.data.invitation);
                        } else {
                            vm.showAlert('Alert', response.data.response.error.error_msg, false);
                        }
                    }).catch(error => {
                        console.log(error)
                        vm.showAlert('Alert', error.data.response.error.error_msg, false);
                        vm.invitingUser = false;
                    });
                }
            });

        },
        // getError: function getError(fieldName) {
        //     return this.errors.first(fieldName);
        // },
        // getFieldTeams() {
        //     let vm = this;
        //     axios.get('/field_team/teams').then(response => {
        //         console.log(response.data.response.data.teams);
        //         vm.teams = response.data.response.data.teams;
        //     }).catch(error => {

        //     });
        // },
        async handleChange() {
            const fieldTeamResponse = await axios.get(`/field_team/teams/org/${this.model.selectedOrganisation}`)
            const {response: {data: {teams}}} = fieldTeamResponse.data
            this.teams = teams
            if (this.teams.length) {
                this.selectedTeam = this.teams[0].id
                this.getInvitations()
            }
        }
        // End of merge InviteFieldTeamUser.vue
    },
    computed: {
        ...mapGetters('user', ['userOrganisations']),
        // Merged Computed of InviteFieldTeamUser.vue
        selectedFieldTeam: function () {
            if (this.selectedTeam != null) {
                let team = this.teams.filter(item => {
                    return item.id == this.selectedTeam;
                });
                return ' on Team: ' + team[0].name;
            }
        },
        getTitle: function () {
            if (this.addNewFieldTeam) {
                return "Add New Field Team"
            }
            if (this.addNewTeamUser) {
                return "Add New Field Team User"
            }
            return ""
        }

        // End of merge.
    },
    mounted() {

        if (this.$route.query.view === 'add-team-user') {
            this.addNewTeamUser = true
        }

        if (this.$route.query.view === 'add-field-team') {
            this.addNewFieldTeam = true
        }

        this.init();


    },

}

</script>

<style>
.el-select .el-input:hover .el-input__icon,
.el-select .el-input:hover input {
    color: black;
}

.el-select .el-input:hover input::placeholder {
    color: darkgrey;
}

.set_row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.invite-user-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 100%;
}

.manage-team-section {
    box-shadow: 0 8px 6px -6px gray;
    background-color: white;
    padding: 30px;
    margin: 15px;
    border: 1px solid none;
    border-radius: 35px;
    min-height: 320px;
}

.invitation-label {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
}

.invitation-table {
    width: 100%;
    margin: 15px 0px;
}

.title-row {
    padding-left: 40px;
    padding-top: 25px;
}

@media only screen and (max-device-width: 991px) {
    .set_data_table_textbox_width {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .invitation-table td {
        word-break: break-word;
    }

    .manage-team-section {
        padding: 15px;
        border-radius: 15px;
        margin: 0 0 20px;
    }

    .manage-team-section h3 {
        font-size: 1.4em;
        margin-bottom: 15px;
    }

    .title-row {
        padding-left: 15px;
        padding-top: 15px;
    }

    .align-items-center.title-row {
        justify-content: center;
    }

    .title-row h5 {
        margin-bottom: 0;
        font-size: 1.4em;
    }

    body .set_data_table_textbox_width {
        width: 100% !important;
    }
}
</style>
