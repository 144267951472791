<template>

    <div v-loading="isLoadingData" class="pm-post-mortem">
        <el-form ref="postMortemForm" :model="postMortemModal" label-width="80px" label-position="top">
            <div class="row">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-lg-6">
                            <el-form-item
                                label="Select Date Time"
                                prop="dateTime"
                                :rules="[
                                    { required: true, message: 'Please select date time', trigger: 'change' }
                                ]">
                                <el-date-picker
                                    v-model="postMortemModal.dateTime"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="Select date and time">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="col-lg-6">
                            <el-form-item
                                label="Select Veterinarian"
                                prop="vet"
                                :rules="[
                                    { required: true, message: 'Please select veterinarian', trigger: 'change' }
                                ]">
                                <el-select v-model="postMortemModal.vet" placeholder="Select">
                                    <el-option
                                        v-for="item in list.vets"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <el-form-item label="Pre vs Post" prop="prePostOp">
                                <el-radio-group v-model="postMortemModal.prePostOp">
                                    <el-radio-button label="Pre"></el-radio-button>
                                    <el-radio-button label="Post"></el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                        <div class="col-lg-8">
                            <el-form-item
                                label="Cause"
                                prop="causeOfDeath"
                                :rules="[
                                    { required: true, message: 'Cause is required' }
                                ]">
                                <el-input
                                    type="text"
                                    placeholder="Please input"
                                    v-model="postMortemModal.causeOfDeath">
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-6">
                            <el-form-item
                                label="Hours passed since death"
                                prop="durationSinceDeath"
                                :rules="[
                                    { required: true, message: 'Please select hours', trigger: 'change' }
                                ]">
                                <el-select v-model="postMortemModal.durationSinceDeath" placeholder="Select">
                                    <el-option
                                        v-for="item in list.durations"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item label="Note" prop="note">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="Please input"
                            v-model="postMortemModal.note">
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-right">
                    <button type="button" class="btn btn-success" :disabled="isSavingData||isLoadingData"
                            @click="saveOrUpdatePostmortem">{{ getButtonLabel }}
                    </button>
                </div>
            </div>
        </el-form>
    </div>

</template>

<script>
import {DatePicker, Select, Option, Input, RadioButton, RadioGroup, Form, FormItem} from 'element-ui';


export default {
    components: {
        [DatePicker.name]: DatePicker,
        [Select.name]: Select,
        [Option.name]: Option,
        [Input.name]: Input,
        [RadioButton.name]: RadioButton,
        [RadioGroup.name]: RadioGroup,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    data()
    {
        return {
            isLoadingData: false,
            isSavingData: false,

            postMortemModal: {
                dateTime: '',
                vet: '',
                prePostOp: 'Post',
                causeOfDeath: '',
                durationSinceDeath: '',
                note: '',
            },

            list: {
                vets: [],

                durations: [
                    {
                        label: '0-6 hrs',
                        value: '0-6 hrs',
                    },
                    {
                        label: '6-12 hrs',
                        value: '6-12 hrs',
                    },
                    {
                        label: '12-18 hrs',
                        value: '12-18 hrs',
                    },
                    {
                        label: '18-24 hrs',
                        value: '18-24 hrs',
                    },
                    {
                        label: '24-36 hrs',
                        value: '24-36 hrs',
                    },
                    {
                        label: '36-48 hrs',
                        value: '36-48 hrs',
                    },
                    {
                        label: '48-72 hrs',
                        value: '48-72 hrs',
                    },
                    {
                        label: '> 72 hrs',
                        value: '> 72 hrs',
                    },
                ]

            },

        }
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        recordId: {
            type: String,
            required: true
        }
    },
    methods: {
        saveOrUpdatePostmortem()
        {
            this.$refs['postMortemForm'].validate((valid) => {
                if (valid) {
                    let vm = this;

                    vm.isSavingData = true

                    let url = "";

                    if (vm.id != null) {
                        if (vm.id > 0) {
                            url = '/postmortem/update/' + vm.recordId + '/' + vm.id;
                        } else {
                            url = '/postmortem/add/' + vm.recordId;
                        }
                    } else {
                        url = '/postmortem/add/' + vm.recordId;
                    }

                    axios.post(url, {
                        date_time: vm.postMortemModal.dateTime,
                        cause_of_death: vm.postMortemModal.causeOfDeath,
                        pre_or_post: vm.postMortemModal.prePostOp,
                        vet_name: vm.postMortemModal.vet,
                        duration: vm.postMortemModal.durationSinceDeath,
                        note: vm.postMortemModal.note,
                    }).then(response => {
                        vm.isSavingData = false
                        if (response.data.response.error.error_code == 0) {

                            this.$root.$emit('HistoryTableUpdateRecords') //like this
                            if (vm.id > 0) {
                                vm.$message({
                                    message: 'Postmortem Details Updated Successfully.',
                                    type: 'success'
                                });
                            } else {
                                vm.clearModalData()
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        }
                    }).catch(e => {
                        vm.isSavingData = false
                        console.log(e);
                    });
                } else {
                    return false;
                }
            });
        },
        clearModalData()
        {
            this.postMortemModal.dateTime = ''
            this.postMortemModal.vet = ''
            this.postMortemModal.prePostOp = 'Post'
            this.postMortemModal.causeOfDeath = ''
            this.postMortemModal.durationSinceDeath = ''
            this.postMortemModal.note = ''
            this.$refs['postMortemForm'].resetFields();
        },
        init()
        {
            if (this.id != null) {
                this.getPostmortemDetails();
            }
            this.getVets();
        },
        getVets()
        {
            var vm = this;
            axios.get("/organisation/get/vets", {
                params: {
                    organisation_id: vm.$store.getters.getCurrentSelectedProject.organisation_id,
                },
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.list.vets = []
                    var v = response.data.response.data.vets;
                    for (let i = 0; i < v.length; i++) {
                        vm.list.vets.push({
                            value: v[i].first_name + ' ' + v[i].last_name,
                            label: v[i].first_name + ' ' + v[i].last_name
                        });
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getPostmortemDetails()
        {
            let vm = this;

            vm.isLoadingData = true

            axios.get('/postmortem/details/' + vm.id)
                .then(response => {
                    vm.isLoadingData = false
                    if (response.data.response.error.error_code == 0) {
                        let record = response.data.response.data.postmortem;
                        vm.postMortemModal.dateTime = record.date_time
                        vm.postMortemModal.vet = record.vet_name
                        vm.postMortemModal.prePostOp = record.pre_or_post
                        vm.postMortemModal.causeOfDeath = record.cause_of_death
                        vm.postMortemModal.durationSinceDeath = record.duration
                        vm.postMortemModal.note = record.note
                    }
                })
                .catch(e => {
                    vm.isLoadingData = false
                    console.log(e)
                })
        }
    },
    computed: {
        getButtonLabel: function () {
            if (this.id != null) {
                if (this.id > 0) {
                    return "UPDATE"
                } else {
                    return "SAVE"
                }
            } else {
                return "SAVE"
            }
        }
    },
    mounted()
    {
        this.init();
    }

}

</script>

<style scoped>

</style>
