<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <el-row class="setMarginTop">
            <!-- <div class="container"> -->
            <el-col>
                <div class="panel-heading">
                    <!-- <h3>Vaccination Area</h3> -->
                    <h5>Records > Vaccination > Map {{ activeAreaName }}</h5>
                    <hr>
                </div>
            </el-col>
            <!-- </div> -->
        </el-row>

        <div class="row">

        </div>

        <div class="row set_div_map">
            <div class="col-sm-12">
                <p style="margin-bottom: 10px;margin-top: 4px;">Filters</p>
            </div>
            <div v-bind:class="datePickerStyleClass">
                <span @click="showModal=false">
                    <v-md-date-range-picker v-show="filterByDate" :presets="dateRangePresets" :start-date="startDatePast" :end-date="endDatePast"
                                            @change="handleChange"></v-md-date-range-picker>
                    <el-checkbox v-model="filterByDate">Date Range</el-checkbox>
                </span>
            </div>
            <div class="col-xs-12 col-md-2 col-xl-2">
                <fg-input type="text" placeholder="HSIApps Animal ID" name="uniqueid" value=""
                          v-model="uniqueid"></fg-input>
            </div>
            <div class="col-xs-12 col-md-3 col-xl-3">
                <fg-input type="text" placeholder="RFID" name="rfid" v-model="rfid"></fg-input>
            </div>
            <div v-if="projectLevelView" class="col-xs-12 col-md-3 col-xl-3">
                <div class="form-group">
                    <template>
                        <el-select v-model="selectedAreaId" filterable placeholder="Vaccination Area"
                                   class="el_select_width">
                            <el-option
                                    label="All"
                                    value="All">
                            </el-option>
                            <el-option
                                    v-for="item in area"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
        </div>
        <div class="row set_div_map">
            <div class="col-sm-12">
                <p style="margin-bottom: 10px;margin-top: 4px;">Location</p>
            </div>
            <div class="col-xs-12 col-md-3 col-xl-2">
                <div class="form-group">
                    <template>
                        <el-select v-model="selectFilterCountry" filterable placeholder="Country"
                                   class="el_select_width" :autocomplete="randomStringForAutoComplete">
                            <el-option
                                    label="All"
                                    value="All"
                            >
                            </el-option>
                            <el-option
                                    v-for="item in countryArr"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 col-xl-3">
                <div class="form-group">
                    <template>
                        <el-select v-model="selectFilterState" filterable placeholder="State/Province"
                                   class="el_select_width" :autocomplete="randomStringForAutoComplete">
                            <el-option
                                    label="All"
                                    value="All"
                            >
                            </el-option>
                            <el-option
                                    v-for="item in stateArr"
                                    :key="item+1"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 col-xl-3">
                <div class="form-group">
                    <template>
                        <el-select v-model="selectFilterCity" filterable placeholder="City/District/Village"
                                   class="el_select_width" autocomplete="off"
                                   :autocomplete="randomStringForAutoComplete">
                            <el-option
                                    label="All"
                                    value="All"
                            >
                            </el-option>
                            <el-option
                                    v-for="item in cityArr"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-xs-12 col-md-3 col-xl-1">
                <button v-bind:disabled="!onLine" type="button" class="btn btn-primary"
                        @click="getFilterRecords()">Filter
                </button>
            </div>
            <div class="col-xs-12 col-md-3 col-xl-1">
                <button v-bind:disabled="!onLine" type="button" class="btn btn-warning"
                        @click="clearFilters()">Clear Filters
                </button>
            </div>
        </div>
        <br>
        <div class="card setShadow set_redius set_div_maps" v-if="showModal">
            <div class="card-body" style="text-align: center; background-color: #eee">
                <button type="button" class="close close_modal" @click="showModal=false">
                    <span class="close-icon" aria-hidden="true">&times;</span>
                </button>
                <!--<h5>Detail</h5>-->
                <p style="margin-bottom: 10px;"><img :src="image_url" style="height: 80px;width: 80px;"/></p>
                <div class="set_p_btn">
                    <span class="span_font">HSIApps ID</span>
                    <p class="p_font">{{ hsapps_id }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">RF - ID</span>
                    <p class="p_font">{{ rf_id }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Gender</span>
                    <p class="p_font">{{ gender }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Last detail Location</span>
                    <p class="p_font">{{ vaccination_location_lat_lng }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Last Vaccination Date</span>
                    <p class="p_font">{{ vaccination_date }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Animal Type</span>
                    <p class="p_font">{{ animal_type }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Lactating/Young</span>
                    <p class="p_font">{{ reproductive_status }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Note</span>
                    <p class="p_font">{{ (note != '' ? note : '-NA-') }}</p>
                </div>
                <!--                <div class="set_p_btn">
                                    <span class="span_font">Owner Detail</span>
                                    <p class="p_font">{{ (ownerInfo != '' ? ownerInfo : '-NA-') }}</p>
                                </div>-->
            </div>
        </div>
        <div class="row setMarginTop">
            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="card setShadow">
                    <div class="card-body" style="padding: 10px;">
                        <div class="legend-div">

<!--                            <label class="label-color" style="background-color: #0000ff;"></label>-->
<!--                            <span class="type-span">Young Male</span>-->
<!--                            &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                            <label class="label-color" style="background-color: #add8e6;"></label>-->
<!--                            <span class="type-span">Adult Male</span>-->
<!--                            &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                            <label class="label-color" style="background-color: #90ee90;"></label>-->
<!--                            <span class="type-span">Young Female</span>-->
<!--                            &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                            <label class="label-color" style="background-color: #006400;"></label>-->
<!--                            <span class="type-span">Adult Female</span>-->
<!--                            &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                            <label class="label-color" style="background-color: #ff0000;"></label>-->
<!--                            <span class="type-span">Adult Female Lactating</span>-->
<!--                            &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                            <label class="label-color" style="background-color: #808080;"></label>-->
<!--                            <span class="type-span">Unknown</span>-->

                            <label class="label-color" style="background-color: #0000ff;"></label>
                            <span class="type-span">Male</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <label class="label-color" style="background-color: #fff600;"></label>
                            <span class="type-span">Female</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <label class="label-color" style="background-color: #ff0000;"></label>
                            <span class="type-span">Female Lactating</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <label class="label-color" style="background-color: #808080;"></label>
                            <span class="type-span">Unknown</span>

                        </div>
                        <GmapMap
                                ref="mapRef"
                                gestureHandling="greedy"
                                :center="center"
                                :options="googleMapOptions"
                                :zoom="3"
                                style="width: 100%; height: 800px">
                            <GmapCluster :zoom-on-click="true" max-zoom="21">
                                <gmap-marker
                                        :key="index"
                                        v-for="(m, index) in markers"
                                        :position="m.postion"
                                        :icon="m.icon"
                                        @click="markersclick(m.recordDetails)">
                                </gmap-marker>
                            </GmapCluster>
                        </GmapMap>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col col-sm-12">
                <p>Found total {{ markers.length }} record(s).</p>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../../mixins/GlobalMixin';
import dateRangeDefaultPresetsMixin from '../../mixins/DateRangeDefaultPresetsMixin';
import {MAP_STYLE} from './Maps/MAP_STYLE';
import AreaCard from "./UIComponents/Cards/AreaCard.vue";
import {Select, Option, Row, Col, Checkbox} from 'element-ui';
import VOffline from 'v-offline';
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";

Vue.use(VMdDateRangePicker);

export default {
    components: {
        "area-card": AreaCard,
        [Option.name]: Option,
        [Select.name]: Select,
        [Checkbox.name]: Checkbox,
        [Row.name]: Row,
        [Col.name]: Col,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
        areaId: {
            type: Number,
        },
        projectLevel: {
            type: Boolean,
            default: false,
        }
    },
    data()
    {
        return {
            datePickerStyleClass: 'col-xs-12 col-md-2 col-xl-2',
            googleMapOptions: {
                scrollwheel: true,
                disableDefaultUI: true,
                styles: MAP_STYLE
            },
            filterByDate: false,
            country: '',
            selectedAreaId: "",
            selectFilterCountry: "",
            selectFilterState: "",
            selectFilterCity: "",
            project: null,
            area: [],
            startDate: '',
            endDate: '',
            markers: [],
            layers: [],
            center: {lat: 33.8291523, lng: -116.8004315},
            level1Pop: false,
            level2Pop: false,
            areaPop: false,
            showModal: false,
            uniqueid: '',
            rfid: '',
            //Details popup Start
            image_url: '',
            hsapps_id: '',
            rf_id: '',
            gender: '',
            vaccination_location_lat_lng: '',
            vaccination_date: '',
            animal_type: '',
            reproductive_status: '',
            note: '',
            ownerInfo: '',
            startDatePast: '',
            endDatePast: '',
            //Details popup End
            countryArr: [],
            stateArr: [],
            cityArr: [],
            activeAreaName: '',
            randomStringForAutoComplete: null,
            projectLevelView: false,
            dateRangePresets: []
        }
    },
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    methods: {
        FilterAreas()
        {
            //console.log("filter click");
        },
        markersclick(pinDetail)
        {
            this.showModal = true;
            this.image_url = pinDetail.image_url;
            this.hsapps_id = pinDetail.hsapps_id;
            this.rf_id = pinDetail.rf_id;
            this.gender = pinDetail.gender;
            this.vaccination_location_lat_lng = pinDetail.vaccination_location_lat_lng;
            this.vaccination_date = pinDetail.vaccination_date;
            this.animal_type = pinDetail.ownership;
            this.reproductive_status = pinDetail.reproductive_status;
            this.note = pinDetail.note;
        },
        Level1droppopup()
        {
            this.level1Pop = true;
            this.level2Pop = false;
            this.areaPop = false;
        },
        Level2droppopup()
        {
            this.level2Pop = true;
            this.level1Pop = false;
            this.areaPop = false;
        },
        Areadroppopup()
        {
            this.areaPop = true;
            this.level1Pop = false;
            this.level2Pop = false;
        },
        hideDropPop()
        {
            this.level1Pop = false;
            this.level2Pop = false;
            this.areaPop = false;
        },
        handleChange(values)
        {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.startDate = sDate;
            this.endDate = eDate;
            console.log("START DATE: " + this.startDate);
            console.log("END DATE: " + this.endDate);
        },
        formatDate(date)
        {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        getArea()
        {
            axios.get("/area?project_id=" + this.projectId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        this.area = response.data.response.data;
                        this.plotAllKmlLayers(this.area);
                        var data = response.data.response.data;
                        data.map((item) => {
                            this.countryArr.push(item.google_country);
                            this.stateArr.push(item.google_admin_area_level1);
                            this.cityArr.push(item.google_locality);
                        });
                        // var uniqueCountry = this.countryArr.filter(function(elem, index, self) {
                        //   console.log(self);
                        //   console.log(elem);
                        //     return index === self.indexOf(elem);
                        // });
                        let uNames = {};
                        this.countryArr.forEach(n => {
                            let lower = n.toLowerCase();
                            if (!uNames[lower]) {
                                uNames[lower] = n;
                            }
                        });

                        this.countryArr = Object.values(uNames);

                        var uniqueState = this.stateArr.filter(function (elem, index, self) {
                            if (elem != null && elem != "null") {
                                return index === self.indexOf(elem);
                            }
                        });
                        var uniqueCity = this.cityArr.filter(function (elem, index, self) {
                            if (elem != null && elem != "null") {
                                return index === self.indexOf(elem);
                            }
                        });
                        // this.countryArr = uniqueCountry;
                        this.stateArr = uniqueState;
                        this.cityArr = uniqueCity;
                    }
                })
                .catch(error => {
                    if (error.response.data.response.error) {

                    }
                });
        },
        clearFilters()
        {
            let vm = this;
            vm.startDate = '';
            vm.endDate = '';
            vm.uniqueid = '';
            vm.selectedAreaId = '';
            vm.rfid = '';
            vm.selectFilterCountry = "";
            vm.selectFilterState = "";
            vm.selectFilterCity = "";
            vm.getFilterRecords();
        },
        getFilterRecords()
        {
            let vm = this;

            if (vm.selectFilterCountry == "All") {
                vm.selectFilterCountry = "";
            }

            if (vm.selectFilterState == "All") {
                vm.selectFilterCountry = "";
            }

            if (vm.selectFilterCity == "All") {
                vm.selectFilterCountry = "";
            }

            if (vm.selectedAreaId == "All") {
                vm.selectFilterCountry = "";
            }


            vm.markers = [];
            if (vm.projectLevelView) {
                axios.get('/vaccination/get_records/project/filter/' + this.projectId, {
                    params: {
                        start_date: vm.startDate,
                        end_date: vm.endDate,
                        hsapps_id: vm.hsappsId,
                        selected_area_id: vm.selectedAreaId,
                        rfid: vm.rfid,
                        selected_country: vm.selectFilterCountry,
                        selected_state: vm.selectFilterState,
                        selected_city: vm.selectFilterCity,
                    }
                }).then(response => {
                    if (response.data.response.error.error_code == 0) {
                        var records = response.data.response.data.records;
                        let recordCount = 0;
                        for (var i = 0; i < records.length; i++) {
                            var vaccinationsArr = records[i].vaccinations;
                            recordCount = recordCount + vaccinationsArr.length;
                            for (var j = 0; j < vaccinationsArr.length; j++) {
                                var vaccination_location_lat_lng = vaccinationsArr[j].vaccination_location_lat_lng;
                                let latLng = vaccination_location_lat_lng.split(',');
                                var latitude = latLng[0];
                                var longitude = latLng[1];
                                var gender = vaccinationsArr[j].animal.gender;
                                var age_category = vaccinationsArr[j].age_category;
                                var details = vaccinationsArr[j];
                                var recordMapping = {
                                    image_url: details.image_url,
                                    hsapps_id: details.animal.hsapps_id,
                                    rf_id: details.animal.rf_id,
                                    gender: gender,
                                    vaccination_location_lat_lng: details.vaccination_location_lat_lng,
                                    vaccination_date: details.vaccination_date,
                                    type: details.type,
                                    reproductive_status: details.reproductive_status,
                                    note: details.note,
                                    vaccine_assistant: details.vaccine_assistant
                                }
                                var obj = null;
                                obj = vm.pushMarkersOnMap(gender, latitude, longitude, age_category, recordMapping, details.reproductive_status);
                                if (obj != null) {
                                    vm.markers.push(obj);
                                }

                            }
                            if (vm.markers.length > 0) {
                                let lat = vm.markers[vm.markers.length - 1].postion.lat;
                                let lng = vm.markers[vm.markers.length - 1].postion.lng;
                                vm.setMapCenter(lat + "," + lng);
                            }
                        }
                        if (recordCount == 0) {
                            alert("No records found in project!");
                        }
                    }
                });
            } else {
                axios.get('/vaccination/get_records/filter/' + this.areaId, {
                    params: {
                        start_date: vm.startDate,
                        end_date: vm.endDate,
                        hsapps_id: vm.hsappsId,
                        rfid: vm.rfid,
                    }
                }).then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.uniqueid = '';
                        vm.rfid = '';
                        var records = response.data.response.data.records;
                        let recordCount = 0;
                        let vaccinationsAreaArr = response.data.response.data.records;
                        for (var j = 0; j < vaccinationsAreaArr.length; j++) {
                            recordCount = recordCount + vaccinationsAreaArr.length;
                            var vaccination_location_lat_lng = vaccinationsAreaArr[j].vaccination_location_lat_lng;
                            let latLng = vaccination_location_lat_lng.split(',');
                            var latitude = latLng[0];
                            var longitude = latLng[1];
                            var gender = vaccinationsAreaArr[j].gender;
                            var age_category = vaccinationsAreaArr[j].age_category;
                            var details = vaccinationsAreaArr[j];
                            var recordMapping = {
                                image_url: details.image_url,
                                hsapps_id: details.hsapps_id,
                                rf_id: details.rf_id,
                                gender: gender,
                                vaccination_location_lat_lng: details.vaccination_location_lat_lng,
                                vaccination_date: details.vaccination_date,
                                type: details.type,
                                reproductive_status: details.reproductive_status,
                                note: details.note,
                                vaccine_assistant: details.vaccine_assistant
                            }
                            var obj = null;
                            obj = vm.pushMarkersOnMap(gender, latitude, longitude, age_category, recordMapping, details.reproductive_status);
                            if (obj != null) {
                                vm.markers.push(obj);
                            }
                        }
                        if (vm.markers.length > 0) {
                            let lat = vm.markers[vm.markers.length - 1].postion.lat;
                            let lng = vm.markers[vm.markers.length - 1].postion.lng;
                            vm.setMapCenter(lat + "," + lng);
                        }
                        if (recordCount == 0) {
                            alert("No records found in this area!");
                        }
                    }
                });
            }


        },
        pushMarkersOnMap(gender, latitude, longitude, age_category, recordMapping, reproductiveStatus)
        {
            var obj = null;
            if (gender.toLowerCase() == "male") {
                obj = {
                    postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                    icon: "https://hsapps-prod-media.s3.amazonaws.com/dog_markers/blue_dog.png",
                    recordDetails: recordMapping
                }
                if (age_category.toLowerCase() == "young") {
                    obj = {
                        postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                        icon: "https://hsapps-prod-media.s3.amazonaws.com/dog_markers/blue_dog.png",
                        recordDetails: recordMapping
                    }
                } else
                    if (age_category.toLowerCase() == "adult") {
                        obj = {
                            postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                            icon: "https://hsapps-prod-media.s3.amazonaws.com/dog_markers/blue_dog.png",
                            recordDetails: recordMapping
                        }
                    }
            } else
                if (gender.toLowerCase() == "female") {
                    obj = {
                        postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                        icon: "https://hsapps-prod-media.s3.amazonaws.com/dog_markers/yellow_dog.png",
                        recordDetails: recordMapping
                    }
                    if (age_category.toLowerCase() == "young") {
                        obj = {
                            postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                            icon: "https://hsapps-prod-media.s3.amazonaws.com/dog_markers/yellow_dog.png",
                            recordDetails: recordMapping
                        }
                    } else
                        if (age_category.toLowerCase() == "adult") {
                            obj = {
                                postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                                icon: "https://hsapps-prod-media.s3.amazonaws.com/dog_markers/yellow_dog.png",
                                recordDetails: recordMapping
                            }
                        }

                        if(reproductiveStatus.toLowerCase()=="lactating"){
                            obj = {
                                postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                                icon: "https://hsapps-prod-media.s3.amazonaws.com/dog_markers/red_dog.png",
                                recordDetails: recordMapping
                            }
                        }

                } else {
                    obj = {
                        postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                        icon: "https://hsapps-prod-media.s3.amazonaws.com/dog_markers/grey_dog.png",
                        recordDetails: recordMapping
                    }
                }
            return obj;
        },
        plotAllKmlLayers()
        {
            var vm = this;
            for (var l = 0; l < vm.layers.length; l++) {
                vm.layers[l].setMap(null);
            }
            vm.layers = [];
            this.$refs.mapRef.$mapPromise.then((map) => {
                for (var i = 0; i < vm.project.areas.length; i++) {
                    var layer = null;
                    if (vm.selectedAreaId == 'All') {
                        layer = new google.maps.KmlLayer({
                            url: vm.project.areas[i].file_url,
                            suppressInfoWindows: true,
                            preserveViewport: true,
                            clickable: false,
                            visibility: "visible",
                        });
                        layer.setMap(map);
                        vm.layers.push(layer);
                    } else
                        if (vm.selectedAreaId == vm.project.areas[i].id) {
                            layer = new google.maps.KmlLayer({
                                url: vm.project.areas[i].file_url,
                                suppressInfoWindows: true,
                                preserveViewport: true,
                                clickable: false,
                                visibility: "visible",
                            });
                            layer.setMap(map);
                            vm.layers.push(layer);
                        }

                }
            });
        },
        setMapCenter(location)
        {
            console.log("Map center: " + location);
            let latLng = location.split(',');
            var latitude = latLng[0];
            var longitude = latLng[1];

            this.center = {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude)
            };
        },
        disableAutoFill()
        {
            this.randomStringForAutoComplete = this.generateRandomString(10)
        },
        generateRandomString(length)
        {
            // add it to autocomplete attribute so browser can't save it.(it's the best solution for now. Maybe in future we get a better solution.)
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;

        },
    },
    computed: {},
    created()
    {
        var self = this
        document.querySelector('body').addEventListener('click', function (e) {
            if (self.showModal === true) {
                //console.log("if");
                //document.querySelector('.close_modal').click()
            }
        }, false)
    },
    mounted()
    {
        let vm = this;

        if (vm.projectLevel) {
            localStorage.setItem('vaccinationProjectLevelViewMap', 1);
        }

        vm.project = vm.$store.getters.getCurrentSelectedProject;
        if (localStorage.getItem('vaccinationProjectLevelViewMap') == 1) {
            vm.activeAreaName = "> Project Level View";
            vm.projectLevelView = true;
        } else {
            vm.activeAreaName = "> Area (" + localStorage.getItem('VaccinationAreaName') + ")";
            vm.projectLevelView = false;
            vm.selectedAreaId = localStorage.getItem('VaccinationAreaId');
        }
        this.projectId = vm.project.id;
        //Bus.$emit("projectype", vm.project);
        try {
            setTimeout(function () {
                var x = document.getElementsByTagName("label")[0].textContent = "";
            }, 50);
        } catch (e) {
        }
        vm.getArea();
        vm.getFilterRecords();
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.startDatePast = firstDay.toLocaleDateString('en-US');
        this.endDatePast = lastDay.toLocaleDateString('en-US');
        this.disableAutoFill();
        this.dateRangePresets = this.getDateRangePresets();
    },
    watch: {
        filterByDate: function (val) {
            if (val) {
                this.datePickerStyleClass = 'col-xs-12 col-md-4 col-xl-4';
            } else {
                this.datePickerStyleClass = 'col-xs-12 col-md-2 col-xl-2';
            }
        }
    }
}

</script>
<style lang="scss" scoped>


.el-select .el-input:hover .el-input__icon,
.el-select .el-input:hover input {
  color: #66615B !important;
}

.el-select .el-input:hover .el-input__icon::-webkit-input-placeholder,
.el-select .el-input:hover input::-webkit-input-placeholder {
  color: #66615B !important;
}

.el-select .el-input:hover .el-input__icon::-ms-input-placeholder,
.el-select .el-input:hover input::-ms-input-placeholder {
  color: #66615B !important;
}

.el-select .el-input:hover .el-input__icon::placeholder,
.el-select .el-input:hover input::placeholder {
  color: #66615B !important;
}

.form-group .el-input__inner[readonly] {
  background-color: white !important;
}

.setShadow {
  box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.btn {
  margin: 0px !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background-color: #FFFFFF;
  border: 0 none;
  border-radius: 0px;
  margin-top: 0px;
  padding: 15px;
  min-width: 20rem !important;
}

.navbar-nav .dropdown-menu:after, .dropdown .dropdown-menu[x-placement="bottom-start"]:after, .dropdown .dropdown-menu[x-placement="bottom-end"]:after, .card.card-just-text .dropdown .dropdown-menu:after, .card-just-text .dropdown .dropdown-menu:after, .dropdown-btn .dropdown-menu:after {
  display: none;
}

.navbar-nav .dropdown-menu:before, .dropdown .dropdown-menu[x-placement="bottom-start"]:before, .dropdown .dropdown-menu[x-placement="bottom-end"]:before, .card.card-just-text .dropdown .dropdown-menu:before, .card-just-text .dropdown .dropdown-menu:before, .dropdown-btn .dropdown-menu:before {
  display: none;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

/* .set_div_map{
 position: absolute;
  z-index: 10000000;
  opacity: 1.5;
  width: calc(100% - 3.5%);
  padding: 15px;
  margin: 0px;
} */
@media (min-width: 1440px) and (max-width: 1441px) {
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 22% !important;
  }
}

.img_marker_popup {
  height: 70px;
  width: 70px;
}

.set_redius {
  border-radius: 0px !important;
  background: transparent !important;
}

.set_p_btn {
  /* background-color: darkgrey; */
  background-color: white;
  margin-bottom: 10px;
}

.p_font {
  font-size: 17px;
}

.span_font {
  font-size: 13px;
}

.label-color {
  width: 12px;
  height: 12px;
  margin-bottom: 0px !important;
  border-radius: 3px;
  margin-right: 5px;
}

.legend-div {
  margin: 0px 0px 8px 0px;
}

.type-span {
  margin-right: 5px;
  font-size: 15px;
}

.close-icon {
  font-size: 32px;
}

.set_div_maps {
  top: 34%;
  left: 5%;
  position: absolute;
  z-index: 1;
  opacity: 1.5;
  width: 25%;
  border: 1px solid #ddd;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .set_div_maps {
    top: 31%;
    left: 7%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .set_div_maps {
    top: 48%;
    left: 9%;
    position: absolute;
    z-index: 1;
    opacity: 1.5;
    width: 42%;
    border: 1px solid #ddd;
  }
}
</style>
