<template>
    <div class="card img-left card_pos">
        <div class="card-header row">
            <!-- <div class="col-xs-6 col-sm-6 col-md-3">
            <div class="area-img">
               <img :src="areaImage"/>
            </div>
         </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12">

                <div class="area-details">
                    <div class="row">
                        <span class="area-title col-sm-12">
                            {{ title }}
                        </span>
                    </div>
                    <hr>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Created On:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ formatDate(createdOn) }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Country:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ country }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>State/Province:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ areaState }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>City/District/Village:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ locality }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Survey Goal ({{ surveyStatus.target_percentage }}%):</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ getPercentageCovered }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Vaccinations:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ vaccinationCount }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Surveyed Animals:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ surveyCount }}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="area-card-footer row set_row">
            <div class="col-xs-12 col-sm-12 footer_div">
                <!-- <span class="area-contributors footer_tags"
                    >Animals Vaccinated</span
                > -->
                <p class="footer_tags text-left">
                    <b-icon-circle-fill
                            :style="{ color: vaccinationStatus.color }"
                    />
                    {{ vaccinationStatus.text }}
                </p>
                <p class="footer_tags text-left">
                    <b-icon-triangle-fill
                            :style="{ color: surveyStatus.color }"
                    />
                    {{ surveyStatus.text }}
                </p>
                <!-- <span v-show="true">
                    this will only appear if status color is red.</span
                > -->
            </div>
        </div>
    </div>
</template>
<script>
import {BIconTriangleFill, BIconCircleFill} from "bootstrap-vue";

export default {
    components: {
        BIconTriangleFill,
        BIconCircleFill
    },
    name: "card",
    props: {
        areaId: {
            type: Number
        },
        title: {
            type: String,
            description: "Card title",
            default: "area Title"
        },
        createdOn: {
            type: String,
            default: "N/A"
        },
        country: {
            type: String,
            default: "N/A"
        },
        areaState: {
            type: String,
            default: "N/A"
        },
        locality: {
            type: String,
            default: "0"
        },
        vaccinationCount: {
            type: Number,
            default: 0
        },
        surveyCount: {
            type: Number,
            default: 0
        },
        fieldUsers: {
            type: [String, Number],
            default: "0"
        },
        areaImage: {
            type: String,
            default: "https://vuejs.org/images/logo.png"
        },
        submitter_name: {
            type: String,
            default: ""
        },
        areaStatus: {
            type: Number,
            default: 0
        },
        statusColor: {
            type: String,
            default: "black"
        },
        surveyStatus: {
            type: Object
        },
        vaccinationStatus: {
            type: Object
        }
    },
    methods: {
        formatDate(date)
        {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
    },
    computed: {
        getPercentageCovered: function () {

            if (this.surveyCount <= 10) {
                return "Not enough data";
            } else {
                return this.surveyStatus.percentage_covered + "%";

            }
        },
        areaDetails: function () {
            return "area/details/" + this.areaId;
        }
        //    areaStatusColor: function () {
        //         let vm = this;
        //         switch (vm.areaStatus) {
        //             case 0:
        //                 return 'black';
        //             case 1:
        //                 return 'yellow';
        //             case 2:
        //                 return 'blue';
        //             case 3:
        //                 return 'green';
        //             case 4:
        //                 return 'red';
        //             case 5:
        //                 return 'orange';
        //             case -1:
        //                 return 'grey';

        //         }
        //     }
    }
};
</script>
<style scoped>
.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.area-img {
    margin-right: 15px;
    width: 80px;
}

.area-details {
    margin-bottom: 15px;
}

.area-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    color: #51cbce;
}

.area-info-text {
    margin: 0;
}

.area-contributors {
    text-align: right;
}

.a_title {
    text-align: center;
    color: #51cbce;
}

/* .card_pos{
       height: 185px;
   }
   @media (min-width: 768px)and (max-width: 1025px){
      .card_pos{
       height: 230px;
     }
  } */
.set_row {
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
}

.area-card-footer {
    /* background-color: #66615b; */
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    padding: 5px;
    background: ghostwhite;
}

.footer_tags {
    /* color: white; */
    margin-bottom: 0px !important;
}

.footer_div {
    text-align: center !important;
}

/* @media (max-width: 768px){
    .footer_div{
        text-align: center !important;
    }
  } */
</style>
