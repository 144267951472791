<template>
  <div class="validate-verify-url">
    Loading...
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    query: {
      type: Object,
    },
  },
  methods: {
    joinOrgProject() {
      let vm = this;
      axios
        .post(
          `/organisation/join/project`, {
            //email: this.query.email,
            invitation_code: this.query.code
          }
        )
        .then((response) => {
          this.removeValidateUrl();
          if (response.data.response.error.error_code === 0) {
            vm.showAlert(
              "Success",
              "Organisation project joined successfully",
              true,
              function(confirm) {
                vm.$router.replace({ name: "platform" });
              }
            );
          } else if (response.data.response.error.error_code === 1) {
            vm.showAlert(
              "Error",
              response.data.response.error.error_msg,
              false,
              function(confirm) {
                vm.$router.replace({ name: "platform" });
              }
            );
          }
        })
        .catch((error) => {
          this.removeValidateUrl();
          vm.showAlert(
            "Error",
            error.data.response.error.error_msg,
            false,
            function(confirm) {
              vm.$router.replace({ name: "platform" });
            }
          );
        });
    },
      joinExtProject() {
          let vm = this;
          axios
              .post(
                  `/organisation/join/ext/project`, {
                      //email: this.query.email,
                      invitation_code: this.query.code
                  }
              )
              .then((response) => {
                  this.removeValidateUrl();
                  if (response.data.response.error.error_code === 0) {
                      vm.showAlert(
                          "Success",
                          "Project joined successfully",
                          true,
                          function(confirm) {
                              vm.$router.replace({ name: "platform" });
                          }
                      );
                  } else if (response.data.response.error.error_code === 1) {
                      vm.showAlert(
                          "Error",
                          response.data.response.error.error_msg,
                          false,
                          function(confirm) {
                              vm.$router.replace({ name: "platform" });
                          }
                      );
                  }
              })
              .catch((error) => {
                  this.removeValidateUrl();
                  vm.showAlert(
                      "Error",
                      error.data.response.error.error_msg,
                      false,
                      function(confirm) {
                          vm.$router.replace({ name: "platform" });
                      }
                  );
              });
      },
      updateUserEmail(){
          let vm = this;
          axios
              .post(`/user/verify/updated/email/${this.query.code}`)
              .then((response) => {
                  this.removeValidateUrl();
                  if (response.data.response.error.error_code === 0) {
                      vm.showAlert(
                          "Success",
                          response.data.response.error.msg,
                          true,
                          function(confirm) {
                              vm.$router.replace({ name: "platform" });
                          }
                      );
                  } else if (response.data.response.error.error_code === 1) {
                      vm.showAlert(
                          "Error",
                          response.data.response.error.error_msg,
                          false,
                          function(confirm) {
                              vm.$router.replace({ name: "platform" });
                          }
                      );
                  }
              })
              .catch((error) => {
                  this.removeValidateUrl();
                  vm.showAlert(
                      "Error",
                      error.data.response.error.error_msg,
                      false,
                      function(confirm) {
                          vm.$router.replace({ name: "platform" });
                      }
                  );
              });
      },

      removeValidateUrl() {
      localStorage.removeItem('validateUrl');
    }
  },
  created() {
    const type = this.query.type;
    if (this.$store.getters.isAuthenticated) {
      window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.getJwt;
      switch (type) {
      case "org_project_invitation":
          this.joinOrgProject();
          break;
      case "ext_project_invitation":
          this.joinExtProject();
          break;
      case "email_update":
          this.updateUserEmail();
          break;

      default:
          break;
      }
    } else {
      const data = {
        route: 'validate',
        query: this.query
      }
      const vm = this;
      localStorage.setItem('validateUrl', JSON.stringify(data));
      vm.showAlert(
        "Welcome!",
        'To join this invitation, Please login if you already have an account with this email id or sign up if you have not yet registered',
        null,
        function(confirm) {
          vm.$router.replace({name: 'login'});
        }
      );
    }
  },
};
</script>
<style></style>
