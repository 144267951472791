<template>

    <div class="container-fluid" style="padding: 25px; background-color: wheat">

        <div class="col-sm-10">
            <p>Select Animal Image ({{ uploadingLabel }})</p>
            <image-upload
                :target="getImageUploadUrl"
                selector="example"
                :auto-push="true">
            </image-upload>
            <br/>
        </div>

        <fieldset>
            <div class="form-group">
                <div class="col-sm-12 col-md-6">
                    <label @click="modifyUrl=!modifyUrl">{{ uploadURL }} (Change
                        URL)</label>
                    <fg-input
                        v-if="modifyUrl"
                        type="text"
                        label="Image URL"
                        name="Image URL"
                        v-model="uploadURL">
                    </fg-input>
                </div>
            </div>
            <br/>

        </fieldset>

        <fieldset>
            <div class="form-group">
                <div class="col-sm-12 col-md-4">
                    <fg-input
                        type="text"
                        label="RFID"
                        name="RFID"
                        v-model="record.rfid">
                    </fg-input>
                </div>
            </div>
            <br/>

        </fieldset>

        <fieldset>
            <div class="form-group">
                <div class="col-sm-12 col-md-3">
                    <fg-input
                        type="text"
                        label="Location Point"
                        name="Location Point"
                        v-model="record.locationPoints">
                    </fg-input>
                </div>
            </div>
            <br/>

        </fieldset>


        <label class="col-sm-12 control-label">Species</label>
        <div class="col-sm-12">
            <el-radio-group v-model="record.species">
                <el-radio-button label="DOG" key="DOG"></el-radio-button>
            </el-radio-group>
        </div>

        <br/>

        <label class="col-sm-12 control-label">Gender</label>
        <div class="col-sm-12">
            <el-radio-group v-model="record.gender">
                <el-radio-button label="Male" key="1"></el-radio-button>
                <el-radio-button label="Female" key="0"></el-radio-button>
            </el-radio-group>

        </div>


        <fieldset>
            <br/>

            <div class="form-group">
                <label class="col-sm-12 control-label">Age Category</label>
                <div class="col-sm-12">
                    <el-radio-group v-model="record.ageCategory">

                        <el-radio-button label="Young" key="Young">
                            Young
                        </el-radio-button>
                        <el-radio-button label="Adult" key="Adult">
                            Adult
                        </el-radio-button>
                        <el-radio-button label="Senior" key="Senior">
                            Senior
                        </el-radio-button>

                    </el-radio-group>
                </div>
            </div>
        </fieldset>

        <br/>


        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Ownership</label>
                <div class="col-sm-12">
                    <el-radio-group v-model="record.ownership">

                        <el-radio-button label="Owned" key="Owned">
                            Owned
                        </el-radio-button>
                        <el-radio-button label="Owned free roaming" key="Owned free roaming">
                            Owned free roaming
                        </el-radio-button>
                        <el-radio-button label="Community" key="Community">
                            Community
                        </el-radio-button>
                        <el-radio-button label="Community free roaming" key="Community free roaming">
                            Community free roaming
                        </el-radio-button>
                        <el-radio-button label="Wild" key="Wild">
                            Wild
                        </el-radio-button>

                    </el-radio-group>
                </div>
            </div>
        </fieldset>

        <br>

        <label class="col-sm-12 control-label">Previously Vaccinated</label>
        <div class="col-sm-12">
            <el-radio-group v-model="record.isPreviouslyVaccinated">
                <el-radio-button label="Yes" key="1"></el-radio-button>
                <el-radio-button label="No" key="0"></el-radio-button>
            </el-radio-group>
        </div>


        <br>

        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Notes</label>
                <div class="col-sm-8">
                     <textarea v-model="record.notes" class="form-control"
                               rows="3"></textarea>
                </div>
            </div>
        </fieldset>


        <br>


        <div class="col-md-12">

            <button :disabled="isCallingApi" v-on:click="saveVaccinationData" type="button"
                    class="btn btn-round btn-info">SAVE
            </button>

        </div>
    </div>

</template>

<script>

import mixin from '../../../mixins/GlobalMixin';

import ImageUpload from "../../ImageUpload";

import {Select, Option, CheckboxGroup, Checkbox, RadioGroup, RadioButton} from 'element-ui';


export default {
    name: 'AddVaccinationData',
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [CheckboxGroup.name]: CheckboxGroup,
        [RadioGroup.name]: RadioGroup,
        [Checkbox.name]: Checkbox,
        [RadioButton.name]: RadioButton,
        'image-upload': ImageUpload,
    },
    mixins: [mixin],
    props: {
        areaId: {
            type: String,
            default: "0",
        },
        projectId: {
            type: String,
            default: "0",
        },
    },
    data() {
        return {

            record: {
                rfid: '',
                species: '',
                gender: '',
                ageCategory: '',
                ownership: '',
                isPreviouslyVaccinated: '',
                selectedVaccinationDrugs: [1],
                notes: '',
                locationPoints: ''
            },

            imageUploadApiUrl: window.API_BASE_URL + '/UploadToS3/slim/upload/animal/picture/for/project',

            ownerships: [
                "Owned",
                "Street / Free roaming",
                "Community",
                "Wild"
            ],

            uploadURL: '',
            uploadingLabel: 'Animal Image',
            uploadPending: false,

            modifyUrl: false,

            isCallingApi: false,

        }
    },

    methods: {
        init() {

            let vm = this;

            Bus.$on('example-did-upload', (error, data, response) => {
                if (error) {
                    toastr.error(error);
                } else {
                    vm.uploadURL = response.src;
                    vm.uploadingLabel = "Uploaded";
                    vm.uploadPending = false;
                }
            });

            Bus.$on('example-is-saving', () => {
                vm.uploadPending = true;
                vm.uploadingLabel = "Uploading Image...";
            });

        },

        saveVaccinationData() {

            const vm = this

            if (vm.uploadURL === '') {
                alert("Please select and upload animal image")
                return
            }

            vm.isCallingApi = true

            if (vm.record.locationPoints !== "") {

                vm.uploadData()

            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {

                        vm.record.locationPoints = position.coords.latitude + ',' + position.coords.longitude

                        vm.uploadData()

                    }, function () {

                        alert('Please allow location access to save this record')

                        vm.isCallingApi = false

                    });
                } else {
                    // Browser doesn't support Geolocation

                    alert('ERROR: Please allow location permission access')

                    vm.isCallingApi = false

                }
            }


        },

        uploadData() {

            const vm = this

            window.axios.post('/vaccination/add', {
                type: vm.record.ownership,
                species: vm.record.species,
                image_url: vm.uploadURL,
                project_id: vm.$props.projectId,
                area_id: vm.$props.areaId,
                note: "This is added manually from Web " + vm.record.notes,
                vaccination_location: vm.record.locationPoints,
                given_vaccination_drug_ids: vm.record.selectedVaccinationDrugs,
                age_category: vm.record.ageCategory,
                age: 1,
                duration: (1000 * 60),
                outside_the_area: 0,
                already_vaccinated: vm.record.isPreviouslyVaccinated === 1,
                gender: vm.record.gender,
                rf_id: vm.record.rfid,
                colour: '',
                rf_id_location: '',
                breed: 'Street Dog',
                name: '',
                condition: '',
                vaccinator_assistant: '',
            }).then(response => {

                console.log(response)

                vm.isCallingApi = false

                if (response.data.response.error.error_code === 0) {

                    vm.record.ownership = ''
                    vm.record.species = ''
                    vm.uploadURL = ''
                    vm.record.locationPoints = ''
                    vm.record.ageCategory = ''
                    vm.record.isPreviouslyVaccinated = ''
                    vm.record.gender = ''
                    vm.record.rfid = ''

                    vm.showAlert("Success", "Record Added Successfully", true, null)
                }else {

                }

            }).catch(error => {

                vm.isCallingApi = false

                console.log(error)
            })

        }

    },

    mounted() {
        this.init();
    },

    computed: {

        getImageUploadUrl() {
            return this.imageUploadApiUrl + '/' + this.$props.projectId
        }


    },

    beforeRouteLeave(to, from, next) {
        // If the form is dirty and the user did not confirm leave,
        // prevent losing unsaved changes by canceling navigation
        const leaveConfirm = window.confirm(
            "Are you sure you want to leave? any unsaved changes will be lost"
        );
        if (leaveConfirm) {
            next();
        } else {
            // Navigate to next view
            next(false);
        }
    },


}

</script>
<style scoped>

#description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
    width: 100%;
    max-width: 424px;
}

.pac-card #pac-input {
    width: 100%;
    max-width: 400px;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

a {
    color: #51cbce;
}

a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #51cbce;
}

.checkbox_lbl {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-top: -14px;
}

.hideMvOptions {
    display: none;
}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

.hideMvOptions {
    display: none;
}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

/* Pradeep Start */
.set_msg {
    margin-top: 5%;
}

.help-icon {
    cursor: pointer;
    position: absolute;
    margin-left: 5px;
    margin-top: -15px;
    color: #51cbce !important;;
}

@media (max-width: 320px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .set_msg {
        margin-top: 5% !important;

    }
}

.el-checkbox {
    margin: 0;
    padding: 0px 20px 5px 0px;
}

.el-select {
    width: inherit;
}

/* Pradeep End */
</style>
