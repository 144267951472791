<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12">
                <h5>Records <i style="color: darkgrey" class="ti-angle-right small"></i> Released</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>To search by date enter date in YYYY-MM-DD Format. Eg: 2024-01-31</p>
            </div>
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row set_row">
                            <!-- <div class="col-xl-1">
                            </div> -->
                            <div class="col-sm-12 col-xl-12 set_table_center">
                                <table id="releasedRecords" class="display table-striped table-bordered select"
                                       cellspacing="0" width="100%">
                                    <thead>
                                    <tr>
                                        <th><input type="checkbox" id="allCheckbox" @click="inputCheckBox"></th>
                                        <th>Caught Date</th>
                                        <th>Photo</th>
                                        <th>Collar ID</th>
                                        <th>Gender</th>
                                        <th>Surgery Date</th>
                                        <th>Surgery Time</th>
                                        <th>Caught Method</th>
                                        <th>Surgeon Name</th>
                                        <th>Release Date</th>
                                        <th>Ownership</th>
                                        <th>RFID</th>
                                        <th>HSIApps ID</th>
                                        <th>Species</th>
                                        <th>Area</th>
                                        <th>Caught Location</th>
                                        <th>Release Location</th>
                                        <th>Outcome</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- <div class="col-xl-1">
                            </div> -->
                        </div>
                    </div>
                    <div class="card-footer">
                        <button v-bind:disabled="!onLine" class="btn btn-warning edit_btn mr-2" type="button"
                                @click="changeStageOfSelectedRecords">
                            Change Stage
                        </button>
                        <!--                    <button class="btn btn-warning" type="button" @click="editBulkRecords">Edit Selected Records</button>-->
                        <button type="button" class="btn" @click="archiveRecords">Archive</button>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="showChangeStageDialog">
            <PmRecordStageChangerDialog
                :record-ids="selectedRecordIds"></PmRecordStageChangerDialog>
        </template>

    </div>
</template>
<script>
import mixin from '../../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import {Select, Option,} from 'element-ui';
import PmRecordStageChangerDialog from "../../PmRecordStageChangerDialog";
import apiService from '../../../services/api.service';

export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline,
        PmRecordStageChangerDialog,

    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data() {
        return {
            table: null,
            selectedRecordIds: [],
            showChangeStageDialog: false,
        }
    },
    mixins: [mixin],
    methods: {
        changeStageOfSelectedRecords() {

            const vm = this
            vm.selectedRecordIds = []

            document.querySelectorAll('.check_input').forEach(function (element) {
                var inputChecked = element.checked;
                if (inputChecked == true) {
                    vm.selectedRecordIds.push(element.value);
                }
            });

            if (vm.selectedRecordIds.length > 0) {
                vm.showChangeStageDialog = true
            } else {
                vm.showAlert('Alert', 'No records selected', false)
            }


        },
        releasedRecords() {
            let vm = this;

            if (vm.table != null) {
                vm.table.destroy();
            }
            try {
                vm.table = $('#releasedRecords').DataTable({
                    "columnDefs": [
                        {"visible": true, "targets": [18]}
                    ],
                    "order": [[1, "desc"]],
                    "scrollX": true,
                    responsive: true,
                    dom: 'lBfrtip',
                    "bInfo": false,
                    'columns': [
                        {
                            'targets': 0,
                            'render': function (data, type, full, meta) {
                                return '<input type="checkbox" class="check_input" name="id[]" value="' + full[17] + '">';
                            },
                            'orderable': false
                        }, {
                            'targets': 1,
                            'render': function (data, type, full, meta) {
                                return vm.formatRecordDisplayDate(full[0], 'Do MMM YYYY, HH:mm:ss', ',');
                            }
                        }, {
                            'targets': 2,
                            'render': function (data, type, full, meta) {
                                return '<img src=' + full[1] + ' class="datatable_image_thumbnail" style="cursor: pointer;" height="70" width="70"/>';
                            },
                            'width': '9%'
                        }, {
                            'targets': 3,
                            'render': function (data, type, full, meta) {
                                return full[2];
                            }
                        }, {
                            'targets': 4,
                            'render': function (data, type, full, meta) {
                                return full[3];
                            }
                        }, {
                            'targets': 5,
                            'render': function (data, type, full, meta) {
                                return vm.formatRecordDisplayDate(full[4], 'Do MMM YYYY, HH:mm:ss', ',');
                            }
                        }, {
                            'targets': 6,
                            'render': function (data, type, full, meta) {
                                return full[5];
                            }
                        }, {
                            'targets': 7,
                            'render': function (data, type, full, meta) {
                                return full[6];
                            }
                        }, {
                            'targets': 8,
                            'render': function (data, type, full, meta) {
                                return full[7];
                            }
                        }, {
                            'targets': 9,
                            'render': function (data, type, full, meta) {
                                return full[8];
                            }
                        }, {
                            'targets': 10,
                            'render': function (data, type, full, meta) {
                                return full[9];
                            }
                        }, {
                            'targets': 11,
                            'render': function (data, type, full, meta) {
                                return full[10];
                            }
                        }, {
                            'targets': 12,
                            'render': function (data, type, full, meta) {
                                return full[11]
                            }
                        }, {
                            'targets': 13,
                            'render': function (data, type, full, meta) {
                                return full[12]
                            }
                        },
                        {
                            'targets': 14,
                            'render': function (data, type, full, meta) {
                                return full[13]
                            }
                        },
                        {
                            'targets': 15,
                            'render': function (data, type, full, meta) {
                                return `<a href="https://www.google.com/maps/search/?api=1&query=${full[14]}" target="_blank">Map</a>`
                            }
                        },
                        {
                            'targets': 16,
                            'render': function (data, type, full, meta) {
                                return full[15]
                            }
                        },
                        {
                            'targets': 17,
                            'render': function (data, type, full, meta) {
                                return full[16]
                            }
                        },
                        {
                            'targets': 18,
                            'render': function (data, type, full, meta) {
                                if (vm.isReadOnly) {
                                    return '<button type="button" class="btn btn-warning viewHealthRecordDetails" data-data="' + full[17] + '">View</button>';
                                }
                                return '<button type="button" class="btn btn-warning viewHealthRecordDetails" data-data="' + full[17] + '">View/Edit</button>';
                            }
                        },
                    ],
                    select: {
                        style: 'os',
                        selector: 'td:first-child'
                    },
                    buttons: [
                        {
                            extend: 'excel',
                            text: 'Export to excel',
                            className: 'btn btn-default',
                            exportOptions: {
                                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            }
                        }
                    ],
                    "ajax": {
                        "url": window.DATATABLES_BASE_URL + "catch_release/released",
                        "type": "POST",
                        "data": {
                            project_id: vm.projectId,
                        }
                    },
                    "processing": true,
                    "serverSide": true,
                    'fnRowCallback': function (nRow, aData, iDisplayIndex) {
                        const viewEditBtn = $('.viewHealthRecordDetails', nRow);
                        vm.addHealthRecordViewListener(viewEditBtn);
                        const imageElement = $('.datatable_image_thumbnail', nRow);
                        vm.zoomImagePopup(imageElement);
                        return nRow;
                    },
                    lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                    pageLength: 15,
                });
            } catch (e) {
            }
        },
        addHealthRecordViewListener(editEl) {
            let vm = this;
            editEl[0].addEventListener('click', function () {
                let id = this.getAttribute("data-data");
                vm.$router.push({name: 'HealthRecordDetailsPm', params: {recordId: id}})
            }, false);
        },
        editBulkRecords() {
            const selectedRecords = []
            document.querySelectorAll('.check_input').forEach(function (element) {
                var inputChecked = element.checked;
                if (inputChecked == true) {
                    selectedRecords.push(element.value);
                }
            });
            this.$router.push({
                name: 'EditBulkRecords',
                query: {ref: 'released', selectedRecordIds: JSON.stringify(selectedRecords), fromText: 'Released'}
            })
        },
        inputCheckBox() {
            if (document.getElementById('allCheckbox').checked) {
                document.querySelectorAll('.check_input').forEach(function (element) {
                    element.checked = true;
                });
            } else {
                document.querySelectorAll('.check_input').forEach(function (element) {
                    element.checked = false;
                });
            }
        },
        async archiveRecords() {
            const vm = this;
            const selectedRecordIds = [];
            document.querySelectorAll('.check_input').forEach(function (element) {
                var inputChecked = element.checked;
                if (inputChecked == true) {
                    selectedRecordIds.push(element.value);
                }
            });

            if (selectedRecordIds.length > 0) {
                const loading = vm.$loading({
                    lock: true,
                    text: 'Archiving records...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                const apiResponse = await apiService.archivePmRecords({ids: selectedRecordIds});

                loading.close();

                if (apiResponse.response.error.error_code === 0) {
                    vm.showModal = false;
                    vm.showAlert('Success', apiResponse.response.error.msg, true);
                    vm.releasedRecords();
                } else {
                    vm.showAlert('Error', apiResponse.response.error.error_msg, false);
                }
            } else {
                vm.showAlert('Alert', 'No records selected', false)
            }
        }
    },
    computed: {},
    mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        this.projectId = vm.project.id;

        window.Bus.$on('pm-close-stage-changer-dialog-box', function () {
            vm.showChangeStageDialog = false

            vm.NotOperatedRecords();
        })

        //Bus.$emit("projectype", vm.project);
        vm.releasedRecords();
    }
}

</script>
<style lang="scss">
.containBytitile h4 {
    font-size: 1.2em;
    line-height: 1.45em;
}

.set_row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.dt-buttons {
    margin-top: -10px;
    margin-left: 10px;
}
</style>
