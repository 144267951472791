<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">

                    <div class="card-header">
                        <div class="containBytitile">
                            <h4 class="card-title">{{ purpose == 'vaccination' ? 'Vaccination' : 'Survey' }}
                                Report by City/District/Village</h4>
                        </div>
                        <hr>
                    </div>

                    <div class="card-body">

                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <p>Select Date(s)</p>
                                        <el-select v-model="dateFilterOption">
                                            <el-option value="All">All</el-option>
                                            <el-option value="Catch Date Range">Catch Date Range</el-option>
                                        </el-select>
                                    </div>

                                    <div class="col" v-if="dateFilterOption==='Catch Date Range'">
                                        <p>Select Date(s)</p>
                                        <v-md-date-range-picker :presets="dateRangePresets" :start-date="startDatePast"
                                                                :end-date="endDatePast"
                                                                @change="handleChange"
                                                                style="width:100% !important"></v-md-date-range-picker>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                <p>Country</p>
                                <multiselect
                                    v-model="selectedCountry"
                                    :options="allCountries"
                                    :show-labels="false"
                                    placeholder="All"
                                    :multiple="true"
                                    :close-on-select="false"
                                    @remove="countryRemoved"
                                    @select="countrySelected"
                                ></multiselect>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <p>State</p>
                                <multiselect
                                    v-model="selectedState"
                                    :options="allStates"
                                    :show-labels="false"
                                    placeholder="All"
                                    :disabled="!selectedCountry"
                                    :multiple="true"
                                    :close-on-select="false"
                                    @remove="stateRemoved"
                                    @select="stateSelected"
                                ></multiselect>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <p>City/District/Village</p>
                                <multiselect
                                    v-model="selectedCity"
                                    :options="allCities"
                                    :show-labels="false"
                                    placeholder="All"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :disabled="!selectedState"
                                ></multiselect>
                            </div>

                        </div>

                        <div>

                            <el-button
                                @click="generatePDF"
                                :loading="isGenerating"
                                class="btn btn-primary float-right text-white mt-3 mr-1"
                            >
                                Generate PDF
                            </el-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <FullscreenLoader ref="loader" msg="Please wait generating report..." :api-source="apiSource"></FullscreenLoader>
    </div>
</template>

<script>
import mixin from '../../mixins/GlobalMixin';
import FullscreenLoader from "../FullscreenLoader";
import dateRangeDefaultPresetsMixin from '../../mixins/DateRangeDefaultPresetsMixin';
import Multiselect from "vue-multiselect";
import {Row, Col, Button} from "element-ui";

export default {
    name: "ReportByCity",
    data: () => ({
        apiSource: null,
        dateFilterOption: 'All',

        allCountries: [],
        allStates: [],
        allCities: [],
        selectedCountry: [],
        selectedState: [],
        selectedCity: [],
        isGenerating: false,
        startDate: '',
        endDate: '',
        startDatePast: '',
        endDatePast: '',
        dateRangePresets: []
    }),
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    props: {
        projectId: {
            type: [Number, String],
            required: true
        },
        purpose: {
            type: [String],
            default: '',
            required: true
        }
    },
    components: {
        FullscreenLoader,
        Multiselect,
        [Row.name]: Row,
        [Col.name]: Col,
        [Button.name]: Button,
    },
    async mounted() {
        let vm = this;
        if (vm.purpose == '') {
            alert("Invalid purpose string")
            return
        }
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.startDatePast = firstDay.toLocaleDateString('en-US');
        this.endDatePast = lastDay.toLocaleDateString('en-US');
        this.dateRangePresets = this.getDateRangePresets();
        this.dateChanged([this.startDatePast, this.endDatePast]);
        setTimeout(function () {
            var x = document.getElementsByTagName("label")[0].textContent = "";
            var dateSize = document.getElementsByClassName("text-field__filled")[0];
            dateSize.style.width = '100%';
            document.querySelectorAll('.mdrp__panel')[0].style.zIndex = '10111111';
        }, 50);
        await this.getAllData();
    },
    methods: {
        handleChange(values) {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.startDate = sDate;
            this.endDate = eDate;
        },
        dateChanged(dates) {
            var sDate = this.changeDateFormat(dates[0], 'YYYY-MM-DD');
            var eDate = this.changeDateFormat(dates[1], 'YYYY-MM-DD');
            this.startDate = sDate;
            this.endDate = eDate;
        },
        async getAllData() {
            await this.getAllCountries();
        },
        stateSelected(state) {
            this.getAllCities(state, false);
        },
        stateRemoved(state) {
            this.getAllCities(state, true);
        },
        async getAllCities(state, isRemoved) {
            this.resetCity();

            for (let i = 0; i < this.selectedState.length; i++) {

                let state2 = this.selectedState[i];

                try {
                    const {data} = await axios.get(
                        `/project/cities/by/state/${this.projectId}`,
                        {
                            params: {
                                state: state2
                            }
                        }
                    );
                    console.log("city", data.response.data);
                    for (let j = 0; j < data.response.data.cities.length; j++) {
                        let newItem = data.response.data.cities[j];
                        this.allCities.indexOf(newItem) === -1 ? this.allCities.push(newItem) : true;
                    }
                } catch (error) {
                    console.error(error.response);
                }

            }

            try {
                const {data} = await axios.get(
                    `/project/cities/by/state/${this.projectId}`,
                    {
                        params: {
                            state
                        }
                    }
                );
                console.log("city", data.response.data);
                for (let j = 0; j < data.response.data.cities.length; j++) {
                    let newItem = data.response.data.cities[j];
                    if (isRemoved) {
                        this.allCities.splice(this.allCities.indexOf(newItem), 1)
                    } else {
                        this.allCities.indexOf(newItem) === -1 ? this.allCities.push(newItem) : true;
                    }
                }
            } catch (error) {
                console.error(error.response);
            }
        },
        countrySelected(country) {
            this.getAllStates(country, false);
        },
        countryRemoved(country) {
            this.getAllStates(country, true);
        },
        async getAllStates(country, isRemoved) {
            this.resetAllOtherFields()

            for (let i = 0; i < this.selectedCountry.length; i++) {

                let country2 = this.selectedCountry[i];

                try {
                    const {data} = await axios.get(
                        `/project/states/by/country/${this.projectId}`,
                        {
                            params: {
                                country: country2
                            }
                        }
                    );
                    console.log("state", data.response.data);
                    for (let j = 0; j < data.response.data.states.length; j++) {
                        let newItem = data.response.data.states[j];
                        this.allStates.indexOf(newItem) === -1 ? this.allStates.push(newItem) : true;
                    }

                    //this.allStates = data.response.data.states;
                } catch (error) {
                    console.error(error.response);
                }

            }

            try {
                const {data} = await axios.get(
                    `/project/states/by/country/${this.projectId}`,
                    {
                        params: {
                            country: country
                        }
                    }
                );
                console.log("state", data.response.data);
                for (let j = 0; j < data.response.data.states.length; j++) {
                    let newItem = data.response.data.states[j];
                    if (isRemoved) {
                        this.allStates.splice(this.allStates.indexOf(newItem), 1)
                    } else {
                        this.allStates.indexOf(newItem) === -1 ? this.allStates.push(newItem) : true;
                    }
                }

                //this.allStates = data.response.data.states;
            } catch (error) {
                console.error(error.response);
            }

        },
        async getAllCountries() {
            try {
                const {data} = await axios.get(
                    `/project/countries/${this.projectId}`
                );
                console.log("countries", data.response.data.countries);
                this.allCountries = data.response.data.countries;
            } catch (error) {
                console.error(error.response);
            }
        },
        async generatePDF() {

            const vm=this

            if (vm.selectedCountry.length == 0) {
                alert("You must select a country");
                return;
            }

            if (vm.dateFilterOption === 'Catch Date Range') {
                if (vm.startDate === '' || vm.endDate === '') {

                    vm.startDate = vm.formatDate(vm.dateRangePresets[4].range[0]);
                    vm.endDate = vm.formatDate(vm.dateRangePresets[4].range[1]);

                }
            } else if (vm.dateFilterOption === 'All') {
                vm.startDate = '';
                vm.endDate = '';
            }

            const cancelToken = axios.CancelToken;
            vm.apiSource = cancelToken.source();

            this.isGenerating = true;

            this.$refs.loader.show()

            const URL = `/pm/reports/generate/report/${this.projectId}`;
            const body = {
                report_type: vm.purpose == 'survey' ? 24 : 2,
                countries: vm.selectedCountry,
                states: vm.selectedState,
                cities: vm.selectedCity,
                start_date: vm.startDate,
                end_date: vm.endDate,
            }
            try {
                const {data} = await axios.post(URL, body, {
                    cancelToken: vm.apiSource.token
                });
                const downloadURL = `/pm/reports/download/pdf/${data.response.data.report_id}`
                // const downloadURL = `/pm/reports/download/pdf/1`

                // window.location.href = downloadURL
                const pdfResponse = await axios.get(downloadURL)
                this.isGenerating = false;
                console.log('pdf response', pdfResponse.data.response);
                if (pdfResponse.data.response.data === null) {
                    this.$refs.loader.hide()
                    this.showAlert('Oops!', pdfResponse.data.response.error.msg, false)
                } else {
                    window.location.href = pdfResponse.data.response.data.src
                    this.$refs.loader.hide()
                }
            } catch (error) {
                this.isGenerating = false;
                console.error('pdf error', error.response)
                this.$refs.loader.hide()
            }
        },
        resetAllOtherFields() {
            this.selectedState = [];
            this.selectedCity = []
        },
        resetCity() {
            this.selectedCity = []
        },
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.set_font_title {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black !important;
    margin-left: -12px !important;
}

.multiselect__input:disabled {
    cursor: not-allowed;
}
</style>
