import { render, staticRenderFns } from "./HistoryTable.vue?vue&type=template&id=91a66b06&scoped=true&"
import script from "./HistoryTable.vue?vue&type=script&lang=js&"
export * from "./HistoryTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91a66b06",
  null
  
)

export default component.exports