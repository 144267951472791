<template>
    <navbar v-model="showNavbar">
        <!-- pradeep -->
        <div class="setformheight">
            <button v-on:click="openSignupPage" class="btn mb-3 btn-round btn-block btn-info buttonright">{{buttonName}}</button>
        </div>
        <!-- End -->
        <template slot="navbar-menu">

            <ul class="navbar-nav">

            </ul>

        </template>

    </navbar>
</template>

<script>
    import {Navbar, NavbarToggleButton, Button} from '../UIComponents';

    export default {
        props: {
            buttonName: String,
        },
        components: {
            Navbar,
            NavbarToggleButton,
        },
        data() {
            return {
                activeNotifications: false,
                showNavbar: false,
            };
        },
        methods: {
            openSignupPage(){
              this.$router.push({name:'signup'})
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            toggleNotificationDropDown() {
                this.activeNotifications = !this.activeNotifications;
            },
            closeDropDown() {
                this.activeNotifications = false;
            },
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false);
            },
            minimizeSidebar() {
                this.$sidebar.toggleMinimize();
            },
            toggleNavbar() {
                this.showNavbar = !this.showNavbar;
            },
        },
        computed: {
            appName() {
                return window.AppName;
            },
        }
    };

</script>
<!--pradeep -->
<style scoped>

.setformheight {
    height: 65px;
}

.buttonright {
    position: absolute;
    width: 113px;
    right: 0;
    margin-right: 20px !important;
    top: 18px;
}

</style>
<!-- End  -->
