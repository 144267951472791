<template>

    <div>

        <div class="row">

            <div class="col-lg-4">

                <div class="demo-image__preview">
                    <el-image
                        size="1"
                        style="width: 250px; height: 250px"
                        :src="animalImageUrl"
                        :preview-src-list="animalImages">
                    </el-image>
                </div>

            </div>

            <div class="col-lg-4">

                <label>Record ID:</label>
                <span>{{ recordId }}</span>
                <br>

                <label>HSIApps ID: </label>
                <span>{{ summary.hsappsId }}</span>
                <br>

                <label>RFID: </label>
                <span>{{ summary.rfid }}</span>
                <br>

                <label>Temporary Collar ID: </label>
                <span>{{ summary.collarId }}</span>
                <br>

                <label>Species: </label>
                <span>{{ summary.species }}</span>
                <br>

                <label>Animal Name: </label>
                <span>{{ summary.animalName }}</span>
                <br>

                <label>Gender: </label>
                <span>{{ summary.gender }}</span>
                <br>

                <label>Colour: </label>
                <span>{{ summary.colour }}</span>
                <br>

            </div>

            <div class="col-lg-4">

                <h6><u>Status</u></h6>

                <div style="font-weight: bold">

                    <label>Stage: </label>
                    <span>{{ summary.stage }}</span>
                    <br>


                    <label>Sterilization Status: </label>
                    <span>{{ summary.sterilizationStatus == '' ? 'N/A' : summary.sterilizationStatus }}</span>
                    <br>

                </div>

                <div style="font-weight: bold">

                    <label>Outcome: </label>
                    <span>{{ summary.outcome == '' ? 'N/A' : summary.outcome }}</span>
                    <br>

                </div>

                <br>

                <label>Note: </label>
                <span>{{ summary.note == '' ? 'N/A' : summary.note }}</span>
                <br>

            </div>

        </div>

        <div class="row" v-if="!isReadOnly">
            <div class="col-sm-12 text-right">
                <button class="btn btn-primary" @click="stageAndOutcomeChangeDialogVisible = true">CHANGE STATUS
                </button>
                <!--                <button class="btn btn-primary" @click="outcomeChangeDialogVisible = true">CHANGE OUTCOME</button>-->
                <el-popover
                    placement="left"
                    popper-class="edit-detail-pop"
                    id="dddd"
                    width="500"
                    trigger="click">
                    <h5>Edit Details</h5>
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-12">

                                    <label>Animal Name</label>
                                    <el-input
                                        v-model="summary.newAnimalName"
                                        type="text"
                                        placeholder=""></el-input>

                                </div>

                                <div class="col-12">

                                    <label>Temporary Collar ID</label>
                                    <el-input
                                        v-model="summary.newTempCollarId"
                                        type="text"
                                        placeholder=""></el-input>

                                </div>

                                <div class="col-12">


                                    <label>Colour</label>
                                    <el-select v-model="summary.newColour" placeholder="Select">
                                        <el-option
                                            v-for="item in list.animalColors"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>

                                </div>

                            </div>

                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label>Note</label>
                                    <el-input
                                        v-model="summary.newNote"
                                        type="textarea"
                                        placeholder="Please enter here..."
                                        :rows="5"></el-input>
                                </div>

                                <div class="col-12">


                                    <label>Gender</label>
                                    <el-select v-model="summary.newGender" placeholder="Select">
                                        <el-option
                                            v-for="item in list.animalGenders"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button @click="saveRecordSummaryDetails" class="btn btn-success text-right">SAVE
                            </button>
                        </div>
                    </div>

                    <el-button class="btn btn-primary" style="    border-width: 2px;
    font-weight: 600;
    font-size: 0.8571em;
    line-height: 1.35em;
    text-transform: uppercase;
    border: none;
    margin: 10px 1px;
    border-radius: 3px;
    padding: 11px 22px;
    cursor: pointer;
    background-color: #66615B;
    color: #FFFFFF;
    transition: all 150ms linear;" slot="reference">EDIT DETAILS
                    </el-button>
                </el-popover>
            </div>
        </div>

        <el-dialog
            title="Are you sure?"
            :visible.sync="stageChangeDialogVisible"
            width="50%"
            :before-close="handleClose">
            <span>Stage should be set automatically, changing it here manually may have unknown side effects.</span>
            <br>
            <br>
            <span>
                <label>Change Stage to:</label>
                <br>
                <el-select v-model="newStageSet" placeholder="Stage">
                    <el-option
                        v-for="item in stages"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <br>
                <br>
                <template v-if="newStageSet=='Cleared For Release'">
                    <label>Select Release Date</label>
                    <el-date-picker
                        v-model="releaseDate"
                        type="date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="Select date and time">
                    </el-date-picker>
                    <br>
                    <br>
                </template>

                <br>
            </span>

            <span slot="footer" class="dialog-footer">
                <el-button @click="stageChangeDialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="changeRecordStage">Change Stage</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="Are you sure?"
            :visible.sync="outcomeChangeDialogVisible"
            width="50%"
            :before-close="handleClose">
            <span>Outcome should be set automatically, changing it here manually may have unknown side effects.</span>
            <br>
            <br>
            <span>
                <label>Change Outcome to</label>
                <br>
                <el-select v-model="newOutcomeSet" placeholder="Outcome">
                    <el-option
                        v-for="item in outcomes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <br>
                <br>
            </span>

            <span slot="footer" class="dialog-footer">
                <el-button @click="outcomeChangeDialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="changeRecordOutcome">Change Outcome</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="Are you sure?"
            :visible.sync="stageAndOutcomeChangeDialogVisible"
            width="50%"
            :before-close="handleClose">
            <span>Stage and Outcome should be set automatically, changing it here manually may have unknown side effects.</span>
            <br>
            <br>
            <span>Stage is currently set to: {{ summary.stage }}</span>
            <br>
            <br>
            <label>Change Stage to</label>
            <br>
            <el-select v-model="newStageSet" placeholder="Stage">
                <el-option
                    v-for="item in stages"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <br>
            <br>

            <template v-if="newStageSet=='Cleared For Release'">
                <label>Select Release Date</label>
                <el-date-picker
                    v-model="releaseDate"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="Select date and time">
                </el-date-picker>
                <br>
                <br>
            </template>
            <br>
            <span>Outcome is currently set to: {{ summary.outcome }}</span>
            <br>
            <br>
            <div class="row">
                    <div class="col-4">
                        <label>Change Outcome to</label>
                        <br>
                        <el-select v-model="newOutcomeSet" placeholder="Outcome" class="mr-2">
                            <el-option
                                v-for="item in outcomes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-6" v-if="['Died Pre Op', 'Died Post Op'].includes(newOutcomeSet)">
                        <div class="row">
                            <div class="col-6">
                                <label>Death Cause</label>
                                <br>
                                <el-select
                                    v-model="death_cause"
                                    placeholder="Death Cause"
                                    class="mr-2">
                                    <el-option
                                        v-for="item in deathCauses"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-6" v-if="death_cause === 'other'">
                                <label>Other Death Cause</label>
                                <br>
                                <el-input
                                    v-model="death_cause_other"
                                    type="text">
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="stageAndOutcomeChangeDialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="changeRecordStageAndOutcome">Save</el-button>
            </span>
        </el-dialog>

    </div>

</template>
<script>

import {Image, Popover, Button, Input, Dialog, Select, Option, DatePicker} from 'element-ui';

export default {

    components: {
        [Image.name]: Image,
        [Button.name]: Button,
        [Popover.name]: Popover,
        [Input.name]: Input,
        [Dialog.name]: Dialog,
        [Select.name]: Select,
        [Option.name]: Option,
        [DatePicker.name]: DatePicker,
    },
    props: {
        recordId: {
            type: String,
        }
    },
    data() {
        return {

            isReadOnly: false,

            isFemaleRecord: false,
            stillInCare: true,

            list: {

                animalGenders:[
                    {
                        'label': 'Male',
                        'value': 'Male',
                    },
                    {
                        'label': 'Female',
                        'value': 'Female',
                    },
                ],

                animalColors: [
                    {
                        'label': 'Black',
                        'value': 'Black',
                    },
                    {
                        'label': 'Black/ tan',
                        'value': 'Black/ tan',
                    },
                    {
                        'label': 'Black /white',
                        'value': 'Black /white',
                    },
                    {
                        'label': 'Brindle',
                        'value': 'Brindle',
                    },
                    {
                        'label': 'Brown',
                        'value': 'Brown',
                    },
                    {
                        'label': 'Brown /white',
                        'value': 'Brown /white',
                    },
                    {
                        'label': 'Sable',
                        'value': 'Sable',
                    },
                    {
                        'label': 'White',
                        'value': 'White',
                    },
                    {
                        'label': 'White mix',
                        'value': 'White mix',
                    },

                ]

            },

            summary: {
                hsappsId: '',
                rfid: '',
                collarId: '',
                species: '',
                animalName: '',
                gender: '',
                lastLocation: '',
                stage: '',
                sterilizationStatus: '',
                outcome: '',
                note: '',
                colour: '',
                newNote: '',
                newAnimalName: '',
                newColour: '',
                newTempCollarId: '',
                newGender:''
            },

            stageAndOutcomeChangeDialogVisible: false,

            stageChangeDialogVisible: false,

            newStageSet: 'Awaiting Exam',
            releaseDate: '',

            outcomeChangeDialogVisible: false,

            newOutcomeSet: '',

            stages: [
                {
                    label: 'Awaiting Exam',
                    value: 'Awaiting Exam',
                },
                {
                    label: 'Cleared For Sterilization',
                    value: 'Cleared For Sterilization',
                },
                {
                    label: 'Pre-Op Hold',
                    value: 'Pre-Op Hold',
                },
                {
                    label: 'Sterilized',
                    value: 'Sterilized',
                },
                {
                    label: 'Post-Op Hold',
                    value: 'Post-Op Hold',
                },
                {
                    label: 'Cleared For Release',
                    value: 'Cleared For Release',
                },
                {
                    label: 'Released/Out-of-care',
                    value: 'Released/Out-of-care',
                },
            ],

            outcomes: [
                {
                    label: 'Still In Care',
                    value: 'Still In Care',
                },
                {
                    label: 'Released In Field',
                    value: 'Released In Field',
                },
                {
                    label: 'Returned To Owner',
                    value: 'Returned To Owner',
                },
                {
                    label: 'Returned To Home',
                    value: 'Returned To Home',
                },
                {
                    label: 'Died Pre Op',
                    value: 'Died Pre Op',
                },
                {
                    label: 'Died Post Op',
                    value: 'Died Post Op',
                },
                {
                    label: 'Claimed By Owner',
                    value: 'Claimed By Owner',
                },
                {
                    label: 'Escaped',
                    value: 'Escaped',
                },
                {
                    label: 'Adopted',
                    value: 'Adopted',
                },
                {
                    label: 'Euthanasia',
                    value: 'Euthanasia',
                },
            ],

            animalImageUrl: '',
            animalImages: [],

            deathCauses: [{lable: 'Cardiac Shock', value: 'Cardiac Shock'},
                {lable: 'Heat Stroke', value: 'Heat Stroke'},
                {lable: 'Hypothermia', value: 'Hypothermia'},
                {lable: 'Internal Bleeding', value: 'Internal Bleeding'},
                {lable: 'Other (for cases where rabies is suspected)', value: 'other'}],
            death_cause: '',
            death_cause_other: ''
        }
    },
    methods: {

        handleClose() {
            this.stageAndOutcomeChangeDialogVisible = false
            this.stageChangeDialogVisible = false
            this.outcomeChangeDialogVisible = false
        },
        changeRecordStage() {
            let vm = this;

            if (vm.newStageSet == 'Cleared For Release') {
                if (vm.releaseDate == '') {
                    alert("Please set release date")
                    return;
                }
            }

            vm.stageChangeDialogVisible = false;

            axios.post('catch_release/record/change/stage', {
                record_id: vm.recordId,
                new_stage: vm.newStageSet,
                release_date: vm.releaseDate
            }).then(response => {
                if (response.data.response.error.error_code === 0) {
                    vm.summary.stage = response.data.response.data.new_stage
                    vm.newStageSet = vm.summary.stage
                    vm.showAlert('Success', response.data.response.error.msg, true);
                } else {
                    vm.showAlert('Error', response.data.response.error.error_msg, false);
                }
            }).catch(error => {
                vm.showAlert('Error', error.response.data.response.error.error_msg, false);
            })
        },

        changeRecordOutcome() {
            let vm = this;

            vm.outcomeChangeDialogVisible = false;

            axios.post('catch_release/record/change/outcome', {
                record_id: vm.recordId,
                new_outcome: vm.newOutcomeSet,
                release_date: vm.releaseDate,
                death_cause: vm.death_cause,
                death_cause_other: vm.death_cause_other
            }).then(response => {
                if (response.data.response.error.error_code === 0) {
                    vm.summary.outcome = response.data.response.data.new_outcome
                    vm.newOutcomeSet = vm.summary.outcome
                    vm.showAlert('Success', response.data.response.error.msg, true);
                } else {
                    vm.showAlert('Error', response.data.response.error.error_msg, false);
                }
            }).catch(error => {
                vm.showAlert('Error', error.response.data.response.error.error_msg, false);
            })
        },

        getRecordSummary() {

            let vm = this;

            axios.get('/catch_release/record/summary/' + vm.recordId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.summary.stage = response.data.response.data.record_details.stage;
                        vm.newStageSet = response.data.response.data.record_details.stage;

                        vm.summary.sterilizationStatus = response.data.response.data.record_details.sterilisation_status;

                        vm.summary.outcome = response.data.response.data.record_details.outcome;

                        if (vm.summary.outcome.toLowerCase() === 'still in care') {
                            vm.stillInCare = true;
                        } else {
                            vm.stillInCare = false;
                        }

                        vm.newOutcomeSet = response.data.response.data.record_details.outcome;

                        vm.summary.note = response.data.response.data.record_details.note;
                        vm.summary.species = response.data.response.data.record_details.animal.species;
                        vm.animalImages.push(response.data.response.data.record_details.animal.most_recent_image_url);
                        vm.animalImages.push(response.data.response.data.record_details.image_url);
                        vm.animalImageUrl = response.data.response.data.record_details.image_url;
                        vm.summary.gender = response.data.response.data.record_details.animal.gender;

                        vm.isFemaleRecord = vm.summary.gender.toLowerCase() == 'female';

                        vm.summary.hsappsId = response.data.response.data.record_details.animal.hsapps_id;
                        vm.summary.rfid = response.data.response.data.record_details.animal.rf_id;
                        vm.summary.collarId = response.data.response.data.record_details.collar_id;
                        vm.summary.animalName = response.data.response.data.record_details.animal.name;
                        vm.summary.colour = response.data.response.data.record_details.animal.colour;

                        vm.summary.newNote = response.data.response.data.record_details.note;
                        vm.summary.newTempCollarId = response.data.response.data.record_details.collar_id;
                        vm.summary.newColour = response.data.response.data.record_details.animal.colour;
                        vm.summary.newGender = response.data.response.data.record_details.animal.gender;
                        vm.summary.newAnimalName = response.data.response.data.record_details.animal.name;
                    }
                }).catch(e => {

            });

        },

        saveRecordSummaryDetails() {
            let vm = this;

            let newNote = vm.summary.newNote;
            let newCollarId = vm.summary.newTempCollarId;
            let newColour = vm.summary.newColour;
            let newGender = vm.summary.newGender;
            let newName = vm.summary.newAnimalName;

            axios.post('/catch_release/record/edit/summary/details/' + vm.recordId, {
                new_note: newNote,
                new_colour: newColour,
                new_gender: newGender,
                new_collar_id: newCollarId,
                new_animal_name: newName,
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.summary.note = newNote
                    vm.summary.colour = newColour
                    vm.summary.gender = newGender
                    vm.summary.collarId = newCollarId
                    vm.summary.animalName = newName

                    vm.summary.newNote = "";
                    vm.summary.newTempCollarId = "";
                    vm.summary.newColour = "";
                    vm.summary.newGender = "";
                    vm.summary.newAnimalName = "";

                    vm.showAlert('Success', response.data.response.error.msg, true);
                }
            }).catch(e => {
                console.log(e);
            })

        },
        changeRecordStageAndOutcome() {
            let vm = this
            if (vm.newStageSet.toLowerCase() === 'released/out-of-care') {
                if (vm.newOutcomeSet.toLowerCase() === 'still in care') {
                    alert('Outcome must be set to still in care to set stages other than released/out-of-care')
                    return
                }
            }

            if (vm.newStageSet.toLowerCase() !== 'released/out-of-care') {
                if (vm.newOutcomeSet.toLowerCase() !== 'still in care') {
                    alert('Outcome must be set to still in care to set stages other than released/out-of-care')
                    return
                }
            }

            if (['Died Pre Op', 'Died Post Op'].includes(vm.newOutcomeSet)) {
                if (vm.death_cause == '') {
                    alert("Please set death cause")
                    return;
                }

                if (vm.death_cause == 'other' && vm.death_cause_other == '') {
                    alert("Please write other death cause")
                    return;
                }
            }

            vm.changeRecordOutcome()
            vm.changeRecordStage()
            vm.stageAndOutcomeChangeDialogVisible = false
        },

        showStageAndOutcomeChangeDialog() {
            this.stageAndOutcomeChangeDialogVisible = true
        }

    },
    computed: {
        getLocationLink() {
            return 'https://www.google.com/maps/search/?api=1&query=';
        }
    },
    mounted() {
        const vm = this
        vm.isReadOnly = false
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if (r.role_name == 'VIEWER') {
                vm.isReadOnly = true
            }
        })
        this.$root.$on('UpdateSummaryComponent', () => {
            // your code goes here
            this.getRecordSummary()
        });
        this.getRecordSummary();
    }

}

</script>
<style scoped>
label {
    margin-right: 10px;
}

</style>
