<template>
    <div>
        <canvas id="pie-chart" width="395" height="395" style="display: block;
    height: 283px;
    width: 284px;
    margin-left: 0px;" class="chartjs-render-monitor"></canvas>
    </div>
</template>
<script>
    import {Card} from './UIComponents';
    import ChartCard from './UIComponents/Cards/ChartCard';
    import PieChart from './UIComponents/Charts/PieChart';
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
    const tooltipOptions = {
        tooltipFillColor: 'rgba(0,0,0,0.5)',
        tooltipFontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
        tooltipFontSize: 14,
        tooltipFontStyle: 'normal',
        tooltipFontColor: '#fff',
        tooltipTitleFontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: 'bold',
        tooltipTitleFontColor: '#fff',
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10,
    };
    export default {
        components: {
            Card,
            ChartCard,
            ChartJsPluginDataLabels,
            PieChart,
        },
        created(){
            var letthis=this;
            setTimeout(function(){ letthis.read() }, 3000);
        },
        methods:{
            read() {
                var config = {
                    type: 'pie',
                    options: {
                        plugins: {
                            datalabels: {
                                display: true,
                                formatter: (value, ctx) => {
                                    let sum = 0;
                                    let dataArr = ctx.chart.data.datasets[0].data;
                                    dataArr.map(data => {
                                        sum += data;
                                    });
                                    let percentage = (value*100 / sum).toFixed(2)+"%";
                                    return percentage;
                                },
                            }
                        },
                        responsive: true,
                        maintainAspectRatio:false,
                    },
                    data: {
                        datasets: [{
                            data: [400,400,300,200,220],
                            backgroundColor: [
                                '#98ccaf',
                                '#4acccd',
                                '#fcc468',
                                '#ed7107',
                                '#ffed4a',
                            ],
                            label: 'ProJect'
                        }],
                        labels: ['Dog', 'Cat', 'Cattels','Horse','Camel'],
                    },
                };

                var abc= document.querySelectorAll(".chartjs-render-monitor");
                for(var i=0;i<abc.length;i++)
                {
                    if(abc[i].id=='pie-chart')
                    {
                        var ctx=abc[i].getContext('2d');
                        window.myPie = new Chart(ctx, config);
                    }
                }

            },

        },
        data() {
            return {
                emailChart: {},
            };
        },
    };
</script>
<style>
    #pie-chart{
        height: 283px;
        width: 284px;
        margin-left: 84px;
    }
</style>
