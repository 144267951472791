<template>
    <div>
        <div class="sidebar" style="background-color:#66615B !important;">
            <!--pradeep-->
            <div class="logo logo_img_hsaapps">
                <!--pradeep-->
                <a href="/admin/dashboard" v-if="usertype=='admin'">
                    <div class="logo-img" style="text-align: center;">
                        <img :src="logo" style="width: 150px;">
                    </div>
                </a>
                <router-link to="/platform/projects" v-else>
                    <div class="logo-img" style="text-align: center;">
                        <img :src="logo" style="width: 150px;">
                    </div>
                </router-link>
                <!--<a class="simple-text logo-normal" href="/admin/dashboard" v-if="usertype=='admin'">
                  {{ title }}
                </a>
                <a class="simple-text logo-normal" href="/projects" v-else>
                  {{ title }}
                </a>-->
                <!--End-->
            </div>
            <!--End-->
            <div class="sidebar-wrapper set_border" ref="sidebarScrollArea" v-if="usertype=='admin'">
                <slot>

                </slot>
                <ul class="nav">
                    <slot name="links">
                        <sidebar-item v-for="(link, index) in sidebarLinksAdmin"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>
                    </slot>
                </ul>
            </div>
            <!-- Pradeep Start -->
            <div class="sidebar-wrapper set_border" ref="sidebarScrollArea" v-if="usertype=='user'">
                <ul class="nav"
                    v-if="projecttype && projecttype!='MV' && projecttype != 'PM'">
                    <slot name="links">
                        <sidebar-item v-for="(link, index) in sidebarLinks"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>

                    </slot>

                </ul>
                <!-- Pradeep -->
                <ul class="nav"
                    v-if="projecttype && projecttype!='MV' && projecttype != 'PM'">
                    <slot name="links">
                        <sidebar-item v-for="(link, index) in projectsidebarLinks"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>

                    </slot>

                </ul>
                <div v-if="projecttype=='MV' || projecttype=='PM'"
                     class="setShadow_menu" @click="mvdHome" style="padding: 5px;">
                    <div class="logo-img projectimg" v-bind:style="{width: '100%', height: '150px', backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat', backgroundImage : 'url('+projectimg+')', backgroundSize: 'contain', borderRadius: '5px'}">
                    </div>
                    <div class="project_name_div" :title="projectname">
                        <!--                        <h5 class="project_name">{{ projectname }}</h5>-->
                        <h6 class="project_name">Mass Vaccination</h6>
                    </div>
                </div>
                <ul class="nav"
                    v-if="projecttype=='MV'">
                    <slot name="links">
                        <sidebar-item v-for="(link, index) in MVDprojectsidebarLinks"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>

                    </slot>

                </ul>
                <ul class="nav" v-if="projecttype == 'PM' && projecttype != 'MV'">

                    <slot name="links">
                        <sidebar-item v-for="(link, index) in DPMprojectsidebarLinks"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>
                    </slot>

                    <li>
                        <a href="/overview">Overview</a>
                        <a href="/areas">Areas</a>
                    </li>

                </ul>
                <!-- End -->
            </div>
            <!-- Pradeep End -->
        </div>
        <div class="main-panel">
            <project-navbar
                    usertype="user">
            </project-navbar>
            <div class="project-details-content">
                <transition name="slide-fade" mode="out-in">
                    <router-view :projectId="projectId"></router-view>
                </transition>
            </div>
            <content-footer></content-footer>
        </div>
    </div>
</template>
<script>

import ProjectNavbar from "../../Layout/ProjectNavbar";
import ContentFooter from "../../Layout/ContentFooter";
import mvLogo from '../../../../assets/images/mv_logo.png';

export default {
    components: {
        'project-navbar': ProjectNavbar,
        'content-footer': ContentFooter
    },
    data()
    {
        return {
            location: {...location},
            ps: null,
            projecttype: '',
            projectname: '',
            projectimg: '',
        };
    },
    props: {
        usertype: {
            type: String,
            default: 'user',
        },
        projecturl: {
            type: String,
            default: 'url',
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
        projectId: {
            type: String,
        }
    },
    provide()
    {
        return {
            autoClose: this.autoClose,
        };
    },
    methods: {
        mvdHome()
        {
            window.location = '/project/details/' + this.projectId;
        },
        getLocation()
        {
            return this.location;
        },
        async initScrollBarAsync()
        {
            let PerfectScroll = await import('perfect-scrollbar');
            PerfectScroll.initialize(this.$refs.sidebarScrollArea);
        }
    },

    computed: {
        sidebarLinks()
        {
            //pradeep
            let loc = this.location;
            var tmp = [];

            for (var i = 0; i < 4; i++) {
                tmp.push(window.appConfig.nav[i]);
            }
            //End
            let links = tmp.map((item, index) => {
                item.collapsed = true;
                if (item.children && item.children.length) {
                    for (let idx in item.children) {
                        let child = item.children[idx];
                        if (item.collapsed) {
                            item.collapsed = !(child.path === loc.hash);
                        }
                    }
                }

                return item;
            });
            return links;
            return window.appConfig.nav;
        },
        sidebarLinksAdmin()
        {
            let loc = this.location;
            var tmp = [];
            for (var i = 12; i < 14; i++) {
                tmp.push(window.appConfig.nav[i]);
            }
            let links = tmp.map((item, index) => {
                item.collapsed = true;
                if (item.children && item.children.length) {
                    for (let idx in item.children) {
                        let child = item.children[idx];
                        if (item.collapsed) {
                            item.collapsed = !(child.path === loc.hash);
                        }
                    }
                }
                return item;

            });
            return links;
            return window.appConfig.nav;
        },
        //pradeep
        projectsidebarLinks()
        {

            let loc = this.location;
            var tmp = [];

            for (var i = 14; i < 18; i++) {
                tmp.push(window.appConfig.nav[i]);
            }

            let links = tmp.map((item, index) => {
                item.collapsed = true;
                if (item.children && item.children.length) {
                    for (let idx in item.children) {
                        let child = item.children[idx];
                        if (item.collapsed) {
                            item.collapsed = !(child.path === loc.hash);
                        }
                    }
                }

                return item;
            });
            return links;
            return window.appConfig.nav;
        },
        MVDprojectsidebarLinks()
        {

            let loc = this.location;
            //alert(this.location);

            var tmp = [];

            for (var i = 18; i < 24; i++) {
                tmp.push(window.appConfig.nav[i]);
            }
            let links = tmp.map((item, index) => {
                item.collapsed = true;
                if (item.children && item.children.length) {
                    for (let idx = 0; idx < item.children.length; idx++) {
                        let child = item.children[idx];
                        item.children[idx].path = '/project/mv/' + this.projectId + item.children[idx].p;
                        //item.children[idx].path = 'project/' + this.projectId + '/' + item.children[idx].path;
                        /*if (child.name == 'Vaccination' || child.name == "Out-of-Area Vaccinations" || child.name == "Archived" || child.name == "Surveys"
                            || child.name == 'Date Range' || child.name == "City/District/Village" || child.name == "Area" || child.name == "Survey" || child.name == "Lactating" || child.name == "Young") {
                            if (item.collapsed) {
                                item.collapsed = !(child.path === loc.hash);
                            }
                        } else {
                            if (item.collapsed) {
                                item.collapsed = !(child.path === loc.hash);
                            }
                        }*/
                    }
                } else {
                    item.path = '/project/mv/' + this.projectId + item.p;
                }
                console.log("ITEM")
                console.log(item)
                return item;
            });
            console.log("LINKS")
            console.log(links)
            return links;
        },
        DPMprojectsidebarLinks()
        {

            let loc = this.location;
            var tmp = [];

            for (var i = 23; i < 28; i++) {
                tmp.push(window.appConfig.nav[i]);
            }
            let links = tmp.map((item, index) => {
                if (item.name == "Overview" || item.name == "Users" || item.name == "Settings") {
                    item.path = item.path + '/' + this.projectId
                    item.path = item.path.replace("//", "/");
                }
                item.collapsed = true;
                if (item.children && item.children.length) {
                    for (let idx in item.children) {
                        let child = item.children[idx];
                        if (child.name == 'Microchip' || child.name == 'Map' || child.name == 'Locality' || child.name == 'Operated' || child.name == 'Not Operated' || child.name == 'Ready for Release' || child.name == 'Treatment Status' || child.name == 'Released' || child.name == 'Archive') {
                            if (item.collapsed) {
                                item.collapsed = !(child.path === loc.hash);
                            }
                            child.path = child.path + '/' + this.projectId
                            child.path = child.path.replace("//", "/");
                        } else {
                            if (item.collapsed) {
                                item.collapsed = !(child.path === loc.hash);
                            }
                        }
                    }
                }

                return item;
            });
            return links;
            return window.appConfig.nav;
        },
        //End
        title()
        {
            return window.appConfig.appName;
        },
        logo()
        {
            return window.appConfig.logo;
        },
    },
    mounted()
    {
        let vm = this;
        /*Bus.$on('projectype', data => {
            console.log("PROJECT DATA: " + data);
            vm.projecttype = data.type;
            vm.projectname = data.name;
            vm.projectimg = data.image_url;
            vm.projectId = data.id;
            vm.$store.dispatch("setProjectType", "Mass Vaccination");
            vm.$store.dispatch("setProjectName", this.projectname);
        });*/

        axios
            .get("/project/details", {
                params: {
                    project_id: vm.projectId,
                }
            })
            .then(response => {
                let project = response.data.response.data.project;
                //localStorage.project = JSON.stringify(vm.project);

                this.$store.dispatch("setCurrentSelectedProject", project);
                this.$store.dispatch("user/getUserRoles");

                vm.projecttype = project.type;
                vm.projectname = project.name;
                vm.projectimg = project.image_url;
                vm.projectId = project.id;
                if (project.image_url == null) {
                    vm.projectimg = mvLogo;
                } else
                    if (project.image_url.includes("mv_logo.png")) {
                        vm.projectimg = mvLogo;
                    } else {
                        vm.projectimg = project.image_url;
                    }

                vm.$store.dispatch("setProjectType", "Mass Vaccination");
                //vm.$store.dispatch("setOrganisationName", vm.$store.getters['user/organisation'].name);

                vm.$store.dispatch("setProjectName", vm.projectname);

                if(project.organisation){
                    vm.$store.dispatch("setOrganisationName", project.organisation.name);
                    vm.$store.dispatch("setOrganisationLogo", project.organisation.logo_url);
                }

            })
            .catch(error => {
                console.log(error);
                console.log(error.response.data.response);
                if (error.response.data.response.data !== null) {
                    console.log(error.response.data.response.data);
                } else {
                    console.log(vm.errorMsg);
                }
            });

        //this.ps = new PerfectScroll(this.$refs.sidebarScrollArea);
        this.initScrollBarAsync();
        /*Bus.$on('link.clicked', (path) => {
            this.location.hash = '#' + path;
        });*/
    },
    beforeDestroy()
    {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
};

</script>
<style>

.main-panel > .project-details-content {
    padding: 0 30px 30px;
    min-height: calc(100vh - 123px);
    margin-top: 30px;
}

@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}

.project_name_div {
    margin-top: 10px;
    width: 99%;
    height: 50px;
    margin-bottom: -15px;
}

.project_name {
    text-align: center;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.projectimg {
    text-align: center;
    margin-top: 10px;
}

.img_fix_size {
    height: 100px;
    margin-top: 12px;
}

.setShadow_menu {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer !important;
}

.sidebar-normal {
    margin-left: 44px !important;
}

.sidebar-mini-icon {
    display: none !important;
}

.logo_img_hsaapps {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer !important;
}

.set_border {
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.sidebar .nav li.active > a.active::after,
.sidebar .nav li.active > a.active::before {
    opacity: 0 !important;
}

.sidebar .nav li > a.active, .off-canvas-sidebar .nav li > a.active {
    font-weight: 800;
    font-size: 14px;
}
</style>

<style scoped>

.sidebar:after {
    /*background-color: #1B5E20 !important;*/
}

</style>
