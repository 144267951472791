<template>

    <div>
        <top-navbar button-name="Sign-Up"
                    style="border-bottom: 0; position: absolute; top: 0; background-color: transparent !important;"></top-navbar>
        <div class="login-page">
            <div class="wrapper wrapper-full-page">
                <div class="full-page login-page section-image">
                    <div class="content">
                        <div class="container">

                            <div class="col-lg-4 col-md-6 ml-auto mr-auto">

                                <card type="login" footer-line>
                                    <div class="text-center">
                                        <img :src="hsappsLogo" height="70" style="margin: 0 auto;">
                                    </div>
                                    <hr>
                                    <div class="text-left">
                                        <div class="form-group">
                                            <fg-input type="email"
                                                      label="Email Address"
                                                      name="email"
                                                      @keyup.enter="tryLogin"
                                                      v-model="form.email">
                                            </fg-input>
                                        </div>

                                        <div class="form-group">
                                            <fg-input type="password"
                                                      label="Password"
                                                      name="password"
                                                      @keyup.enter="tryLogin"
                                                      v-model="form.password">
                                            </fg-input>
                                        </div>
                                        <!--                                        <p-checkbox name="rememberMe" :inline="true" v-model="model.rememberMeChecked"
                                                                                            v-on:change="setRemember">
                                                                                    Remember Me
                                                                                </p-checkbox>
                                                                                <br>-->
                                        <hr>
                                        <div slot="footer">
                                            <button v-on:click="tryLogin"
                                                    class="btn mb-3 btn-round btn-block btn-info">
                                                LOGIN
                                            </button>

                                            <div id="signin_button"></div>

                                            <div style="margin-top: 10px">
                                                <router-link to="forgot">
                                                    <a style="margin-top: 21px;margin-left: 30%;">Forgot Password?</a>
                                                </router-link>
                                            </div>
                                        </div>

                                    </div>

                                </card>


                                <card>
                                    <div class="text-center">
                                        <h5>Field User App </h5>
                                        <a class="m-0 p-0" target="_blank"
                                           href="https://play.google.com/store/apps/details?id=in.fusionbit.hsapps">
                                            <img class="m-0 p-0" height="50" :src="googlePlayIcon">
                                        </a>
                                        <br>
                                        <br>
                                        <a target="_blank"
                                           href="https://hsapps-prod-media.s3.amazonaws.com/android_app/HSApps-release-11-06-2024_11_52.apk">Download APK File</a>
                                    </div>
                                </card>


                            </div>
                        </div>
                    </div>
                    <div class="full-page-background"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {Button, Card, Checkbox, InfoSection} from "./UIComponents";
import PCheckbox from "./UIComponents/Inputs/Checkbox";
import TopNavbar from "./Layout/TopNavbarNew";

import {mapActions} from "vuex";

//import hsappsLogo from '../../assets/images/hsapps-green.png'
import hsappsLogo from '../../assets/images/HSIApps-Trnprnt-green.png'
import googlePlayIcon from '../../assets/images/google_play_icon.png'

export default {
    components: {
        Card,
        [Checkbox.name]: Checkbox,
        [Button.name]: Button,
        InfoSection,
        PCheckbox,
        'top-navbar': TopNavbar
    },
    data() {
        return {
            hsappsLogo: hsappsLogo,
            googlePlayIcon: googlePlayIcon,
            model: {
                email: '',
                password: '',
                rememberMeChecked: false,
                isLoggingIn: false,
            },
            modelValidations: {
                email: {
                    //required: true,
                    email: true,
                },
                password: {
                    //required: true,
                    min: 6,
                },
            },

            reset_email: '',
            form: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                acceptTerms: false,
                isEmailDisabled: false,
            },
            formValidations: {
                firstName: {
                    alpha_spaces: true,
                },
                lastName: {
                    alpha_spaces: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                acceptTerms: {
                    required: true
                },
                password: {
                    required: true,
                    min: 6,
                },
                phone: {
                    required: true,
                    min: 10,
                    max: 15,
                },
            }
        }
    },
    props: {
        props: {
            invitationCode: {
                type: String,
                default: ''
            },
            invitationEmail: {
                type: String,
                default: ''
            },
            invitationType: {
                type: String,
                default: ''
            }
        },
    },
    methods: {
        ...mapActions(["LogIn", "GoogleLogIn"]),
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        googleCallBack(googleUserResponse) {
            const googleUser = this.parseJwt(googleUserResponse.credential);

            alert("Google Login successful")

            console.log("----------")
            console.log("TOKEN: " + googleUserResponse.credential)
            console.log(googleUser)
            console.log("----------")

            let user = {
                email: googleUser.email,
                google_id: googleUser.sub,
                google_token: googleUserResponse.credential,
                first_name: googleUser.given_name,
                last_name: googleUser.family_name,
                photo_url: googleUser.picture
            };

            //this.tryGoogleLogin(user)

        },
        parseJwt(token) {
            return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
        },
        onFailure(e) {
            console.log(e);
        },
        setRemember() {
            this.model.rememberMeChecked = !this.model.rememberMeChecked;
        },

        validateBeforeSubmit(e) {
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#loginForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        validateBeforeadminSubmit(e) {
            let vm = this;
            e.preventDefault();
            vm.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    vm.tryLogin();
                    /* axios.post('/user/login', {
                         email: vm.form.email,
                         password: vm.form.password,
                     }).then(response => {
                         if (response.data.response.error.error_code === 0) {
                             vm.showAlert('Success', 'Welcome ' + vm.form.email, true);
                         }
                     }).catch(error => {
                         console.log(error)
                         vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                     });*/
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        async tryLogin() {
            const vm = this
            const loading = vm.$loading({
                lock: true,
                text: 'Please wait logging in...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let user = {
                email: vm.form.email,
                password: vm.form.password
            }
            try {
                await vm.LogIn(user)
                loading.close();
                const isPresent = vm.invitationCode && vm.invitationType && vm.invitationEmail
                let isBlank = true
                if (isPresent) {
                    isBlank = vm.invitationCode !== '' && vm.invitationType !== '' && vm.invitationEmail !== ''
                }
                const validateUrl = localStorage.getItem('validateUrl') ? JSON.parse(localStorage.getItem('validateUrl')) : null;
                if (validateUrl) {
                    vm.$router.push({name: 'validate', query: validateUrl.query});
                } else if (!isBlank) {
                    if (vm.invitationType === 'project') {
                        vm.joinProject()
                    } else if (vm.invitationType === 'field_team') {
                        vm.joinFieldTeam()
                    } else if (vm.invitationType === 'organisation') {
                        vm.joinOrganisation()
                    }
                } else {
                    vm.$router.push("/platform/projects");
                }
            } catch (e) {
                loading.close();
                vm.showAlert('Error', vm.$store.state.auth.authenticationErrorMsg, false);
            }
        },
        async tryGoogleLogin(user) {
            try {
                await this.GoogleLogIn(user)
                const validateUrl = localStorage.getItem('validateUrl') ? JSON.parse(localStorage.getItem('validateUrl')) : null;
                if (validateUrl) {
                    this.$router.push({name: 'validate', query: validateUrl.query});
                } else {
                    this.$router.push("/platform/projects");
                }
            } catch (e) {
                this.showAlert('Error', this.$store.state.auth.authenticationErrorMsg, false);
            }
        },
        validateSignUpForm(e) {
            var acceptTerms = document.querySelector("input[name=acceptTerms]").checked;
            if (!acceptTerms) {
                $("#error").show();
            } else {
                $("#error").hide();
            }
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#signupForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        getErrorAcceptTerms() {
            setTimeout(function () {
                var acceptTerms = document.querySelector("input[name=acceptTerms]").checked;
                if (acceptTerms) {
                    $("#error").hide();
                } else {
                    $("#error").show();
                }
            }, 200);
        },
        validateresetSubmit(e) {
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#resetForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        validateforgotSubmit(e) {
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#forgotForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        validateNumber(e) {
            let val = e.target.value;
            let rex = /^[0-9+]+$/;
            if (rex.test(val)) {
                return true;
            } else {
                e.target.value = val.slice(0, -1);
            }
        },

        joinProject() {
            let vm = this;
            axios.get("/project/join/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Project joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        },

        joinFieldTeam() {
            let vm = this;
            axios.get("/field_team/join/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Field team joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        },

        joinOrganisation() {
            let vm = this;
            axios.get("/organisation/join/member/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Organisation joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        },

        loadGoogleScript() {
            window.google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID,
                callback: this.googleCallBack
            });
            window.google.accounts.id.renderButton(
                document.getElementById("signin_button"),
                {theme: "outline", size: "large", shape: "circle"}  // customization attributes
            );
        }

    },
    mounted() {

        if (this.$store.state.auth.authenticationErrorMsg !== '') {
            this.showAlert('Error', this.$store.state.auth.authenticationErrorMsg, false);
            this.$store.state.auth.authenticationErrorMsg = ''
        }

        this.loadGoogleScript();

    },
}
</script>

<style type="text/css" scoped>
.buttonright {
    position: absolute;
    width: 113px;
    right: 0;
    margin-right: 20px;
    top: 18px;
}

.setformheight {
    height: 65px;
}

.loginBtn--google {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0.2em;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    box-shadow: 1px 1px 6px grey;
    padding: 5px;
}

.navbar-toggler {
    display: none;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
    .buttonright {
        top: 6px !important;
    }
}

@media only screen and (max-device-width: 768px) {
    .buttonright {
        top: -5px;
    }

}

</style>
