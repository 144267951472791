<template>

    <div>

        <div class="row">
            <div class="col-12">
                <h4>Export Sterilization Data</h4>
                <p>Export creates a summary record for each animal using the most recent exam and sterilization data (if
                    there are multiple records).</p>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-md-3">
                        <p>Select Date(s)</p>
                        <el-select v-model="dateFilterOption">
                            <el-option value="All">All</el-option>
                            <el-option value="Catch Date Range">Catch Date Range</el-option>
                        </el-select>
                    </div>

                    <div class="col" v-if="dateFilterOption==='Catch Date Range'">
                        <p>Select Date(s)</p>
                        <v-md-date-range-picker :presets="dateRangePresets" :start-date="startDatePast"
                                                :end-date="endDatePast"
                                                @change="handleChange"
                                                style="width:100% !important"></v-md-date-range-picker>
                        <span>Based on caught date</span>
                    </div>
                </div>
            </div>

            <div class="col-12" style="display: none">
                <div v-html="tableData"></div>
            </div>

            <div class="col-12 text-right">
                <button :disabled="isGeneratingData" type="button" class="btn btn-primary"
                        @click="downloadReport">{{ getDownloadButtonLabel }}
                </button>
            </div>

        </div>

    </div>

</template>

<script>


import dateRangeDefaultPresetsMixin from '../../../mixins/DateRangeDefaultPresetsMixin';
import VMdDateRangePicker from "v-md-date-range-picker";
import Vue from "vue";
import mixin from "../../../mixins/GlobalMixin";

Vue.use(VMdDateRangePicker);


export default {
    data() {
        return {
            dateFilterOption: 'All',

            generationStatus: null,
            isGeneratingData: false,
            startDatePast: '',
            endDatePast: '',
            dateRangePresets: [],
            tableData: '',
            startDate: '',
            endDate: '',
            project: null,
            project_id: '',
            fileName: null,
        }
    },
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    methods: {
        tableToCSV() {

            const vm = this

            vm.generationStatus = 'creating_csv'

            // Variable to store the final csv data
            let csv_data = [];

            // Get each row data
            let table = document.getElementById('csv-table');
            let rows = table.getElementsByTagName('tr');

            for (let i = 0; i < rows.length; i++) {

                // Get each column data
                let cols = rows[i].querySelectorAll('td,th');

                // Stores each csv row data
                let csvrow = [];
                for (let j = 0; j < cols.length; j++) {

                    // Get the text data of each cell
                    // of a row and push it to csvrow
                    csvrow.push(cols[j].innerHTML);
                }

                // Combine each column value with comma
                csv_data.push(csvrow.join(","));
            }

            // Combine each row data with new line character
            csv_data = csv_data.join('\n');

            // Call this function to download csv file
            this.downloadCSVFile(csv_data);

        },
        downloadCSVFile(csv_data) {
            const vm = this
            vm.generationStatus = 'downloading_file'

            // Create CSV file object and feed
            // our csv_data into it
            let CSVFile = new Blob([csv_data], {
                type: "text/csv"
            });

            // Create to temporary link to initiate
            // download process
            let temp_link = document.createElement('a');

            // Download csv file
            temp_link.download = vm.fileName + ".csv";
            let url = window.URL.createObjectURL(CSVFile);
            temp_link.href = url;

            // This link should not be displayed
            temp_link.style.display = "none";
            document.body.appendChild(temp_link);

            // Automatically click the link to
            // trigger download
            temp_link.click();
            document.body.removeChild(temp_link);

            setTimeout(function () {
                vm.generationStatus = null
            }, 500)

        },
        downloadReport() {
            let vm = this

            if (vm.dateFilterOption === 'Catch Date Range') {
                if (vm.startDate === '' || vm.endDate === '') {

                    this.startDate = vm.formatDate(vm.dateRangePresets[4].range[0]);
                    this.endDate = vm.formatDate(vm.dateRangePresets[4].range[1]);

                }
            } else if (vm.dateFilterOption === 'All') {
                this.startDate = '';
                this.endDate = '';
            }

            vm.isGeneratingData = true
            vm.generationStatus = 'calling_api'
            axios.get('/pm/reports/generate/full/program/data/' + vm.project_id, {
                params: {
                    start_date: vm.startDate,
                    end_date: vm.endDate,
                }
            })
                .then(response => {
                    console.log(response)
                    vm.tableData = response.data.response.data.data
                    vm.fileName = response.data.response.data.file_name

                    setTimeout(() => {
                        vm.tableToCSV()
                        vm.isGeneratingData = false
                    }, 2000)

                })
                .catch(error => {
                    vm.generationStatus = 'api_error'
                    console.log(error)
                })
        },
        handleChange(values) {
            let sDate = this.formatDate(values[0]._d);
            let eDate = this.formatDate(values[1]._d);

            //alert(sDate)
            //alert(eDate)

            this.startDate = sDate;
            this.endDate = eDate;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
    },
    mounted() {
        let vm = this;

        vm.project = vm.$store.getters.getCurrentSelectedProject;
        vm.project_id = vm.project.id;


        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.startDatePast = firstDay.toLocaleDateString('en-US');
        this.endDatePast = lastDay.toLocaleDateString('en-US');
        this.dateRangePresets = this.getDateRangePresets();
    },
    computed: {
        getDownloadButtonLabel() {
            if (this.generationStatus === 'api_error') {
                return "API Error"
            }
            if (this.generationStatus === 'calling_api') {
                return "Generating Report..."
            }
            if (this.generationStatus === 'creating_csv') {
                return "Creating CSV..."
            }
            if (this.generationStatus === 'downloading_file') {
                return "Downloading File..."
            }
            return "Download"
        }
    }

}

</script>

<style scoped>


</style>
