<template>
    <div>
        <!--<h5>Assign Users and Teams on Areas</h5>-->
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <h5 class="set_font_title">Assigned Users</h5>
            </div>
            <div v-if="project && project.organisation_id && !isReadOnly" class="col-sm-12 col-md-4 text-right">
                <el-button @click="dialogVisible=true">+ Add User</el-button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 card">
                <div class="card-body row">
                    <div class="col-sm-12 col-xl-12">
                        <el-table
                            ref="multipleTable"
                            v-loading="loadingData"
                            :data="assignedUsers"
                            style="width: 100%"
                            border
                            :pagination="true">
                            <el-table-column
                                label="First name">
                                <template slot-scope="scope">
                                    {{ scope.row.user.first_name }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="Last name">
                                <template slot-scope="scope">
                                    {{ scope.row.user.last_name }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="Email">
                                <template slot-scope="scope">
                                    {{ scope.row.user.email }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="Action">
                                <template slot-scope="scope">
                                    <el-button @click="deleteUser(scope.row.user.email)" type="danger">Delete</el-button>
                                    <el-button @click="updatePasswordDialogVisible=true;selectedUser=scope.row.user" type="primary">Update Password</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <el-dialog width="50%" title="Add New User" :visible.sync="dialogVisible">
                <form @submit.prevent="addUser">
                    <div class="row">
                        <div class="col-6">
                            <label class="invite-user-label">First Name</label>
                            <fg-input v-model="userForm.firstName"
                                type="text"
                                name="First Name"
                                :disabled="show.actionLoading"
                                placeholder="Enter First Name"></fg-input>
                        </div>

                        <div class="col-6">
                            <label class="invite-user-label">Last Name</label>
                            <fg-input v-model="userForm.lastName"
                                type="text"
                                name="Last Name"
                                :disabled="show.actionLoading"
                                placeholder="Enter Last Name"></fg-input>
                        </div>
                        <div class="col-12">
                            <label class="invite-user-label">Email</label>
                            <fg-input v-model="userForm.email"
                                type="text"
                                ref="inviteUser"
                                :disabled="show.actionLoading"
                                placeholder="Enter user email..."></fg-input>
                            <p>*Email address could be dummy or fake</p>
                        </div>
                        <div class="col-6">
                            <label class="invite-user-label">Password</label>
                            <fg-input v-model="userForm.password"
                                type="password"
                                name="Password"
                                :disabled="show.actionLoading"
                                placeholder="Enter Password"></fg-input>
                        </div>
                        <div class="col-6">
                            <label class="invite-user-label">Confirm Password</label>
                            <fg-input v-model="userForm.confirmPassword"
                                type="password"
                                name="Password"
                                :disabled="show.actionLoading"
                                placeholder="Enter Password"></fg-input>
                        </div>
                        <div class="col-12 text-left">
                            <p>*Password will remain unchanged for any existing users</p>
                        </div>
                        <div class="col-12 text-right">
                            <el-button native-type="submit" type="primary" :loading="show.actionLoading">
                                {{ show.actionLoading ? 'Adding' : 'Add' }} User
                            </el-button>
                        </div>
                    </div>
                </form>
            </el-dialog>

            <el-dialog width="50%" title="Update password" :visible.sync="updatePasswordDialogVisible">
                <form @submit.prevent="updatePassword">
                    <div class="row pb-3">
                        <div class="col">
                            Email: {{ selectedUser.email }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label class="invite-user-label">Password</label>
                            <fg-input v-model="userForm.password"
                                type="password"
                                name="Password"
                                :disabled="show.actionLoading"
                                placeholder="Enter Password"></fg-input>
                        </div>
                        <div class="col-6">
                            <label class="invite-user-label">Confirm Password</label>
                            <fg-input v-model="userForm.confirmPassword"
                                type="password"
                                name="Password"
                                :disabled="show.actionLoading"
                                placeholder="Enter Password"></fg-input>
                        </div>
                        <div class="col-12 text-right">
                            <el-button native-type="submit" type="primary" :loading="show.actionLoading">
                                {{ show.actionLoading ? 'Updating' : 'Update' }} password
                            </el-button>
                        </div>
                    </div>
                </form>
            </el-dialog>
        </div>

    </div>
</template>
<script>

import mixin from '../../../mixins/GlobalMixin';
import {Dialog, Button} from 'element-ui';
import Swal from 'sweetalert2';
import VOffline from 'v-offline';
import healthClinicService from "../../../services/health-clinic.service";
export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
    },
    data() {
        return {

            loadingData: false,
            isReadOnly: false,
            dialogVisible: false,
            project: null,
            assignedUsers: [],
            show: {
                actionLoading: false
            },
            userForm: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: ''
            },
            updatePasswordDialogVisible: false,
            selectedUser: {}

        }
    },
    mixins: [mixin],
    methods: {
        init() {
            this.$store.dispatch("setScreenName", "Assignments");
            this.getAssignedUsers();
        },
        getAssignedUsers() {
            let vm = this;
            vm.loadingData = true;
            healthClinicService.getAssignedUsers(vm.projectId)
            .then((apiResponse) => {
                vm.loadingData = false;
                vm.assignedUsers = apiResponse.response.data.assigned_users;
            });
        },
        addUser() {
            const vm = this;
            const payload = {
                first_name: vm.userForm.firstName,
                last_name: vm.userForm.lastName,
                email: vm.userForm.email,
                password: vm.userForm.password,
                password_confirmation: vm.userForm.confirmPassword
            };
            vm.show.actionLoading = true;
            healthClinicService.assignUser(vm.projectId, payload)
            .then((apiResponse) => {
                apiResponse = apiResponse.response;
                vm.show.actionLoading = false;
                if (!apiResponse.error.error_code && !apiResponse.error.error_msg) {
                    vm.dialogVisible = false;
                    vm.showAlert('Assigned', "User "+vm.userForm.email+" Assigned with Password: "+vm.userForm.password, true);
                    vm.assignedUsers = apiResponse.data.assigned_users;
                } else {
                    vm.showAlert('Error', apiResponse.error.msg, false);
                }
            }).catch(error => {
                vm.show.actionLoading = false;
                vm.showAlert('Error', error.response.error.error_msg, false);
            });
        },
        deleteUser(userEmail) {
            const vm = this;
            new Swal({
                    title: "Are you sure you want to delete user?",
                    text: `You won't be able to revert this!`,
                    type: "warning",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Delete"
                }).then(function (result) {
                    if (result.value) {
                        const loading = vm.$loading({
                            lock: true,
                            text: 'Please wait deleting user...',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        healthClinicService.deleteUser(vm.projectId, userEmail)
                        .then((apiResponse) => {
                            apiResponse = apiResponse.response;
                            loading.close();
                            if (!apiResponse.error.error_code && !apiResponse.error.error_msg) {
                                vm.showAlert('Success', apiResponse.error.msg, true);
                                vm.assignedUsers = apiResponse.data.assigned_users;
                            } else {
                                vm.showAlert('Error', apiResponse.error.msg, false);
                            }
                        }).catch(error => {
                            loading.close();
                            vm.showAlert('Error', error.response.error.error_msg, false);
                        });
                    }
                });
        },

        updatePassword() {
            const vm = this;
            const payload = {
                new_password: vm.userForm.password,
                new_password_confirmation: vm.userForm.confirmPassword
            };
            vm.show.actionLoading = true;
            healthClinicService.updateUserPassword(vm.projectId, vm.selectedUser.id, payload)
            .then((apiResponse) => {
                apiResponse = apiResponse.response;
                vm.show.actionLoading = false;
                if (!apiResponse.error.error_code) {
                    vm.updatePasswordDialogVisible = false;
                    vm.showAlert('Password updated', `User ${apiResponse.data.email} updated with Password: ${apiResponse.data.new_password}`, true);
                } else {
                    vm.showAlert('Error', apiResponse.error.msg, false);
                }
            }).catch(error => {
                vm.show.actionLoading = false;
                vm.showAlert('Error', error.response.error.error_msg, false);
            });
        }

    },
    mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        vm.isReadOnly = false
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if (r.role_name == 'VIEWER') {
                vm.isReadOnly = true
            }
        });
        vm.init();
    }
}

</script>
<style scoped>
.set_font_title {
    font-size: 20px;
    font-weight: 600;
    color: black;
}

</style>
