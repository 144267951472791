import defaultLogo from './assets/images/HSIApps-Trnprnt-green.png';

export default {
    appName: 'HSAPPS',
    logo: defaultLogo,
    title: 'MVD App',
    project: 'mvd',
    nav: [
        {
            name: 'Dashboard',
            icon: 'ti-dashboard',
            path: '/project/details',
            p: '/overview',
        },
        {
            name: 'Areas',
            icon: 'ti-map-alt',
            path: 'areas',
            p: '/areas',
        },
        {
            name: 'Assignments',
            icon: 'ti-check-box',
            path: '/project/catch_rlease_user',
            p: '/assignments',
        },
        {
            name: 'Records',
            icon: 'ti-view-list-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Awaiting Exam',
                    path: '/project/awaiting_evaluation',
                    p: '/awaitingEvaluation',
                },
                {
                    name: 'Cleared for Sterilization',
                    path: '/project/not_operated',
                    p: '/clear-for-sterilization',
                },
                {
                    name: 'Pre-op Hold',
                    path: '/project/pre_op_hold',
                    p: '/preOpHold',
                },
                {
                    name: 'Sterilized',
                    path: '/project/operated',
                    p: '/operated',
                },
                {
                    name: 'Post-op Hold',
                    path: '/project/post_op_hold',
                    p: '/postOpHold',
                },
                /*{
                    name: 'Treatment Status',
                    path: '/project/treatment_status',
                    p: '/treatmentstatus',
                },*/
                {
                    name: 'Cleared for Release',
                    path: '/project/ready_for_release',
                    p: '/readyforrelease',
                },
                {
                    name: 'Released/Out-of-care',
                    path: '/project/released',
                    p: '/released',
                },
                {
                    name: 'All',
                    path: '/project/all',
                    p: '/all',
                },
                {
                    name: 'Archived Records',
                    path: '/catch_release/archived/records',
                    p: '/archived',
                },
                {
                    name: 'Deaths',
                    path: '/catch_release/death/records',
                    p: '/death',
                }
            ],
        },
        {
            name: 'Reports',
            icon: 'ti-bar-chart-alt',
            id: 'third-party',
            children: [
                {
                    name: 'City/District/Village',
                    path: '/project/city',
                    p: '/report/city',
                },
                {
                    name: 'Area',
                    path: '/project/area',
                    p: '/report/area',
                },
                {
                    name: 'Map',
                    path: '/project/dpm_report_map',
                    p: '/report/map',
                },
                {
                    name: 'Sterilization Data',
                    path: '/project/full_program_data',
                    p: '/fullprogramdata',
                },
                /*{
                    name: 'Microchip',
                    path: '/project/microchip',
                    p: '/report/microchip',
                },*/
            ],
        },
        {
            name: 'Settings',
            icon: 'nc-icon nc-settings',
            path: '/pm/edit/project',
            p: '/settings',
        }
    ],
};
