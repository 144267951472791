<template>

    <div>
        <div class="login-page">
            <div class="wrapper wrapper-full-page">
                <div class="full-page login-page section-image">
                    <div class="content">
                        <div class="container">

                            <div class="col-lg-4 col-md-6 ml-auto mr-auto">

                                <card type="login" footer-line>
                                    <div class="text-center">
                                        <img :src="hsappsLogo" height="70" style="margin: 0 auto;">
                                    </div>
                                    <hr>
                                    <div class="text-left">
                                        <div class="form-group">
                                            <fg-input type="email"
                                                      label="Email Address"
                                                      name="email"
                                                      @keyup.enter="tryLogin"
                                                      v-model="form.email">
                                            </fg-input>
                                        </div>

                                        <div class="form-group">
                                            <fg-input type="password"
                                                      label="Password"
                                                      name="password"
                                                      @keyup.enter="tryLogin"
                                                      v-model="form.password">
                                            </fg-input>
                                        </div>
                                        <hr>
                                        <div slot="footer">
                                            <button v-on:click="tryLogin"
                                                    class="btn mb-3 btn-round btn-block btn-info">
                                                LOGIN AS SUPER ADMIN
                                            </button>
                                        </div>

                                    </div>

                                </card>

                            </div>
                        </div>
                    </div>
                    <div class="full-page-background"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {Button, Card, Checkbox} from "../UIComponents";

//import hsappsLogo from '../../../assets/images/hsapps-green.png'
import hsappsLogo from '../../../assets/images/HSIApps-Trnprnt-green.png'
import {mapActions} from "vuex";

export default {
    components: {
        Card,
        [Checkbox.name]: Checkbox,
        [Button.name]: Button,
    },
    data()
    {
        return {
            hsappsLogo: hsappsLogo,

            form: {
                email: '',
                password: '',
            },
        }
    },
    methods: {
        ...mapActions(["LogInSuperAdmin"]),
        getError(fieldName)
        {
            return this.errors.first(fieldName);
        },

        validateBeforeSubmit(e)
        {
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#loginForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        validateBeforeadminSubmit(e)
        {
            let vm = this;
            e.preventDefault();
            vm.$validator.validateAll().then((result) => {
                if (result) {
                    vm.tryLogin();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        async tryLogin()
        {
            const loading = this.$loading({
                lock: true,
                text: 'Please wait logging in...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let user = {
                email: this.form.email,
                password: this.form.password
            }
            try {
                await this.LogInSuperAdmin(user)
                loading.close();
                const validateUrl = localStorage.getItem('validateUrl') ? JSON.parse(localStorage.getItem('validateUrl')) : null;
                if (validateUrl) {
                    this.$router.push({name: 'validate', query: validateUrl.query});
                } else {
                    this.$router.push("/admin/application");
                }
            } catch (e) {
                console.log(e)
                loading.close();
                this.showAlert('Error', this.$store.state.auth.authenticationErrorMsg, false);
            }
        },
    },
    mounted()
    {

        if (this.$store.state.auth.authenticationErrorMsg !== '') {
            this.showAlert('Error', this.$store.state.auth.authenticationErrorMsg, false);
        }


    },
}
</script>

<style type="text/css" scoped>
.buttonright {
    position: absolute;
    width: 113px;
    right: 0;
    margin-right: 20px;
    top: 18px;
}

.setformheight {
    height: 65px;
}

.loginBtn--google {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0.2em;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    box-shadow: 1px 1px 6px grey;
    padding: 5px;
}

.navbar-toggler {
    display: none;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
    .buttonright {
        top: 6px !important;
    }
}

@media only screen and (max-device-width: 768px) {
    .buttonright {
        top: -5px;
    }

}

</style>
