<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox ">
                    <div class="ibox-content">
                        <div class="panel-heading">
                            <h2 class="set_font_title">All Vaccination Records</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="card setShadow">
                                    <div class="card-body text_left row">
                                        <div class="col-sm-9">
                                            <b class="set_b_font_card">Animals Vaccinated</b>
                                            <div style="font-size: 16px; font-weight: 600;">
                                                {{ getVaccinatedAnimalsCount }}
                                            </div>
                                            <p>Of which {{ getOuterVaccinatedAnimalsCount }} animals are out-of-area</p>
                                        </div>
                                        <div class="col-sm-3">
                                            <button v-bind:disabled="!onLine" type="submit"
                                                    class="btn btn-primary" @click="listProjectVaccinations">List
                                            </button>
<!--                                            <button v-bind:disabled="!onLine" type="submit"
                                                    class="btn btn-primary" @click="viewProjectVaccinationsOnMap">Map
                                            </button>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row setMarginTop set_res_margin_top">
                            <div class="col-xs-12 col-md-12 col-xl-12">
                                <div class="row setTitleDiv">
                                    <p>By Project Areas</p>
                                </div>
                                <div class="row setMarginTop set_res_margin_top">
                                    <div class="col-xs-6 col-sm-12 col-md-3 col-xl-3">
                                        <span style="color: #4285f4;">Select Filter</span>
                                        <div class="form-group">
                                            <template>
                                                <el-select v-model="selectFilter" filterable placeholder="All"
                                                           class="el_select_width">
                                                    <el-option
                                                            v-for="area in filterArrAreas"
                                                            :key="area.id"
                                                            :label="area.name"
                                                            :value="area.key">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-12 col-md-3 col-xl-3" v-if="!inputshow">
                                        <fg-input type="text" style="margin-top: 23px;" :placeholder="placeholderVal"
                                                  name="selectFilterVal" v-model="selectFilterVal">
                                        </fg-input>
                                    </div>
                                    <div class="col-xs-6 col-sm-12 col-md-3 col-xl-3" v-if="!daterangPicker">
                                        <span style="color: #4285f4;">Select Date Range</span>
                                        <v-md-date-range-picker :presets="dateRangePresets" :start-date="startDatePast" :end-date="endDatePast"
                                                                @change="handleChange"
                                                                style="width:100% !important"></v-md-date-range-picker>
                                    </div>
                                </div>
                                <p></p>
                                <div class="setMarginTop set_res_margin_top" v-if="filteredVaccinationArea.length == 0"
                                     style="text-align: center;">
                                    <p class="no_rec_msg">No Records found</p>
                                </div>
                                <div class="row setMarginTop">
                                    <!--                                    <div class="col-md-12" v-if="filteredVaccinationArea.length">
                                                                            <get-stats />
                                                                        </div>-->
                                    <div class="col-xs-6 col-sm-12 col-md-4 col-xl-4 set_cursor"
                                         v-for="item in filteredVaccinationArea" :key="item.id"
                                         @click="areaDetail(item)">
                                        <area-card
                                                :areaId="item.id"
                                                :areaImage="item.areaImage"
                                                :title="item.name"
                                                :vaccination-count="item.vaccinations_count"
                                                :survey-count="item.surveys_count"
                                                :country="item.google_country"
                                                :createdOn="item.created_at"
                                                :areaState="item.google_admin_area_level1!='null'?item.google_admin_area_level1:'Not specified'"
                                                :locality="item.google_locality!='null'?item.google_locality:'Not specified'"
                                                :submitter_name="item.submitter_name?item.submitter_name:'N/A'"
                                                :fieldUsers="item.vaccinations_count"
                                                :areaStatus="item.status"
                                                :statusColor="item.status_color_code"
                                                :vaccinationStatus="item.status_info.vaccination"
                                                :surveyStatus="item.status_info.survey"
                                        ></area-card>
                                        <!-- <div class="area-card-footer">
                                            <p class="area-contributors">Animal Vaccinated - UC</p>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../../mixins/GlobalMixin';
import dateRangeDefaultPresetsMixin from '../../mixins/DateRangeDefaultPresetsMixin';
import AreaCard from "./UIComponents/Cards/VaccinationRecordCard.vue";
import {Select, Option,} from 'element-ui';
import Swal from 'sweetalert2';
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";
import VOffline from 'v-offline';
import GetStats from '../modules/GetStats.vue'

Vue.use(VMdDateRangePicker);

export default {
    components: {
        "area-card": AreaCard,
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline,
        GetStats,
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data()
    {
        return {
            areaId: '',
            title: '',
            createdOn: '',
            country: '',
            areaState: '',
            locality: '',
            fieldUsers: '',
            areaImage: '',
            selectFilter: "",
            selectFilterVal: "",
            project: null,
            area: [],
            filterArrAreas: [
                {id: 1, name: 'All', 'key': 'all'},
                {id: 2, name: 'Created On', 'key': 'created_at'},
                {id: 3, name: 'Modifed On', 'key': 'last_modified'},
                {id: 4, name: 'Country', 'key': 'country'},
                {id: 5, name: 'State/Province', 'key': 'google_admin_area_level1'},
                {id: 6, name: 'City/District/Village', 'key': 'google_locality'},
            ],
            stratDate: '',
            endDate: '',
            inputshow: true,
            daterangPicker: true,
            placeholderVal: "",
            vaccSurFilterRecords: "",
            submitter_name: '',
            startDatePast: '',
            endDatePast: '',
            dateRangePresets: []
        }
    },
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    methods: {
        FilterAreas()
        {
            console.log("filter click");
        },
        handleChange(values)
        {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.stratDate = sDate;
            this.endDate = eDate;
        },
        formatDate(date)
        {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        viewProjectVaccinationsOnMap()
        {
            localStorage.setItem('vaccinationProjectLevelViewMap', 1);
            //window.location = '/project/view_on_map/' + this.projectId;
            this.$router.push({name: 'projectVaccinationMapview', params: {projectId: this.projectId}})
        },
        listProjectVaccinations()
        {
            localStorage.setItem('vaccinationProjectLevelViewList', 1);
            //window.location = '/project/record_detail_list/' + this.projectId;
            this.$router.push({name: 'projectVaccinationList', params: {projectId: this.projectId}})
        },
        getArea()
        {
            axios.get("/area?project_id=" + this.projectId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        //this.area = response.data.response.data;
                        let areaArr = response.data.response.data;
                        this.area = this.matchSubmitName(areaArr, this.vaccSurFilterRecords).filter(x => !!x);
                        //console.log(matchSubmitName);
                    }
                })
                .catch(error => {
                    if (error.response.data.response.error) {

                    }
                });
        },
        matchSubmitName(array1, array2)
        {
            array1.forEach(function (value1, key1) {
                var area_id = value1.id;
                array1[key1]['submitter_name'] = '';
                array2.forEach(function (value2, key2) {
                    let submitter_name = value2.submitter_name;
                    if (value2.area_id == area_id) {
                        array1[key1]['submitter_name'] = submitter_name;
                    }
                })
            });
            return array1;
        },
        areaDetail(item)
        {
            Bus.$emit("area_id", item.id);
            localStorage.setItem("VaccinationAreaName", item.name)
            localStorage.setItem("VaccinationAreaId", item.id)
            localStorage.setItem("selectedArea", item.id)
            localStorage.setItem("vaccinationProjectLevelViewList", 0)
            //window.location = '/project/record_detail_list/' + this.projectId;
            this.$router.push({name: 'areaVaccinationList', params: {areaId: item.id}})
        },
        getAllAreaDetails()
        {
            axios.get("/area/all/details/" + this.projectId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        let vm = this;
                        let assigned_survey_areas = response.data.response.data.assigned_survey_areas;
                        let assigned_vaccination_areas = response.data.response.data.assigned_vaccination_areas;
                        let survey = this.getUnique(Object.keys(assigned_survey_areas).flatMap((itm) => assigned_survey_areas[itm]).filter(x => !!x), 'area_id');
                        let vaccination = this.getUnique(Object.keys(assigned_vaccination_areas).flatMap((itm) => assigned_vaccination_areas[itm]).filter(x => !!x), 'area_id');
                        vm.vaccSurFilterRecords = vm.matchUserRecord(survey, vaccination).filter(x => !!x);
                    }
                })
                .catch(error => {
                });
        },
        getUnique(arr, comp)
        {

            const unique = arr
                .map(e => e[comp])

                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)

                // eliminate the dead keys & store unique objects
                .filter(e => arr[e]).map(e => arr[e]);

            return unique;
        },
        matchUserRecord(array1, array2)
        {
            array1.forEach(function (value1, key1) {
                var area_id = value1.area_id;
                array2.forEach(function (value2, key2) {
                    if (value2.area_id == area_id) {
                        delete array2[key2]
                    }
                })
            });
            return array1.concat(array2);
        }
    },
    computed: {
        filteredVaccinationArea: function () {
            var type = this.selectFilter;
            if (type == "country" || type == "google_admin_area_level1" || type == "google_locality") {
                this.daterangPicker = true;
                this.inputshow = false;
                if (type == "country") {
                    this.placeholderVal = "Enter Country";
                } else
                    if (type == "google_admin_area_level1") {
                        this.placeholderVal = "Enter State/Province";
                    } else
                        if (type == "google_locality") {
                            this.placeholderVal = "Enter City/District/Village";
                        }
                if (this.selectFilter && this.selectFilterVal) {
                    return this.area.filter(item => {
                        if (item[type] != null) {
                            return (
                                item[type].toLowerCase().indexOf(this.selectFilterVal.toLowerCase()) > -1
                            );
                        }
                    });
                } else {
                    return this.area.filter(item => {
                        return item
                    });
                }
            } else
                if (type == "last_modified" || type == "created_at") {
                    this.inputshow = true;
                    this.daterangPicker = false;
                    try {
                        setTimeout(function () {
                            var x = document.getElementsByTagName("label")[0].textContent = "";
                        }, 50);
                    } catch (e) {
                    }
                    if (this.selectFilter && this.stratDate && this.endDate) {
                        return this.area.filter(item => {
                            return (
                                new Date(this.formatDate(item[type])) >= new Date(this.stratDate) && new Date(this.formatDate(item[type])) <= new Date(this.endDate)
                            );
                        });
                    } else {
                        return this.area.filter(item => {
                            return item
                        });
                    }
                } else {
                    this.daterangPicker = true;
                    this.inputshow = true;
                    return this.area.filter(item => {
                        return item
                    });
                }
        },
        getVaccinatedAnimalsCount: function () {
            let count = 0;
            for (let i = 0; i < this.area.length; i++) {
                count = count + this.area[i].vaccinations_count;
            }
            return count;
        },
        getOuterVaccinatedAnimalsCount: function () {
            let count = 0;
            for (let i = 0; i < this.area.length; i++) {
                count = count + this.area[i].outer_vaccinations_count;
            }
            return count;
        }
    },
    mounted()
    {
        this.getAllAreaDetails();
        setTimeout(() => {
            this.getArea();
        }, 300);
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        //this.area = vm.project.areas;
        this.projectId = vm.project.id;
        //Bus.$emit("projectype", vm.project);
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);
        this.startDatePast = firstDay.toLocaleDateString('en-US');
        this.endDatePast = lastDay.toLocaleDateString('en-US');
        this.dateRangePresets = this.getDateRangePresets();
    }
}

</script>
<style scoped>
.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}

#pac-container {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls label {
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

.setTitleDiv p {
    font-size: 17px;
    font-weight: 600;
    color: black;
}

.el_select_width {
    width: 100% !important;
}

.setShadow {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.card .numbers p {
    color: #9A9A9A;
    font-size: 14px;
}

.card .numbers {
    font-size: 1.5em !important;
}

.card .card-body {
    text-align: center;
}

.set_b_font_card {
    font-size: 17px !important;
    color: #9a9a9a !important;
}

.set_btn {
    text-align: right;
    margin-top: -5px;
}

@media (max-width: 768px) {
    .set_btn {
        text-align: center !important;
        margin-top: -5px;
    }
}

.btn_card {
    cursor: pointer;
    height: 85px;
}

.no_rec_msg {
    font-size: 20px;
    height: 220px;
}

.text-field__filled {
    display: block;
    font-size: 18px;
    padding: 4px 10px 10px 5px;
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #757575;
}

.set_font_title {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black !important;
    margin-left: -12px !important;
}

/* .area-card-footer{
  position: absolute;
  margin-top: -60px;
  margin-left: 185px;
}
@media (min-width: 320px)and (max-width: 374px){
    .area-card-footer{
      position: absolute;
      margin-top: -60px;
      margin-left: 70px;
    }
}
@media (min-width: 375px)and (max-width: 424px){
    .area-card-footer{
      position: absolute;
      margin-top: -60px;
      margin-left: 90px;
    }
}
@media (min-width: 425px)and (max-width: 767px){
    .area-card-footer{
      position: absolute;
      margin-top: -60px;
      margin-left: 120px;
    }
}
@media (min-width: 768px)and (max-width: 769px){
    .area-card-footer{
      position: absolute;
      margin-top: -60px;
      margin-left: 300px;
    }
}
@media (min-width: 1024px)and (max-width: 1025px){
    .area-card-footer{
      position: absolute;
      margin-top: -47px;
      margin-left: 25px;
    }
}
@media (min-width: 1440px)and (max-width: 1441px){
    .area-card-footer{
      position: absolute;
      margin-top: -60px;
      margin-left: 100px;
    }
} */
.set_cursor {
    cursor: pointer;
}

.mdrp__activator .activator-wrapper .text-field__filled ~ label {
    top: -20px;
    font-size: 14px;
    color: #4285f4;
    display: none !important;
}

.mdrp__activator .activator-wrapper label {
    color: #999;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: .2s ease all;
    display: none !important;
}

.bar:after label {
    content: 'hi! im after';
    display: none !important;
}
</style>
