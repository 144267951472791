<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-md-6">
                        <h5 class="set_font_title">Vets</h5>
                    </div>
                    <div class="col-md-6">
                        <div class="add-vet-button">
                            <button
                                type="button"
                                class="btn btn-default"
                                style="background-color: #2a9055 !important;"
                                @click="showVetModal"
                            >
                                Add Vet
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p>Add the names of Veterinarians that will work for the organization so they can later be
                            associated with various projects. They will be associated with the animal records any time a
                            veterinarian is involved.</p>
                    </div>
                </div>

                <div class="row" v-for="(item, index) in vets" :key="index">
                    <div class="col-md-10 col-sm-6">
                        <p class="vet_name">{{ item.first_name + " " + item.last_name }}</p>
                    </div>
                    <div class="col-md-2 col-sm-6 icon-main">
                        <span class="ti-pencil icon-pencil" @click="updateVet(item)"></span>
                        <span
                            class="ti-trash icon-trash"
                            @click="deleteVet(item.id)"
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="vetModal" class="modal vet-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content animle-modal-content">
                    <div class="modal-header animal-header">
                        <h4 class="modal-title">{{ allowEditVet ? "Edit" : "Add" }} Veterinarian</h4>
                    </div>
                    <div class="modal-body vet-modal-body">
                        <div class="form-group row animal-row">
                            <label>First Name</label><br/>
                            <input
                                class="form-control col-sm-12"
                                type="text"
                                name="animal-name"
                                v-model="vet.first_name"
                            />
                            <br/>
                            <label>Last Name</label><br/>
                            <input
                                class="form-control col-sm-12"
                                type="text"
                                name="animal-name"
                                v-model="vet.last_name"
                            />
                            <br/>
                            <label>Phone Number</label><br/>
                            <input
                                class="form-control col-sm-12"
                                type="text"
                                name="animal-name"
                                v-model="vet.phone_number"
                            />
                            <br/>
                            <p>
                                (Note: This will make new veterinarian visible to entire organisation)
                            </p>
                        </div>
                    </div>
                    <div class="vet-footer">
                        <button
                            type="button"
                            class="btn btn-default"
                            style="background-color: #eb6532 !important;"
                            data-dismiss="modal"
                            @click="hideVetModal"
                        >
                            Close
                        </button>
                        <button
                            v-bind:disabled="!onLine"
                            type="button"
                            class="btn btn-default"
                            style="background-color: #2a9055 !important;"
                            data-dismiss="modal"
                            @click="editVet"
                        >
                            {{ allowEditVet ? "Edit" : "Add" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

import mixin from "../../mixins/GlobalMixin";
import Swal from "sweetalert2"

export default {
    components: {},
    data: function () {
        return {
            vets: [],
            allowEditVet: false,
            vetModal: false,
            vet: {
                id: "",
                first_name: "",
                last_name: "",
                phone_number: "",
            },
        };
    },
    mixins: [mixin],
    methods: {
        showVetModal() {
            this.allowEditVet = false;
            this.vetModal = true;
        },
        getVets(id) {
            var vm = this;
            axios
                .get("/organisation/get/vets", {
                    params: {
                        organisation_id: id,
                    },
                })
                .then((response) => {
                    if (response.data.response.error.error_code == 0) {
                        var vets = response.data.response.data.vets;
                        vm.vets = vets;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateVet(data) {
            this.vetModal = true;
            this.allowEditVet = true;
            this.vet = data;
        },
        addVet() {
            let vm = this;
            axios
                .put("/organisation/add/vet", {
                    first_name: vm.vet.first_name,
                    last_name: vm.vet.last_name,
                    phone_number: vm.vet.phone_number,
                    organisation_id: vm.organisationId,
                })
                .then((response) => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert("Success", response.data.response.error.msg, true);
                        vm.getVets(vm.organisationId);
                        vm.vet.first_name=''
                        vm.vet.last_name=''
                        vm.vet.phone_number=''
                        vm.vetModal = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        editVet() {
            var vm = this;
            if (!vm.allowEditVet) {
                vm.addVet();
                return;
            }
            axios
                .post("/organisation/edit/vet/" + vm.vet.id, {
                    first_name: vm.vet.first_name,
                    last_name: vm.vet.last_name,
                    phone_number: vm.vet.phone_number,
                })
                .then((response) => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert("Success", response.data.response.error.msg, true);
                        vm.getVets(vm.organisationId);
                        vm.vet.first_name=''
                        vm.vet.last_name=''
                        vm.vet.phone_number=''
                        vm.vetModal = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        deleteVet(vetId) {
            let vm = this;
            new Swal({
                title: "Alert!",
                text: "Are you sure you want to delete this vet? ",
                type: "warning",
                icon: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "yes",
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios
                        .delete("/organisation/delete/vet/" + vetId)
                        .then((response) => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert("Success", response.data.response.error.msg, true);
                                vm.getVets(vm.organisationId);
                            }
                        })
                        .catch((error) => {
                            vm.showAlert(
                                "Error",
                                error.response.data.response.error.error_msg,
                                false
                            );
                        });
                }
            });
        },
        hideVetModal() {
            this.vetModal = false;
        },
    },
    computed: {
        ...mapGetters("user", ["organisation"]),
    },
    mounted() {
        const vm = this;
        vm.organisationId = this.organisation.id;
        this.getVets(vm.organisationId);
    },
};
</script>
<style scoped>
.card-body {
    text-align: left !important;
}

a {
    color: white;
}

.a_color {
    color: black !important;
}

@media (min-width: 320px) {
    .set_row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

.set_font_title {
    margin-top: 15px;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.icon-main {
    margin-top: 5px !important;
    text-align: right;
}

.icon-pencil {
    cursor: pointer;
}

.icon-trash {
    cursor: pointer;
    margin-left: 10px;
}

.modal-title {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.vet-footer {
    text-align: right;
    margin-right: 4%;
    margin-bottom: 1%;
}

.add-vet-button {
    text-align: left;
    float: right;
    /* margin-left: 12px; */
}

.vet-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    padding: 80px;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
    display: block;
    opacity: 1 !important;
}
@media (max-width: 767px) {
    .vet-modal{
        padding: 0;
    }
    .modal-title {
        font-size: 1.4em;
    }
    .modal-body{
        padding: 20px 30px;
    }
}

.vet-modal-body {
    color: #000;
}
</style>
