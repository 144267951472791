<template>

    <div v-loading="isLoadingData" class="pm-surgery-other">
        <el-form ref="surgeryOtherForm" :model="surgeryModal" label-width="80px" label-position="top">
            <div class="row">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-6">
                            <el-form-item
                                label="Select Date Time"
                                prop="dateTime"
                                :rules="[
                                    { required: true, message: 'Please select date time', trigger: 'change' }
                                ]">
                                <el-date-picker
                                    v-model="surgeryModal.dateTime"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="Select date and time">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="col-lg-6">
                            <el-form-item
                                label="Select Veterinarian"
                                prop="vet"
                                :rules="[
                                    { required: true, message: 'Please select veterinarian', trigger: 'change' }
                                ]">
                                <el-select v-model="surgeryModal.vet" placeholder="Select">
                                    <el-option
                                        v-for="item in list.vets"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-lg-6">
                            <el-form-item
                                label="Procedure"
                                prop="procedure"
                                :rules="[
                                    { required: true, message: 'Procedure is required' },
                                ]">
                                <el-input placeholder="Procedure" v-model="surgeryModal.procedure">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-6">
                            <el-form-item label="Procedure Complications" prop="procedureComplication">
                                <el-select v-model="surgeryModal.procedureComplication" placeholder="Select">
                                    <el-option
                                            v-for="item in list.procedureComplications"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>


                    <template v-if="surgeryModal.procedureComplication==='Other'">
                        <el-form-item label="Procedure Complication Note" prop="otherProcedureComplicationNote">
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="Procedure Complication Note"
                                v-model="surgeryModal.otherProcedureComplicationNote">
                            </el-input>
                        </el-form-item>
                    </template>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-4">
                            <el-form-item label="No of Top-Ups" prop="noOfTopUps">
                                <el-select v-model="surgeryModal.noOfTopUps" placeholder="Select">
                                    <el-option
                                        v-for="item in list.topUps"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-lg-4">
                            <el-form-item
                                label="Anaesthesia Given"
                                prop="anaesthesiaGiven"
                                :rules="[
                                    { validator: checkAnaesthesiaGiven}
                                ]">
                                <el-input type="number" placeholder="" v-model="surgeryModal.anaesthesiaGiven">
                                    <template slot="append">ml</template>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-4">
                            <el-form-item
                                label="Surgery Duration"
                                prop="surgeryDuration"
                                :rules="[
                                    { required: true, message: 'Surgery Duration is required' },
                                    { validator: checkSurgeryDuration}
                                ]">
                                <el-input placeholder="" v-model="surgeryModal.surgeryDuration">
                                    <template slot="append">mins</template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item label="Note" prop="note">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="Please input"
                            v-model="surgeryModal.note">
                        </el-input>
                    </el-form-item>

                    <el-checkbox v-model="surgeryModal.postOpHold">Post-Op Hold</el-checkbox>
                </div>

            </div>

            <div class="row text-right">
                <div class="col-sm-12">
                    <button type="button" class="btn btn-success" :disabled="isSavingData||isLoadingData"
                            @click="saveOrUpdateOperation">{{ getButtonLabel }}
                    </button>
                </div>
            </div>
        </el-form>

    </div>

</template>

<script>

import {DatePicker, Select, Option, Checkbox, Input, Form, FormItem} from 'element-ui';

export default {

    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Checkbox.name]: Checkbox,
        [Input.name]: Input,
        [DatePicker.name]: DatePicker,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    data()
    {
        return {

            isLoadingData: false,
            isSavingData: false,

            surgeryModal: {
                dateTime: '',
                vet: '',
                procedure: '',
                procedureComplication: '',
                otherProcedureComplicationNote: '',
                noOfTopUps: '',
                anaesthesiaGiven: '',
                surgeryDuration: '',
                note: '',
                postOpHold: false,
            },

            list: {
                vets: [],
                procedureComplications: [
                    {
                        label: 'Bleeding',
                        value: 'Bleeding'
                    },
                    {
                        label: 'Increased Incision',
                        value: 'Increased Incision'
                    },
                    {
                        label: 'Other',
                        value: 'Other'
                    }
                ],
                topUps: [
                    {label: '0', value: '0'}, {label: '1', value: '1'}, {label: '2', value: '2'}, {
                        label: '3',
                        value: '3'
                    }, {label: '4', value: '4'}, {label: '5', value: '5'}, {label: '6', value: '6'}, {
                        label: '7',
                        value: '7'
                    }, {label: '8', value: '8'}, {label: '9', value: '9'}, {label: '10', value: '10'}, {
                        label: '11',
                        value: '11'
                    }, {label: '12', value: '12'}, {label: '13', value: '13'}, {label: '14', value: '14'}, {
                        label: '15',
                        value: '15'
                    },
                ]
            }

        }
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        recordId: {
            type: String,
            required: true
        }
    },
    methods: {
        init()
        {
            if (this.id != null) {
                this.getOperationDetails();
            }
            this.getVets();
        },
        saveOrUpdateOperation()
        {
            this.$refs['surgeryOtherForm'].validate((valid) => {
                if (valid) {
                    let vm = this;
        
                    vm.isSavingData = true
        
                    let url = "";
        
                    if (vm.id != null) {
                        if (vm.id > 0) {
                            url = '/operation/update/' + vm.recordId + '/' + vm.id;
                        } else {
                            url = '/operation/add/' + vm.recordId;
                        }
                    } else {
                        url = '/operation/add/' + vm.recordId;
                    }
        
                    axios.post(url, {
                        procedure: vm.surgeryModal.procedure,
                        operation_date: vm.surgeryModal.dateTime,
                        surgeon_name: vm.surgeryModal.vet,
                        anesthesia_given: vm.surgeryModal.anaesthesiaGiven,
                        duration: vm.surgeryModal.surgeryDuration,
                        note: vm.surgeryModal.note,
                        post_op_hold: vm.surgeryModal.postOpHold ? 1 : 0,
                        top_ups: vm.surgeryModal.noOfTopUps,
                        complications: vm.surgeryModal.procedureComplication,
                        complication_other_note: vm.surgeryModal.otherProcedureComplicationNote,
                    })
                    .then(response => {
                        vm.isSavingData = false
                        if (response.data.response.error.error_code == 0) {
                            this.$root.$emit('HistoryTableUpdateRecords') //like this
                            if (vm.id > 0) {
                                vm.$message({
                                    message: 'Operation Details Updated Successfully.',
                                    type: 'success'
                                });
                            } else {
                                vm.clearModalData()
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        }
                    })
                    .catch(e => {
                        vm.isSavingData = false
                        console.log(e);
                    });
                } else {
                    return false;
                }
            });

        },
        clearModalData()
        {
            this.surgeryModal.dateTime = ''
            this.surgeryModal.vet = ''
            this.surgeryModal.procedure = ''
            this.surgeryModal.procedureComplication = ''
            this.surgeryModal.otherProcedureComplicationNote = ''
            this.surgeryModal.noOfTopUps = ''
            this.surgeryModal.anaesthesiaGiven = ''
            this.surgeryModal.surgeryDuration = ''
            this.surgeryModal.note = ''
            this.surgeryModal.postOpHold = false
            this.$refs['surgeryOtherForm'].resetFields();
        },
        getOperationDetails()
        {
            let vm = this;

            vm.isLoadingData = true

            axios.get('/operation/details/' + vm.id)
                .then(response => {
                    vm.isLoadingData = false
                    if (response.data.response.error.error_code == 0) {
                        let record = response.data.response.data.operation;
                        this.surgeryModal.dateTime = record.operation_date
                        this.surgeryModal.vet = record.surgeon_name
                        this.surgeryModal.procedure = record.operation_type
                        this.surgeryModal.procedureComplication = record.complications
                        this.surgeryModal.otherProcedureComplicationNote = record.complication_other_note
                        this.surgeryModal.noOfTopUps = record.top_ups
                        this.surgeryModal.anaesthesiaGiven = record.anesthesia_given
                        this.surgeryModal.surgeryDuration = record.duration
                        this.surgeryModal.note = record.note
                        this.surgeryModal.postOpHold = record.post_op_hold == 1
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        },
        getVets()
        {
            var vm = this;
            axios.get("/organisation/get/vets", {
                params: {
                    organisation_id: vm.$store.getters.getCurrentSelectedProject.organisation_id,
                },
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.list.vets = []
                    var v = response.data.response.data.vets;
                    for (let i = 0; i < v.length; i++) {
                        vm.list.vets.push({
                            value: v[i].first_name + ' ' + v[i].last_name,
                            label: v[i].first_name + ' ' + v[i].last_name
                        });
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        },
        checkSurgeryDuration(rule, value, callback) {
            if (value < 0 || value > 999) {
                callback(new Error('Surgery duration must be between 0 to 999'));
            } else {
                callback();
            }
        },
        checkAnaesthesiaGiven(rule, value, callback) {
            if (value < 0 || value > 999) {
                callback(new Error('Anaesthesia ml must be between 0 to 999'));
            } else {
                callback();
            }
        },
    },
    computed: {
        getButtonLabel: function () {
            if (this.id != null) {
                if (this.id > 0) {
                    return "UPDATE"
                } else {
                    return "SAVE"
                }
            } else {
                return "SAVE"
            }

        }
    },
    mounted()
    {
        this.init();
    }

}

</script>

<style scoped>

</style>
