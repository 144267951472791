<template>
    <div class="save_all_dogs_main">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6 dogs-col">
                    <ProjectSection
                        title="Mass Vaccination"
                        :report="report.mv_projects"
                        :kmlUrls="kmlUrlList1"
                        mapRefId="mvMapRef"
                        :initMap="initMvMap"
                    />
                </div>
                <div class="col-6 manag-col">
                    <ProjectSection
                        title="Population Management"
                        :report="report.pm_projects"
                        :kmlUrls="kmlUrlList2"
                        mapRefId="pmMapRef"
                        :initMap="initPmMap"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectSection from './ProjectSection.vue';
import {API_KEY} from "../Maps/API_KEY";
import {MAP_STYLE_2} from "../Maps/MAP_STYLE_2";
import {MarkerClusterer} from "@googlemaps/markerclusterer";

export default {
    components: {ProjectSection},
    data() {
        return {
            pos: {lat: 20.7374838, lng: 72.1133452},
            report: {
                mv_projects: {...defaultProjectData},
                pm_projects: {...defaultProjectData}
            },
            kmlUrlList1: [],
            kmlUrlList2: [],
            mvMap: null,
            pmMap: null,
            organisationId: null,
            mvMarkers: [],
            pmMarkers: [],
        }
    },
    methods: {
        init() {
            this.getOrgReport();
        },
        async initMvMap() {
            const vm = this;
            await vm.loadMapsApi();

            vm.mvMap = new google.maps.Map(document.getElementById("mvMapRef"), {
                center: vm.pos,
                zoom: 5,
                streetViewControl: false,
                zoomControl: false,
                mapTypeControl: false,
                styles: MAP_STYLE_2
            });

            vm.kmlUrlList1.forEach(url => {
                new google.maps.KmlLayer({
                    url,
                    map: vm.mvMap,
                    suppressInfoWindows: true,
                    preserveViewport: true
                });
            });

            const heatmap = new google.maps.visualization.HeatmapLayer({data: vm.getMvPoints()});
            heatmap.setMap(vm.mvMap);

            if (vm.mvMarkers.length > 0) {
                const bounds = new google.maps.LatLngBounds();
                vm.mvMarkers.forEach(marker => {
                    bounds.extend(marker);
                });
                vm.mvMap.fitBounds(bounds);
            }

            const infoWindow = new google.maps.InfoWindow({content: "hello", disableAutoPan: true});

            const markerList = vm.mvMarkers.map((position) => {
                const marker = new google.maps.Marker({
                    position,
                    map: vm.mvMap
                });

                marker.addListener("click", () => {
                    infoWindow.setContent(`${position.lat},${position.lng}`);
                    infoWindow.open(vm.mvMap, marker);
                });

                return marker;
            });

            new MarkerClusterer({map: vm.mvMap, markers: markerList});

            vm.initPmMap()

        },
        async initPmMap() {
            const vm = this;
            await vm.loadMapsApi();

            vm.pmMap = new google.maps.Map(document.getElementById("pmMapRef"), {
                center: vm.pos,
                zoom: 5,
                streetViewControl: false,
                zoomControl: false,
                mapTypeControl: false,
                styles: MAP_STYLE_2
            });

            vm.kmlUrlList2.forEach(url => {
                new google.maps.KmlLayer({
                    url,
                    map: vm.pmMap,
                    suppressInfoWindows: true,
                    preserveViewport: true
                });
            });

            const heatmap = new google.maps.visualization.HeatmapLayer({data: vm.getPmPoints()});
            heatmap.setMap(vm.pmMap);

            if (vm.pmMarkers.length > 0) {
                const bounds = new google.maps.LatLngBounds();
                vm.pmMarkers.forEach(marker => {
                    bounds.extend(marker);
                });
                vm.pmMap.fitBounds(bounds);
            }

            const infoWindow = new google.maps.InfoWindow({content: "hello", disableAutoPan: true});

            const markerList = vm.pmMarkers.map((position) => {
                const marker = new google.maps.Marker({
                    position,
                    map: vm.pmMap
                });

                marker.addListener("click", () => {
                    infoWindow.setContent(`${position.lat},${position.lng}`);
                    infoWindow.open(vm.mvMap, marker);
                });

                return marker;
            });

            new MarkerClusterer({map: vm.pmMap, markers: markerList});

        },
        loadMapsApi() {
            return new Promise((resolve, reject) => {
                if (typeof google !== 'undefined' && typeof google.maps !== 'undefined') {
                    resolve();
                    return;
                }

                window.initMap = () => {
                    resolve();
                };

                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing,places,visualization&callback=initMap`;
                script.async = true;
                script.defer = true;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        },
        getMvPoints() {
            const vm = this;
            const dataPoints = [];
            vm.report.mv_projects.projects.forEach(project => {
                project.data.record_location_points.forEach(point => {
                    const [lat, lng] = point.split(",").map(parseFloat);
                    dataPoints.push(new google.maps.LatLng(lat, lng));
                    vm.mvMarkers.push({lat, lng});
                });
            });
            return dataPoints;
        },
        getPmPoints() {
            const vm = this;
            const dataPoints = [];
            vm.report.pm_projects.projects.forEach(project => {
                project.data.record_location_points.forEach(point => {
                    const [lat, lng] = point.split(",").map(parseFloat);
                    dataPoints.push(new google.maps.LatLng(lat, lng));
                    vm.pmMarkers.push({lat, lng});
                });
            });
            return dataPoints;
        },
        getOrgReport() {
            const vm = this;
            vm.organisationId = vm.$store.getters['user/organisation'].id;
            window.axios.get(`organisation/generate/report/overview/${vm.organisationId}`)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        const data = response.data.response.data;
                        vm.report = data.org_overview;
                        vm.kmlUrlList1.push(...vm.extractKmlUrls(vm.report.mv_projects.projects));
                        vm.kmlUrlList2.push(...vm.extractKmlUrls(vm.report.pm_projects.projects));
                        vm.initMvMap();
                    }
                })
                .catch(console.log);
        },
        extractKmlUrls(projects) {
            return projects.flatMap(project => project.data.areas.map(area => area.file_url));
        }
    },
    mounted() {
        this.init();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => this.pos = {lat: position.coords.latitude, lng: position.coords.longitude},
                () => alert("Current location not allowed or is disabled")
            );
        }
    }
}

const defaultProjectData = {
    total_projects: 0,
    total_vaccinations: 0,
    total_male: 0,
    total_female: 0,
    area_surveyed: 0,
    avg_treated_per_day: 0,
    vaccination_rate_based_on_survey: 0,
    projects: []
};
</script>

<style scoped>
.save_all_dogs_main {

@media (max-width: 1200px) {
    .row {
        margin-left: -10px;
        margin-right: -10px;

    .col-sm-6, .col-6, .col-12 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

}
@media (max-width: 900px) {
    .container-fluid {
        padding: 0;
    }
}

@media (max-width: 600px) {
    .dogs-col, .manag-col {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 400px) {
    .dogs-col, .manag-col {

    .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

}
}
</style>
