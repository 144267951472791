<template>
    <div class="row set_row">
        <div class="col-md-12">
            <h4 class="title">Profile</h4>
        </div>
        <div class="col-md-12 card" style="padding-top: 10px">
            <v-offline
                    online-class="online"
                    offline-class="offline"
                    @detected-condition="amIOnline">
                <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                    {{ onlineOfflineMsg }}
                </template>
                <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                    {{ onlineOfflineMsg }}
                </template>
            </v-offline>
            <!--    Pradeep Start   -->
            <!-- Set responsive  -->
            <div class="row">
                <div class="col-md-4 col-sm-4"></div>
                <div class="col-md-4 col-sm-4">
                    <template v-if="!uploadNew">
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4 project-img">
                                <img :src="uploadURL" class="profile_img"/>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                    </template>
                    <template v-if="uploadNew">
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <p>Profile photo ({{ uploadingLabel }})</p>
                                <image-upload
                                        :target="imageUploadApiUrl"
                                        selector="example"
                                        :auto-push="true">
                                </image-upload>
                                <button type="button" class="btn btn-primary btn-sm"
                                        v-on:click="toggleUploadNew">Cancel
                                </button>
                            </div>
                            <br/>
                            <div class="col-md-4"></div>
                        </div>
                    </template>
                </div>
                <div class="col-md-4 col-sm-4"></div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4"></div>
                <div class="col-md-4 col-sm-4">
                    <template>
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button type="button" class="btn btn-primary btn-sm" v-on:click="toggleUploadNew">Change
                                </button>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                    </template>
                </div>
                <div class="col-md-4 col-sm-4"></div>
            </div>
            <div class="row set_row">
                <div class="col-md-4 col-sm-2"></div>
                <div class="col-md-4 col-sm-8">
                    <fieldset>
                        <div class="form-group">
                            <fg-input
                                    disabled
                                    type="text"
                                    label="Email"
                                    v-model="model.user.email">
                            </fg-input>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div class="form-group">
                            <fg-input
                                    type="text"
                                    v-validate="modelValidations.firstName"
                                    :error="getError('First Name')"
                                    name="First Name"
                                    label="First Name"
                                    v-model="model.user.first_name">
                            </fg-input>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div class="form-group">
                            <fg-input
                                    type="text"
                                    v-validate="modelValidations.lastName"
                                    :error="getError('Last Name')"
                                    name="Last Name"
                                    label="Last Name"
                                    v-model="model.user.last_name">
                            </fg-input>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div class="form-group">
                            <fg-input
                                    type="text"
                                    v-validate="modelValidations.phone"
                                    :error="getError('Phone')"
                                    name="Phone"
                                    label="Phone"
                                    v-model="model.user.phone">
                            </fg-input>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-4 col-sm-2"></div>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4" style="text-align: center !important;">
                    <button v-if="onLine" v-bind:disabled="button.isDisabled" v-on:click="validateAndSubmit"
                            type="button"
                            class="btn btn-round btn-info">Update
                    </button>
                    <button v-else v-bind:disabled="true" v-on:click="validateAndSubmit" type="button"
                            class="btn btn-round btn-info">Update
                    </button>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import ImageUpload from '../../ImageUpload.vue';

import Swal from 'sweetalert2';
import defaultuser from '../../../assets/images/defaultuser.png';

export default {

    components: {
        'image-upload': ImageUpload,
        VOffline
    },
    mixins: [mixin],
    data()
    {
        return {

            model: {
                user: {},
            },

            button: {
                isDisabled: false,
            },

            modelValidations: {
                firstName: {
                    alpha: true,
                },
                lastName: {
                    alpha: true,
                },
                phone: {
                    digits: 10
                },
            },

            imageUploadApiUrl: window.API_BASE_URL + '/UploadToS3/upload/profile_picture',
            uploadURL: defaultuser,
            uploadingLabel: 'None',
            uploadPending: false,
            uploadNew: false,

            joinedOrganisations: [],

        }
    },
    methods: {
        init()
        {
            let vm = this;

            Bus.$on('example-did-upload', (error, data, response) => {
                if (error) {
                    toastr.error(error);
                } else {
                    this.uploadURL = response.src;
                    this.uploadingLabel = "Uploaded";
                    this.uploadPending = false;
                }
            });

            Bus.$on('example-is-saving', () => {
                this.uploadPending = true;
                this.uploadingLabel = "Uploading Image...";
            });

            vm.getUserDetails();

            vm.getOrganisations();


        },
        getUserDetails()
        {
            let vm = this;
            axios.get('/user').then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.model.user = response.data.response.data.user;
                    if (response.data.response.data.user.photo_url != null) {
                        if (response.data.response.data.user.photo_url != '') {
                            vm.uploadURL = response.data.response.data.user.photo_url;
                        }
                    } else {
                        //vm.uploadURL = 'https://s3.amazonaws.com/hsi-platform/defaultuser.png';
                        vm.uploadURL = defaultuser;
                    }
                }
            }).catch(error => {

            });
        },
        getOrganisations()
        {
            let vm = this;
            axios.get('/user/organisations')
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.joinedOrganisations = response.data.response.data.joined_organisations;
                    }
                }).catch(error => {

            })
        },
        toggleUploadNew()
        {
            this.uploadNew = !this.uploadNew;
        },
        validateAndSubmit()
        {
            let vm = this;

            if (vm.uploadPending) {
                alert('Please wait image is being uploaded');
                return;
            }

            vm.$validator.validateAll().then((result) => {
                if (result) {
                    vm.button.isDisabled = true;
                    axios.put('/user/update', {
                        first_name: vm.model.user.first_name,
                        last_name: vm.model.user.last_name,
                        photo_url: vm.uploadURL,
                        phone: vm.model.user.phone,
                    }).then(response => {
                        vm.button.isDisabled = false;
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            window.DefaultUserImage = vm.uploadURL;
                            var userData = response.data.response.data.user;
                            var user_name = userData.first_name + ' ' + userData.last_name;
                            var user_image = userData.photo_url;
                            var obj = {userName: user_name, userImage: user_image}
                            localStorage.setItem('userdata', JSON.stringify(obj));
                            this.$root.$emit('userDataSet');
                            Bus.$emit("userData", userData);
                        } else {
                            vm.showAlert('Error', response.data.response.error.error_msg, false);
                        }
                    }).catch(error => {
                        vm.button.isDisabled = false;
                    });
                }
            });
        },
        leaveOrganisation(orgId, index)
        {
            let vm = this;

            new Swal({
                title: 'Are you sure you want to leave this organisation?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Yes, Leave Org.',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.get('/organisation/leave/' + orgId)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(response.data.response.error.msg, '', true);
                                vm.joinedOrganisations.splice(index, 1);
                                vm.getOrganisations();
                            } else {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        })
                        .catch(error => {
                            if (error.response.data.response.error) {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        });
                }
            });
        }
    },
    mounted()
    {
        this.init();
    }

}

</script>
<style scoped>
td, th {
    padding-right: 10px;
}

/* Pradeep Start */
.profile_img {
    height: 100px !important;
    width: 100px !important;
    max-width: 100px !important;
}

@media (min-width: 320px) {
    .set_row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

@media (max-width: 320px) {
    .set_msg {
        margin-top: 53% !important;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .set_msg {
        margin-top: 43% !important;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .set_msg {
        margin-top: 43% !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .set_msg {
        margin-top: 35% !important;
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .set_msg {
        margin-top: 19% !important;
    }
}

/* Pradeep End */
</style>
