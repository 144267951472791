<template>
    <div>
        <super-project-navbar></super-project-navbar>

        <transition name="slide-fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>
<script>

import SuperProjectNavbar from "./SuperProjectNavbar";

export default {
    components: {

        SuperProjectNavbar,

    },
    data()
    {
        return {}
    },


}
</script>
