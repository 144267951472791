<template>
    <div>
        <h1>Hello Super Admin Dashboard</h1>
    </div>
</template>
<script>


export default {
    components: {

    },
    data()
    {
        return {}
    },


}
</script>
