<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <h4>Upload Vaccination Area</h4>
        <hr>
        <!--        <p>you can upload multiple KML files. Please note only KML file types are supported</p>-->

        <div class="row" style="margin-bottom: 50px;">
            <div class="col-sm-12">
                <input type="file" name="kml_file" id="kml_file"
                       accept=".kml" ref="file"
                       v-on:change="handleKmlUpload()"/>
            </div>
            <div class="col-sm-12">
                <p>*Only KML Files are supported</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6"><label>Vaccination Area Name <span class="error">*</span></label><input
                    class="form-control" value=""
                    v-model="vaccinationAreaName"
                    required>
            </div>
        </div>
        <!-- <div class="row"> -->
        <!-- <div class="col-sm-12 col-md-4"> -->
        <!-- <label>Country <span class="error">*</span></label> -->
        <!-- <input
                class="form-control" value=""
                v-model="country"
                required> -->
        <!-- </div> -->
        <el-row>
            <!-- <label>Country <span class="error">*</span></label> -->
            <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8">
                <!-- <el-input></el-input> -->
                <label>Country <span class="error">*</span></label>
                <br>
                <el-autocomplete
                        id="countryAutocomplete"
                        name="countryAutocomplete"
                        readonly
                        class="setWidth"
                        v-model="country"
                        :fetch-suggestions="filterCountries"
                        placeholder="Country"
                        @focus="removeReadonly('countryAutocomplete')"
                        :autocomplete="randomStringForAutoComplete"
                        autocorrect="off"
                        spellcheck="false"
                        required
                ></el-autocomplete>
            </el-col>
        </el-row>
        <!-- </div> -->

        <!-- <div class="row"> -->
        <!-- <div class="col-sm-12 col-md-4"> -->
        <!-- <input
                class="form-control" value=""
                v-model="state"
                required> -->
        <el-row>
            <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8">
                <label>State/Province <span class="error">*</span></label>
                <br>
                <el-autocomplete
                        id="stateAutocomplete"
                        name="stateAutocomplete"
                        readonly
                        class="setWidth"
                        v-model="state"
                        :fetch-suggestions="filterStates"
                        placeholder="State/Province"
                        :autocomplete="randomStringForAutoComplete"
                        autocorrect="off"
                        spellcheck="false"
                        aria-readonly
                        required
                        @focus="removeReadonly('stateAutocomplete')"
                ></el-autocomplete>
            </el-col>
        </el-row>
        <!-- </div> -->
        <!-- </div> -->

        <!-- <div class="row"> -->
        <!-- <div class="col-sm-12 col-md-4"> -->
        <!-- <input
                class="form-control" value=""
                v-model="city"
                required> -->
        <el-row>
            <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8">
                <label>City/District/Village <span class="error">*</span></label>
                <br>
                <el-autocomplete
                        id="cityAutocomplete"
                        name="cityAutocomplete"
                        readonly
                        class="setWidth"
                        v-model="city"
                        :fetch-suggestions="filterCities"
                        placeholder="City/District/Village"
                        :autocomplete="randomStringForAutoComplete"
                        autocorrect="off"
                        spellcheck="false"
                        @focus="removeReadonly('cityAutocomplete')"
                        required
                ></el-autocomplete>
            </el-col>
        </el-row>
        <!-- </div>
    </div> -->

        <div class="row text-right">
            <div class="col-sm-12">
                <button v-if="onLine" :disabled="isUploadingKml" v-on:click="submitFile()" class="btn btn-info">
                    {{ getUploadStatus }}
                </button>
                <button v-else v-bind:disabled="true" class="btn btn-info">{{ getUploadStatus }}
                </button>
            </div>
        </div>


    </div>
</template>
<script>

import mixin from '../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import Swal from 'sweetalert2';
import {Row, Col, Autocomplete, Input} from 'element-ui';

export default {
    components: {
        VOffline,
        [Autocomplete.name]: Autocomplete,
        [Row.name]: Row,
        [Col.name]: Col,
        [Input.name]: Input
    },
    props: {
        projectId: {
            // It is areaId needs to be fixed using php
            type: Number,
        }
    },
    data()
    {
        return {
            projectID: null,
            file: null,
            isUploadingKml: false,
            vaccinationAreaName: '',
            country: '',
            state: '',
            city: '',
            allCitiesList: [],
            allStatesList: [],
            allCountriesList: [],
            elementFormatCities: [],
            elementFormatStates: [],
            elementFormatCountries: [],
            elementModelState: '',
            elementModelCity: '',
            elementModelCountry: '',
            randomStringForAutoComplete: null
        }
    },
    mixins: [mixin],
    methods: {
        handleKmlUpload()
        {
            this.file = this.$refs.file.files[0];
        },
        submitFile()
        {
            let vm = this;
            let fileName = vm.file != null ? vm.file.name : '';
            if ((fileName && fileName.indexOf(".kml") < 0) || (fileName == '')) {
                alert('Please select KML file');
                return;
            }
            if (vm.vaccinationAreaName == '' || vm.country == '' || vm.state == '' || vm.locality == '') {
                alert('All fields are required');
                return;
            }
            vm.isUploadingKml = true;
            let formData = new FormData();
            formData.append('kml_file', vm.file);
            formData.append('project_id', vm.projectId);
            formData.append('name', vm.vaccinationAreaName);
            formData.append('country', vm.country);
            formData.append('state', vm.state);
            formData.append('locality', vm.locality);
            axios.post('/area/create',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(response => {
                vm.isUploadingKml = false;
                if (response.data.response.error.error_code == 0) {
                    vm.file = null;
                    vm.vaccinationAreaName = '';
                    vm.country = '';
                    vm.state = '';
                    vm.locality = '';
                    //vm.attachments.documents.push(response.data.response.data);
                    vm.showAlert('DONE', response.data.response.error.msg, true);
                }
            }).catch(error => {
                vm.isUploadingKml = false;
                console.log(error);
                vm.showAlert('ERROR', error.response.data.response.error.error_msg, false);
            });

        },
        async fetchCities()
        {
            try {
                const cities = await axios.get('/project/list/area/cities', {
                    params: {
                        project_id: this.projectID
                    }
                })
                this.allCitiesList = cities.data.response.data.cities;
                this.allCitiesList.forEach(city => {
                    this.elementFormatCities.push({'value': city});
                })
            } catch (error) {

            }
        },
        async fetchStates()
        {
            try {
                const states = await axios.get('/project/list/area/states', {
                    params: {
                        project_id: this.projectID
                    }
                })
                this.allStatesList = states.data.response.data.states;
                this.allStatesList.forEach(state => {
                    this.elementFormatStates.push({'value': state});
                })
            } catch (error) {

            }
        },
        async fetchCountries()
        {
            try {
                const countries = await axios.get('/project/list/area/countries', {
                    params: {
                        project_id: this.projectID
                    }
                })
                this.allCountriesList = countries.data.response.data.countries;
                this.allCountriesList.forEach(country => {
                    this.elementFormatCountries.push({'value': country});
                })
            } catch (error) {

            }
        },
        filterCities(stringQuery, cb)
        {
            if (this.elementFormatCities.length) {
                const filteredCities = this.elementFormatCities.filter(city => {
                    return city['value'].toLowerCase().match(stringQuery.toLowerCase()) ? city.value : '';
                })
                setTimeout(() => {
                    cb(filteredCities);
                }, 300); // for debounce effect. Also used for Api call
            } else {
                cb([]);
            }
        },
        filterStates(stringQuery, cb)
        {
            if (this.elementFormatStates.length) {
                const filteredStates = this.elementFormatStates.filter(state => {
                    return state['value'].toLowerCase().match(stringQuery.toLowerCase()) ? state.value : '';
                })
                setTimeout(() => {
                    cb(filteredStates);
                }, 300); // for debounce effect. Also used for Api call
            } else {
                cb([]);
            }
        },
        filterCountries(stringQuery, cb)
        {
            if (this.elementFormatCountries.length) {
                const filteredCountries = this.elementFormatCountries.filter(country => {
                    return country['value'].toLowerCase().match(stringQuery.toLowerCase()) ? country.value : '';
                })
                setTimeout(() => {
                    cb(filteredCountries);
                }, 300); // for debounce effect. Also used for Api call
            } else {
                cb([]);
            }
        },

        // handleSelect(val, target) {
        //     switch (target) {
        //         case 'city':
        //             this.city = val;
        //             break;
        //         case 'state':
        //             this.state = val;
        //             break;
        //         case 'country':
        //             this.country = val;
        //             break;
        //         default:
        //             break;
        //     }
        // }
        setProjectId()
        {
            const PROJECT = JSON.parse(localStorage.getItem('project'));
            this.projectID = PROJECT.id
        },
        removeReadonly(refName)
        {
            document.getElementById(refName).removeAttribute('readonly')
        },
        generateRandomString(length)
        {
            // add it to autocomplete attribute so browser can't save it.(it's the best solution for now. Maybe in future we get a better solution.)
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;

        },
        disableAutoFill()
        {
            const uniqueString = this.generateRandomString(10)
            this.randomStringForAutoComplete = uniqueString
        }
    },
    computed: {
        getUploadStatus: function () {
            if (this.isUploadingKml) {
                return 'Please Wait...'
            } else {
                return 'Upload';
            }
        }

    },
    mounted()
    {
        this.disableAutoFill()
        this.setProjectId()
        this.fetchCities();
        this.fetchStates();
        this.fetchCountries();
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        //Bus.$emit("projectype", vm.project);
    }
}

</script>
<style scoped>
.setWidth {
    width: 100%;
}
</style>
