<template>
    <div>
        <div class="sidebar" style="background-color:#66615B !important;">
            <div class="logo logo_img_hsaapps">
                <a href="/admin/dashboard" v-if="usertype=='admin'">
                    <div class="logo-img" style="text-align: center;">
                        <img :src="logo" style="width: 150px;">
                    </div>
                </a>
                <router-link to="/platform/projects" v-else>
                    <div class="logo-img" style="text-align: center;">
                        <img :src="logo" style="width: 150px;">
                    </div>
                </router-link>
            </div>
            <div class="sidebar-wrapper set_border" ref="sidebarScrollArea" v-if="usertype=='user'">
                <div class="setShadow_menu" style="padding: 5px;">
<!--                    <div class="logo-img projectimg" v-bind:style="{width: '100%', height: '150px', backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat', backgroundImage : 'url('+projectimg+')', backgroundSize: 'contain', borderRadius: '5px'}">
                    </div>-->
                    <div class="row text-center" style="padding: 10px;">
                        <div class="col-sm-2 text-center">
                            <p style="text-align: center; margin: 0"><img style="max-width: none;" :src="mvdlogo" class="logo_img"/></p>
                        </div>
                        <div class="col-sm-10 text-center">
                            <h6 style="margin: 0" class="project_name">Mass<br>Vaccination</h6>
                        </div>
                    </div>
                </div>
                <ul class="nav">
                    <slot name="links">
                        <sidebar-item v-for="(link, index) in MVDprojectsidebarLinks"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>

                    </slot>
                    <li><img :src="hsiLogo" style="height: 60px; width: 60px; margin-left: 100px; margin-top: 70px;"/></li>
                </ul>
            </div>
        </div>
        <div class="main-panel">
            <project-navbar
                    usertype="user">
            </project-navbar>
            <div class="project-details-content">
                <transition name="slide-fade" mode="out-in">
                    <router-view :projectId="projectId"></router-view>
                </transition>
            </div>
            <content-footer></content-footer>
        </div>
    </div>
</template>
<script>

import ProjectNavbar from "../../Layout/ProjectNavbar";
import ContentFooter from "../../Layout/ContentFooter";
import mvLogo from '../../../../assets/images/mv_logo.png';

import MvSideNav from '../../../../../src/MvSideNav';
import mvdlogo from '../../../../assets/images/mv-logo.png';
import hsiLogo from '../../../../assets/images/hsi-transparent.png';
import PmSideNav from "../../../../PmSideNav";

export default {
    components: {
        'project-navbar': ProjectNavbar,
        'content-footer': ContentFooter
    },
    data()
    {
        return {
            location: {...location},
            ps: null,
            projecttype: '',
            projectname: '',
            projectimg: '',
            isReadOnly:false,
        };
    },
    props: {
        usertype: {
            type: String,
            default: 'user',
        },
        projecturl: {
            type: String,
            default: 'url',
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
        projectId: {
            type: String,
        },
        mvdlogo: {
            type: String,
            default: mvdlogo,
        },
        hsiLogo: {
            type: String,
            default: hsiLogo,
        },
    },
    provide()
    {
        return {
            autoClose: this.autoClose,
        };
    },
    methods: {
        mvdHome()
        {
            window.location = '/project/details/' + this.projectId;
        },
        getLocation()
        {
            return this.location;
        },
        async initScrollBarAsync()
        {
            let PerfectScroll = await import('perfect-scrollbar');
            PerfectScroll.initialize(this.$refs.sidebarScrollArea);
        }
    },

    computed: {
        MVDprojectsidebarLinks()
        {

            let loc = this.location;
            //alert(this.location);

            var tmp = [];

            for (var i = 0; i < MvSideNav.nav.length; i++) {
                if ((MvSideNav.nav[i].name == "Settings" || MvSideNav.nav[i].name == "Assignments") && this.isReadOnly) {
                    continue
                }
                tmp.push(MvSideNav.nav[i]);
            }
            let links = tmp.filter((item, index) => {

                if (item.children && item.children.length) {
                    for (let idx = 0; idx < item.children.length; idx++) {
                        if (item.children[idx].name == "Archived") {
                            item.children.splice(idx, 1);
                        }
                    }
                }
                return item

            }).map((item, index) => {
                item.collapsed = true;
                if (item.children && item.children.length) {
                    for (let idx = 0; idx < item.children.length; idx++) {
                        item.children[idx].path = '/project/mv/' + this.projectId + item.children[idx].p;
                    }
                } else {
                    item.path = '/project/mv/' + this.projectId + item.p;
                }
                return item;
            });
            return links;
        },
        //End
        title()
        {
            return window.appConfig.appName;
        },
        logo()
        {
            return window.appConfig.logo;
        },
    },
    mounted()
    {
        let vm = this;
        /*Bus.$on('projectype', data => {
            console.log("PROJECT DATA: " + data);
            vm.projecttype = data.type;
            vm.projectname = data.name;
            vm.projectimg = data.image_url;
            vm.projectId = data.id;
            vm.$store.dispatch("setProjectType", "Mass Vaccination");
            vm.$store.dispatch("setProjectName", this.projectname);
        });*/

        axios
            .get("/project/details", {
                params: {
                    project_id: vm.projectId,
                }
            })
            .then(response => {
                let project = response.data.response.data.project;
                //localStorage.project = JSON.stringify(vm.project);

                this.$store.dispatch("setCurrentSelectedProject", project);
                this.$store.dispatch("user/getUserRoles");

                vm.isReadOnly = false
                vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
                    if(r.role_name == 'VIEWER'){
                        vm.isReadOnly = true
                    }
                })

                vm.projecttype = project.type;
                vm.projectname = project.name;
                vm.projectimg = project.image_url;
                vm.projectId = project.id;
                if (project.image_url == null) {
                    vm.projectimg = mvLogo;
                } else
                    if (project.image_url.includes("mv_logo.png")) {
                        vm.projectimg = mvLogo;
                    } else {
                        vm.projectimg = project.image_url;
                    }

                vm.$store.dispatch("setProjectType", "Mass Vaccination");
                //vm.$store.dispatch("setOrganisationName", vm.$store.getters['user/organisation'].name);

                vm.$store.dispatch("setProjectName", vm.projectname);

                if(project.organisation){
                    vm.$store.dispatch("setOrganisationName", project.organisation.name);
                    vm.$store.dispatch("setOrganisationLogo", project.organisation.logo_url);
                }

            })
            .catch(error => {
                console.log(error);
                console.log(error.response.data.response);
                if (error.response.data.response.data !== null) {
                    console.log(error.response.data.response.data);
                } else {
                    console.log(vm.errorMsg);
                }
            });

        //this.ps = new PerfectScroll(this.$refs.sidebarScrollArea);
        this.initScrollBarAsync();
        /*Bus.$on('link.clicked', (path) => {
            this.location.hash = '#' + path;
        });*/
    },
    beforeDestroy()
    {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
};

</script>
<style>

.main-panel > .project-details-content {
    padding: 0 30px 30px;
    min-height: calc(100vh - 123px);
    margin-top: 30px;
}

@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}

.project_name_div {
    margin-top: 10px;
    width: 99%;
    height: 50px;
    margin-bottom: -15px;
}

.project_name {
    text-align: center;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.projectimg {
    text-align: center;
    margin-top: 10px;
}

.img_fix_size {
    height: 100px;
    margin-top: 12px;
}

.setShadow_menu {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
}

.sidebar-normal {
    margin-left: 44px !important;
}

.sidebar-mini-icon {
    display: none !important;
}

.logo_img_hsaapps {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
}

.set_border {
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.sidebar .nav li.active > a.active::after,
.sidebar .nav li.active > a.active::before {
    opacity: 0 !important;
}

.sidebar .nav li > a.active, .off-canvas-sidebar .nav li > a.active {
    font-weight: 800;
    font-size: 14px;
}
</style>

<style scoped>

.sidebar:after {
    /*background-color: #1B5E20 !important;*/
}

</style>
