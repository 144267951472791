import axios from 'axios';

class HealthClinicService {
    routeAPIPrefix = 'health/clinic';
    getAssignedUsers(projectId) {
        return axios.get(`${this.routeAPIPrefix}/assigned/users/${projectId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    assignUser(projectId, payload) {
        return axios.post(`${this.routeAPIPrefix}/assign/user/${projectId}`, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    deleteUser(projectId, payload) {
        return axios.delete(`${this.routeAPIPrefix}/remove/user/${projectId}?email=${payload}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    getRecords(projectId) {
        return axios.get(`${this.routeAPIPrefix}/records/${projectId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    getStats(projectId) {
        return axios.get(`${this.routeAPIPrefix}/stats/${projectId}`)
        .then((response) => {
            return response.data.response;
        })
        .catch((error) => {
            return error.data.response;
        });
    }

    getUserReport(projectId, params) {
        return axios.get(`${this.routeAPIPrefix}/users/report/${projectId}`, { params })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data.response;
        });
    }

    getMapReport(projectId, params) {
        return axios.get(`${this.routeAPIPrefix}/map/report/${projectId}`, { params })
        .then((response) => {
            return response.data.response;
        })
        .catch((error) => {
            return error.data.response;
        });
    }

    updateUserPassword(projectId, userId, payload) {
        return axios.post(`${this.routeAPIPrefix}/user/change/password/${projectId}/${userId}`, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    updateRecord(projectId, recordId, payload) {
        return axios.post(`${this.routeAPIPrefix}/update/data/${projectId}/${recordId}`, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }
}

export default new HealthClinicService();