import * as types from '../mutation-types';

const state = {

    animals:[]

};

const getters = {

    animals: state => state.animals,

};

const mutations = {

    [types.SET_ANIMALS](state, data){
        state.animals=data.animals;
    }

};

const actions = {

    getAnimals({commit}){
        return axios.get('/animal').then(r => {
            commit(types.SET_ANIMALS,r.data.response.data);
        });
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}