<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row setMarginTop">
            <template v-if="$store.getters.getCurrentSelectedProject.banner_message!=null">
                <div v-if="$store.getters.getCurrentSelectedProject.banner_message!=''" class="col-sm-12">
                    <div class="card setShadow">
                        <div class="card-body" style="padding: 10px;">
                            <p style="margin-top: 15px; font-size: 15px; font-weight: bold;">{{
                                    $store.getters.getCurrentSelectedProject.banner_message
                                }}</p>
                        </div>
                    </div>
                </div>
            </template>
            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="card setShadow">
                    <div class="card-body" style="padding: 10px;">
                        <div
                            id="map"
                            style="height: 400px !important;width:100%;"
                        ></div>
                        <div v-if="mapLoadError" class="text-center">
                            <p style="color: red;">Failed to load the map.</p>
                            <button @click="loadMap" class="btn btn-primary">Retry</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="card setShadow">
                    <div class="card-body" style="padding: 10px;">
                        <span>Location</span>
                        <h5>{{ projectOverview.project_location }}</h5>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Project Owner</p>
                                        <a :href="'mailto:' + projectOverview.ownerEmail + '?subject=' + projectOverview.name ">
                                            {{ projectOverview.project_owner }} </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Records</p>
                                        {{ projectOverview.total_records }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Treatments</p>
                                        {{ projectOverview.total_treatments }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Sterilized</p>
                                        {{ projectOverview.total_sterilized }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row setMarginTop">

            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Male</p>
                                        {{ projectOverview.total_males }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Female</p>
                                        {{ projectOverview.total_females }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Deworming</p>
                                        {{ projectOverview.total_deworming }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Unsterilized</p>
                                        {{ projectOverview.total_unsterilized }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row setMarginTop">
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Owned</p>
                                        {{ projectOverview.total_owned }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Not Owned</p>
                                        {{ projectOverview.total_not_owned }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Vaccination Other</p>
                                        {{ projectOverview.total_vaccination_other }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Field Team Users</p>
                                        {{ projectOverview.total_field_team_users }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '../../../mixins/GlobalMixin';
import VOffline from 'v-offline';

import {API_KEY} from "../Maps/API_KEY";
import {MAP_STYLE} from "../Maps/MAP_STYLE";
import GoogleMapsLoader from "google-maps";
import healthClinicService from "../../../services/health-clinic.service";

GoogleMapsLoader.KEY = API_KEY;

export default {
    components: {
        VOffline
    },
    props: {
        projectId: {
            type: String | Number
        },
    },
    mixins: [mixin],
    data() {
        return {
            map: null,
            center: '',
            project: {},
            errorMsg: null,
            callingApi: true,
            selectedUserRole: 1,
            mapLoadError: false,  // Track map load errors

            projectOverview: {
                project_name: '',
                project_owner: '',
                project_lat_lng: '',
                project_location: '',
                total_records: '',
                total_males: '',
                total_females: '',
                total_treatments: '',
                total_deworming: '',
                total_vaccination_other: '',
                total_owned: '',
                total_not_owned: '',
                total_sterilized: '',
                total_unsterilized: '',
                total_field_team_users: '',

            },

        }
    },
    methods: {

        loadMap() {
            const vm = this;
            vm.mapLoadError = false; // Reset error state before loading the map
            // Ensure the DOM is updated before proceeding
            vm.$nextTick(() => {
                const latLngArr = vm.projectOverview.project_lat_lng.split(",");
                const center = { lat: +latLngArr[0], lng: +latLngArr[1] };

                // Promisify the GoogleMapsLoader
                return new Promise((resolve, reject) => {
                    GoogleMapsLoader.load(google => {
                        if (google) {
                            const map = new google.maps.Map(document.getElementById("map"), {
                                zoom: 7,
                                center: center,
                                styles: MAP_STYLE,
                                streetViewControl: false,
                                mapTypeControl: false
                            });
                            const marker = new google.maps.Marker({
                                position: center
                            });
                            marker.setMap(map);
                            resolve();
                        } else {
                            reject('Google Maps failed to load.');
                        }
                    });
                })
                    .then(() => {
                        console.log('Map loaded successfully.');
                    })
                    .catch(error => {
                        console.error('Error loading map:', error);
                        vm.mapLoadError = true; // Set error state if loading fails
                    });
            });
        },

        async getDashboardData() {
            let vm = this;
            healthClinicService.getStats(vm.projectId)
            .then((apiResposne) => {
                if (!apiResposne.error.error_code) {
                    vm.projectOverview = apiResposne.data.stats;
                    const {owner, name} = vm.$store.getters.getCurrentSelectedProject;
                    vm.projectOverview = Object.assign(vm.projectOverview, {
                        name,
                        ownerEmail: owner.email
                    });
                    vm.loadMap();
                }
            });
        }

    },
    mounted() {
        let vm = this;
        vm.$store.dispatch("setScreenName", "Overview");
        vm.getDashboardData();
    }
}
</script>

<style scoped>

.card .numbers p {
    font-size: 14px !important;
    color: #9a9a9a !important;
    margin: 0;
}

.card .number {
    /* font-size: 14px !important; */
    color: #9a9a9a !important;
    font-size: 1.5em;
    text-align: right;
}

.setShadow {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.card .card-body {
    text-align: center;
}
</style>
