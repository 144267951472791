import moment from 'moment';

const dateRangeDefaultPresetsMixin = {
  methods: {
    getRange (startOffset = 0, endOffset = 0, period = 'day') {
        return [
          moment().subtract(startOffset, period).startOf(period),
          moment().subtract(endOffset, period).endOf(period),
        ];
    },
    getDateRangePresets() {
        return [
            {
              label: 'Today',
              range: this.getRange(0, 0),
            },
            {
              label: 'Yesterday',
              range: this.getRange(1, 1),
            },
            {
              label: 'Last 7 Days',
              range: this.getRange(6, 0),
            },
            {
              label: 'Last 30 Days',
              range: this.getRange(29, 0),
            },
            {
              label: 'This Month',
              range: this.getRange(0, 0, 'month'),
            },
            {
              label: 'Prior Month',
              range: this.getRange(1, 1, 'month'),
            },
            {
              label: 'This-Calendar Year',
              range: this.getRange(0, 0, 'year'),
            },
            {
              label: 'Prior 12 Month',
              range: this.getRange(12, 1, 'month'),
            },
        ];
    }
  },
};

export default dateRangeDefaultPresetsMixin;

