<template>
    <div>

        <div class="row set_row" style="background-color: #f3efde; padding: 5px; border-radius: 10px;">

            <div class="col-md-6 col-sm-12">

                <label class="invite-user-label">Select Field Team</label>
                <el-select
                    id="feildTeams"
                    class="mb-2"
                    @change="getInvitations"
                    v-model="selectedTeam"
                    placeholder="Field Teams">
                    <el-option
                        v-for="(team, key) in teams"
                        :key="key"
                        :label="team.name"
                        :value="team.id"
                    ></el-option>
                </el-select>


                <div class="row">
                    <div class="col-12">
                        <br>
                        <label class="invite-user-label">Enter Email to Invite/add User on a selected
                            team</label>
                        <fg-input v-model="userEmail"
                                  type="text"
                                  ref="inviteUser"
                                  :disabled="invitingUser"
                                  placeholder="Enter user email..."></fg-input>
                    </div>

                    <div class="col-6">
                        <label class="invite-user-label">First Name</label>
                        <fg-input v-model="firstName"
                                  type="text"
                                  name="First Name"
                                  :disabled="invitingUser"
                                  placeholder="Enter First Name"></fg-input>
                    </div>

                    <div class="col-6">
                        <label class="invite-user-label">Last Name</label>
                        <fg-input v-model="lastName"
                                  type="text"
                                  name="Last Name"
                                  :disabled="invitingUser"
                                  placeholder="Enter Last Name"></fg-input>
                    </div>
                    <div class="col-6">
                        <label class="invite-user-label">Password</label>
                        <fg-input v-model="password"
                                  type="password"
                                  name="Password"
                                  :disabled="invitingUser"
                                  placeholder="Enter Password"></fg-input>
                    </div>
                    <div class="col-6">
                        <label class="invite-user-label">Confirm Password</label>
                        <fg-input v-model="passwordConfirmation"
                                  type="password"
                                  name="Confirm Password"
                                  :disabled="invitingUser"
                                  placeholder="Confirm Password"></fg-input>
                    </div>

                    <div class="col-12">
                        <p>*Password will remain unchanged for any existing users</p>
                    </div>


                    <div class="col-12">

                        <label class="invite-user-label">Notes</label>
                        <fg-input v-model="userNote"
                                  type="text"
                                  textarea
                                  name="Note"
                                  :disabled="invitingUser"
                                  placeholder="Enter note if any..."></fg-input>


                    </div>


                    <div class="col-12 text-right">

                        <button type="button"
                                @click="inviteUserToProject" name="invite"
                                class="btn btn-round btn-info">
                            Add Field User
                        </button>

                    </div>


                </div>


            </div>
            <div class="col-md-6 col-sm-12">
                <label class="invite-user-label invitation-label">Invitation List</label>
                <div v-if="invitations.length>0">
                    <table class="invitation-table">
                        <tr>
                            <th>Name/Email</th>
                            <th>Status</th>
                        </tr>
                        <template v-for="(invitation,index) in invitations">
                            <tr style="border: 1px solid #606266; padding: 5px;">
                                <td>
                                    <span style="font-weight: bold;">{{ getName(invitation) }}</span>
                                    <br>
                                    <a :href="'mailto:'+invitation.email">{{ invitation.email }}</a>
                                </td>
                                <td :style="{color:invitation.joined == 0 ?'red':'green'}">
                                    ({{ invitation.joined == 0 ? 'Pending' : 'Joined' }})
                                    <a href="#" v-if="invitation.joined==0"
                                       v-on:click.prevent="cancelInvitation(invitation.invitation_code,index)"><i
                                        class="nc-icon nc-simple-remove"></i></a>
                                </td>
                            </tr>

                        </template>
                    </table>
                </div>
                <div v-else>
                    <br>
                    <p class="text-center">No Invitations found</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import Swal from "sweetalert2";
import {Select, Option,} from 'element-ui';


export default {

    components: {
        [Option.name]: Option,
        [Select.name]: Select,
    },

    props: {
        projectId: {
            type: Number,
        }
    },

    data() {
        return {

            selectedTeam: null,
            teams: [],
            invitations: [],

            userEmail: '',
            firstName: '',
            lastName: '',
            userNote: '',
            password: '',
            passwordConfirmation: '',

            invitingUser: false

        }
    },

    methods: {
        getName(invitation) {
            if (invitation.first_name != "" || invitation.last_name != "") {
                return invitation.first_name + " " + invitation.last_name
            } else {
                return "Unknown"
            }
        },
        cancelInvitation(invitationCode, index) {
            let vm = this;

            new Swal({
                title: 'Are you sure you want to cancel this invitation?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Cancel Invitation',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.get('/field_team/cancel/invitation/' + invitationCode)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(response.data.response.error.msg, '', true);
                                vm.invitations.splice(index, 1);
                            } else {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        })
                        .catch(error => {
                            if (error.response.data.response.error) {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        });
                }
            });
        },
        getInvitations() {
            let vm = this;

            vm.invitations = [];

            axios.get('/field_team/get_invitations', {
                params: {
                    field_team_id: vm.selectedTeam,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.invitations = response.data.response.data.invitations;
                }
            }).catch(error => {

            });
        },
        init() {
            this.getFieldTeams();
        },
        getFieldTeams() {
            let vm = this;
            vm.project = vm.$store.getters.getCurrentSelectedProject;
            axios.get('/field_team/teams/org/' + vm.project.organisation_id)
                .then(response => {
                    console.log(response.data.response.data.teams);
                    vm.teams = response.data.response.data.teams;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        inviteUserToProject() {
            let vm = this;

            if (vm.selectedTeam == null) {
                alert('Please select field team');
                return;
            }
            if (vm.userEmail == '') {
                alert('Please enter email');
                return;
            }

            vm.invitingUser = true;
            axios.post('/field_team/invite_user/platform', {
                email: vm.userEmail,
                on_team: vm.selectedTeam,
                first_name: vm.firstName,
                last_name: vm.lastName,
                password: vm.password,
                password_confirmation: vm.passwordConfirmation,
                note: vm.userNote,
            }).then(response => {
                vm.invitingUser = false;
                if (response.data.response.error.error_code === 0) {
                    vm.showAlert(response.data.response.error.msg, vm.userEmail, true);
                    vm.userEmail = '';
                    vm.firstName = '';
                    vm.lastName = '';
                    vm.userNote = '';
                    vm.password = ''
                    vm.passwordConfirmation = ''
                    vm.$validator.reset();
                    vm.invitations.push(response.data.response.data.invitation);
                } else {
                    vm.showAlert('Alert', response.data.response.error.error_msg, false);
                }
            }).catch(error => {
                console.log(error)
                vm.showAlert('Alert', error.data.response.error.error_msg, false);
                vm.invitingUser = false;
            });

        },


    },
    created() {
        this.init();
    }

}

</script>

<style scoped>

</style>
