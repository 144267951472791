<template>
    <div class="card img-left">
        <div class="card-header row">
            <!-- <div class="col-xs-6 col-sm-6 col-md-3">
               <div class="area-img">
                  <img :src="areaImage"/>
               </div>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="area-details">
                    <a :title="title" href="#" class="a_title">
                        <p class="area-title">
                      <span
                          style="margin: 5px; float: left; height: 15px; width: 15px; border-radius: 30px;"
                          :style="{'background-color': colorStatus}"></span>
                            {{title}}
                        </p>
                    </a>
                    <p class="area-info-text">Created On : {{createdOn}}</p>
                    <p class="area-info-text">Country : {{country}}</p>
                    <p class="area-info-text">State/Province : {{areaState}}</p>
                    <p class="area-info-text">City/District/Village : {{locality}}</p>
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="area-card-footer">
           <p class="area-contributors">Field Users: {{fieldUsers}}</p>
        </div> -->
    </div>
</template>
<script>
    export default {
        components: {},
        name: 'card',
        props: {
            areaId: {
                type: Number,
            },
            title: {
                type: String,
                description: 'Card title',
                default: 'area Title'
            },
            createdOn: {
                type: String,
                default: 'N/A',
            },
            country: {
                type: String,
                default: 'Country',
            },
            areaState: {
                type: String,
                default: 'N/A',
            },
            locality: {
                type: String,
                default: '0',
            },
            fieldUsers: {
                type: Number,
                default: 0,
            },
            areaImage: {
                type: String,
                default: 'https://vuejs.org/images/logo.png',
            },
            areaStatus: {
                type: Number,
                default: 0,
            },
            colorStatus: {
                type: String,
                default: 'grey'
            }
        },
        computed: {
            areaDetails: function () {
                return 'area/details/' + this.areaId;
            },
            areaStatusColor: function () {
                let vm = this;
                switch (vm.areaStatus) {
                    case 0:
                        return 'black';
                    case 1:
                        return 'yellow';
                    case 2:
                        return 'blue';
                    case 3:
                        return 'green';
                    case 4:
                        return 'red';
                    case 5:
                        return 'orange';
                    case -1:
                        return 'grey';


                }
            }
        }
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .area-img {
        margin-right: 15px;
        width: 80px;
    }

    .area-details {
        margin-bottom: 15px;
    }

    .area-title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
    }

    .area-info-text {
        margin: 0;
    }

    .area-contributors {
        float: left;
    }

    .area-card-footer {
        margin-left: 15px;
        margin-right: 15px;
    }

    .a_title {
        text-align: center;
    }
</style>
