<template>
    <div style="background-color: #f3efde !important; padding: 10px; border-radius: 10px;">


        <div class="row">

            <div class="col-sm-12 col-lg-6">
                <div class="form-group">
                    <template>
                        <label>Areas ({{ roleClinicSelected ? 'All' : selectedAreas.length }})</label>
                        <el-select :disabled="roleClinicSelected" v-model="selectedAreas" multiple filterable
                                   :placeholder="roleClinicSelected ? 'All' : 'Select Areas'"
                                   no-data-text="Go to 'AREAS' to add one">
                            <el-option
                                v-for="area in projectAreas"
                                :key="area.id"
                                :label="area.name"
                                :value="area.id">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>

            <div class="col-sm-12 col-lg-6">
            </div>

            <div class="col-sm-12 col-lg-6">
                <br>
                <label>Role</label>
                <br>
                <p-radio inline v-model="assignSelection" value="catch_release" label="catch_release">
                    Catch-Release
                </p-radio>
                <p-radio @change="onChangeRoleSelection" inline v-model="assignSelection" value="clinic"
                         label="clinic">Clinic
                </p-radio>
            </div>

        </div>


        <br>


        <div class="row center text-center">

            <div class="col-sm-12 col-lg-4">

                <div class="list-box">
                    <h6 style="background-color: #f5f7fa; padding: 12px; border-radius: 5px 5px 0px 0px; color: #34b5b8;">
                        Field Teams ({{ teams.length }})
                    </h6>
                    <select v-bind:disabled="isDisabled" @change="getInvitations" size="6" v-model="selectedTeam">
                        <template v-for="team in teams">
                            <option :value="team.id">{{ team.name }}</option>
                        </template>
                    </select>
                </div>
            </div>

            <div class="col-sm-12 col-lg-4">
                <div>

                    <span style="font-size: large;">Invite User {{ selectedFieldTeam }}</span>

                    <fg-input v-model="userEmail"
                              type="text"
                              name="User Email"
                              :disabled="invitingUser"
                              v-validate="modelValidations.userEmail"
                              :error="getError('User Email')"
                              placeholder="Enter user email..."></fg-input>

                    <fg-input v-model="firstName"
                              type="text"
                              name="First Name"
                              :disabled="invitingUser"
                              placeholder="Enter First Name"></fg-input>

                    <fg-input v-model="lastName"
                              type="text"
                              name="Last Name"
                              :disabled="invitingUser"
                              placeholder="Enter Last Name"></fg-input>

                    <fg-input v-model="userNote"
                              type="text"
                              name="Note"
                              :disabled="invitingUser"
                              placeholder="Enter note if any..."></fg-input>

                    <button v-if="onLine" :disabled="invitingUser" type="button"
                            @click="inviteUserToProject" name="invite"
                            class="btn btn-round btn-default">
                        Invite User
                    </button>
                    <button v-else v-bind:disabled="true" type="button"
                            @click="inviteUserToProject" name="invite"
                            class="btn btn-round btn-default">
                        Invite User
                    </button>

                </div>
            </div>


            <div class="col-sm-12 col-lg-4 text-left">
                <div v-if="invitations.length>0">
                    <table width="100%">
                        <tr>
                            <th>Name/Email</th>
                            <th>Role</th>
                            <th>Status</th>
                        </tr>
                        <template v-for="(invitation,index) in invitations">
                            <tr style="border: 1px solid #606266; padding: 5px;">
                                <td>
                                    <span style="font-weight: bold;">{{ getName(invitation) }}</span>
                                    <br>
                                    <a :href="'mailto:'+invitation.email">{{ invitation.email }}</a>
                                    <p v-if="invitation.role" style="font-weight: bold;">Role: {{ getRole(invitation) }}</p>
                                </td>
                                <td>

                                </td>
                                <td :style="{color:invitation.joined == 0 ?'red':'green'}">
                                    ({{ invitation.joined == 0 ? 'Pending' : 'Joined' }})
                                    <a href="#" v-if="invitation.joined==0"
                                       v-on:click.prevent="cancelInvitation(invitation.invitation_code,index)"><i
                                        class="nc-icon nc-simple-remove"></i></a>
                                </td>
                            </tr>

                        </template>
                    </table>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

import mixin from '../../../mixins/GlobalMixin';
import Swal from 'sweetalert2';

export default {
    props: {
        projectAreas: {
            type: Array
        },
        projectId: {
            type: Number,
        }
    },
    components: {},
    data() {
        return {

            roleClinicSelected: false,
            selectedAreas: [],
            assignSelection: 'catch_release',
            firstName: '',
            lastName: '',

            selectedTeam: null,
            teams: [],
            isDisabled: false,

            userEmail: '',
            userNote: '',

            modelValidations: {
                userEmail: {
                    required: true,
                    email: true,
                },
            },

            invitingUser: false,
            invitations: [],
        }
    },
    mixins: [mixin],
    methods: {
        getName(invitation) {
            if (invitation.first_name != "" || invitation.last_name != "") {
                return invitation.first_name + " " + invitation.last_name
            } else {
                return "Unknown"
            }
        },
        getRole(invitation) {
            if (invitation.role === 7) {
                return 'Catch-Release'
            } else if (invitation.role === 6) {
                return "Clinic"
            }
        },
        getInvitations() {
            let vm = this;

            vm.invitations = [];

            axios.get('/field_team/get_invitations', {
                params: {
                    field_team_id: vm.selectedTeam,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.invitations = response.data.response.data.invitations;
                }
            }).catch(error => {

            });
        },
        cancelInvitation(invitationCode, index) {
            let vm = this;

            Swal.fire({
                icon:'info',
                title: 'Are you sure you want to cancel this invitation?',
                text: `You won't be able to revert this!`,
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Cancel Invitation',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.get('/field_team/cancel/invitation/' + invitationCode)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(response.data.response.error.msg, '', true);
                                vm.invitations.splice(index, 1);
                            } else {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        })
                        .catch(error => {
                            if (error.response.data.response.error) {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        });
                }
            });
        },
        inviteUserToProject() {
            let vm = this;

            if (vm.selectedTeam == null) {
                alert('Please select field team');
                return;
            }

            if (vm.selectedAreas.length === 0) {
                alert('Please select areas');
                return;
            }

            this.$validator.validateAll().then((result) => {
                if (result) {
                    vm.invitingUser = true;
                    axios.post('/field_team/invite_user', {
                        email: vm.userEmail,
                        on_team: vm.selectedTeam,
                        note: vm.userNote,
                        first_name: vm.firstName,
                        last_name: vm.lastName,
                        project_id: vm.projectId,
                        area_ids: vm.selectedAreas,
                        role: vm.assignSelection === 'catch_release' ? 7 : 6,
                    }).then(response => {
                        vm.invitingUser = false;
                        if (response.data.response.error.error_code === 0) {
                            vm.showAlert(response.data.response.error.msg, vm.userEmail, true);
                            if (response.data.response.data) {
                                vm.invitations.push(response.data.response.data.invitation);
                            }
                            vm.userEmail = '';
                            vm.userNote = '';
                            vm.selectedAreas = []
                            vm.assignSelection = 'catch_release'
                            vm.$validator.reset();
                        } else {
                            alert(response.data.response.error.error_msg);
                        }
                    }).catch(error => {
                        vm.showAlert('Sorry', error.response.data.response.error.error_msg, false);
                        vm.invitingUser = false;
                    });
                }
            });

        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        getFieldTeams() {
            let vm = this;
            vm.project = vm.$store.getters.getCurrentSelectedProject;
            axios.get('/field_team/teams/org/' + vm.project.organisation_id).then(response => {
                console.log(response.data.response.data.teams);
                vm.teams = response.data.response.data.teams;
            }).catch(error => {
                console.log(error);
            });
        },
        init() {
            this.getFieldTeams();
        },
    },
    computed: {
        selectedFieldTeam: function () {
            if (this.selectedTeam != null) {
                let team = this.teams.filter(item => {
                    return item.id == this.selectedTeam;
                });
                return ' on Team ' + team[0].name;
            }
        },
        onChangeRoleSelection: function () {
            if (this.assignSelection == "clinic") {
                this.roleClinicSelected = true;
                this.selectedAreas = this.projectAreas[0];
                //this.showAlert("Note", "Clinic users would be able to access all records of the project, No matter which area you select/assign them to.");
            } else {
                this.roleClinicSelected = false;
                this.selectedAreas = []
            }
        }
    },
    created() {
        this.init();
    }

}

</script>

<style>
.swal2-container {
    z-index: 2100 !important;
}
</style>

<style scoped>

.list-box {
    width: 300px;
    background-color: white;
    border-radius: 5px;
    float: left;
    margin-right: 50px;
}

select {
    width: 300px;
    height: 243px;
    border: none;
}

option {
    width: 300px;
    padding: 10px;
}

h6 {
    margin: 0;
}

.set_row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

</style>
