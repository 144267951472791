<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <div class="containBytitile">
                            <h4 class="card-title">Report By City/District/Village</h4>
                        </div>
                        <hr>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <p>Select Date(s)</p>
                                        <el-select v-model="dateFilterOption">
                                            <el-option value="All">All</el-option>
                                            <el-option value="Catch Date Range">Catch Date Range</el-option>
                                        </el-select>
                                    </div>

                                    <div class="col" v-if="dateFilterOption==='Catch Date Range'">
                                        <p>Select Date(s)</p>
                                        <v-md-date-range-picker :presets="dateRangePresets" :start-date="startDatePast"
                                                                :end-date="endDatePast"
                                                                @change="handleChange"
                                                                style="width:100% !important"></v-md-date-range-picker>
                                    </div>
                                </div>
                                <br>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <p>Country</p>
                                <multiselect
                                    v-model="selectedCountry"
                                    :options="allCountries"
                                    :show-labels="false"
                                    placeholder="All"
                                    :multiple="true"
                                    :close-on-select="false"
                                    @remove="countryRemoved"
                                    @select="countrySelected"
                                ></multiselect>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <p>State</p>
                                <multiselect
                                    v-model="selectedState"
                                    :options="allStates"
                                    :show-labels="false"
                                    placeholder="All"
                                    :disabled="!selectedCountry"
                                    :multiple="true"
                                    :close-on-select="false"
                                    @remove="stateRemoved"
                                    @select="stateSelected"
                                ></multiselect>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <p>City/District/Village</p>
                                <multiselect
                                    v-model="selectedCity"
                                    :options="allCities"
                                    :show-labels="false"
                                    placeholder="All"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :disabled="!selectedState"
                                ></multiselect>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-right">
                                <button @click="generateLocalityReport" class="btn btn-primary">DOWNLOAD PDF</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../../../../mixins/GlobalMixin';
import dateRangeDefaultPresetsMixin from '../../../../mixins/DateRangeDefaultPresetsMixin';
import VOffline from 'v-offline';
import {Select, Option,} from 'element-ui';
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";
import Multiselect from "vue-multiselect";


Vue.use(VMdDateRangePicker);

export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline,
        Multiselect,
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data() {
        return {
            dateFilterOption: 'All',

            table: null,
            startDatePast: '',
            endDatePast: '',
            projectName: '',
            allCountries: [],
            allStates: [],
            allCities: [],
            selectedCountry: [],
            selectedState: [],
            selectedCity: [],
            startDate: '',
            endDate: '',
            dateRangePresets: []
        }
    },
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    methods: {
        getPmAreas() {
            let vm = this

            axios.get('/area/pm/list/' + vm.projectId)
                .then(response => {
                    vm.pmAreas = response.data.response.data
                })
                .catch(e => {
                    console.log(e)
                })
        },
        getLocalities() {
            let vm = this

            axios.get('/project/pm/list/locality/' + vm.projectId)
                .then(response => {
                    vm.googleLocalities = response.data.response.data.google_locality
                })
                .catch(e => {
                    console.log(e)
                })
        },
        handleChange(values) {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.startDate = sDate;
            this.endDate = eDate;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        generateLocalityReport() {
            let vm = this;

            if (vm.dateFilterOption === 'Catch Date Range') {
                if (vm.startDate === '' || vm.endDate === '') {

                    this.startDate = vm.formatDate(vm.dateRangePresets[4].range[0]);
                    this.endDate = vm.formatDate(vm.dateRangePresets[4].range[1]);

                }
            } else if (vm.dateFilterOption === 'All') {
                this.startDate = '';
                this.endDate = '';
            }

            axios.post('/pm/reports/generate/location/report', {
                project_id: vm.projectId,
                start_date: vm.startDate,
                end_date: vm.endDate,
                countries: vm.selectedCountry,
                states: vm.selectedState,
                cities: vm.selectedCity,
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    window.location.href = response.data.response.data.src
                }
            }).catch(error => {
                console.log(error);
            })
        },
        async getAllData() {
            await this.getAllCountries();
        },
        stateSelected(state) {
            this.getAllCities(state, false);
        },
        stateRemoved(state) {
            this.getAllCities(state, true);
        },
        async getAllCities(state, isRemoved) {
            this.resetCity();

            for (let i = 0; i < this.selectedState.length; i++) {

                let state2 = this.selectedState[i];

                try {
                    const {data} = await axios.get(
                        `/project/cities/by/state/${this.projectId}`,
                        {
                            params: {
                                state: state2
                            }
                        }
                    );
                    console.log("city", data.response.data);
                    for (let j = 0; j < data.response.data.cities.length; j++) {
                        let newItem = data.response.data.cities[j];
                        this.allCities.indexOf(newItem) === -1 ? this.allCities.push(newItem) : true;
                    }
                } catch (error) {
                    console.error(error.response);
                }

            }

            try {
                const {data} = await axios.get(
                    `/project/cities/by/state/${this.projectId}`,
                    {
                        params: {
                            state
                        }
                    }
                );
                console.log("city", data.response.data);
                for (let j = 0; j < data.response.data.cities.length; j++) {
                    let newItem = data.response.data.cities[j];
                    if (isRemoved) {
                        this.allCities.splice(this.allCities.indexOf(newItem), 1)
                    } else {
                        this.allCities.indexOf(newItem) === -1 ? this.allCities.push(newItem) : true;
                    }
                }
            } catch (error) {
                console.error(error.response);
            }
        },
        countrySelected(country) {
            this.getAllStates(country, false);
        },
        countryRemoved(country) {
            this.getAllStates(country, true);
        },
        async getAllStates(country, isRemoved) {
            this.resetAllOtherFields()

            for (let i = 0; i < this.selectedCountry.length; i++) {

                let country2 = this.selectedCountry[i];

                try {
                    const {data} = await axios.get(
                        `/project/states/by/country/${this.projectId}`,
                        {
                            params: {
                                country: country2
                            }
                        }
                    );
                    console.log("state", data.response.data);
                    for (let j = 0; j < data.response.data.states.length; j++) {
                        let newItem = data.response.data.states[j];
                        this.allStates.indexOf(newItem) === -1 ? this.allStates.push(newItem) : true;
                    }

                    //this.allStates = data.response.data.states;
                } catch (error) {
                    console.error(error.response);
                }

            }

            try {
                const {data} = await axios.get(
                    `/project/states/by/country/${this.projectId}`,
                    {
                        params: {
                            country: country
                        }
                    }
                );
                console.log("state", data.response.data);
                for (let j = 0; j < data.response.data.states.length; j++) {
                    let newItem = data.response.data.states[j];
                    if (isRemoved) {
                        this.allStates.splice(this.allStates.indexOf(newItem), 1)
                    } else {
                        this.allStates.indexOf(newItem) === -1 ? this.allStates.push(newItem) : true;
                    }
                }

                //this.allStates = data.response.data.states;
            } catch (error) {
                console.error(error.response);
            }

        },
        async getAllCountries() {
            try {
                const {data} = await axios.get(
                    `/project/countries/${this.projectId}`
                );
                console.log("countries", data.response.data.countries);
                this.allCountries = data.response.data.countries;
            } catch (error) {
                console.error(error.response);
            }
        },
        resetAllOtherFields() {
            this.selectedState = [];
            this.selectedCity = []
        },
        resetCity() {
            this.selectedCity = []
        },
    },
    computed: {},
    mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        vm.projectId = vm.project.id;
        vm.projectName = vm.project.name;

        vm.getAllData()
        try {
            setTimeout(function () {
                var x = document.getElementsByTagName("label")[0].textContent = "";
                var dateSize = document.getElementsByClassName("text-field__filled")[0];
                dateSize.style.width = '100%';
            }, 50);
        } catch (e) {
            console.log(e)
        }
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.startDatePast = firstDay.toLocaleDateString('en-US');
        this.endDatePast = lastDay.toLocaleDateString('en-US');
        this.dateRangePresets = this.getDateRangePresets();
    }
}

</script>
<style lang="scss">
.containBytitile h4 {
    font-size: 1.2em;
    line-height: 1.45em;
}

.mdrp__activator .activator-wrapper .text-field {
    display: block;
    font-size: 18px;
    padding: 4px 10px 10px 5px;
    width: 300px;
    border-bottom: 0px solid #757575 !important;
    border: solid 1px #e4e7ed !important;
}

.dt-buttons {
    margin-top: -10px;
    margin-left: 10px;
}

.buttons-excel {
    margin: 10px;
}

//    .mdrp__activator .activator-wrapper .text-field, .mdrp__panel.show-calendar{
//        display: block !important;
//        width: 235px !important;
//    }
</style>
