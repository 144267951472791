<template>
   <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
        <template v-slot:[onlineSlot] :slot-name="onlineSlot">
            {{ onlineOfflineMsg }}
        </template>
        <template v-slot:[offlineSlot] :slot-name="offlineSlot">
            {{ onlineOfflineMsg }}
        </template>
      </v-offline>
       <div class="row">
           <div class="col-sm-12">
               <h5>Records <i style="color: darkgrey" class="ti-angle-right small"></i> Treatment Status</h5>
           </div>
       </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="row set_row">
                        <!-- <div class="col-xl-1">
                        </div> -->
                        <div class="col-sm-12 col-xl-12 set_table_center">
                            <table id="treatmentStatusRecords" class="display table-striped table-bordered select" cellspacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>SI. NO</th>
                                        <th>Caught Date</th>
                                        <th>ID</th>
                                        <th>Photo</th>
                                        <th>Locality</th>
                                        <th>Gender</th>
                                        <th>Surgeon Name</th>
                                        <th>Surgery Date</th>
                                        <th>Surgery Time</th>
                                        <th>Duration of Surgery</th>
                                        <th>Weight</th>
                                        <th>NO</th>
                                        <th>Action</th>
                                        <th>Trash</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- <div class="col-xl-1">
                        </div> -->
                    </div>
                </div>
                <div class="card-footer">
                    <button class="btn btn-warning" type="button">Edit Selected Records</button>
                </div>
            </div>
        </div>
      </div>
   </div>
</template>
<script>
   import mixin from '../../../mixins/GlobalMixin';
   import VOffline from 'v-offline';
   import {Select, Option,} from 'element-ui';

   export default{
       components: {
           [Option.name]: Option,
           [Select.name]: Select,
           VOffline
       },
       props: {
           projectId: {
               type: Number,
           }
       },
       data(){
           return {
               table: null,
           }
       },
       mixins: [mixin],
       methods: {
            TreatmentStatus(){
                let vm = this;

               if (vm.table != null) {
                  vm.table.destroy();
               }
               try{
                  vm.table = $('#treatmentStatusRecords').DataTable({
                     "scrollX": true,
                     responsive: true,
                     dom: 'lBfrtip',
                      "bInfo" : false,
                     'columns': [
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null,
                           null
                     ],
                     select: {
                        style:    'os',
                        selector: 'td:first-child'
                     },
                     buttons: [
                           {
                              extend: 'excel',
                              text: 'Export to excel',
                              className: 'btn btn-default',
                              exportOptions: {
                                 columns: [0,1, 2, 3, 4,5,6,7,8,9,10,11,12,13,14],
                              }
                           }
                     ],
                     "processing": true,
                     "serverSide": true,
                      lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                      pageLength: 15,
                  });
               }catch(e){}
            }
       },
       computed: {

       },
       mounted(){
           let vm = this;
           vm.project = vm.$store.getters.getCurrentSelectedProject;
           this.projectId = vm.project.id;
           //Bus.$emit("projectype", vm.project);
           vm.TreatmentStatus();
       }
   }

</script>
<style lang="scss">
   .containBytitile h4 {
    font-size: 1.2em;
    line-height: 1.45em;
   }
   .set_row{
       margin-right: 0px !important;
       margin-left: 0px !important;
   }
</style>
