<template>

    <div>

        <div class="row">
            <div class="col-sm-12">

                <label>Select Date Time</label>
                <el-date-picker
                        v-model="noteModal.dateTime"
                        type="datetime"
                        placeholder="Select date and time">
                </el-date-picker>
                <br>
                <br>

                <label>Note</label>
                <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="Please input"
                        v-model="noteModal.note">
                </el-input>
                <br>
                <br>

            </div>
        </div>

        <div class="row text-right">
            <div class="col-sm-12">
                <button type="button" class="btn btn-success">SAVE</button>
            </div>
        </div>

    </div>

</template>

<script>

import {DatePicker, Input} from 'element-ui';

export default {

    components: {
        [DatePicker.name]: DatePicker,
        [Input.name]: Input,
    },
    data()
    {
        return {
            noteModal: {
                dateTime: '',
                note: '',
            }
        }
    },
    props: {},
    methods: {},
    computed: {},
    mounted()
    {

    }

}

</script>

<style scoped>

</style>
