<template>

    <div v-loading="isLoadingData" class="pm-treatment">
        <el-form ref="treatmentForm" :model="treatmentModal" label-width="80px" label-position="top">
            <div class="row">

                <div class="col-lg-4">
                    <el-form-item
                        label="Select Date Time"
                        prop="dateTime"
                        :rules="[
                            { required: true, message: 'Please select date time', trigger: 'change' }
                        ]">
                        <el-date-picker
                            v-model="treatmentModal.dateTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="Select date and time">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item
                        label="Select Veterinarian"
                        prop="vet"
                        :rules="[
                            { required: true, message: 'Please select veterinarian', trigger: 'change' }
                        ]">
                        <el-select v-model="treatmentModal.vet" placeholder="Select">
                            <el-option
                                v-for="item in list.vets"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="Treatment Type"
                        prop="treatmentType"
                        :rules="[
                            { required: true, message: 'Please select treatment type', trigger: 'change' }
                        ]">
                        <el-select v-model="treatmentModal.treatmentType" placeholder="Select" multiple>
                            <el-option
                                v-for="item in list.treatmentTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <template v-if="treatmentModal.treatmentType==='Other'">
                        <el-form-item label="Treatment Note" prop="treatmentOtherNote">
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="Please input"
                                v-model="treatmentModal.treatmentOtherNote">
                            </el-input>
                        </el-form-item>
                    </template>
                </div>

                <div class="col-lg-4">

                    <div class="row" v-for="(drugGiven, index) in treatmentModal.drugsGiven" :key="index">

                        <div class="col-sm-4" style="margin: 0; padding: 0;">
                            <el-form-item label="Drug Name" :prop="'drugsGiven.' + index + '.drugName'">
                                <el-select v-model="drugGiven.drugName" placeholder="Select">
                                    <el-option
                                        v-for="item in list.vaccines"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>


                        <div class="col-sm-4" style="margin: 0; padding: 0;">
                            <el-form-item label="Dose" :prop="'drugsGiven.' + index + '.dose'">
                                <el-input
                                    type="number"
                                    placeholder="Please input"
                                    v-model="drugGiven.dose">
                                </el-input>
                            </el-form-item>
                        </div>

                        <div class="col-sm-4" style="margin: 0; padding: 0;">
                            <el-form-item label="Unit" :prop="'drugsGiven.' + index + '.unit'">
                                <el-select v-model="drugGiven.unit" placeholder="Select">
                                    <el-option
                                        v-for="item in list.doseUnits"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6 text-left" style="margin: 0; padding: 0;">
                            <button v-if="treatmentModal.drugsGiven.length>1" type="button" @click="removeDrugSection"
                                    class="btn btn-danger">Remove
                            </button>
                        </div>
                        <div class="col-sm-6 text-right" style="margin: 0; padding: 0;">
                            <button type="button" @click="addNewDrugSection"
                                    class="btn btn-success">+ Add Drug
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <el-form-item label="Vaccines Given" prop="vaccineGiven">
                        <el-select v-model="treatmentModal.vaccineGiven" multiple placeholder="Select">
                            <el-option
                                v-for="item in list.vaccines"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Notes" prop="note">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="Please input"
                            v-model="treatmentModal.note">
                        </el-input>
                    </el-form-item>
                    <el-checkbox v-model="treatmentModal.postOpComplication">Sterilization Post-op Complications</el-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-right">
                    <button type="button" class="btn btn-success" :disabled="isSavingData||isLoadingData"
                            @click="saveOrUpdateTreatment">{{ getButtonLabel }}
                    </button>
                </div>
            </div>
        </el-form>
    </div>

</template>

<script>

import {DatePicker, Select, Option, Checkbox, Input, Form, FormItem} from 'element-ui';

export default {

    components: {
        [DatePicker.name]: DatePicker,
        [Select.name]: Select,
        [Option.name]: Option,
        [Checkbox.name]: Checkbox,
        [Input.name]: Input,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    data()
    {
        return {

            isLoadingData: false,
            isSavingData: false,

            treatmentModal: {
                dateTime: '',
                vet: '',
                treatmentType: [],
                treatmentOtherNote: '',
                drugsGiven: [
                    {
                        drugName: '',
                        dose: '',
                        unit: '',
                    }
                ],
                vaccineGiven: [],
                note: '',
                postOpComplication: false,
            },

            list: {
                treatmentTypes: [
                    {
                        label: 'Post-Op',
                        value: 'Post-Op',
                    },
                    {
                        label: 'Re-suture',
                        value: 'Re-suture',
                    },
                    {
                        label: 'Dressing',
                        value: 'Dressing',
                    },
                    {
                        label: 'Redness',
                        value: 'Redness',
                    },
                    {
                        label: 'Swelling',
                        value: 'Swelling',
                    },
                    {
                        label: 'Infection',
                        value: 'Infection',
                    },
                    {
                        label: 'Pus',
                        value: 'Pus',
                    },
                    {
                        label: 'Opened Suture',
                        value: 'Opened Suture',
                    },
                    {
                        label: 'Vaccination',
                        value: 'Vaccination',
                    },
                    {
                        label: 'Accident Wound',
                        value: 'Accident Wound',
                    },
                    {
                        label: 'Maggot wound',
                        value: 'Maggot wound',
                    },
                    {
                        label: 'Skin Infection',
                        value: 'Skin Infection',
                    },
                    {
                        label: 'Eye infection',
                        value: 'Eye infection',
                    },
                    {
                        label: 'Ear infection',
                        value: 'Ear infection',
                    },
                    {
                        label: 'Fracture',
                        value: 'Fracture',
                    },
                    {
                        label: 'Surgical intervention',
                        value: 'Surgical intervention',
                    },
                    {
                        label: 'Deworming',
                        value: 'Deworming',
                    },
                    {
                        label: 'Disease ',
                        value: 'Disease ',
                    },
                    {
                        label: 'Other',
                        value: 'Other',
                    },


                ],

                vets: [

                ],

                vaccines: [

                ],

                doseUnits: [
                    {
                        label: 'ml',
                        value: 'ml',
                    },
                    {
                        label: 'mg',
                        value: 'mg',
                    }
                ],

                drugs: [
                    {
                        label: 'drug 1',
                        value: 'drug 1',
                    },
                    {
                        label: 'drug 2',
                        value: 'drug 2',
                    },
                    {
                        label: 'drug 3',
                        value: 'drug 3',
                    },
                    {
                        label: 'drug 4',
                        value: 'drug 4',
                    },
                    {
                        label: 'drug 5',
                        value: 'drug 5',
                    },
                ]
            },

        }
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        recordId: {
            type: String,
            required: true
        }
    },
    methods: {
        init()
        {
            if (this.id != null) {
                this.getTreatmentDetails()
            }
            this.getVets()
            this.getVaccines()
        },
        saveOrUpdateTreatment()
        {
            this.$refs['treatmentForm'].validate((valid) => {
                if (valid) {
                    let vm = this;

                    vm.isSavingData = true

                    let url = "";

                    if (vm.id != null) {
                        if (vm.id > 0) {
                            url = '/treatment/update/' + vm.recordId + '/' + vm.id;
                        } else {
                            url = '/treatment/add/' + vm.recordId;
                        }
                    } else {
                        url = '/treatment/add/' + vm.recordId;
                    }

                    axios.post(url, {
                        date_time: vm.treatmentModal.dateTime,
                        treatment_type: vm.treatmentModal.treatmentType,
                        treatment_other_note: vm.treatmentModal.treatmentOtherNote,
                        note: vm.treatmentModal.note,
                        vaccinations_given: vm.treatmentModal.vaccineGiven,
                        vet_name: vm.treatmentModal.vet,
                        drugs_given: vm.treatmentModal.drugsGiven,
                        post_op_complication: vm.treatmentModal.postOpComplication,
                    })
                    .then(response => {
                        vm.isSavingData = false
                        if (response.data.response.error.error_code == 0) {

                            this.$root.$emit('HistoryTableUpdateRecords') //like this
                            if (vm.id > 0) {
                                vm.$message({
                                    message: 'Treatment Details Updated Successfully.',
                                    type: 'success'
                                });
                            } else {
                                vm.clearModalData()
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        }
                    })
                    .catch(e => {
                        vm.isSavingData = false
                        console.log(e);
                    })
                } else {
                    return false;
                }
            });
        },
        clearModalData()
        {
            this.treatmentModal.dateTime = ''
            this.treatmentModal.vet = ''
            this.treatmentModal.treatmentType = ''
            this.treatmentModal.treatmentOtherNote = ''
            this.treatmentModal.drugsGiven = [
                {
                    drugName: '',
                    dose: '',
                    unit: '',
                }
            ]
            this.treatmentModal.note = ''
            this.treatmentModal.postOpComplication = ''
            this.treatmentModal.vaccineGiven = []
            this.$refs['treatmentForm'].resetFields();
        },
        getTreatmentDetails()
        {
            let vm = this;

            vm.isLoadingData = true

            axios.get('/treatment/details/' + vm.id)
                .then(response => {
                    vm.isLoadingData = false
                    if (response.data.response.error.error_code == 0) {
                        let record = response.data.response.data.treatment;
                        this.treatmentModal.dateTime = record.treatment_date
                        this.treatmentModal.vet = record.vet_name
                        this.treatmentModal.treatmentType = record.treatment_type
                        this.treatmentModal.treatmentOtherNote = record.treatment_other_note
                        this.treatmentModal.drugsGiven=[];
                        this.treatmentModal.drugsGiven = JSON.parse(record.drugs_given)
                        this.treatmentModal.vaccineGiven = JSON.parse(record.vaccinations_given)
                        this.treatmentModal.note = record.note
                        this.treatmentModal.postOpComplication = record.post_op_complication==1

                    }
                })
                .catch(e => {
                    console.log(e)
                })
        },
        addNewDrugSection()
        {
            this.treatmentModal.drugsGiven.push({
                drugName: '',
                dose: '',
                unit: '',
            });
        },
        removeDrugSection()
        {
            if (this.treatmentModal.drugsGiven.length > 1) {
                this.treatmentModal.drugsGiven.pop();
            }
        },
        getVets()
        {
            var vm = this;
            axios.get("/organisation/get/vets", {
                params: {
                    organisation_id: vm.$store.getters.getCurrentSelectedProject.organisation_id,
                },
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.list.vets = []
                    var v = response.data.response.data.vets;
                    for (let i = 0; i < v.length; i++) {
                        vm.list.vets.push({
                            value: v[i].first_name + ' ' + v[i].last_name,
                            label: v[i].first_name + ' ' + v[i].last_name
                        });
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        },

        getVaccines()
        {
            var vm = this;
            axios.get("/vaccination_drug/get_all/" + vm.$store.getters.getCurrentSelectedProject.organisation_id).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.list.vaccines = []
                    var v = response.data.response.data.vaccination_drugs;
                    for (let i = 0; i < v.length; i++) {
                        vm.list.vaccines.push({
                            value: v[i].vaccination_drug_name,
                            label: v[i].vaccination_drug_name
                        });
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        },
    },
    computed: {
        getButtonLabel: function () {
            if (this.id != null) {
                if (this.id > 0) {
                    return "UPDATE"
                } else {
                    return "SAVE"
                }
            } else {
                return "SAVE"
            }
        }
    },
    mounted()
    {
        this.init()
    }

}

</script>

<style scoped>

</style>
