import FormGroupInput from "./Template/UIComponents/Inputs/formGroupInput.vue";

import DropDown from "./Template/UIComponents/Dropdown.vue";
import Button from "./Template/UIComponents/Button";

import Card from "./Template/UIComponents/Cards/Card.vue";
import ChartCard from "./Template/UIComponents/Cards/ChartCard.vue";
import StatsCard from "./Template/UIComponents/Cards/StatsCard.vue";

import SidebarPlugin from "./Template/UIComponents/SidebarPlugin/index";

let components = {
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  DropDown,
  SidebarPlugin
};

export default components;

export {
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  DropDown,
  Button,
  SidebarPlugin
};
