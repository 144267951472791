import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueNotify from 'vue-notifyjs';

import GlobalComponents from '../globalComponents';
import GlobalDirectives from '../globalDirectives';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

import store from '../store';

import Swal from 'sweetalert2';



import SideBar from '../components/Template/UIComponents/SidebarPlugin/index';
import TopnavBar from '../components/Template/Layout/TopNavbar.vue';
import ContentFooter from '../components/Template/Layout/ContentFooter.vue';
import UserMenu from "../components/Template/UIComponents/SidebarPlugin/UserMenuNew.vue";
import UserNavbar from '../components/Template/Layout/UserNavbar.vue';
//Pradeep//
import ProjectNavbar from '../components/Template/Layout/ProjectNavbar.vue';
import HelpModal from "../components/Template/Layout/HelpModal";
//End//
const queryString = require('query-string');
Vue.use(SideBar);

Vue.use(VeeValidate);
Vue.use(VueNotify);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
locale.use(lang);

export default {
    store,
    data() {
        return {
            appConfig: {},
            onLine: true,
            wasOffline:false,
            onlineSlot: 'online',
            offlineSlot: 'offline',
        };
    },
    components: {
        'top-navbar': TopnavBar,
        'user-navbar': UserNavbar,
        'project-navbar':ProjectNavbar,
        'content-footer': ContentFooter,
        UserMenu,
        "help-modal":HelpModal
    },
    created() {
        this.appConfig = window.appConfig;
    },
    methods: {
        convertDate(convertDate, toFormat, timezone){
           //console.log(convertDate)
            try {
                let localTime = moment.utc(convertDate).toDate();
                // console.log(localTime)
                // console.log(moment(localTime).format('Do MMM YYYY'))
                //return moment(localTime).tz(timezone).format(toFormat);
                return moment(localTime).format('Do MMM YYYY');
            }catch (e){
                // console.log(e);
                // console.log("maybe timezone not given");
                return e
            }
        },
        changeDateFormat(date, format) {
            return moment(date).format(format);
        },
        getUserTimeZone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
        convertUtcToLocal(utcTimestamp) {
            // Append 'Z' to the timestamp to interpret it as UTC
            const date = new Date(`${utcTimestamp}Z`);
            const userTimeZone = this.getUserTimeZone();

            return date.toLocaleString('en-US', { timeZone: userTimeZone });
        },
        formatRecordDisplayDate(date, format, splitBy) {
            if (date === null || date === '0000-00-00 00:00:00') {
                return null;
            }

            date=this.convertUtcToLocal(date)

            const formatedDate = this.changeDateFormat(date, format).split(splitBy);
            if (formatedDate.length > 1) {
                return `${formatedDate[0]} <br/>${formatedDate[1]}`;
            } else {
                return formatedDate[0];
            }
        },
        formatRecordDisplayDateV2(date, format, splitBy) {
            if (date === null || date === '0000-00-00 00:00:00') {
                return null;
            }

            date=this.convertUtcToLocal(date)

            const formatedDate = this.changeDateFormat(date, format).split(splitBy);
            if (formatedDate.length > 1) {
                return `${formatedDate[0]} ${formatedDate[1]}`;
            } else {
                return formatedDate[0];
            }
        },
        activeAgo(lastActiveDate, timezone){
            let localTime = moment.utc(lastActiveDate).toDate();
            return moment(localTime).tz(timezone).fromNow();
        },
        showAlert(title, text, success,callback=null){
            return new Swal({
                title: title,
                text: text,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: success==null? 'simple': success ? 'success' : 'error',
                icon:success==null? 'simple': success ? 'success' : 'error',
            }).then(callback);
        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        amIOnline(e) {
            this.onLine = e;
            if(this.onLine){
                if(this.wasOffline) {
                    setTimeout(function () {
                        document.querySelector('.online').style.display = "block";
                    }, 100);
                    setTimeout(function () {
                        document.querySelector('.online').style.display = "none";
                    }, 2000)
                }
            }else{
                setTimeout(function () {
                    document.querySelector('.offline').style.display="block";
                }, 100);
            }
        },
        zoomImagePopup(imgZoomEl, src = false)
        {
            var modal = document.getElementById("image-modal");
            var modalImg = document.getElementById("image-modal-src");
            var captionText = document.getElementById("caption");

            if (src) {
                modal.style.display = "block";
                modalImg.src = src;
            } else {
                imgZoomEl[0].addEventListener('click', function () {
                    modal.style.display = "block";
                    modalImg.src = this.src;
                    captionText.innerHTML = this.alt;
                }, false);
            }

            var span = document.getElementsByClassName("close-image-modal")[0];
            span.onclick = function () {
                modal.style.display = "none";
            }
        },
    },
    computed: {
        appName() {
            return this.appConfig.appName;
        },
        sidebarLinks() {
            return this.appConfig.sidebarLinks;
        },
        onlineOfflineMsg: function () {
            if (this.onLine) {
                if(this.wasOffline){
                    this.wasOffline=false;
                    setTimeout(function(){
                        document.querySelector('.online').style.display="none";
                    },2000)
                    return "You are back Online."
                }
            } else {
                this.wasOffline=true;
                setTimeout(function(){
                    document.querySelector('.offline').style.display="none";
                },60000)
                return "You have gone Offline, Please check you network connection.";
            }
        },
    },
};
