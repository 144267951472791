<template>
    <div class="maintenance">
        <div id="article">
            <h1>Our site is getting a little tune up and some love.</h1>
            <div>
                <p>We apologize for the inconvenience, but we're performing some maintenance. You can still contact us at <a
                        href="mailto:mailto:admin@hsiapps.org">admin@hsiapps.org</a>. We'll be back up soon!</p>
                <p>— HSI</p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";


export default {
    name: 'MaintenanceMessage',
    computed: {
        maintenanceDetail() {
            return this.$store.getters.getMaintenanceDetail
        }
    },
    methods: {
        ...mapActions(["getMaintenanceDetail"])
    }
}
</script>

<style>

.maintenance {
    text-align: center;
    padding: 150px;
    font: 20px;
    color: #333;
}

h1 {
    font-size: 40px;
}

#article {
    display: block;
    text-align: left;
    width: 650px;
    margin: 0 auto;
}
</style>
