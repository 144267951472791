<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <div class="containBytitile">
                            <h4 class="card-title">Report By Area</h4>
                        </div>
                        <hr>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <p>Select Date(s)</p>
                                        <el-select v-model="dateFilterOption">
                                            <el-option value="All">All</el-option>
                                            <el-option value="Catch Date Range">Catch Date Range</el-option>
                                        </el-select>
                                    </div>

                                    <div class="col" v-if="dateFilterOption==='Catch Date Range'">
                                        <p>Select Date(s)</p>
                                        <v-md-date-range-picker :presets="dateRangePresets" :start-date="startDatePast"
                                                                :end-date="endDatePast"
                                                                @change="handleChange"
                                                                style="width:100% !important"></v-md-date-range-picker>
                                    </div>

                                    <div class="col">
                                        <p>Select Areas</p>
                                        <el-select placeholder="All" multiple class="el_select_width"
                                                   v-model="selectedAreas">
                                            <el-option v-for="area in pmAreas" :value="area.id" :label="area.name">
                                                {{ area.name }}
                                            </el-option>
                                        </el-select>
                                    </div>


                                </div>
                            </div>

                            <div class="col-12" style="text-align: right;">
                                <br>
                                <button style="margin: 0;" @click="downloadPdfReport" class="btn btn-primary">Download
                                    PDF
                                </button>
                            </div>
                        </div>

                        <div class="row">

                        </div>

                        <!--                        <hr>-->
                        <!--                        <p></p>-->
                        <!--                        &lt;!&ndash; <locality-tables></locality-tables> &ndash;&gt;-->
                        <!--                        <div class="row set_row">-->
                        <!--                            &lt;!&ndash; <div class="col-xl-1">-->
                        <!--                            </div> &ndash;&gt;-->
                        <!--                            <div class="col-sm-12 col-xl-12 set_table_center">-->
                        <!--                                <table id="example" class="display table-striped table-bordered select" cellspacing="0"-->
                        <!--                                       width="100%">-->
                        <!--                                    <thead>-->
                        <!--                                    <tr>-->
                        <!--                                        <th>Area</th>-->
                        <!--                                        <th>Male</th>-->
                        <!--                                        <th>Female</th>-->
                        <!--                                        <th>Total</th>-->
                        <!--                                    </tr>-->
                        <!--                                    </thead>-->
                        <!--                                </table>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>

        <FullscreenLoader ref="loader" :api-source="apiSource" msg="Please wait generating PDF..."></FullscreenLoader>

    </div>
</template>
<script>
import mixin from '../../../../mixins/GlobalMixin';
import dateRangeDefaultPresetsMixin from '../../../../mixins/DateRangeDefaultPresetsMixin';
import VOffline from 'v-offline';
import {Select, Option,} from 'element-ui';
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";
import FullscreenLoader from "../../../FullscreenLoader";

Vue.use(VMdDateRangePicker);

export default {
    components: {
        FullscreenLoader,
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline
        // "locality-tables": LocalityTables,
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data() {
        return {
            dateFilterOption: 'All',
            apiSource: null,
            table: null,
            startDatePast: '',
            endDatePast: '',
            projectName: '',
            pmAreas: [],
            selectedAreas: [],
            startDate: '',
            endDate: '',
            dateRangePresets: []
        }
    },
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    methods: {
        getPmAreas() {
            let vm = this

            axios.get('/area/pm/list/' + vm.projectId)
                .then(response => {
                    vm.pmAreas = response.data.response.data
                })
                .catch(e => {
                    console.log(e)
                })
        },
        handleChange(values) {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.startDate = sDate;
            this.endDate = eDate;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        downloadPdfReport() {
            const vm = this


            if (vm.dateFilterOption === 'Catch Date Range') {
                if (vm.startDate === '' || vm.endDate === '') {

                    this.startDate = vm.formatDate(vm.dateRangePresets[4].range[0]);
                    this.endDate = vm.formatDate(vm.dateRangePresets[4].range[1]);

                }
            } else if (vm.dateFilterOption === 'All') {
                this.startDate = '';
                this.endDate = '';
            }

            const cancelToken = axios.CancelToken;
            vm.apiSource = cancelToken.source();

            vm.$refs.loader.show()

            axios.post('/pm/reports/generate/area/report', {
                project_id: vm.projectId,
                start_date: vm.startDate,
                end_date: vm.endDate,
                selected_areas: vm.selectedAreas
            }, {
                cancelToken: vm.apiSource.token
            }).then(response => {
                if (response.data.response.error.error_code === 0) {
                    window.location.href = response.data.response.data.src
                    vm.$refs.loader.hide()
                }
            }).catch(e => {
                console.log(e)
                vm.$refs.loader.hide()
            })
        },
        getLocality() {
            let vm = this;

            axios.post('/pm/reports/area/' + vm.projectId, {
                start_date: vm.startDate,
                end_date: vm.endDate,
                selected_areas: vm.selectedAreas
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    var locality_records = response.data.response.data.locality_records;
                    var localityRecordsArr = [];
                    for (let i = 0; i < locality_records.length; i++) {
                        let record = locality_records[i];
                        var total = parseInt(record.Males) + parseInt(record.Females);
                        let data = {
                            locality: record.name,
                            male: record.Males,
                            female: record.Females,
                            total: total,
                        };
                        localityRecordsArr.push(data);
                    }

                    if (vm.table != null) {
                        vm.table.destroy();
                    }
                    try {
                        vm.table = $('#example').DataTable({
                            "scrollX": true,
                            responsive: true,
                            data: localityRecordsArr,
                            dom: 'lBfrtip',
                            columns: [
                                {data: 'locality'},
                                {data: 'male'},
                                {data: 'female'},
                                {data: 'total'},
                            ],
                            buttons: [
                                {
                                    extend: 'excel',
                                    text: 'Export to Excel',
                                    title: 'Export Excel',
                                    messageTop: 'Report',
                                    messageBottom: 'Export Excel',
                                },
                            ],
                            lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                            pageLength: 15,
                        });
                    } catch (e) {
                        console.log(e)
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
    },
    computed: {},
    mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        vm.projectId = vm.project.id;
        vm.projectName = vm.project.name;
        //Bus.$emit("projectype", vm.project);
        //vm.getLocality();
        vm.getPmAreas()
        try {
            setTimeout(function () {
                var x = document.getElementsByTagName("label")[0].textContent = "";
                var dateSize = document.getElementsByClassName("text-field__filled")[0];
                dateSize.style.width = '100%';
            }, 50);
        } catch (e) {
            console.log(e)
        }
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.startDatePast = firstDay.toLocaleDateString('en-US');
        this.endDatePast = lastDay.toLocaleDateString('en-US');
        this.dateRangePresets = this.getDateRangePresets();
    }
}

</script>
<style lang="scss">
.containBytitile h4 {
    font-size: 1.2em;
    line-height: 1.45em;
}

.mdrp__activator .activator-wrapper .text-field {
    display: block;
    font-size: 18px;
    padding: 4px 10px 10px 5px;
    width: 300px;
    border-bottom: 0px solid #757575 !important;
    border: solid 1px #e4e7ed !important;
}

.dt-buttons {
    margin-top: -10px;
    margin-left: 10px;
}

.buttons-excel {
    margin: 10px;
}

//    .mdrp__activator .activator-wrapper .text-field, .mdrp__panel.show-calendar{
//        display: block !important;
//        width: 235px !important;
//    }
</style>
