<template>
    <div>
        <!--        <div class="top-bar set_msg">
                    <h5 style="margin-left:40px;margin-top:25px">Notifications
                    </h5>
                </div>
                <hr>
                <br/>-->
        <!-- Pradeep Start -->
        <div class="row set_row">
            <div class="col-sm-12" v-if="notifications.length>0">
                <template v-for="notification in notifications">
                    <div class="alert alert-info alert-with-icon alert-dismissible fade show set_notification"
                         data-notify="container">
                        <button @click="deleteNotification(notification)" type="button" aria-hidden="true" class="close"
                                data-dismiss="alert" aria-label="Close">
                            <i class="nc-icon nc-simple-remove"></i>
                        </button>
                        <span data-notify="icon" class="nc-icon nc-bell-55"></span>
                        <span><b>{{ notification.title }}</b></span>
                        <span data-notify="message">{{ notification.body }}</span>
                        <div v-if="notification.type === 'TYPE_PROJECT_INVITATION' ">
                            <button type="button" class="btn btn-default noti-response btn-accept"
                                    @click="joinExtProject(notification)">
                                <i class="nc-icon nc-check-2"></i>
                                Accept
                            </button>
                        </div>
                        <div v-if="notification.type === 'REQUEST_REASSIGN_VACCINATION_AREA' ">
                            <button type="button" class="btn btn-default noti-response btn-reject"
                                    @click="respondReassign(notification, 'reject')">
                                <i class="nc-icon nc-simple-remove"></i>
                                Reject
                            </button>
                            <button type="button" class="btn btn-default noti-response btn-accept"
                                    @click="respondReassign(notification, 'accept')">
                                <i class="nc-icon nc-check-2"></i>
                                Accept
                            </button>
                        </div>
                        <div v-if="notification.type === 'REQUEST_REASSIGN_SURVEY_AREA' ">
                            <button type="button" class="btn btn-default noti-response btn-reject"
                                    @click="respondReassignSurvey(notification, 'reject')">
                                <i class="nc-icon nc-simple-remove"></i>
                                Reject
                            </button>
                            <button type="button" class="btn btn-default noti-response btn-accept"
                                    @click="respondReassignSurvey(notification, 'accept')">
                                <i class="nc-icon nc-check-2"></i>
                                Accept
                            </button>
                        </div>
                        <br/>
                        <span data-notify="message">{{ activeAgo(notification.updated_at, 'UTC') }}</span>
                    </div>
                </template>
            </div>
            <!-- <div v-else>
               <div class="col-lg-12">
                  <p class="text-center">
                     No Notifications Found.
                  </p>
               </div>
            </div> -->
            <div class="col-sm-1 col-md-3"></div>
        </div>
        <!-- Pradeep End -->
    </div>
</template>
<script>
import VOffline from 'v-offline';

export default {

    components: {
        VOffline
    },
    data() {
        return {

            notifications: [],

        }
    },
    methods: {

        init() {
            let vm = this;

            axios.get('user/notifications').then(response => {

                if (response.data.response.error.error_code == 0) {
                    vm.notifications = response.data.response.data.notifications;
                }

            }).catch(error => {

            });

        },
        async respondReassign(noti, status) {
            let respondFlag;
            const {id} = noti;
            respondFlag = status === 'accept' ? 1 : 0;
            try {
                const response = await axios.post(`/notification/approve/reassignVaccinationArea/${id}/${respondFlag}`);
                this.showAlert('Success', response.data.response.error.msg, true);
                this.init();
            } catch (error) {
                this.showAlert('Failed', error.response.data.response.error.msg, false);
            }

        },
        async joinExtProject(noti) {
            let data = JSON.parse(noti.data)
            window.location.href = data.invitation_link;
        },
        async respondReassignSurvey(noti, status) {
            let respondFlag;
            const {id} = noti;
            respondFlag = status === 'accept' ? 1 : 0;
            try {
                const response = await axios.post(`/notification/approve/reassignSurveyArea/${id}/${respondFlag}`);
                this.showAlert('Success', response.data.response.error.msg, true);
                this.init();
            } catch (error) {
                this.showAlert('Failed', error.response.data.response.error.msg, false);
            }

        },
        async deleteNotification(noti) {
            const {id} = noti;
            try {
                const response = await axios.delete(`/notification/remove/${id}`);
                //this.showAlert('Success', response.data.response.error.msg, true);
                this.init();
            } catch (error) {
                this.showAlert('Failed', error.response.data.response.error.msg, false);
            }
        }

    },
    computed: {},
    mounted() {
        this.init();

        axios.put('/notification/mark/all/read').then(response => {
            if (response.data.response.error.error_code == 0) {

            } else {

            }
        });
    }

}

</script>
<style scoped>
/* Pradeep Start */
.btn.btn-default.noti-response {
    border-width: 2px;
    border-radius: 30px;
    font-size: 10px;
    padding: 10px 15px;
    cursor: pointer;
    /* background-color: #66615B; */
    color: #FFFFFF;
}

.btn.btn-default.noti-response.btn-accept {
    background-color: rgb(42, 144, 85) !important;
}

.btn.btn-default.noti-response.btn-reject {
    background-color: rgb(235, 101, 50) !important;
}

@media (max-width: 425px) {
    .set_row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .set_notification {
        width: 100% !important;
    }
}

@media (max-width: 320px) {
    .set_msg {
        margin-top: 40% !important;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .set_msg {
        margin-top: 35% !important;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .set_msg {
        margin-top: 35% !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .set_msg {
        margin-top: 30% !important;
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .set_msg {
        margin-top: 18% !important;
    }
}

/* Pradeep End */
</style>
