<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox ">
                    <div class="ibox-content">
                        <div class="panel-heading">
                            <h2 class="set_font_title">Area Details</h2>
                        </div>
                        <div class="row col-md-12 col-xl-12">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card setShadow">
                                    <div class="card-body" style="padding: 15px 15px 0px;">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div style="padding-top: 10px;">
                                                    <a :title="title" href="#"
                                                       style="float: left; font-size: 22px; font-weight: bold;">
                                                        {{ title }}
                                                    </a>
                                                </div>
                                            </div>
                                            <div v-if="!isReadOnly" class="col-sm-6 text-right">
                                                <button v-bind:disabled="!onLine" type="submit"
                                                        class="btn btn-primary"
                                                        @click="editArea(areaId)">Edit Area
                                                </button>
                                                <button v-bind:disabled="!onLine" type="submit"
                                                        class="btn btn-primary"
                                                        @click="kmlDownload(areaImage)">Download KML
                                                </button>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                                <div id="kmlMap" style="height: 95%;">
                                                    <!-- <img :src="areaImage"/> -->
                                                </div>
                                                <!--                                                <p style="text-align:right;margin-bottom: 5px;">
                                                                                                <span class="ti-zoom-in zoom_icon"
                                                                                                      @click="view_download_kml(areaId)"></span>
                                                                                                </p>-->
                                            </div>
                                            <div class="col-sm-12 col-md-6 contain_card">
                                                <div class="area-details">
                                                    <p class="area-info-text">Created On <br> <span
                                                        class="area-details-text">{{
                                                            createdOn.toLocaleString()
                                                        }}</span></p>
                                                    <p class="area-info-text">Country <br> <span
                                                        class="area-details-text">{{ country }}</span></p>
                                                    <p class="area-info-text">State/Province <br> <span
                                                        class="area-details-text">{{
                                                            areaState != 'null' ? areaState : 'Not specified'
                                                        }}</span></p>
                                                    <p class="area-info-text">City/District/Village <br> <span
                                                        class="area-details-text">{{ locality }}</span></p>


                                                    <p class="footer_tags text-left">
                                                        <b-icon-circle-fill
                                                            :style="{ color: vaccinationStatus.color }"
                                                        />
                                                        {{ vaccinationStatus.text }}
                                                    </p>

                                                    <p class="footer_tags text-left">
                                                        <b-icon-triangle-fill
                                                            :style="{ color: surveyStatus.color }"
                                                        />
                                                        {{ surveyStatus.text }}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>

                        <div class="row setTitleDiv">
                            <p>Records</p>
                        </div>
                        <div class="row col-md-12 col-xl-12">
                            <div class="col-sm-12 col-md-12 col-xl-6">
                                <div class="card setShadow">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <b style="font-size: 17px !important;color: #9a9a9a !important;">Vaccination</b>
                                                <hr>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <b>Animals Vaccinated</b>
                                                <div style="font-size: 24px;">
                                                    <!--Under Construction-->
                                                    {{ getVaccinationStats }}
                                                </div>
                                                <p>Of which {{ vaccinationsRecLenOuter }} animals are out-of-area</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <button v-bind:disabled="!onLine" type="submit"
                                                        class="btn btn-primary" @click="listAreaVaccinations">
                                                    List
                                                </button>
                                                <button v-bind:disabled="!onLine" type="submit"
                                                        class="btn btn-primary" @click="viewAreaVaccinationsOnMap">Map
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-xl-6">
                                <div class="card setShadow">
                                    <div class="card-body text_left">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <b style="font-size: 17px !important;color: #9a9a9a !important;">Surveys</b>
                                                <hr>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <b>Vaccination Rate (%)</b>
                                                <div style="font-size: 24px;">
                                                    <!--Under Construction-->
                                                    {{ getSurveyStats }}
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <button v-bind:disabled="!onLine" type="submit"
                                                        class="btn btn-primary"
                                                        @click="listAreaSurveys">List
                                                </button>
                                                <button v-bind:disabled="!onLine" type="submit"
                                                        class="btn btn-primary" @click="viewAreaSurveyOnMap">
                                                    Map
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>

                        <div class="row setMarginTop set_res_margin_top">
                            <div class="col-xs-12 col-md-12 col-xl-12">
                                <div class="row setTitleDiv">
                                    <p>Field User Assignments - Vaccination</p>
                                </div>
                                <div class="row col-md-12 col-xl-12">
                                    <div class="col-sm-12 col-md-12 col-xl-12">
                                        <div class="card setShadow"
                                             style="overflow: scroll; position: relative; border: 0px; width: 100%;">
                                            <div class="card-body text_left">
                                                <table id="example"
                                                       class="display table table-striped table-bordered select"
                                                       cellspacing="0" width="100%">
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Role</th>
                                                        <th>Status</th>
                                                        <th>#Records</th>
                                                        <th v-if="!isReadOnly"><span style="color: rgb(19, 206, 102);">Active</span> /
                                                            <span
                                                                style="color: rgb(255, 73, 73);">De-Active</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="(item, index) in vaccinationAssignedUsers" :key="index">
                                                        <td>{{ item.first_name + " " + item.last_name }}</td>
                                                        <td>{{ item.email }}</td>
                                                        <td>{{ item.purpose }}</td>
                                                        <td>
                                                            <b-icon-circle-fill
                                                                :style="{ color: item.statusColor }"/>
                                                            <template
                                                                v-if="(item.submissionStatus=='Closed/Submitted'||item.submissionStatus=='Requested Re-Opening')">
                                                                {{ item.submissionStatus }}<br>
                                                                <button v-if="!isReadOnly"
                                                                    style="border: none; background: none; color:lightseagreen;"
                                                                    @click='reassignArea(item.assignationId)'>
                                                                    Re-Open
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                {{ item.submissionStatus }}<br>
                                                                <button v-if="!isReadOnly"
                                                                        style="border: none; background: none; color:lightseagreen;"
                                                                        @click='closeArea(item.assignationId)'>
                                                                    Close Area
                                                                </button>
                                                            </template>
                                                        </td>
                                                        <td>{{ item.recordsCount }}</td>
                                                        <td v-if="!isReadOnly">
                                                            <el-switch
                                                                v-model="item.activeStatus"
                                                                @change="changeUserActiveStatus(item)"
                                                                active-color="#13ce66"
                                                                inactive-color="#ff4949">
                                                            </el-switch>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <p>De-activating stops further submissions from the field user, but keeps any
                                            data they have entered. It also considers their data "submitted" as it
                                            relates to determine if an area is Submitted/Finalized</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <br>

                        <div class="row setMarginTop set_res_margin_top">
                            <div class="col-xs-12 col-md-12 col-xl-12">
                                <div class="row setTitleDiv">
                                    <p>Field User Assignments - Survey</p>
                                </div>
                                <div class="row col-md-12 col-xl-12">
                                    <div class="col-sm-12 col-md-12 col-xl-12">
                                        <div class="card setShadow"
                                             style="overflow: scroll; position: relative; border: 0px; width: 100%;">
                                            <div class="card-body text_left">
                                                <table id="example2"
                                                       class="display table table-striped table-bordered select"
                                                       cellspacing="0" width="100%">
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Role</th>
                                                        <th>Status</th>
                                                        <th>#Records</th>
                                                        <th v-if="!isReadOnly"><span style="color: rgb(19, 206, 102);">Active</span> /
                                                            <span
                                                                style="color: rgb(255, 73, 73);">De-Active</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="(item, index) in surveyAssignedUsers" :key="index">
                                                        <td>{{ item.first_name + " " + item.last_name }}</td>
                                                        <td>{{ item.email }}</td>
                                                        <td>{{ item.purpose }}</td>
                                                        <td>
                                                            <b-icon-triangle-fill
                                                                :style="{ color: item.statusColor }"/>
                                                            <template
                                                                v-if="(item.submissionStatus=='Closed/Submitted'||item.submissionStatus=='Requested Re-Opening')">
                                                                {{ item.submissionStatus }}<br>
                                                                <button v-if="!isReadOnly"
                                                                    style="border: none; background: none; color:lightseagreen;"
                                                                    @click='reassignArea(item.assignationId)'>
                                                                    Re-Open
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                {{ item.submissionStatus }}<br>
                                                                <button v-if="!isReadOnly"
                                                                        style="border: none; background: none; color:lightseagreen;"
                                                                        @click='closeArea(item.assignationId)'>
                                                                    Close Area
                                                                </button>
                                                            </template>
                                                        </td>
                                                        <td>{{ item.recordsCount }}</td>
                                                        <td v-if="!isReadOnly">
                                                            <el-switch
                                                                v-model="item.activeStatus"
                                                                @change="changeUserActiveStatus(item)"
                                                                active-color="#13ce66"
                                                                inactive-color="#ff4949">
                                                            </el-switch>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <p>De-activating stops further submissions from the field user, but keeps any
                                            data they have entered. It also considers their data "submitted" as it
                                            relates to determine if an area is Submitted/Finalized</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../../mixins/GlobalMixin';
import {Switch} from 'element-ui';
import VOffline from 'v-offline';
import {API_KEY} from "../Template/Maps/API_KEY";
import GoogleMapsLoader from "google-maps";
import {BIconTriangleFill, BIconCircleFill} from "bootstrap-vue";


GoogleMapsLoader.KEY = API_KEY;

export default {
    components: {
        VOffline,
        [Switch.name]: Switch,
        BIconTriangleFill,
        BIconCircleFill
    },
    props: {
        projectId: {
            type: Number,
        },
        areaId: {
            type: Number,
        }
    },
    data() {
        return {
            isReadOnly: false,
            title: 'title',
            createdOn: '',
            country: '',
            areaState: '',
            locality: '',
            areaImage: '',
            areaId: '',
            surveys: '',
            vaccinations: '',
            vaccinationsImg: '',
            vaccinationsName: '',
            surveysImg: '',
            surveysName: '',
            vaccinationAssignedUsers: null,
            surveyAssignedUsers: null,
            areaStatusColor: 'black',
            vaccinationsRecLen: 0,
            vaccinationsRecLenOuter: 0,
            vaccinationsRecPer: 0,
            surveysRecLen: 0,
            surveysRecPer: 0,
            surveyStatus: {
                type: Object
            },
            vaccinationStatus: {
                type: Object
            }
        }
    },
    mixins: [mixin],
    methods: {
        viewAreaVaccinationsOnMap() {
            localStorage.setItem('vaccinationProjectLevelViewMap', 0);
            localStorage.setItem('VaccinationAreaId', this.areaId);
            localStorage.setItem('VaccinationAreaName', this.title);
            this.$router.push({
                name: 'areaVaccinationMapview',
                params: {projectId: this.projectId, areaId: this.areaId}
            })
        },
        showAllKMLs() {
            window.location = '/project/map_kml_files/' + this.areaId;
        },
        listAreaVaccinations() {
            localStorage.setItem('vaccinationProjectLevelViewList', 0);
            localStorage.setItem('VaccinationAreaId', this.areaId);
            localStorage.setItem('VaccinationAreaName', this.title);
            this.$router.push({name: 'areaVaccinationList', params: {projectId: this.projectId, areaId: this.areaId}})
        },
        viewAreaSurveyOnMap() {
            localStorage.setItem('surveyProjectLevelViewMap', 0);
            localStorage.setItem('SurveyAreaId', this.areaId);
            localStorage.setItem('SurveyAreaName', this.title);
            this.$router.push({name: 'areaSurveyMapview', params: {projectId: this.projectId, areaId: this.areaId}})
        },
        listAreaSurveys() {
            localStorage.setItem('surveyProjectLevelViewList', 0);
            localStorage.setItem('SurveyAreaId', this.areaId);
            localStorage.setItem('SurveyAreaName', this.title);
            this.$router.push({name: 'areaSurveyList', params: {projectId: this.projectId, areaId: this.areaId}})
        },
        getAreaDetails(areaId) {
            axios.get("/area/details/" + areaId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        let areaDetails = response.data.response.data.area;
                        let vm = this;

                        vm.vaccinationsRecLen = areaDetails.vaccinations.length;
                        vm.vaccinationsRecLenOuter = areaDetails.vaccinations.filter(record => record.outside_the_area == 1).length;
                        vm.surveysRecLen = areaDetails.surveys.length;

                        vm.vaccinationStatus = areaDetails.status_info.vaccination;
                        vm.vaccinationsRecPer = areaDetails.status_info.vaccination.percentage_covered;


                        vm.surveyStatus = areaDetails.status_info.survey;
                        vm.surveysRecPer = areaDetails.status_info.survey.percentage_covered;

                        vm.areaStatusColor = areaDetails.status_color_code;
                        vm.title = areaDetails.name;
                        vm.createdOn = new Date(areaDetails.created_at);
                        vm.country = areaDetails.google_country;
                        vm.areaState = areaDetails.google_admin_area_level1;
                        vm.locality = areaDetails.google_locality;
                        vm.areaImage = areaDetails.file_url;
                        vm.areaId = areaDetails.id;
                        GoogleMapsLoader.load(google => {
                            var map = new google.maps.Map(document.getElementById('kmlMap'), {
                                zoom: 7,
                                center: {lat: 41.876, lng: -87.624},
                                disableDefaultUI: true,
                            });

                            var kmlUrl = vm.areaImage;
                            var ctaLayer = new google.maps.KmlLayer({
                                url: kmlUrl,
                                map: map,
                                clickable: false
                            });
                        });
                        let assigned_survey_areas = response.data.response.data.area.assigned_areas;
                        let assignedUsers = [];
                        for (let i = 0; i < assigned_survey_areas.length; i++) {
                            let assignedArea = assigned_survey_areas[i];
                            if (assignedArea.user == null) {
                                continue;
                            }
                            let user = assignedArea.user;
                            user.assignationId = assignedArea.id;
                            if (assignedArea.purpose == 1) {
                                user.purpose = 'Surveyor';
                                user.recordsCount = areaDetails.surveys.filter(x => x.record_taken_by == assignedArea.user_id).length
                            } else if (assignedArea.purpose == 2) {
                                user.purpose = 'Vaccinator';
                                user.recordsCount = areaDetails.vaccinations.filter(x => x.vaccinated_by == assignedArea.user_id).length
                            }

                            if (assignedArea.status == 0) {
                                user.submissionStatus = "Ready to start";
                                user.statusColor = "#000000";
                            } else if (assignedArea.status == 1) {
                                user.submissionStatus = "In progress";
                                user.statusColor = "#ffff00";
                            } else if (assignedArea.status == 5) {
                                user.submissionStatus = "Closed/Submitted";
                                user.statusColor = "#ff0000";
                            } else if (assignedArea.status == -1) {
                                user.submissionStatus = "Re-Opened";
                                user.statusColor = "#00ff00";
                            } else if (assignedArea.status == -2) {
                                user.submissionStatus = "Requested Re-Opening";
                                user.statusColor = "#808080";
                            }

                            // this.$set(user, 'recordId', assignedArea.id)
                            // this.$set(user, 'areaId', assignedArea.area_id)
                            // this.$set(user, 'activeStatus', assignedArea.is_active)
                            user.recordId = assignedArea.id
                            user.areaId = assignedArea.area_id
                            user.activeStatus = Boolean(assignedArea.is_active)
                            assignedUsers.push(user);
                        }
                        vm.vaccinationAssignedUsers = assignedUsers.filter(x => x.purpose == "Vaccinator");
                        vm.surveyAssignedUsers = assignedUsers.filter(x => x.purpose == "Surveyor");
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        reassignArea(assignmentId) {
            let vm = this;
            axios.post("/area/reassign/" + assignmentId, {})
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert('Success', response.data.response.error.msg, true, function (result) {
                            if (result.value) {
                                vm.getAreaDetails(vm.areaId);
                            }
                        });
                    }
                })
                .catch(error => {
                    vm.showAlert('Failed', response.data.response.error.error_msg, false);
                });
        },
        closeArea(assignmentId) {
            let vm = this;
            axios.post("/area/close/" + assignmentId, {})
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert('Success', response.data.response.error.msg, true, function (result) {
                            if (result.value) {
                                vm.getAreaDetails(vm.areaId);
                            }
                        });
                    }
                })
                .catch(error => {
                    vm.showAlert('Failed', response.data.response.error.error_msg, false);
                });
        },
        getAllAreaDetails(project_id) {
            axios.get("/area/all/details/" + project_id)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        let vm = this;
                        let assigned_survey_areas = response.data.response.data.assigned_survey_areas;
                        let assigned_vaccination_areas = response.data.response.data.assigned_vaccination_areas;
                        let survey = this.getUnique(Object.keys(assigned_survey_areas).flatMap((itm) => assigned_survey_areas[itm].user).filter(x => !!x), 'email');
                        let vaccination = this.getUnique(Object.keys(assigned_vaccination_areas).flatMap((itm) => assigned_vaccination_areas[itm].user).filter(x => !!x), 'email');
                        vm.vaccinationAssignedUsers = vm.matchUserRecord(survey, vaccination).filter(x => !!x);
                    }
                })
                .catch(error => {
                    if (error.response.data.response.error) {

                    }
                });
        },
        getUnique(arr, comp) {

            const unique = arr
                .map(e => e[comp])

                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)

                // eliminate the dead keys & store unique objects
                .filter(e => arr[e]).map(e => arr[e]);

            return unique;
        },
        editArea(id) {
            //this.areaId = id;
            //window.location = '/project/edit_area/' + this.areaId;
            this.$router.push({name: 'editArea', params: {areaId: id}})
        },
        view_download_kml(area_id) {
            window.location = '/project/view_download_kml/' + area_id;
        },
        kmlDownload(kmlFileUrl) {
            window.location.href = kmlFileUrl;
        },
        async changeUserActiveStatus(user) {
            console.log('user', user)
            const url = '/area/toggle_active_user';
            const query = {
                id: user.recordId,
                user_id: user.id,
                area_id: user.areaId,
                purpose: user.purpose === 'Surveyor' ? 1 : user.purpose === 'Vaccinator' ? 2 : 3
            }
            try {
                const response = await axios.post(url, null, {
                    params: query
                })
            } catch (error) {
                user.activeStatus = !user.activeStatus
                this.showAlert('Error', error.response.data.message, false);
            }
        }
    },
    computed: {
        getVaccinationStats: function () {
            return this.vaccinationsRecLen;
        },
        getSurveyStats: function () {
            if (this.surveysRecLen > 10) {
                return this.surveysRecLen + ' (' + this.surveysRecPer + '%)';
            }
            return "Not enough data";
        }
    },
    mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;

        vm.isReadOnly = false
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if(r.role_name == 'VIEWER'){
                vm.isReadOnly = true
            }
        })

        //this.areaId = vm.project.id;
        //Bus.$emit("projectype", vm.project);
        let url = location.href;
        let res = url.split("/");
        this.areaId = res[res.length - 1];
        vm.getAreaDetails(vm.areaId);
    }
}

</script>
<style scoped>

table {
    text-align: center;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}

#pac-container {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls label {
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

.setTitleDiv p {
    font-size: 17px;
    font-weight: 600;
    color: black;
}

.el_select_width {
    width: 100% !important;
}

.setShadow {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.card .numbers p {
    color: #9A9A9A;
    font-size: 14px;
}

.card .numbers {
    font-size: 1.5em !important;
}

.card .card-body {
    text-align: center;
}

.set_b_font_card {
    font-size: 17px !important;
    color: #9a9a9a !important;
}

.set_btn {
    text-align: right;
    margin-top: -5px;
}

@media (max-width: 768px) {
    .set_btn {
        text-align: center !important;
        margin-top: -5px;
    }
}

.btn_card {
    cursor: pointer;
    height: 86px;
}

.no_rec_msg {
    font-size: 20px;
    height: 220px;
}

.text-field__filled {
    display: block;
    font-size: 18px;
    padding: 4px 10px 10px 5px;
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #757575;
}

.set_font_title {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black !important;
    margin-left: -12px !important;
}

p {
    margin-bottom: 12px;
}

p {
    cursor: default;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.area-img {
    width: 200px;
    height: 220px;
}

.area-details {
    margin-bottom: 15px;
}

.area-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.area-contributors {
    float: left;
}

.area-card-footer {
    margin-left: 15px;
    margin-right: 15px;
}

.contain_card {
    text-align: left !important;
}

.card_height {
    height: 260px !important;
}

.set_contain {
    margin-left: 3px;
    text-align: left;
    margin-bottom: 10px;
}

.set_font {
    font-size: 25px;
    margin-left: 15px;
}

.zoom_icon {
    font-size: 20px;
    cursor: pointer;
    color: #f58220;
}

.area-details-text {
    font-weight: bold;
    margin-bottom: 5px;
}

</style>
