<template>
    <div>

        <div class="row">

            <div class="col-sm-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <el-table
                                :data="tableData"
                                :default-sort="{prop: 'date', order: 'descending'}"
                                style="width: 100%">
                            <el-table-column
                                    prop="date"
                                    label="Date"
                                    sortable>
                            </el-table-column>
                            <el-table-column
                                    prop="type"
                                    label="Type">
                            </el-table-column>
                            <el-table-column
                                    prop="drug_vaccine"
                                    label="Notes">
                            </el-table-column>
                            <el-table-column
                                    label="View/Edit/Delete">
                                <template slot-scope="scope">
                                    <el-button
                                            size="mini"
                                            type="success"
                                            @click="handleEdit(scope.$index, scope.row)">View/Edit
                                    </el-button>
                                    <!-- <el-button v-if="scope.row.view!='Catching' && scope.row.view!='Vaccination' && scope.row.view!='Release'"
                                               size="mini"
                                               type="simple"
                                               @click="handleDelete(scope.$index, scope.row)">Delete
                                    </el-button> -->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>

import {Table, TableColumn, Button} from 'element-ui';
import Swal from "sweetalert2"

export default {

    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
    },
    props: {
        recordId: {
            type: [Number, String]
        },
    },
    data()
    {
        return {

            table: null,

            tableData: [],

            isEuthanized: false,
            isPostmortemDone: false,
            isSterilizationDone: false,

        }
    },
    methods: {
        getRecordHistory()
        {

            let vm = this;

            axios.get("/catch_release/record/history/" + vm.recordId)
                .then(response => {

                    if (response.data.response.error.error_code == 0) {
                        vm.tableData = response.data.response.data.record_history

                        for (let i = 0; i < vm.tableData.length; i++) {

                            //vm.tableData[i].date=vm.convertUtcToLocal(vm.tableData[i].date.date)
                            vm.tableData[i].date=vm.formatRecordDisplayDateV2(vm.tableData[i].date.date, 'Do MMM YYYY, HH:mm:ss', ',');

                            if (vm.tableData[i].type == 'Euthanasia') {
                                vm.isEuthanized = true;
                            }
                            if (vm.tableData[i].type == 'Postmortem') {
                                vm.isPostmortemDone = true;
                            }
                            if (vm.tableData[i].type == 'Sterilization') {
                                if (vm.tableData[i].drug_vaccine == 'Already Sterilized' || vm.tableData[i].drug_vaccine == 'Sterilization Successful') {
                                    vm.isSterilizationDone = true;
                                }
                            }
                        }

                    } else {
                        alert('Something went wrong while loading history records')
                    }

                })
                .catch(e => {
                    console.log(e)
                });

        },

        formatter(row, column)
        {
            return row.address;
        },
        handleEdit(index, row)
        {
            this.$parent.showEditDialog(row.view, row.id);
        },
        // Calling delete methond from parent
        handleDelete(index, row)
        {
            let vm = this
            let recordType = ''
            switch (row.view) {
                case 'Physical Examination':
                    recordType = 'exam'
                    break
                case 'Sterilization':
                    recordType = 'sterilization'
                    break
                case 'Sterilisation':
                    recordType = 'sterilization'
                    break
                case 'Operation':
                    recordType = 'operation'
                    break
                case 'Treatment':
                    recordType = 'treatment'
                    break
                case 'Euthanasia':
                    recordType = 'euthanasia'
                    break
                case 'Postmortem':
                    recordType = 'postmortem'
                    break
                default:
                    vm.showAlert('Error', 'You cannot delete this type of record', false)
                    return


            }

            new Swal({
                icon:'info',
                title: 'Delete Record',
                text: `Are you sure you want to delete this record? After deleting you will be asked to confirm the animal's stage, outcome and sterilization.`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Delete',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.delete('/catch_release/delete/pm/health/record/' + recordType + '/' + row.id)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.getRecordHistory()
                                vm.$root.$emit('healthRecordDeletedSuccessfully')
                            } else {
                                vm.showAlert('Failed',
                                    response.data.response.error.error_msg,
                                    false)
                            }
                        }).catch(error => {
                        console.log(error)
                    })
                }
            })


        },
    },
    computed: {},
    mounted()
    {
        this.$root.$on('HistoryTableUpdateRecords', () => {
            this.$root.$emit('UpdateSummaryComponent') //like this
            // your code goes here
            this.getRecordHistory()
        });

        this.getRecordHistory();

    }

}

</script>
<style scoped>

</style>
