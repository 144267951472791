import * as types from '../mutation-types';

const state = {

    organisationName: '',

    organisationLogo: 'https://www.iconsdb.com/icons/preview/tropical-blue/organization-xxl.png',

    projectType: '',
    projectName: '',
    screenName: '',

    projectNavBarTitle: '',

    currentSelectedProject: null,

    maintenanceDetail: {
        message_show: false,
        message_text: '',
        maintenance_mode: false
    }

};

const getters = {

    //getProjectNavBarTitle: state => state.projectType + " > " + state.projectName + " > " + state.screenName,
    getProjectNavBarTitle: state => state.organisationName + " - Project " + state.projectName,

    getOrganisationLogoUrl: state => state.organisationLogo,

    getCurrentSelectedProject: state => state.currentSelectedProject,

    getUserRoleForCurrentProject: state => state.currentSelectedProject.roles,

    getMaintenanceDetail: state => state.maintenanceDetail


};

const mutations = {

    [types.SET_PROJECT_TYPE](state, data) {
        state.projectType = data;
        //state.projectNavBarTitle="";
        //state.projectNavBarTitle = data + " > " + state.projectName + " > " + state.screenName;
    },

    [types.SET_PROJECT_NAME](state, projectName) {
        state.projectName = projectName;
        //state.projectNavBarTitle="";
        //state.projectNavBarTitle = state.projectType + " > " + state.projectName + " > " + state.screenName;
    },

    [types.SET_ORGANISATION_NAME](state, orgName) {
        state.organisationName = orgName;
        //state.projectNavBarTitle="";
        //state.projectNavBarTitle = state.projectType + " > " + state.projectName + " > " + state.screenName;
    },

    [types.SET_ORGANISATION_LOGO](state, orgLogo) {
        state.organisationLogo = orgLogo;
        //state.projectNavBarTitle="";
        //state.projectNavBarTitle = state.projectType + " > " + state.projectName + " > " + state.screenName;
    },

    [types.SET_SCREEN_NAME](state, screenName) {
        state.screenName = screenName;
        //state.projectNavBarTitle="";
        //state.projectNavBarTitle = state.projectType + " > " + state.projectName + " > " + state.screenName;
    },

    [types.SET_CURRENT_SELECTED_PROJECT](state, project) {
        state.currentSelectedProject = project;
    },

    [types.SET_MAINTENANCE_DETAILS](state, maintenanceDetail) {
        state.maintenanceDetail = maintenanceDetail;
    },


};

const actions = {

    setProjectType({commit}, data) {
        commit(types.SET_PROJECT_TYPE, data);
    },

    setProjectName({commit}, data) {
        commit(types.SET_PROJECT_NAME, data);
    },

    setOrganisationName({commit}, data) {
        commit(types.SET_ORGANISATION_NAME, data);
    },

    setOrganisationLogo({commit}, data) {
        commit(types.SET_ORGANISATION_LOGO, data);
    },

    setScreenName({commit}, data) {
        commit(types.SET_SCREEN_NAME, data);
    },

    setCurrentSelectedProject({commit}, data) {
        commit(types.SET_CURRENT_SELECTED_PROJECT, data);
    },

    async getMaintenanceDetail({commit})
    {
        await axios.get('app/maintenance/mode').then(r => {
            commit(types.SET_MAINTENANCE_DETAILS, r.data.response.data);
        });
    }

};

export default {
    state,
    getters,
    mutations,
    actions
}
