import { render, staticRenderFns } from "./SurgeryOther.vue?vue&type=template&id=4a41a79a&scoped=true&"
import script from "./SurgeryOther.vue?vue&type=script&lang=js&"
export * from "./SurgeryOther.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a41a79a",
  null
  
)

export default component.exports