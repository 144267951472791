<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>

        <div class="row setMarginTop">
            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="card setShadow">
                    <div class="card-header">
                        <div class="containBytitile">
                            <h4 class="card-title">Report by Map</h4>
                        </div>
                        <hr>
                    </div>
                    <div class="card-body" style="padding: 10px;">

                        <div class="row">

                            <div class="col-md-3">
                                <p>Select Date(s)</p>
                                <el-select v-model="dateFilterOption">
                                    <el-option value="All">All</el-option>
                                    <el-option value="Catch Date Range">Catch Date Range</el-option>
                                </el-select>
                            </div>

                            <div class="col" v-if="dateFilterOption==='Catch Date Range'">
                                <p>Select Date(s)</p>
                                <v-md-date-range-picker :presets="dateRangePresets" :start-date="startDatePast" :end-date="endDatePast"
                                                        @change="handleChange"
                                                        style="width:100% !important"></v-md-date-range-picker>
                            </div>

                            <div class="col">
                                <p>Animal Id</p>
                                <fg-input type="text" placeholder="Enter Animal ID" v-model="dog_id"></fg-input>
                            </div>

                            <div class="col">
                                <br>
                                <button v-bind:disabled="!onLine" type="button" class="btn btn-primary"
                                        @click="getFilterReportRecordsBySearch()">Search
                                </button>
                            </div>
                        </div>

                        <div class="legend-div">
                            <br>
                            <h6>Caught Locations</h6>

                            <label class="label-color" style="background-color: #0000ff;"></label>
                            <span class="type-span">Male</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <label class="label-color" style="background-color: #ff0000;"></label>
                            <span class="type-span">Female</span>
                        </div>

                        <GmapMap
                            gestureHandling="greedy"
                            :center="center"
                            :options="{scrollwheel: true}"
                            :zoom="3"
                            style="width: 100%; height: 700px"
                        >

                            <GmapCluster :zoom-on-click="true" max-zoom="21">

                                <gmap-info-window
                                    :options="infoOptions"
                                    :position="infoWindowPos"
                                    :opened="infoWinOpen"
                                    @closeclick="infoWinOpen=false">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table>
                                                <tr>
                                                    <td rowspan="9" style="padding: 5px;">
                                                        <img :src="currentMarkerContent.photo" height="150"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>HSIApps ID:</td>
                                                    <td>{{ currentMarkerContent.hsappsId }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Caught Date:</td>
                                                    <td>{{ currentMarkerContent.caughtDate }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Collar ID:</td>
                                                    <td>{{ currentMarkerContent.collarId }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Caught Method:</td>
                                                    <td>{{ currentMarkerContent.caughtMethod }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Ownership:</td>
                                                    <td>{{ currentMarkerContent.ownership }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Gender:</td>
                                                    <td>{{ currentMarkerContent.gender }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Species:</td>
                                                    <td>{{ currentMarkerContent.species }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Release Location:</td>
                                                    <td>{{
                                                            currentMarkerContent.dropOffLatLng + ' (' + currentMarkerContent.dropOffDistance + ')'
                                                        }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </gmap-info-window>
                                <gmap-marker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    :position="m.postion"
                                    :icon="m.icon"
                                    @click="markersclick(m,index)"></gmap-marker>
                            </GmapCluster>
                            <!--  -->
                        </GmapMap>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../../../../mixins/GlobalMixin';
import dateRangeDefaultPresetsMixin from '../../../../mixins/DateRangeDefaultPresetsMixin';
import VOffline from 'v-offline';
import {Select, Option,} from 'element-ui';
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";

import haversine from 'haversine-distance'

Vue.use(VMdDateRangePicker);

export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data() {
        return {

            dateFilterOption: 'All',

            markers: [],
            center: {lat: 33.8291523, lng: -116.8004315},
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            currentMarkerContent: {
                photo: 'http://www.hsi.org/assets/images/logos/hsi_global.png',
                hsappsId: '',
                caughtDate: '',
                collarId: '',
                caughtMethod: '',
                ownership: '',
                gender: '',
                species: '',
                dropOffLatLng: '',
                dropOffDistance: 'TBD'
            },
            infoWindowPos: null,
            infoWinOpen: false,
            startDate: '',
            endDate: '',
            dog_id: '',
            project_id: '',
            startDatePast: '',
            endDatePast: '',
            dateRangePresets: []
        }
    },
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    methods: {
        markersclick(marker, idx) {
            this.infoWindowPos = marker.postion;
            this.infoContent = marker.icon;

            this.currentMarkerContent.photo = marker.photo
            this.currentMarkerContent.hsappsId = marker.hsappsId
            this.currentMarkerContent.caughtDate = marker.caughtDate
            this.currentMarkerContent.collarId = marker.collarId
            this.currentMarkerContent.caughtMethod = marker.caughtMethod
            this.currentMarkerContent.ownership = marker.ownership
            this.currentMarkerContent.gender = marker.gender
            this.currentMarkerContent.species = marker.species

            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            } else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        handleChange(values) {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.startDate = sDate;
            this.endDate = eDate;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        getFilterReportRecordsBySearch() {
            let vm = this;

            if (vm.dateFilterOption === 'Catch Date Range') {
                if (vm.startDate === '' || vm.endDate === '') {

                    this.startDate = vm.formatDate(vm.dateRangePresets[4].range[0]);
                    this.endDate = vm.formatDate(vm.dateRangePresets[4].range[1]);

                }
            } else if (vm.dateFilterOption === 'All') {
                this.startDate = '';
                this.endDate = '';
            }

            vm.markers = [];
            axios.get('/pm/reports/map/' + vm.project_id, {
                params: {
                    start_date: vm.startDate,
                    end_date: vm.endDate,
                    dog_id: vm.dog_id
                }
            }).then(response => {
                vm.uniqueid = '';
                vm.rfid = '';
                if (response.data.response.error.error_code == 0) {
                    var record = response.data.response.data.records;
                    if (record.length == 0) {
                        this.showAlert('Error', "No Results", false);
                    } else {
                        record.map((item) => {
                            var latLng = item.caught_location.split(",");
                            var latitude = latLng[0];
                            var longitude = latLng[1];
                            let iconUrl = item.gender.toLowerCase() == 'male' ? 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'

                            let dropOffDistance = 'TBD'
                            let dropOffLatLng = ''
                            if (item.dropoff_lat_lng != null) {
                                dropOffLatLng = item.dropoff_lat_lng
                                var latLng2 = item.dropoff_lat_lng.split(",");
                                var latitude2 = latLng2[0];
                                var longitude2 = latLng2[1];
                                const a = {latitude: latitude, longitude: longitude}
                                const b = {latitude: latitude2, longitude: longitude2}
                                dropOffDistance = haversine(a, b) + 'm away'
                            }

                            console.log("dropOffDistance: " + dropOffDistance)

                            var obj = {
                                postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                                icon: iconUrl,
                                photo: item.image_url,
                                hsappsId: item.hsapps_id,
                                caughtDate: vm.convertDate(item.caught_date),
                                collarId: item.collar_id,
                                caughtMethod: item.caught_method,
                                ownership: item.ownership,
                                gender: item.gender,
                                species: item.species,
                                dropOffDistance: dropOffDistance,
                                dropOffLatLng: dropOffLatLng
                            }
                            vm.markers.push(obj);
                        });
                        if (vm.markers.length != 0) {
                            vm.center = vm.markers[0].postion;
                        }
                    }
                }
            });
        }
    },
    mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        vm.project_id = vm.project.id;
        //Bus.$emit("projectype", vm.project);
        try {
            setTimeout(function () {
                var x = document.getElementsByTagName("label")[0].textContent = "";
                var dateSize = document.getElementsByClassName("text-field__filled")[0];
                dateSize.style.width = '100%';
            }, 50);
        } catch (e) {
            console.log(e)
        }

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.startDatePast = firstDay.toLocaleDateString('en-US');
        this.endDatePast = lastDay.toLocaleDateString('en-US');
        this.dateRangePresets = this.getDateRangePresets();
    }
}

</script>
<style>
.bar {
    display: none !important;
}

.mdrp__activator .activator-wrapper .text-field__filled ~ label {
    display: none !important;
}

</style>
<style scoped>

td {
    padding: 2px;
}

.setShadow {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.btn {
    margin: 0px !important;
}

p {
    margin-top: 0;
    margin-bottom: 0rem;
}

.set_div_posi {
    margin-top: 23px;
    text-align: right;
}

.img_marker_popup {
    height: 70px;
    width: 70px;
}

.legend-div {
    margin: 0px 0px 8px 0px;
}

.label-color {
    width: 12px;
    height: 12px;
    margin-bottom: 0px !important;
    border-radius: 3px;
    margin-right: 5px;
}

.type-span {
    margin-right: 5px;
    font-size: 15px;
}
</style>
