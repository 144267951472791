<template>

    <div>

        <el-dialog
            visible
            :show-close="false"
            title="Are you sure?"
            width="50%">
            <span>Stage and Outcome should are set automatically, changing it manually may have unknown side effects.</span>
            <br>
            <br>
            <label>Change Stage for <b>{{ recordIds.length }} Record(s)</b> to</label>
            <br>

            <el-select v-model="newStageSet" placeholder="Stage">
                <el-option
                    v-for="item in stages"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>

            <br>
            <br>

            <template v-if="newStageSet=='Cleared For Release'">
                <label>Select Release Date</label>
                <el-date-picker
                    v-model="releaseDate"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="Select date and time">
                </el-date-picker>
                <br>
                <br>
            </template>

            <br>

            <span>
                <div class="row">
                    <div class="col-4">
                        <label>Change Outcome for <b>{{ recordIds.length }} Record(s)</b> to</label>
                        <br>
                        <el-select v-model="newOutcomeSet" placeholder="Outcome" class="mr-2">
                            <el-option
                                v-for="item in outcomes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-6" v-if="['Died Pre Op', 'Died Post Op'].includes(newOutcomeSet)">
                        <div class="row">
                            <div class="col-6">
                                <label>Death Cause</label>
                                <br>
                                <el-select
                                    v-model="death_cause"
                                    placeholder="Death Cause"
                                    class="mr-2">
                                    <el-option
                                        v-for="item in deathCauses"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-6" v-if="death_cause === 'other'">
                                <label>Other Death Cause</label>
                                <br>
                                <el-input
                                    v-model="death_cause_other"
                                    type="text">
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </span>


            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialogBox">Close</el-button>
                <el-button type="primary" @click="changeRecordStageAndOutcome">Save</el-button>
            </span>

        </el-dialog>


    </div>

</template>

<script>

import {Button, Dialog, Select, Option, DatePicker, Input} from 'element-ui';

export default {

    components: {
        [Button.name]: Button,
        [Dialog.name]: Dialog,
        [Select.name]: Select,
        [Option.name]: Option,
        [DatePicker.name]: DatePicker,
        [Input.name]: Input
    },

    props: {

        recordIds: {
            type: Array
        },


    },

    data() {
        return {

            newStageSet: 'Awaiting Exam',
            releaseDate: '',

            newOutcomeSet: '',

            stages: [
                {
                    label: 'Awaiting Exam',
                    value: 'Awaiting Exam',
                },
                {
                    label: 'Cleared For Sterilization',
                    value: 'Cleared For Sterilization',
                },
                {
                    label: 'Pre-Op Hold',
                    value: 'Pre-Op Hold',
                },
                {
                    label: 'Sterilized',
                    value: 'Sterilized',
                },
                {
                    label: 'Post-Op Hold',
                    value: 'Post-Op Hold',
                },
                {
                    label: 'Cleared For Release',
                    value: 'Cleared For Release',
                },
                {
                    label: 'Released/Out-of-care',
                    value: 'Released/Out-of-care',
                },
            ],

            outcomes: [
                {
                    label: 'Still In Care',
                    value: 'Still In Care',
                },
                {
                    label: 'Released In Field',
                    value: 'Released In Field',
                },
                {
                    label: 'Returned To Owner',
                    value: 'Returned To Owner',
                },
                {
                    label: 'Returned To Home',
                    value: 'Returned To Home',
                },
                {
                    label: 'Died Pre Op',
                    value: 'Died Pre Op',
                },
                {
                    label: 'Died Post Op',
                    value: 'Died Post Op',
                },
                {
                    label: 'Claimed By Owner',
                    value: 'Claimed By Owner',
                },
                {
                    label: 'Escaped',
                    value: 'Escaped',
                },
                {
                    label: 'Adopted',
                    value: 'Adopted',
                },
                {
                    label: 'Euthanasia',
                    value: 'Euthanasia',
                },
            ],
            deathCauses: [{label: 'Cardiac Shock', value: 'Cardiac Shock'},
                {label: 'Heat Stroke', value: 'Heat Stroke'},
                {label: 'Hypothermia', value: 'Hypothermia'},
                {label: 'Internal Bleeding', value: 'Internal Bleeding'},
                {label: 'Other (for cases where rabies is suspected)', value: 'other'}],
            death_cause: '',
            death_cause_other: ''

        }
    },

    methods: {

        closeDialogBox() {

            window.Bus.$emit('pm-close-stage-changer-dialog-box')

        },

        async changeRecordStageAndOutcome() {
            let vm = this
            if (vm.newStageSet.toLowerCase() === 'released/out-of-care') {
                if (vm.newOutcomeSet.toLowerCase() === 'still in care') {
                    vm.showAlert('Alert', 'Outcome must be set to still in care to set stages other than released/out-of-care', false)
                    return
                }
            }

            if (vm.newStageSet.toLowerCase() !== 'released/out-of-care') {
                if (vm.newOutcomeSet.toLowerCase() !== 'still in care') {
                    vm.showAlert('Alert', 'Outcome must be set to still in care to set stages other than released/out-of-care', false)
                    return
                }
            }

            if (['Died Pre Op', 'Died Post Op'].includes(vm.newOutcomeSet)) {
                if (vm.death_cause == '') {
                    vm.showAlert('Alert', "Please set death cause", false)
                    return;
                }

                if (vm.death_cause == 'other' && vm.death_cause_other == '') {
                    vm.showAlert('Alert', "Please write other death cause", false)
                    return;
                }
            }

            await vm.changeRecordStage()

            await vm.changeRecordOutcome()

            vm.closeDialogBox()

        },

        async changeRecordStage() {
            let vm = this;

            if (vm.newStageSet == 'Cleared For Release') {
                if (vm.releaseDate == '') {
                    vm.showAlert('Alert', "Please set release date", false)
                    return;
                }
            }

            let promises = [];

            for (let i = 0; i < vm.$props.recordIds.length; i++) {

                promises.push(
                    axios.post('catch_release/record/change/stage', {
                        record_id: vm.$props.recordIds[i],
                        new_stage: vm.newStageSet,
                        release_date: vm.releaseDate
                    }).then(response => {
                        if (response.data.response.error.error_code === 0) {
                            window.toastr.success(response.data.response.error.msg)
                        } else {
                            window.toastr.error(response.data.response.error.error_msg)
                        }
                    }).catch(error => {
                        console.log(error)
                        vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                    })
                )

            }

            await Promise.all(promises)


        },

        async changeRecordOutcome() {
            let vm = this;

            let promises = []

            for (let i = 0; i < vm.$props.recordIds.length; i++) {
                promises.push(
                    axios.post('catch_release/record/change/outcome', {
                        record_id: vm.$props.recordIds[i],
                        new_outcome: vm.newOutcomeSet,
                        release_date: vm.releaseDate,
                        death_cause: vm.death_cause,
                        death_cause_other: vm.death_cause_other
                    }).then(response => {
                        if (response.data.response.error.error_code === 0) {
                            window.toastr.success(response.data.response.error.msg)
                        } else {
                            window.toastr.error(response.data.response.error.error_msg)
                        }
                    }).catch(error => {
                        console.log(error)
                        vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                    })
                )

            }

            await Promise.all(promises)

        },

    },


}

</script>

<style scoped>

</style>
