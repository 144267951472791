<template>
   <div class="col-md-12">
      <div class="row">
         <div class="col-sm-8 col-xs-6 col-md-5 col-xl-5  set_pagination_content_resp">
            <el-select
               class="select-default"
               v-model="pagination.perPage"
               placeholder="Per page">
               <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
               </el-option>
            </el-select>
         </div>
         <div class="col-sm-8 col-xs-6 col-md-4 col-xl-4  set_pagination_content_resp">
            <button class="btn btn-warning" type="button">Export to excel</button>
         </div>
         <div class="col-sm-8 col-xs-6 col-md-3 col-xl-3  set_searchbox_responsive">
            <div class="pull-right">
               <fg-input class="input-sm set_Margintop_textbox"
                  placeholder="Search"
                  v-model="searchQuery"
                  addon-right-icon="nc-icon nc-zoom-split">
               </fg-input>
            </div>
         </div>
         <div class="col-sm-12 mt-2">
            <el-table :data="queriedData" border style="width: 100%">
                <el-table-column prop="uniqueid" label="HSIApps ID" width="125"> </el-table-column>
                <el-table-column prop="microChipNo" label="Micro chip Number" width="125"> </el-table-column>
                <el-table-column prop="photo" label="Photo" width="125">
                    <template slot-scope="scope">
                        <img :src="scope.row.photo" style="height: 80px;"/>
                    </template>
                </el-table-column>
                <el-table-column prop="datecatchattend" label="Date of catch/attend" width="125"> </el-table-column>
                <el-table-column prop="datearv" label="Date of Operation & ARV" width="125"> </el-table-column>
                <el-table-column prop="daterelease" label="Date of Release" width="125"> </el-table-column>
                <el-table-column prop="caughtlocality" label="Caught Locality" width="125"> </el-table-column>
                <el-table-column prop="colour" label="Colour" width="125"> </el-table-column>
                <el-table-column prop="systemid" label="System ID" width="125"> </el-table-column>
                <el-table-column prop="gender" label="Gender" width="125"> </el-table-column>
                <el-table-column prop="weight" label="Weight" width="125"> </el-table-column>
                <el-table-column prop="lactating" label="AOP/Pups/Lactating" width="125"> </el-table-column>
            </el-table>
         </div>
         <div class="col-sm-6 pagination-info">
            <p class="category set_margintop">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
         </div>
         <div class="col-sm-6">
            <p-pagination class="pull-right"
               v-model="pagination.currentPage"
               :per-page="pagination.perPage"
               :total="pagination.total">
            </p-pagination>
         </div>
      </div>
   </div>
</template>
<script>
   import Vue from 'vue';
    import {Table, TableColumn, Select, Option} from 'element-ui';
   import PPagination from '../UIComponents/Pagination.vue';
   import microchipRecord from './microchipRecord';
   import Swal from 'sweetalert2';
   Vue.use(Table);
   Vue.use(TableColumn);
   Vue.use(Select);
   Vue.use(Option);

   export default {
       components: {
           PPagination,
       },
       data() {
           return {
               pagination: {
                   perPage: 25,
                   currentPage: 1,
                   perPageOptions: [5, 10, 25, 50],
                   total: 0,
               },
               searchQuery: '',
               propsToSearch: ['uniqueid', 'microChipNo', 'datecatchattend','datearv', 'daterelease', 'caughtlocality','colour', 'systemid', 'gender','weight','lactating'],
               tableColumns: [
                   {
                       prop: 'uniqueid',
                       label: 'HSIApps ID',
                       minWidth: 50,
                   },
                   {
                       prop: 'microChipNo',
                       label: 'Micro chip Number',
                       minWidth: 50,
                   },
                   {
                       prop: 'photo',
                       label: 'Photo',
                       minWidth: 50,
                   },
                   {
                       prop: 'datecatchattend',
                       label: 'Date of catch/attend',
                       minWidth: 50,
                   },
                   {
                       prop: 'datearv',
                       label: 'Date of Operation & ARV',
                       minWidth: 50,
                   },
                   {
                       prop: 'daterelease',
                       label: 'Date of Release',
                       minWidth: 50,
                   },
                   {
                       prop: 'caughtlocality',
                       label: 'Caught Locality',
                       minWidth: 50,
                   },
                   {
                       prop: 'colour',
                       label: 'Colour',
                       minWidth: 50,
                   },
                   {
                       prop: 'systemid',
                       label: 'System ID',
                       minWidth: 50,
                   },
                   {
                       prop: 'gender',
                       label: 'Gender',
                       minWidth: 50,
                   },
                   {
                       prop: 'weight',
                       label: 'Weight',
                       minWidth: 50,
                   },
                   {
                       prop: 'lactating',
                       label: 'AOP/Pups/Lactating',
                       minWidth: 50,
                   },
               ],
               tableData: microchipRecord
           };
       },
       computed: {
           pagedData() {
               return this.tableData.slice(this.from, this.to);
           },
           /***
            * Searches through table data and returns a paginated array.
            * Note that this should not be used for table with a lot of data as it might be slow!
            * Do the search and the pagination on the server and display the data retrieved from server instead.
            * @returns {computed.pagedData}
            */
           queriedData() {
               if (!this.searchQuery) {
                   this.pagination.total = this.tableData.length;
                   return this.pagedData;
               }
               let result = this.tableData.filter((row) => {
                   let isIncluded = false;
                   for (let key of this.propsToSearch) {
                       let rowValue = row[key].toString();
                       // Pradeep Start
                       // Activity Status section case sensitive character search in datatable
                       if (rowValue.toLowerCase().includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                           isIncluded = true;
                       }
                       // Pradeep End
                   }
                   return isIncluded;
               });
               this.pagination.total = result.length;
               return result.slice(this.from, this.to);
           },
           to() {
               let highBound = this.from + this.pagination.perPage;
               if (this.total < highBound) {
                   highBound = this.total;
               }
               return highBound;
           },
           from() {
               return this.pagination.perPage * (this.pagination.currentPage - 1);
           },
           total() {
               this.pagination.total = this.tableData.length;
               return this.tableData.length;
           },
       },
       methods: {}
   };
</script>
<style lang="scss">
   .el-table .td-actions {
        button.btn {
            margin-right: 5px;
        }
   }
   .set_pagination_content_resp{
       text-align: left !important;
   }
   .search_datatable{
       border-left: 0 none !important;
   }
   .set_margintop{
     margin-top: 9px !important;
   }
   .el-table__fixed-body-wrapper{
     background-color: white !important;
   }
   .el-table__fixed-header-wrapper{
     background-color: white !important;
   }
   @media (min-width: 241px) and (max-width: 320px) {
    .pull-right{
        float: none;
    }
    .set_data_table_textbox_width{
         width: 82% !important;
    }
    .set_Margintop_textbox{
         margin-top:10px;
    }
    .setHeader {
        z-index: 0;
    }
   }
   @media only screen  and (max-width: 411px) {
    .pull-right{
        float: none;
    }
    .set_data_table_textbox_width{
        width: 82% !important;
    }
    .set_Margintop_textbox{
        margin-top:10px;
    }
    .setHeader {
        z-index: 0;
    }
   }
   @media (min-width: 341px) and (max-width: 375px) {
    .pull-right{
        float: none;
    }
    .set_data_table_textbox_width{
        width: 86% !important;
    }
    .set_Margintop_textbox{
        margin-top:10px;
    }
    .setHeader {
        z-index: 0;
    }
   }
   @media (min-width: 376px) and (max-width: 425px) {
    .pull-right{
        float: none;
    }
    .set_data_table_textbox_width{
        width: 88% !important;
    }
    .set_Margintop_textbox{
        margin-top:10px;
    }
    .setHeader {
        z-index: 0;
    }
   }
   @media (min-width: 426px) and (max-width: 768px) {
    .pull-right{
        float: none;
    }
    .set_data_table_textbox_width{
        width: 89% !important;
    }
    .setHeader {
        z-index: 0;
    }
   }
   @media only screen and (max-device-width: 1024px) {
    .set_data_table_textbox_width{
        width: 83%;
    }
   }
</style>
