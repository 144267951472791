var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoadingData),expression:"isLoadingData"}],staticClass:"pm-surgery-other"},[_c('el-form',{ref:"surgeryOtherForm",attrs:{"model":_vm.surgeryModal,"label-width":"80px","label-position":"top"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('el-form-item',{attrs:{"label":"Select Date Time","prop":"dateTime","rules":[
                                { required: true, message: 'Please select date time', trigger: 'change' }
                            ]}},[_c('el-date-picker',{attrs:{"type":"datetime","value-format":"yyyy-MM-dd HH:mm:ss","placeholder":"Select date and time"},model:{value:(_vm.surgeryModal.dateTime),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "dateTime", $$v)},expression:"surgeryModal.dateTime"}})],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('el-form-item',{attrs:{"label":"Select Veterinarian","prop":"vet","rules":[
                                { required: true, message: 'Please select veterinarian', trigger: 'change' }
                            ]}},[_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(_vm.surgeryModal.vet),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "vet", $$v)},expression:"surgeryModal.vet"}},_vm._l((_vm.list.vets),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('el-form-item',{attrs:{"label":"Procedure","prop":"procedure","rules":[
                                { required: true, message: 'Procedure is required' } ]}},[_c('el-input',{attrs:{"placeholder":"Procedure"},model:{value:(_vm.surgeryModal.procedure),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "procedure", $$v)},expression:"surgeryModal.procedure"}})],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('el-form-item',{attrs:{"label":"Procedure Complications","prop":"procedureComplication"}},[_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(_vm.surgeryModal.procedureComplication),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "procedureComplication", $$v)},expression:"surgeryModal.procedureComplication"}},_vm._l((_vm.list.procedureComplications),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)]),(_vm.surgeryModal.procedureComplication==='Other')?[_c('el-form-item',{attrs:{"label":"Procedure Complication Note","prop":"otherProcedureComplicationNote"}},[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"Procedure Complication Note"},model:{value:(_vm.surgeryModal.otherProcedureComplicationNote),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "otherProcedureComplicationNote", $$v)},expression:"surgeryModal.otherProcedureComplicationNote"}})],1)]:_vm._e()],2),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('el-form-item',{attrs:{"label":"No of Top-Ups","prop":"noOfTopUps"}},[_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(_vm.surgeryModal.noOfTopUps),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "noOfTopUps", $$v)},expression:"surgeryModal.noOfTopUps"}},_vm._l((_vm.list.topUps),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('el-form-item',{attrs:{"label":"Anaesthesia Given","prop":"anaesthesiaGiven","rules":[
                                { validator: _vm.checkAnaesthesiaGiven}
                            ]}},[_c('el-input',{attrs:{"type":"number","placeholder":""},model:{value:(_vm.surgeryModal.anaesthesiaGiven),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "anaesthesiaGiven", $$v)},expression:"surgeryModal.anaesthesiaGiven"}},[_c('template',{slot:"append"},[_vm._v("ml")])],2)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('el-form-item',{attrs:{"label":"Surgery Duration","prop":"surgeryDuration","rules":[
                                { required: true, message: 'Surgery Duration is required' },
                                { validator: _vm.checkSurgeryDuration}
                            ]}},[_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.surgeryModal.surgeryDuration),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "surgeryDuration", $$v)},expression:"surgeryModal.surgeryDuration"}},[_c('template',{slot:"append"},[_vm._v("mins")])],2)],1)],1)]),_c('el-form-item',{attrs:{"label":"Note","prop":"note"}},[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"Please input"},model:{value:(_vm.surgeryModal.note),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "note", $$v)},expression:"surgeryModal.note"}})],1),_c('el-checkbox',{model:{value:(_vm.surgeryModal.postOpHold),callback:function ($$v) {_vm.$set(_vm.surgeryModal, "postOpHold", $$v)},expression:"surgeryModal.postOpHold"}},[_vm._v("Post-Op Hold")])],1)]),_c('div',{staticClass:"row text-right"},[_c('div',{staticClass:"col-sm-12"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","disabled":_vm.isSavingData||_vm.isLoadingData},on:{"click":_vm.saveOrUpdateOperation}},[_vm._v(_vm._s(_vm.getButtonLabel)+" ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }