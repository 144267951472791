<template>
    <div v-loading="isLoading">

        <div class="row">

            <div class="col-lg-2">
                <img alt="Animal Image" :src="vaccination.imageUrl" height="200" width="200"/>
            </div>

            <div class="col-lg-5">
                <label>Date Time: <b>{{ vaccination.dateTime }}</b></label>
                <br>
                <br>
                <label>Drug/Vaccine Given: {{ vaccination.givenDrugVaccine }}</label>
                <br>
                <label>HSIApps ID For MV Project: {{ vaccination.hsappsId }}</label>
                <br>
                <label>MV Project Name: {{ vaccination.mvProjectName }}</label>
                <br>
                <label>RFID: {{ vaccination.rfid }}</label>
                <br>
            </div>

            <div class="col-lg-5">
                <label>Gender: {{ vaccination.gender }}</label>
                <br>
                <label>Species: {{ vaccination.species }}</label>
                <br>
                <label>Reproductive Status: {{ vaccination.reproductiveStatus }}</label>
                <br>
                <label>Ownership: {{ vaccination.ownership }}</label>
                <br>
                <label>Notes: {{ vaccination.notes }}</label>
                <br>
                <label>Location: {{ vaccination.location }}</label>
                <br>
                <a :href="'https://www.google.com/maps/search/?api=1&query='+vaccination.location"
                   target="_blank">Map</a>
                <br>
            </div>

        </div>

    </div>
</template>
<script>

import {DatePicker, Image, Input} from "element-ui";

export default {

    components: {
        [DatePicker.name]: DatePicker,
        [Image.name]: Image,
        [Input.name]: Input,
    },
    data()
    {
        return {

            isLoading: false,

            vaccination: {
                imageUrl: '',
                dateTime: '',
                givenDrugVaccine: '',
                hsappsId: '',
                rfid: '',
                mvProjectName: '',
                MvProjectName: '',
                gender: '',
                species: '',
                reproductiveStatus: '',
                ownership: '',
                notes: '',
                location: '',
            }
        }
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
    },
    methods: {
        init()
        {
            this.getVaccinationDetails()
        },
        getVaccinationDetails()
        {
            let vm = this
            vm.isLoading = true

            axios.get('/vaccination/record/info/' + vm.id)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.isLoading = false
                        let record = response.data.response.data.record
                        vm.vaccination.dateTime = record.created_at
                        vm.vaccination.gender = record.animal.gender
                        vm.vaccination.reproductiveStatus = record.reproductive_status
                        vm.vaccination.ownership = record.ownership
                        vm.vaccination.species = record.animal.species
                        vm.vaccination.rfid = record.animal.rf_id == '' ? 'N/A' : record.animal.rf_id
                        vm.vaccination.hsappsId = record.animal.hsapps_id
                        vm.vaccination.mvProjectName = record.project.name
                        vm.vaccination.imageUrl = record.image_url
                        vm.vaccination.location = record.vaccination_location_lat_lng
                        vm.vaccination.givenDrugVaccine = record.given_vaccine.map(function (val) {
                            return val;
                        }).join(',');
                        vm.vaccination.notes = record.note == '' ? 'N/A' : record.note
                    }
                })
                .catch(error => {
                    vm.isLoading = false
                    console.log(error)
                })
        }
    },
    computed: {},
    mounted()
    {
        this.init()
    }

}

</script>
<style type="scoped">

</style>
