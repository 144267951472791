<template>

    <div>
        <div v-loading="isLoadingData">
            <div class="row">
                <div class="col-lg-4">
                    <div class="demo-image__preview">
                        <el-image
                                size="1"
                                style="padding: 10px;"
                                :src="animalImageUrl"
                                :preview-src-list="animalImages">
                        </el-image>
                    </div>

                    <div class="row"
                         style="border: 2px solid darkgrey; border-radius: 5px; position:relative; padding-bottom: 10px; margin-bottom: 10px;">
                        <div class="col-12">
                            <div class="form-group row">
                                <label class="col-md-6">Owner Name</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <input class="form-control" id="ownerName" ref="ownerName" type="password"
                                           name="ownerName"
                                           v-model="catchingModal.ownerName" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Owner Number</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <input class="form-control" id="ownerNumber" type="password"
                                           name="ownerNumber"
                                           ref="ownerNumber"
                                           v-model="catchingModal.ownerNumber" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <button type="button" @click="unHideOwnerInfo()" class="btn btn-warning"
                                    ref="showHideButton"
                                    style="position:absolute; bottom: -25px; right: 10px; height: 30px; border-radius: 35px; padding: 0px 10px 0px 10px; margin: 0;">
                                {{ this.isOwnerInfoHidden ? 'SHOW' : 'HIDE' }}
                            </button>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4">

                    <label>Catch Date & Time</label>
                    <el-date-picker
                            disabled
                            v-model="catchingModal.dateTime"
                            type="datetime"
                            placeholder="N/A"
                            format="dd MMM yyyy hh:mm A"
                            value-format="yyyy-MM-ddTHH:mm:ss">
                    </el-date-picker>
                    <br>
                    <br>

                    <label>Caught By</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.caughtBy">
                    </el-input>
                    <br>
                    <br>

                    <label>Caught at Lat/Lng (<a :href="'https://www.google.com/maps/search/?api=1&query='+catchingModal.location" target="_blank">Map</a>)</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.location">
                    </el-input>
                    <br>
                    <br>

                    <label>Caught Method</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.catchingMethod">
                    </el-input>
                    <br>
                    <br>

                    <label>Caught Landmark</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.landmark">
                    </el-input>
                    <br>
                    <br>

                    <label>Species</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.species">
                    </el-input>
                    <br>
                    <br>

                    <label>Colour</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.colour">
                    </el-input>
                    <br>
                    <br>

                </div>
                <div class="col-lg-4">

                    <label>Collar ID</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.collarId">
                    </el-input>
                    <br>
                    <br>

                    <label>Age</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.age">
                    </el-input>
                    <br>
                    <br>

                    <br>
                    <br>

                    <h5 style="float:left;">Release Details</h5> <p> ({{ catchingModal.releasedBy == '' ? 'Pending Release' : 'Released' }})</p>
                    <hr>
                    <label>Set Release Date</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.releaseDate">
                    </el-input>
                    <br>
                    <br>

                    <label>Released On Date</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.releasedOnDate">
                    </el-input>
                    <br>
                    <br>

                    <label>Released By</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.releasedBy">
                    </el-input>
                    <br>
                    <br>

                    <label>Released at Lat/Lng (<a :href="'https://www.google.com/maps/search/?api=1&query='+catchingModal.releasedAtLocation" target="_blank">Map</a>)</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.releasedAtLocation">
                    </el-input>
                    <br>
                    <br>

                    <label>Drop-off Reason</label>
                    <el-input placeholder="N/A" disabled v-model="catchingModal.dropoff_reason">
                    </el-input>
                    <br>
                    <br>


                </div>
            </div>
        </div>
    </div>

</template>
<script>

import {DatePicker, Image, Input} from 'element-ui';

export default {

    props: {
        recordId: {
            type: String,
        }
    },
    components: {
        [DatePicker.name]: DatePicker,
        [Image.name]: Image,
        [Input.name]: Input,
    },
    data()
    {
        return {
            isOwnerInfoHidden: true,

            isLoadingData: false,

            owner: null,
            catchingModal: {
                dateTime: '',
                caughtBy: '',
                catchingMethod: '',
                location: '',
                landmark: '',
                species: '',
                collarId: '',
                ownerName: '',
                ownerNumber: '',
                age: '',
                releaseDate: '',
                releasedOnDate: '',
                releasedBy: '',
                releasedAtLocation: '',
                colour: '',
                dropoff_reason:'',
            },

            animalImageUrl: '',
            animalImages: [''],
        }
    },
    methods: {
        unHideOwnerInfo()
        {
            if (this.isOwnerInfoHidden) {
                this.$refs.ownerName.type = 'text';
                this.$refs.ownerNumber.type = 'text';
            } else {
                this.$refs.ownerName.type = 'password';
                this.$refs.ownerNumber.type = 'password';
            }
            this.isOwnerInfoHidden = !this.isOwnerInfoHidden;
        },
        init()
        {
            //alert("Catching component mounted")
            this.getCatchingDetails()
        },
        getCatchingDetails()
        {
            let vm = this
            vm.isLoadingData = true
            axios.get('/catch_release/record/catching/details/' + this.recordId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        let catching = response.data.response.data.catching
                        console.log(catching)
                        if (catching.animal.owner == null) {
                            vm.catchingModal.ownerName = "N/A"
                            vm.catchingModal.ownerNumber = "N/A"
                        } else {
                            vm.catchingModal.ownerName = catching.animal.owner.name
                            vm.catchingModal.ownerNumber = catching.animal.owner.telephone_mobile
                        }
                        vm.animalImageUrl = catching.image_url
                        vm.animalImages = []
                        vm.animalImages.push(catching.image_url)
                        vm.catchingModal.landmark = catching.landmark == '' ? 'N/A' : catching.landmark
                        vm.catchingModal.dateTime = catching.created_at
                        vm.catchingModal.catchingMethod = catching.caught_method
                        vm.catchingModal.age = catching.age_category
                        vm.catchingModal.species = catching.animal.species
                        vm.catchingModal.colour = catching.animal.colour
                        vm.catchingModal.collarId = catching.collar_id
                        vm.catchingModal.location = catching.caught_location
                        vm.catchingModal.releaseDate = catching.release_date
                        vm.catchingModal.caughtBy = catching.caught_by.first_name + " " + catching.caught_by.last_name
                        vm.catchingModal.releasedOnDate = catching.released_on_date
                        vm.catchingModal.releasedBy = catching.release_by?catching.release_by.first_name + " " + catching.release_by.last_name:''
                        vm.catchingModal.releasedAtLocation = catching.dropoff_lat_lng
                        vm.catchingModal.dropoff_reason = catching.dropoff_reason

                        vm.isLoadingData = false
                    }
                })
                .catch(e => {
                    vm.isLoadingData = false
                    console.log(e)
                })
        }
    },
    computed: {},
    mounted()
    {
        this.init()
    }

}

</script>

<style scoped>

</style>
