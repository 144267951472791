<template>

    <div>
        <fieldset>
            <div class="form-group">
                <div class="col-sm-12 col-md-4">
                    <fg-input
                            type="text"
                            label="Name*"
                            name="projectName"
                            v-validate="modelValidations.projectName"
                            :error="getError('projectName')"
                            v-model="model.projectName">
                    </fg-input>
                </div>
            </div>
        </fieldset>
        <br/>
        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Description</label>
                <div class="col-sm-8">
                     <textarea v-model="model.projectDescription" required class="form-control"
                               rows="3"></textarea>
                </div>
            </div>
        </fieldset>
        <br>
        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Banner Message (Optional)</label>
                <div class="col-sm-8">
                     <textarea
                             placeholder="This message will appear on the top of the project overview screen. You can use it for reminders or notes to yourself or other users."
                             v-model="model.projectBannerMessage" required class="form-control"
                             rows="3"></textarea>
                </div>
            </div>
        </fieldset>
        <br/>
        <div class="col-sm-12">
            <div class="row">
                <label class="col-sm-12 control-label">Visibility</label>
                <div class="col-sm-3">
                    <p-radio v-model="model.projectAssociate" label="1" @change="onVisibilityChange">Private</p-radio>
                    <p-radio v-model="model.projectAssociate" label="2">Entire Organisation</p-radio>
                </div>

                <template v-if="model.projectAssociate=='2' && userOrganisations.length==0">
                    <div class="col-sm-4">
                        <p>
                            You have not yet applied to be an Organisation. Like Teams, Organisations enable collaboration
                            features allowing you to add others as administrators or field users to your projects.<a
                            href="/platform/user/organisation"> Apply Now</a>
                        </p>
                    </div>
                </template>
                <template v-else>
                    <div class="col-sm-5">
                        <p>{{ projectAssociateInfo }}</p>
                    </div>
                </template>
            </div>
        </div>
        <br/>

        <template v-if="model.projectAssociate=='2' && userOrganisations.length>0">
            <fieldset>
                <div class="form-group">
                    <label class="col-sm-12 control-label">Species</label>
                    <div class="col-sm-12">
                        <el-checkbox-group v-model="model.selectedAnimals">
                            <template v-for="(animal, key) in orgAnimals">
                                <el-checkbox :label="animal.species_name" :key="'defaultAnimal' + key">
                                    {{ animal.species_name }}
                                </el-checkbox>
                            </template>
                            <template v-for="(species, index) in allSpeciesName">
                                <el-checkbox :label="species" :key="'recentlyAddedAnimal' + index">
                                    {{ species }}
                                </el-checkbox>
                                <i :label="species" :key="'recentlyAddedAnimalIcon' + index"
                                   class="nc-icon nc-simple-remove"
                                   style="font-size: 15px; padding: 5px; cursor: pointer; color: #51cbce"
                                   @click="removeFromAllSpeciesName(index, species)"></i>
                            </template>
                        </el-checkbox-group>
                    </div>
                </div>
            </fieldset>
            <div ref="addNewAnimalDialogBox" class="col-sm-4">
                <template>
                    <a @click="addNewAnimal" style="color: #51cbce;"><b>{{ getAddAnimalLabel }}</b></a>
                </template>
                <template v-if="addingNewAnimal">
                    <fg-input
                            label="Species Name"
                            type="text"
                            name="Animal Name"
                            v-validate="modelValidations.animalName"
                            :error="getError('Animal Name')"
                            v-model="animalName"
                            v-on:keypress="isCharAllow($event)">
                    </fg-input>
                    <p>(Note: This will make new species type visible to entire organisation)</p>
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button type="button"
                                    name="Delete Project" v-on:click="addNewAnimal"
                                    class="btn btn-round btn-danger">Cancel
                            </button>

                            <button v-on:click="callAddNewAnimalApi" type="button"
                                    class="btn btn-round btn-info">Add
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <fieldset>
                <div class="form-group">
                    <label class="col-sm-12 control-label">Species</label>
                    <div class="col-sm-12">
                        <el-checkbox-group v-model="model.selectedAnimals">
                            <el-checkbox label="1">DOG</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </fieldset>
        </template>
        <br/>
        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Ownership</label>

                <div class="col-sm-12">
                    <el-checkbox-group v-model="model.selectedCategories">
                        <template v-for="ownership in ownerships">
                            <el-checkbox :label="ownership">{{ ownership }}</el-checkbox>
                        </template>
                    </el-checkbox-group>
                </div>

            </div>
        </fieldset>
        <br>

        <fieldset>
            <div class="form-group">
                <label class="col-sm-12 control-label">Vaccine/Drug</label>
                <div class="col-sm-10">
                    <el-checkbox-group v-model="model.vaccinationDrug">
                        <template v-for="(vaccination, key) in vaccinationDrug">
                            <el-checkbox :label="vaccination.id" :key="'defaultVaccine' + key">
                                {{ vaccination.vaccination_drug }}
                            </el-checkbox>
                        </template>
                    </el-checkbox-group>
                    <el-checkbox-group v-model="model.vaccinationDrug">
                        <template v-for="(vaccineDrugName, index) in allVaccinationDrugName">
                            <el-checkbox :label="vaccineDrugName" :key="'recentlyAddedVaccine' + index">
                                {{ vaccineDrugName }}
                            </el-checkbox>
                            <i :label="vaccineDrugName" :key="'recentlyAddedVaccineIcon' + index"
                               class="nc-icon nc-simple-remove"
                               style="font-size: 15px; padding: 5px; cursor: pointer; color: #51cbce"
                               @click="removeFromAllVaccinationDrugName(index, vaccineDrugName)"></i>
                        </template>
                    </el-checkbox-group>
                </div>
            </div>
        </fieldset>
        <template v-if="model.projectAssociate=='2' && userOrganisations.length>0">

            <div ref="addDrugVaccineDialogBox" class="col-sm-4">
                <template>
                    <a @click="addVaccineDrug" style="color: #51cbce;"><b>{{ getAddVaccineLabel }}</b></a>
                </template>
                <template v-if="addingNewVaccine">
                    <fg-input
                            label="Vaccine/Drug Name"
                            type="text"
                            v-model="vaccineName">
                    </fg-input>
                    <p>(Note: This will make new Drug/Vaccine visible to entire organisation)</p>
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button v-on:click="addVaccineDrug" type="button"
                                    class="btn btn-round btn-danger">Cancel
                            </button>

                            <button v-on:click="callAddVaccineApi" type="button"
                                    class="btn btn-round btn-info">Add
                            </button>
                        </div>
                    </div>


                </template>
            </div>

        </template>
        <br/>
        <br/>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <label class="control-label">Project Location</label>
                    <div class="card">
                        <div class="card-header">
                            <p class="card-title"><b>(Search for your city/region and then pin it
                                on the map using your cursor)</b></p>
                        </div>
                        <div class="card-body">
                            <div class="pac-card" id="pac-card">
                                <div>
                                    <div id="title">
                                        Search Locations
                                    </div>
                                    <div id="type-selector" class="pac-controls">
                                        <input type="radio" name="type" id="changetype-all"
                                               checked="checked">
                                        <label for="changetype-all">All</label>
                                        <input type="radio" name="type"
                                               id="changetype-establishment">
                                        <label for="changetype-establishment">Establishments</label>
                                        <input type="radio" name="type" id="changetype-address">
                                        <label for="changetype-address">Addresses</label>
                                    </div>
                                </div>
                                <div id="pac-container">
                                    <input id="pac-input" type="text"
                                           placeholder="Enter a location">
                                </div>
                            </div>
                            <div id="regularMap" class="map"></div>
                            <div id="infowindow-content">
                                <img src="" width="16" height="16" id="place-icon">
                                <span id="place-name" class="title"></span><br>
                                <span id="place-address"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <p>
                        To load Country, State and Province please select location pin point on Google Maps.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <label class="control-label">Country</label>
                    <br/>
                    <fg-input :disabled="fetchingAddress" v-model="model.selectedCountry" :placeholder="selectCountryLabel"/>
                    <el-select
                        hidden
                            :disabled="fetchingAddress"
                            class="select-info"
                            size="large"
                            :placeholder="selectCountryLabel"
                            v-model="model.selectedCountry">
                        <el-option v-for="country in googleCountries"
                                   class="select-danger"
                                   :value="country"
                                   :label="country"
                                   :key="country">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-lg-4">
                    <label class="control-label">State/Province</label>
                    <br/>
                    <fg-input :disabled="fetchingAddress" v-model="model.selectedState" :placeholder="selectStateLabel"/>
                    <el-select
                        hidden
                            :disabled="fetchingAddress"
                            class="select-info"
                            size="large"
                            :placeholder="selectStateLabel"
                            v-model="model.selectedState">
                        <el-option v-for="state in googleStates"
                                   class="select-danger"
                                   :value="state"
                                   :label="state"
                                   :key="state">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-lg-4">
                    <label class="control-label">City/District/Village</label>
                    <br/>
                    <fg-input :disabled="fetchingAddress" v-model="model.selectedLocality" :placeholder="selectLocalityLabel"/>
                    <el-select
                            hidden
                            :disabled="fetchingAddress"
                            class="select-info"
                            size="large"
                            :placeholder="selectLocalityLabel"
                            v-model="model.selectedLocality">
                        <el-option v-for="locality in googleLocality"
                                   class="select-danger"
                                   :value="locality"
                                   :label="locality"
                                   :key="locality">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <label class="col-sm-12 control-label" style="display: none">Project Date Format</label>
        <div class="col-sm-2" style="display: none">
            <el-select class="select-info"
                       size="large"
                       placeholder="Single Select"
                       v-model="model.selectedDateFormat">
                <el-option v-for="dateFormat in model.dateFormats"
                           class="select-danger"
                           :value="dateFormat"
                           :label="dateFormat"
                           :key="dateFormat">
                </el-option>
            </el-select>
        </div>
        <br/>
        <label class="col-sm-12 control-label" style="display: none">Project Time Zone</label>
        <div class="col-sm-12 col-md-4" style="display: none">
            <el-select class="select-info"
                       size="large"
                       placeholder="Single Select"
                       v-model="model.selectedTimezone">
                <el-option v-for="timezone in timezones"
                           class="select-danger"
                           :value="timezone.label"
                           :label="timezone.label"
                           :key="timezone.label">
                </el-option>
            </el-select>
        </div>
        <br/>
        <div class="col-sm-10"
             style="display: none">
            <p>Select Project Image ({{ uploadingLabel }})</p>
            <image-upload
                    :target="imageUploadApiUrl"
                    selector="example"
                    :auto-push="true">
            </image-upload>
            <br/>
        </div>
        <div class="col-md-12" style="text-align: center !important;">

            <button @click="cancleNewProject('warning-message-and-confirmation')" type="button"
                    class="btn btn-round btn-danger">Cancel
            </button>

            <button v-if="onLine" v-bind:disabled="isDisabled || !onLine" v-on:click="validateAndSubmit" type="button"
                    class="btn btn-round btn-info">Create HC Project
            </button>

        </div>
    </div>

</template>

<script>

import mixin from '../../../mixins/GlobalMixin';

import {mapGetters} from 'vuex';

import ImageUpload from '../../ImageUpload.vue';

import {Select, Option, CheckboxGroup, Checkbox} from 'element-ui';

import {API_KEY} from '../Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
import Swal from 'sweetalert2';
GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['places'];

import hclogo from '../../../assets/images/hc_logo_small.png';

import timezoneJson from '../../../assets/json/timezones.json'


export default {
    name: 'NewHcProject',
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [CheckboxGroup.name]: CheckboxGroup,
        [Checkbox.name]: Checkbox,
        'image-upload': ImageUpload,
    },
    mixins: [mixin],
    props: {
        projectId: {
            type: Number,
            default: 0,
        },
        timezones: {
            type: Array,
            default: timezoneJson
        },
        hclogo: {
            type: String,
            default: hclogo,
        },
    },
    data()
    {
        return {
            imageUploadApiUrl: window.API_BASE_URL + '/UploadToS3/upload/project_image',
            ownerships: [
                "Owned",
                "Owned free roaming",
                "Community",
                "Community free roaming",
                "Wild"
            ],
            vaccinationDrug: [
                {
                    id: 1,
                    vaccination_drug: 'Rabies'
                },
            ],
            orgAnimals: [],
            model: {
                selectedVets: [],
                projectName: '',
                projectDescription: '',
                selectedAnimals: [],
                dateFormats: ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'],
                selectedDateFormat: 'DD-MM-YYYY',
                selectedTimezone: 'UTC',
                projectAssociate: '2',
                selectedCategories: [],
                selectedOrganisation: null,
                selectedCountry: null,
                selectedState: null,
                selectedLocality: null,
                selectedLocation: null,
                projectBannerMessage: '',
                vaccinationDrug: [],
            },
            modelValidations: {
                projectName: {
                    required: true,
                },
                animalName: {
                    required: true,
                },
            },
            isDisabled: false,
            uploadURL: '',
            uploadingLabel: 'Optional',
            uploadPending: false,
            googleCountries: [],
            googleStates: [],
            googleLocality: [],
            fetchingAddress: false,
            selectCountryLabel: 'Select Country',
            selectStateLabel: 'Select State',
            selectLocalityLabel: 'Select Locality',
            animalName: '',
            addingNewAnimal: false,
            map: null,
            selectedMarker: null,
            allSpeciesName: [],

            addingNewVaccine: false,
            vaccineName: '',
            allVaccinationDrugName: [],

        }
    },

    methods: {
        init()
        {

            let vm = this;

            vm.$store.dispatch('animal/getAnimals');


            vm.$store.dispatch('user/getUserOrganisations');


            Bus.$on('example-did-upload', (error, data, response) => {
                if (error) {
                    toastr.error(error);
                } else {
                    vm.uploadURL = response.src;
                    vm.uploadingLabel = "Uploaded";
                    vm.uploadPending = false;
                }
            });

            Bus.$on('example-is-saving', () => {
                vm.uploadPending = true;
                vm.uploadingLabel = "Uploading Image...";
            });

            GoogleMapsLoader.load((google) => {
                vm.initRegularMap(google)
            });

        },
        initRegularMap(google)
        {
            let vm = this;

            // Regular Map
            const myLatlng = new window.google.maps.LatLng(20.0532212, 64.4407944);
            const mapOptions = {
                zoom: 7,
                center: myLatlng, // we disable de scroll over the map, it is a really annoing when you scroll through page
            };

            vm.map = new window.google.maps.Map(document.getElementById('regularMap'), mapOptions);
            let map = vm.map;
            let card = document.getElementById('pac-card');
            let input = document.getElementById('pac-input');
            let types = document.getElementById('type-selector');

            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

            let autocomplete = new google.maps.places.Autocomplete(input);

            // Bind the map's bounds (viewport) property to the autocomplete object,
            // so that the autocomplete requests use the current map bounds for the
            // bounds option in the request.
            autocomplete.bindTo('bounds', map);

            // Set the data fields to return when the user selects a place.
            autocomplete.setFields(
                ['address_components', 'geometry', 'icon', 'name']);

            autocomplete.addListener('place_changed', function () {
                let place = autocomplete.getPlace();
                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);  // Why 17? Because it looks good.
                }

                let address = '';
                if (place.address_components) {
                    address = [
                        (place.address_components[0] && place.address_components[0].short_name || ''),
                        (place.address_components[1] && place.address_components[1].short_name || ''),
                        (place.address_components[2] && place.address_components[2].short_name || '')
                    ].join(' ');
                }
            });

            // Sets a listener on a radio button to change the filter type on Places
            // Autocomplete.
            function setupClickListener(id, types)
            {
                let radioButton = document.getElementById(id);
                radioButton.addEventListener('click', function () {
                    autocomplete.setTypes(types);
                });
            }

            setupClickListener('changetype-all', []);
            setupClickListener('changetype-address', ['address']);
            setupClickListener('changetype-establishment', ['establishment']);

            google.maps.event.addListener(map, 'click', function (event) {
                placeMarker(event.latLng);
            });

            if (vm.projectId == 0) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        let pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        map.setCenter(pos);
                        map.setZoom(15);
                    }, function () {
                        handleLocationError(true);
                    });
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false);
                }
            }


            function handleLocationError(browserHasGeolocation)
            {
                if (browserHasGeolocation) {
                    //alert('Error: The Geolocation service failed.');
                    console.log('Error: The Geolocation service failed.');
                } else {
                    //alert('Error: Your browser doesn\'t support geolocation.');
                    console.log('Error: Your browser doesn\'t support geolocation.');
                }
            }

            function placeMarker(location)
            {
                if (vm.selectedMarker && vm.selectedMarker.setMap) {
                    vm.selectedMarker.setMap(null);
                }
                vm.selectedMarker = new google.maps.Marker({
                    position: location,
                    map: map
                });
                let latLng = vm.selectedMarker.getPosition().lat() + ',' + vm.selectedMarker.getPosition().lng();
                vm.getGeoLocation(latLng);
            }

        },
        getGeoLocation: function (latLng) {
            let vm = this;

            vm.selectCountryLabel = 'Loading..';
            vm.selectStateLabel = 'Loading...';
            vm.selectLocalityLabel = 'Loading...';

            vm.model.selectedLocation = latLng;

            vm.googleCountries = [];
            vm.model.selectedCountry = null;

            vm.googleStates = [];
            vm.model.selectedState = null;

            vm.googleLocality = [];
            vm.model.selectedLocality = null;

            vm.fetchingAddress = true;

            delete window.axios.defaults.headers.common['Authorization'];

            /*let instance = axios.create({
             headers: {
             common: {
             'X-CSRF-TOKEN': 'totallyfaketoken',
             },
             },
             });*/

            axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    latlng: latLng,
                    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
                },
            }).then(response => {
                for (let i = 0; i < Object.keys(response.data.results).length; i++) {
                    for (let j = 0; i < Object.keys(response.data.results[i].address_components).length; j++) {
                        if (response.data.results[i].address_components[j].types.indexOf("country") > -1) {
                            vm.googleCountries.push(response.data.results[i].address_components[j].long_name);
                        }
                        if (response.data.results[i].address_components[j].types.indexOf("administrative_area_level_1") > -1) {
                            vm.googleStates.push(response.data.results[i].address_components[j].long_name);
                        }
                        if (response.data.results[i].address_components[j].types.indexOf("locality") > -1) {
                            vm.googleLocality.push(response.data.results[i].address_components[j].long_name);
                        }
                    }
                }
                if (vm.googleStates.length < 1) {
                    vm.googleStates = vm.googleCountries;
                }
                if (vm.googleLocality.length < 1) {
                    vm.googleLocality = vm.googleStates;
                }

                vm.fetchingAddress = false;

                vm.selectCountryLabel = 'Select Country';
                vm.selectStateLabel = 'Select State';
                vm.selectLocalityLabel = 'Select Locality';

                vm.model.selectedCountry = vm.googleCountries[0];
                vm.model.selectedState = vm.googleStates[0];
                vm.model.selectedLocality = vm.googleLocality[0];

            }).catch(error => {
                console.log(error);

                if (vm.googleStates.length < 1) {
                    vm.googleStates = vm.googleCountries;
                }
                if (vm.googleLocality.length < 1) {
                    vm.googleLocality = vm.googleStates;
                }

                vm.fetchingAddress = false;
                vm.selectCountryLabel = 'Select Country';
                vm.selectStateLabel = 'Select State';
                vm.selectLocalityLabel = 'Select Locality';

                vm.model.selectedCountry = vm.googleCountries[0];
                vm.model.selectedState = vm.googleStates[0];
                vm.model.selectedLocality = vm.googleLocality[0];
            });

            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + vm.$store.getters.getJwt;

        },
        validateAndSubmit: function (event) {

            if (this.model.selectedCategories.length == 0) {
                this.showAlert('Error', "You must select at least one ownership option", false);
                return;
            }

            if (!this.onLine) {
                this.showAlert('Offline', "You are offline please check your network connection and try again.", false);
                return;
            }

            if (this.uploadPending) {
                this.showAlert('Error', "Please wait image is being uploaded", false);
                return;
            }
            if (this.model.projectName == '') {
                this.showAlert('Error', "The projectName field is required", false);
                return;
            }
            if (this.model.selectedLocation == null) {
                this.showAlert('Error', "Please select location pin point on map for this project.", false);
                return;
            }
            this.isDisabled = true;

            this.$validator.validateAll().then((result) => {
                let vm = this;
                if (vm.uploadURL != '') {
                    vm.uploadURL = vm.uploadURL;
                } else {
                    vm.uploadURL = '/images/hc_logo_med.png';
                }
                if (result) {
                    if (vm.model.selectedAnimals.length === 0) {
                        vm.isDisabled = false;
                        vm.showAlert('Error', "Please select one or more animals for this project.", false);
                        return;
                    } else {
                        if (vm.projectId == 0) {
                            vm.$store.dispatch('user/createProject', {
                                name: vm.model.projectName,
                                description: vm.model.projectDescription,
                                banner_message: vm.model.projectBannerMessage,
                                timezone: vm.model.selectedTimezone,
                                project_animals: vm.model.selectedAnimals,
                                date_format: vm.model.selectedDateFormat,
                                type: 'HC',
                                image_url: vm.uploadURL,
                                project_location_lat_lng: vm.model.selectedLocation,
                                country: vm.model.selectedCountry,
                                google_admin_area_level1: vm.model.selectedState,
                                google_locality: vm.model.selectedLocality,
                                organisation_id: vm.model.selectedOrganisation,
                                vaccination_drug_ids: vm.model.vaccinationDrug,
                                category: vm.model.selectedCategories.toString(),
                                vet_ids: vm.model.selectedVets,
                            }).then((successful) => {
                                if (successful === true) {
                                    this.$store.dispatch('user/getUser').then((response) => {
                                        this.$root.$emit('userDataSet');
                                        vm.$router.replace("/platform/projects");
                                    });
                                } else {
                                    if (successful.length == 0) {
                                        vm.showAlert('Error', ' Sorry, You cannot create this project because a project with same name already exist.', false);
                                    } else {
                                        vm.showAlert('Error', successful.toString(), false);
                                    }
                                }
                            });
                        }
                    }
                }
                vm.isDisabled = false;
                return false;
            }).catch(() => {
                this.isDisabled = false;
                return false
            });
        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        addNewAnimal()
        {
            this.animalName = ''
            this.addingNewAnimal = !this.addingNewAnimal;
            if (this.addingNewAnimal) {
                this.$refs.addNewAnimalDialogBox.style = 'padding: 10px; box-shadow: 10px 10px 25px 0px #888888; border-radius: 5px;';
            } else {
                this.$refs.addNewAnimalDialogBox.style = '';
            }
        },
        callAddNewAnimalApi(event)
        {


            let vm = this;
            if (vm.animalName != '' && vm.model.selectedOrganisation != null) {
                this.tempAddSpecies()
            } else {
                vm.showAlert('Error', "please enter species name and select organisation", false);
            }
        },
        cancleNewProject(type)
        {
            let vm = this;
            new Swal({
                icon:'info',
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Yes,cancel it!',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    new Swal({
                        icon:'info',
                        title: 'Cancelled!',
                        text: '',
                        type: 'success',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    }).then(function (result) {
                        vm.$router.replace("/platform/projects");
                    });
                }
            });
        },
        isCharAllow: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
                return true;
            } else {
                evt.preventDefault();
                this.showAlert('Error', "Only alphabets are allowed", false);
            }
        },
        getOrgAnimals()
        {
            let vm = this;
            axios.get("/animal/organisation/" + vm.model.selectedOrganisation)
                .then(response => {
                    vm.orgAnimals = response.data.response.data.animals;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getOrgAnimalsAndDrugs()
        {
            this.getOrgAnimals();
            this.getOrgVaccinations();
        },

        addVaccineDrug()
        {
            this.vaccineName = ''
            this.addingNewVaccine = !this.addingNewVaccine;
            if (this.addingNewVaccine) {
                this.$refs.addDrugVaccineDialogBox.style = 'padding: 10px; box-shadow: 10px 10px 25px 0px #888888; border-radius: 5px;';
            } else {
                this.$refs.addDrugVaccineDialogBox.style = '';
            }
        },
        callAddVaccineApi(event)
        {
            let vm = this;

            if (vm.vaccineName != '' && vm.model.selectedOrganisation != null) {

                this.tempAddVaccinationDrug()
            } else {
                vm.showAlert('Error', "Please enter vaccine/drug name and select organisation", false);
            }


        },
        getOrgVaccinations()
        {

            let vm = this;

            if (vm.model.selectedOrganisation != null) {

                axios.get('/vaccination_drug/get_all/' + vm.model.selectedOrganisation)
                    .then(response => {

                        if (response.data.response.error.error_code == 0) {

                            let vaccinationDrugs = response.data.response.data.vaccination_drugs;
                            vm.vaccinationDrug = [];
                            for (let i = 0; i < vaccinationDrugs.length; i++) {

                                let vaccine = {
                                    id: vaccinationDrugs[i].id,
                                    vaccination_drug: vaccinationDrugs[i].vaccination_drug_name,
                                };
                                vm.vaccinationDrug.push(vaccine);

                            }


                        }

                    })
                    .catch(error => {


                    });

            }


        },
        tempAddVaccinationDrug()
        {
            let vm = this;
            if (vm.model.projectAssociate == '1') {
                return
            }
            const isNameAlreadyExists = vm.vaccinationDrug.find(el => el.vaccination_drug.toLowerCase() === this.vaccineName.toLowerCase())
            const isTempNameExists = this.allVaccinationDrugName.find(el => el.toLowerCase() === this.vaccineName.toLowerCase())

            if (isNameAlreadyExists || isTempNameExists) {
                this.showAlert(
                    "Unable to Add",
                    'The Vaccine/Drug name you trying to add is already exists',
                    false
                );
            } else {
                this.allVaccinationDrugName.push(this.vaccineName)
                this.addVaccineDrug()
            }
        },
        removeFromAllVaccinationDrugName(itemIndex, vaccinationDrug)
        {
            this.allVaccinationDrugName.splice(itemIndex, 1);
            const elIndex = this.model.vaccinationDrug.findIndex(el => el === vaccinationDrug)
            if (elIndex !== -1) {
                this.model.vaccinationDrug.splice(elIndex, 1);
            }
        },

        tempAddSpecies()
        {
            const isNameAlreadyExists = this.orgAnimals.find(el => el.species_name.toLowerCase() === this.animalName.toLowerCase())
            const isTempNameExists = this.allSpeciesName.find(el => el.toLowerCase() === this.animalName.toLowerCase())
            console.log('isNameAlreadyExists', isNameAlreadyExists)
            if (isNameAlreadyExists || isTempNameExists) {
                this.showAlert(
                    "Unable to Add",
                    'The species name you trying to add is already exists',
                    false
                );
            } else {
                this.allSpeciesName.push(this.animalName)
                this.addNewAnimal()
            }
        },
        removeFromAllSpeciesName(itemIndex, species)
        {
            this.allSpeciesName.splice(itemIndex, 1);
            // check if it's selected. if selected then remove from model.selectedAnimals as well.
            const elIndex = this.model.selectedAnimals.findIndex(el => el === species)
            if (elIndex !== -1) {
                this.model.selectedAnimals.splice(elIndex, 1)
            }
        },

        onVisibilityChange() {
            const vm = this;
            if (vm.model.projectAssociate === '1') {
                new Swal({
                    icon:'info',
                    title: 'Are you sure you want to change visibility to Private?',
                    text: `Private projects are only visible to you, you can not invite others to the project. Mainly intended for testing/setup purposes.`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: 'Yes',
                    buttonsStyling: false,
                }).then(function (result) {
                    if (!result.value) {
                       vm.model.projectAssociate = '2';
                    }
                });
            }
        }
    },

    computed: {
        ...mapGetters('animal', ['animals']),
        ...mapGetters('user', ['userOrganisations']),
        ...mapGetters('user', ['getVetsInOrganisation']),
        projectAssociateInfo: function () {
            let vm = this;
            if (vm.model.projectAssociate == '1') {
                vm.model.selectedOrganisation = null;
                vm.vaccinationDrug = [
                    {
                        id: 1,
                        vaccination_drug: 'Rabies'
                    },
                ];
                vm.model.vaccinationDrug = [];
                return 'Private projects are only visible to you, you can not invite others to the project.  Mainly intended for testing/setup purposes.  You can later change visibility to Organisation if you are affiliated with one.';
            } else {
                vm.vaccinationDrug = [];
                vm.model.vaccinationDrug = [];
                if (vm.model.projectAssociate == '2' && vm.userOrganisations.length > 0) {
                    vm.model.selectedOrganisation=vm.userOrganisations[0].organisation.id

                    vm.getOrgAnimalsAndDrugs()
                    return vm.userOrganisations[0].organisation.name+' organisation will be notified for the new project and will have full rights to transfer and delete the project.';
                } else {
                    vm.model.selectedOrganisation=null
                    return ''
                }
            }
        },
        constructTitle: function () {
            return "New Project";
            let prefix = this.projectId > 0 ? 'Update ' : 'Create New ';
            if (this.model.projectAssociate == '1') {
                this.model.selectedOrganisation = null;
                return prefix + ' Population Management' + ' Project for Self';
            } else
                if (this.model.projectAssociate == '2' && this.userOrganisations != null) {
                    return prefix + ' Population Management' + ' Project for Organisation';
                }
        },
        getAddAnimalLabel: function () {
            if (this.addingNewAnimal) {
                return "";
            } else {
                return "+ Add New";
            }
        },
        getAddVaccineLabel: function () {
            if (this.addingNewVaccine) {
                return "";
            } else {
                return "+ Add New";
            }
        },
    },

    mounted()
    {
        this.init();
    },

    beforeRouteLeave(to, from, next) {
        // If the form is dirty and the user did not confirm leave,
        // prevent losing unsaved changes by canceling navigation
        const leaveConfirm = window.confirm(
            "Are you sure you want to leave? any unsaved changes will be lost"
        );
        if (leaveConfirm) {
            next();
        } else {
            // Navigate to next view
            next(false);
        }
    },


}

</script>
<style scoped>

#description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

a {
    color: #51cbce;
}

a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #51cbce;
}

.checkbox_lbl {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-top: -14px;
}

.hideMvOptions {
    display: none;
}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

.hideMvOptions {
    display: none;
}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

.set_msg {
    margin-top: 5%;
}

.help-icon {
    cursor: pointer;
    position: absolute;
    margin-left: 5px;
    margin-top: -15px;
    color: #51cbce !important;;
}

@media (max-width: 320px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .set_msg {
        margin-top: 5% !important;

    }
}

.el-checkbox {
    margin: 0;
    padding: 0px 20px 5px 0px;
}

.el-select {
    width: inherit;
}

</style>
