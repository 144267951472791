<template>
    
</template>
<script>
    //import {CollapseTransition} from 'vue2-transitions';

    export default {
        
    };
</script>
<style scoped lang="scss">
  
</style>
