import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import animal from './modules/animal';
import project from './modules/project';
import auth from './modules/auth';
import common from './modules/common';

import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";

const dataState = createPersistedState({
    paths: ['auth', 'user', 'animal', 'project', 'common']
});

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        animal,
        project,
        auth,
        common
    },
    plugins: [
        dataState, createMutationsSharer({predicate: ["mutation1", "mutation2"]})
    ]
});
