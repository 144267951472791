<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>

        <div>
            <div>


                <div class="row set_row top-bar set_msg title-row">
                    <h5>{{ getTitle }}</h5>
                </div>
                <hr>
                <div class="row set_row">

                    <template v-if="addNewFieldTeam">
                        <div class="col-12">
                            <div class="manage-team-section">

                                <fieldset>
                                    <br>

                                    <div class="form-group">
                                        <fg-input
                                            type="text"
                                            ref="addFieldTeamName"
                                            label="Field Team Name*"
                                            name="Field Team Name"
                                            v-validate="fieldTeamValidation.fieldTeamName"
                                            :error="getError('Field Team Name')"
                                            v-model="model.fieldTeamName">
                                        </fg-input>
                                    </div>
                                </fieldset>

                                <fieldset>
                                    <div class="form-group">
                                        <label class="control-label">Field Team Description</label>
                                        <textarea class="form-control" v-model="model.fieldTeamDescription"
                                                  placeholder="Enter description here..." rows="3"></textarea>
                                    </div>
                                </fieldset>

                                <div class="row">
                                    <div class="col-12 text-right">
                                        <br>
                                        <button v-if="onLine" v-bind:disabled="model.isDisabled"
                                                v-on:click="validateAndSubmit"
                                                type="button"
                                                class="btn btn-round btn-info">Add Field Team
                                        </button>
                                        <button v-else v-bind:disabled="true" v-on:click="validateAndSubmit"
                                                type="button"
                                                class="btn btn-round btn-info">Add Field Team
                                        </button>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </template>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import mixin from '../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import {mapGetters} from 'vuex';

import {Select, Option,} from 'element-ui';
import Swal from "sweetalert2";


export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline
    },
    data() {
        return {

            addNewFieldTeam: true,

            model: {
                fieldTeamName: '',
                fieldTeamDescription: '',
                selectedOrganisation: null,
            },
            fieldTeamValidation: {
                fieldTeamName: {
                    required: true,
                }
            },
            // Merged data of inviteFieldTeamUser.vue
            selectedTeam: null,
            password: null,
            passwordConfirmation: null,
            teams: [],
            isDisabled: false,
            userEmail: '',
            userNote: '',
            userInviteValidation: {
                userEmail: {
                    required: true,
                    email: true,
                },
            },

            firstName: '',
            lastName: '',

            invitingUser: false,
            invitations: [],
        }
    },
    mixins: [mixin],
    methods: {
        getName(invitation) {
            if (invitation.first_name != "" || invitation.last_name != "") {
                return invitation.first_name + " " + invitation.last_name
            } else {
                return "Unknown"
            }
        },
        async init() {
            let vm = this;
            const project = vm.$store.getters.getCurrentSelectedProject;
            this.model.selectedOrganisation = project.organisation_id
        },
        validateAndSubmit: function () {
            let vm = this;
            this.isDisabled = true;
            this.$refs.addFieldTeamName.$validator.validate('Field Team Name').then((result) => {
                if (result) {
                    axios.post('/field_team/create', {
                        name: vm.model.fieldTeamName,
                        description: vm.model.fieldTeamDescription,
                        organisation_id: vm.model.selectedOrganisation,
                    }).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            vm.model.fieldTeamName = '';
                            vm.model.fieldTeamDescription = '';
                            vm.$validator.reset();
                        }
                    }).catch(error => {
                        if (error.response.status === 400) {
                            vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                        } else {
                            console.log(error);
                        }

                    });
                }
                this.isDisabled = false;
                return false;
            }).catch(() => {
                this.isDisabled = false;
                return false
            });
        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        // Merged methods of InviteFieldTeamUser.vue
        getInvitations() {
            let vm = this;

            vm.invitations = [];

            axios.get('/field_team/get_invitations', {
                params: {
                    field_team_id: vm.selectedTeam,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.invitations = response.data.response.data.invitations;
                }
            }).catch(error => {

            });
        },
        cancelInvitation(invitationCode, index) {
            let vm = this;

            new Swal({
                title: 'Are you sure you want to cancel this invitation?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Cancel Invitation',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.get('/field_team/cancel/invitation/' + invitationCode)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(response.data.response.error.msg, '', true);
                                vm.invitations.splice(index, 1);
                            } else {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        })
                        .catch(error => {
                            if (error.response.data.response.error) {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        });
                }
            });
        },
        inviteUserToProject() {
            let vm = this;

            if (vm.selectedTeam == null) {
                alert('Please select field team');
                return;
            }
            this.$refs.inviteUser.$validator.validate('User Email').then((result) => {

                if (result) {
                    vm.invitingUser = true;
                    axios.post('/field_team/invite_user/platform', {
                        email: vm.userEmail,
                        on_team: vm.selectedTeam,
                        first_name: vm.firstName,
                        last_name: vm.lastName,
                        password: vm.password,
                        password_confirmation: vm.passwordConfirmation,
                        note: vm.userNote,
                    }).then(response => {
                        vm.invitingUser = false;
                        if (response.data.response.error.error_code === 0) {
                            vm.showAlert(response.data.response.error.msg, vm.userEmail, true);
                            vm.userEmail = '';
                            vm.firstName = '';
                            vm.lastName = '';
                            vm.userNote = '';
                            vm.password = ''
                            vm.passwordConfirmation = ''
                            vm.$validator.reset();
                            vm.invitations.push(response.data.response.data.invitation);
                        } else {
                            vm.showAlert('Alert', response.data.response.error.error_msg, false);
                        }
                    }).catch(error => {
                        console.log(error)
                        vm.showAlert('Alert', error.data.response.error.error_msg, false);
                        vm.invitingUser = false;
                    });
                }
            });

        },
    },
    computed: {
        ...mapGetters('user', ['userOrganisations']),
        // Merged Computed of InviteFieldTeamUser.vue
        selectedFieldTeam: function () {
            if (this.selectedTeam != null) {
                let team = this.teams.filter(item => {
                    return item.id == this.selectedTeam;
                });
                return ' on Team: ' + team[0].name;
            }
        },
        getTitle: function () {
            if (this.addNewFieldTeam) {
                return "Add New Field Team"
            }
            return ""
        }

        // End of merge.
    },
    mounted() {

        if (this.$route.query.view === 'add-field-team') {
            this.addNewFieldTeam = true
        }

        this.init();


    },

}

</script>

<style>
.el-select .el-input:hover .el-input__icon,
.el-select .el-input:hover input {
    color: black;
}

.el-select .el-input:hover input::placeholder {
    color: darkgrey;
}

.set_row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.invite-user-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 100%;
}

.manage-team-section {
    box-shadow: 0 8px 6px -6px gray;
    background-color: white;
    padding: 30px;
    margin: 15px;
    border: 1px solid none;
    border-radius: 35px;
    min-height: 320px;
}

.invitation-label {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
}

.invitation-table {
    width: 100%;
    margin: 15px 0px;
}

.title-row {
    padding-left: 40px;
    padding-top: 25px;
}

@media only screen and (max-device-width: 991px) {
    .set_data_table_textbox_width {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .invitation-table td {
        word-break: break-word;
    }

    .manage-team-section {
        padding: 15px;
        border-radius: 15px;
        margin: 0 0 20px;
    }

    .manage-team-section h3 {
        font-size: 1.4em;
        margin-bottom: 15px;
    }

    .title-row {
        padding-left: 15px;
        padding-top: 15px;
    }

    .align-items-center.title-row {
        justify-content: center;
    }

    .title-row h5 {
        margin-bottom: 0;
        font-size: 1.4em;
    }

    body .set_data_table_textbox_width {
        width: 100% !important;
    }
}
</style>
