<template>
    <div class="locale-switcher">

        <select v-model="$i18n.locale" @change="storeSelectedLanguage">

            <option :value="locale.code" v-for="locale in locales" :key="locale.code">

                {{ locale.name }}

            </option>

        </select>

    </div>
</template>
  
<script>

import { getSupportedLocales } from "../utils/i18n/supported-locales"

export default {

    data: () => ({ locales: getSupportedLocales() }),

    methods: {
        storeSelectedLanguage() {
            localStorage.setItem('localeLanguage', this.$i18n.locale);
        }
    }

}

</script>