<template>

    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>

        <div class="row">
            <div class="col-sm-12">
                <h5>Records <i style="color: darkgrey" class="ti-angle-right small"></i> Archived</h5>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 card">
                <div class="card-body row">
                    <div class="col-sm-12 col-xl-12">
                        <el-table
                            ref="multipleTable"
                            v-loading="loadingData"
                            :data="archivedRecords"
                            style="width: 100%"
                            border
                            :pagination="true"
                            @selection-change="handleSelectionChange">
                            <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column
                                property="record_type"
                                label="Record Type">
                            </el-table-column>
                            <el-table-column
                                label="Image">
                                <template slot-scope="scope">
                                    <img :src="scope.row.data.image_url" class="datatable_image_thumbnail" height="70" width="70" @click="zoomImage(scope.row.data.image_url)"/>
                                </template>
                            </el-table-column>
                            <el-table-column
                                property="data.stage"
                                label="Stage">
                            </el-table-column>
                            <el-table-column
                                property="data.outcome"
                                label="Outcome">
                            </el-table-column>
                            <el-table-column label="Archived On">
                                <template slot-scope="scope">
                                    {{ changeDateFormat(scope.row.created_at, 'Do MMM YYYY, HH:mm:ss') }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="Archived By">
                                <template slot-scope="scope">
                                    {{ scope.row.archived_by_user.first_name }} {{ scope.row.archived_by_user.last_name }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <button type="button" class="btn" @click="restoreRecord">Restore Records</button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import mixin from '../../../mixins/GlobalMixin.js';
import VOffline from 'v-offline';

import Swal from 'sweetalert2';


export default {

    components: {
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
    },
    data()
    {
        return {
            archivedRecords: [],
            selectedRecords: [],
            loadingData: false
        }
    },
    mixins: [mixin],
    methods: {
        init()
        {
            this.getRecords();
        },
        handleSelectionChange(val) {
            this.selectedRecords = val;
        },
        restoreRecord()
        {
            let vm = this;
            const ids = vm.selectedRecords.map((selected) => selected.id.toString());
            new Swal({
                icon:'info',
                title: 'Alert!',
                text: 'Are you sure you want to restore archived record(s)?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Restore',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    const loading = vm.$loading({
                        lock: true,
                        text: 'Restoring records...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    axios.post('restore/pm/catching/records', { ids })
                        .then(response => {
                            loading.close();
                            if (response.data.response.error.error_code == 0) {
                                vm.selectedRecords = [];
                                vm.getRecords();
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        })
                        .catch(error => {
                            loading.close();
                            console.log(error);
                        })

                } else {
                    vm.selectedRecords = [];
                }
            });
        },
        getRecords() {
            const vm = this;
            vm.loadingData = true;
            axios.get(`archive/list/pm/catching/records/${vm.projectId}`, 'selectedRecords').then((response) => {
                vm.loadingData = false;
                if (response.data.response.error.error_code == 0) {
                    vm.archivedRecords = response.data.response.data.records;
                }
            });
        },
        zoomImage(imageUrl) {
            const vm = this;
            vm.zoomImagePopup(false, imageUrl);
        }
    },
    mounted()
    {
        let vm = this;
        vm.init();
    }

}

</script>

<style lang="scss" scoped>
</style>
