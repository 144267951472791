<template>
    <!-- Pradeep Start -->
    <!-- responsive set -->
    <div class="row set_row">
        <div class="col-md-12">
            <h4 class="title">Organization</h4>
        </div>
        <div class="col-md-12 card">
            <div class="card-header">
                <div class="category">Extended tables</div>
            </div>
            <div class="card-body row">
                <div class="col-sm-8 col-xs-6 col-md-6 col-xl-6">
                    <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
                        <el-option
                                class="select-default"
                                v-for="item in pagination.perPageOptions"
                                :key="item"
                                :label="item"
                                :value="item"
                        ></el-option>
                    </el-select>
                </div>
                <div class="col-sm-8 col-xs-6 col-md-6 col-xl-6 set_input_search">
                    <div class="pull-right">
                        <fg-input
                                class="input-sm"
                                placeholder="Search"
                                v-model="searchQuery"
                                addon-right-icon="nc-icon nc-zoom-split"
                        ></fg-input>
                    </div>
                </div>
                <div class="col-sm-12 mt-2">
                    <el-table class="table-striped" :data="queriedData" border style="width: 100%">
                        <el-table-column label="name" width="300">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.name }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="email" width="300">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.email }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Link" width="100">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <a v-bind:href="scope.row.ApplicationLink" target="_blank">Open</a>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="Actions">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <a v-bind:href="scope.row.verification_code" target="_blank">Open</a>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                <p-button
                                        type="success"
                                        size="sm"
                                        icon
                                        v-tooltip.top-center="msg"
                                        @click="showSwal('warning-message-and-confirmation',scope.row.verification_code)">
                                    <i class="ti-check icon_font"></i>
                                </p-button>
                                <p-button
                                        type="danger"
                                        class="reject_btn"
                                        size="sm"
                                        icon
                                        v-tooltip.top-center="msgReject"
                                        @click="showSwalByDelete('warning-message-and-confirmation',scope.row.verification_code)"
                                        style=" background-color: #d80707 !important;">
                                    <i class="ti-close icon_font"></i>
                                </p-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="col-sm-6 pagination-info">
                    <p class="category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
                </div>
                <div class="col-sm-6">
                    <p-pagination
                            class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total"
                    ></p-pagination>
                </div>
            </div>
        </div>
    </div>
    <!-- Pradeep End -->
</template>
<script>
import Vue from "vue";
import {Table, TableColumn, Select, Option} from "element-ui";
import PPagination from "../UIComponents/Pagination.vue";
import users from "./users";
import Swal from "sweetalert2"
import VTooltip from "v-tooltip";
import store from "../../../store";

Vue.use(VTooltip);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
export default {
    components: {
        PPagination
    },
    computed: {
        pagedData()
        {
            return this.tableData.slice(this.from, this.to);
        },
        /***
         * Searches through table data and returns a paginated array.
         * Note that this should not be used for table with a lot of data as it might be slow!
         * Do the search and the pagination on the server and display the data retrieved from server instead.
         * @returns {computed.pagedData}
         */
        queriedData()
        {
            if (!this.searchQuery) {
                this.pagination.total = this.tableData.length;
                return this.pagedData;
            }
            let result = this.tableData.filter(row => {
                let isIncluded = false;
                for (let key of this.propsToSearch) {
                    let rowValue = row[key].toString();
                    // Pradeep Start
                    // case sensitive character search in datatable
                    if (rowValue.toLowerCase().includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        isIncluded = true;
                    }
                    // Pradeep End
                }
                return isIncluded;
            });
            this.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },
        to()
        {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from()
        {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total()
        {
            this.pagination.total = this.tableData.length;
            return this.tableData.length;
        }
    },
    data()
    {
        return {
            msg: 'Accept',
            msgReject: 'Reject',
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            searchQuery: "",
            propsToSearch: ["name", "email"],
            //set dyanemic record of organisation pending request
            tableData: []
        };
    },
    created()
    {
        this.tableData = store.getters.getPendingOrgReq
    },
    methods: {
        handleLike(index, row)
        {
            alert(`Your want to like ${row.name}`);
        },
        handleEdit(index, row)
        {
            alert(`Your want to edit ${row.name}`);
        },
        handleDelete(index, row)
        {
            let indexToDelete = this.tableData.findIndex(
                tableRow => tableRow.id === row.id
            );
            if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
            }
        },
        showSwal(type, verification_code)
        {
            new Swal({
                title: "Are you sure?",
                text: `Clicking Yes will approve this user's account.`,
                type: "warning",
                icon: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, approve it!",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    //call api for organisation request approved
                    axios.get('organisation/approve/' + verification_code, {}).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            new Swal({
                                title: "Approved!",
                                text: "This user has been approved.",
                                type: "success",
                                icon: "success",
                                confirmButtonClass: "btn btn-success btn-fill",
                                buttonsStyling: false
                            });
                        }
                    }).catch(error => {
                        console.log('test');
                    });
                }
            });
        },
        showSwalByDelete(type, verification_code)
        {
            new Swal({
                title: "Are you sure?",
                text: `You won't be able to revert this!`,
                type: "warning",
                icon: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, delete it!",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    //call api for organisation request rejected
                    axios.get('organisation/reject/' + verification_code, {}).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            new Swal({
                                title: "Deleted!",
                                text: "This user has been deleted.",
                                type: "success",
                                icon: "success",
                                confirmButtonClass: "btn btn-success btn-fill",
                                buttonsStyling: false
                            });
                        }
                    }).catch(error => {
                    });
                }
            });
        }
    },
    /*   Pradeep Start    */
    props: {
        pending_organisation: {
            type: String,
            default: 'TEST USER',
        },
    },

};
/*    Pradeep End  */
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }

}

.icon_font {
  font-size: 12px;
  font-weight: 1000;
}


.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

// Pradeep Start
@media (min-width: 320px) and (max-width: 425px) {
  .reject_btn {
    margin-left: 0px !important;
    margin-top: 3px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reject_btn {
    margin-left: 0px !important;
    margin-top: 3px !important;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .set_row {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

@media (max-width: 320px) {
  .set_input_search {
    margin-top: 8px !important;
    margin-left: -9px !important;
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .set_input_search {
    margin-top: 10px !important;
    margin-right: 47px !important;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .set_input_search {
    margin-top: 11px !important;
    margin-right: 98px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .el-table__fixed-right {
    background-color: white !important;
  }
}

// Pradeep End
</style>
