<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="top-bar">

            <h5 style="margin: 0;margin-left:40px;margin-top: 12px;">Invite User to join Field Team</h5>

        </div>

        <hr>
        <!--    Pradeep Start   -->
        <!-- set responsive -->
        <div class="row set_row" style="text-align: center;">
            <div class="col-md-1 col-sm-1"></div>
            <div class="col-md-5 col-sm-5">
                <div class="list-box">
                    <h6 style="background-color: #f5f7fa; padding: 12px; border-radius: 5px 5px 0px 0px; color: #34b5b8; width: 300px;">
                        Field Teams ({{ teams.length }})
                    </h6>
                    <select v-bind:disabled="isDisabled" @change="getInvitations" size="6" v-model="selectedTeam">
                        <template v-for="team in teams">
                            <option :value="team.id">{{ team.name }}</option>
                        </template>
                    </select>
                </div>

            </div>

            <div class="col-md-5 col-sm-5">

                <div v-if="invitations.length>0">
                    <h5>Invitations</h5>
                    <table width="100%">
                        <tr>
                            <th>Name/Email</th>
                            <th>Status</th>
                        </tr>
                        <template v-for="(invitation,index) in invitations">
                            <tr style="border: 1px solid #606266; padding: 5px;">
                                <td>
                                    <span style="font-weight: bold;">{{ getName(invitation) }}</span>
                                    <br>
                                    <a :href="'mailto:'+invitation.email">{{ invitation.email }}</a>
                                </td>
                                <td :style="{color:invitation.joined == 0 ?'red':'green'}">
                                    ({{ invitation.joined == 0 ? 'Pending' : 'Joined' }})
                                    <a href="#" v-if="invitation.joined==0"
                                       v-on:click.prevent="cancelInvitation(invitation.invitation_code,index)"><i
                                            class="nc-icon nc-simple-remove"></i></a>
                                </td>
                            </tr>

                        </template>
                    </table>
                </div>

                <br/>

                <h5>Invite User {{ selectedFieldTeam }}</h5>

                <fg-input v-model="userEmail"
                          type="text"
                          name="User Email"
                          :disabled="invitingUser"
                          v-validate="modelValidations.userEmail"
                          :error="getError('User Email')"
                          placeholder="Enter user email..."></fg-input>

                <fg-input v-model="firstName"
                          type="text"
                          name="First Name"
                          :disabled="invitingUser"
                          placeholder="Enter First Name"></fg-input>

                <fg-input v-model="lastName"
                          type="text"
                          name="Last Name"
                          :disabled="invitingUser"
                          placeholder="Enter Last Name"></fg-input>

                <fg-input v-model="userNote"
                          type="text"
                          name="Note"
                          :disabled="invitingUser"
                          placeholder="Enter note if any..."></fg-input>

                <button v-if="onLine" :disabled="invitingUser" type="button"
                        @click="inviteUserToProject" name="invite"
                        class="btn btn-round btn-default">
                    Invite User
                </button>
                <button v-else v-bind:disabled="true" type="button"
                        @click="inviteUserToProject" name="invite"
                        class="btn btn-round btn-default">
                    Invite User
                </button>

            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>
        <!--    Pradeep End   -->
    </div>
</template>

<script>

import mixin from '../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import Swal from 'sweetalert2';

export default {
    components: {
        VOffline
    },
    data()
    {
        return {
            selectedTeam: null,
            teams: [],
            isDisabled: false,

            userEmail: '',
            userNote: '',

            modelValidations: {
                userEmail: {
                    required: true,
                    email: true,
                },
            },

            invitingUser: false,
            invitations: [],
        }
    },
    mixins: [mixin],
    methods: {
        getName(invitation)
        {
            if (invitation.first_name != "" || invitation.last_name != "") {
                return invitation.first_name + " " + invitation.last_name
            } else {
                return "Unknown"
            }
        },
        getInvitations()
        {
            let vm = this;

            vm.invitations = [];

            axios.get('/field_team/get_invitations', {
                params: {
                    field_team_id: vm.selectedTeam,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.invitations = response.data.response.data.invitations;
                }
            }).catch(error => {

            });
        },
        cancelInvitation(invitationCode, index)
        {
            let vm = this;

            new Swal({
                title: 'Are you sure you want to cancel this invitation?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Cancel Invitation',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.get('/field_team/cancel/invitation/' + invitationCode)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(response.data.response.error.msg, '', true);
                                vm.invitations.splice(index, 1);
                            } else {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        })
                        .catch(error => {
                            if (error.response.data.response.error) {
                                vm.showAlert(response.data.response.error.error_msg, '', false);
                            }
                        });
                }
            });
        },
        inviteUserToProject()
        {
            let vm = this;

            if (vm.selectedTeam == null) {
                alert('Please select field team');
                return;
            }

            this.$validator.validateAll().then((result) => {
                if (result) {
                    vm.invitingUser = true;
                    axios.post('/field_team/invite_user', {
                        email: vm.userEmail,
                        on_team: vm.selectedTeam,
                        first_name: vm.firstName,
                        last_name: vm.lastName,
                        note: vm.userNote,
                    }).then(response => {
                        vm.invitingUser = false;
                        if (response.data.response.error.error_code === 0) {
                            vm.showAlert(response.data.response.error.msg, vm.userEmail, true);
                            if (response.data.response.data) {
                                vm.invitations.push(response.data.response.data.invitation);
                            }
                            vm.userEmail = '';
                            vm.userNote = '';
                            vm.$validator.reset();
                        } else {
                            alert(response.data.response.error.error_msg);
                        }
                    }).catch(error => {
                        vm.showAlert('Sorry', error.response.data.response.error.error_msg, false);
                        vm.invitingUser = false;
                    });
                }
            });

        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        getFieldTeams()
        {
            let vm = this;
            vm.project = vm.$store.getters.getCurrentSelectedProject;
            axios.get('/field_team/teams/org/' + vm.project.organisation_id).then(response => {
                console.log(response.data.response.data.teams);
                vm.teams = response.data.response.data.teams;
            }).catch(error => {
                console.log(error);
            });
        },
        init()
        {
            this.getFieldTeams();
        },
    },
    computed: {
        selectedFieldTeam: function () {
            if (this.selectedTeam != null) {
                let team = this.teams.filter(item => {
                    return item.id == this.selectedTeam;
                });
                return ' on Team ' + team[0].name;
            }
        }
    },
    created()
    {
        this.init();
    }

}

</script>

<style scoped>

.list-box {
    width: 300px;
    background-color: white;
    border-radius: 5px;
    float: left;
    margin-right: 50px;
}

select {
    width: 300px;
    height: 243px;
    border: none;
}

option {
    width: 300px;
    padding: 10px;
}

h6 {
    margin: 0;
}

.set_row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

</style>
