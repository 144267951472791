<template>
    <div>

        <button v-if="isLoading" class="loader-close-btn" @click="hide">X</button>

    </div>
</template>
<script>


export default {


    props: {
        apiSource: {
            type: Object,
            default: null,
        },
        msg: {
            type: String,
            required: true,
        }
    },

    data() {
        return {

            loading: null,
            isLoading:false,

        }
    },

    methods: {

        show() {
            const vm = this
            vm.isLoading=true
            vm.loading = vm.$loading({
                lock: true,
                text: vm.$props.msg,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        hide() {
            const vm = this
            if (vm.$props.apiSource != null) {
                vm.apiSource.cancel('Operation canceled by the user.')
            }
            vm.loading.close()
            vm.loading = null
            vm.isLoading=false
        }

    }


}


</script>
<style scoped>

.loader-close-btn {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10000;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: red;
    color: white;
    margin: 10px;
    border: none;
    font-weight: bold;
    font-family: "Arial Black",serif;
}

</style>
