<template>
    <div class="row">
        <div class="col-12">

            <div class="card">

                <div class="card-header">
                    <div class="containBytitile">
                        <h4 class="card-title">{{ purpose == 'vaccination' ? 'Vaccination' : 'Survey' }} Report By
                            Area</h4>
                    </div>
                    <hr>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <p>Select Date(s)</p>
                                    <el-select v-model="dateFilterOption">
                                        <el-option value="All">All</el-option>
                                        <el-option value="Catch Date Range">Catch Date Range</el-option>
                                    </el-select>
                                </div>

                                <div class="col" v-if="dateFilterOption==='Catch Date Range'">
                                    <p>Select Date(s)</p>
                                    <v-md-date-range-picker :presets="dateRangePresets" :start-date="startDatePast"
                                                            :end-date="endDatePast"
                                                            @change="handleChange"
                                                            style="width:100% !important"></v-md-date-range-picker>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-sm-12 col-md-4">
                            <p>Areas</p>
                            <multiselect
                                v-model="selectedArea"
                                :options="allAreas"
                                :show-labels="false"
                                placeholder="Select Area"
                                track-by="id"
                                label="name"
                                :multiple="true"
                                :close-on-select="false"
                            ></multiselect>
                        </div>

                        <div class="col-12">
                            <br>
                            <el-checkbox @change="selectAllAreas" v-model="isAllAreaSelected">Select All Areas
                            </el-checkbox>
                        </div>

                        <div class="col-12">
                            <el-button
                                :disabled="!selectedArea.length"
                                @click="generatePDF"
                                :loading="isGenerating"
                                class="btn btn-primary float-right text-white mt-3 mr-1"
                            >
                                Generate PDF
                            </el-button>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <FullscreenLoader ref="loader" :api-source="apiSource" msg="Please wait generating PDF..."></FullscreenLoader>

    </div>
</template>

<script>
import mixin from '../../mixins/GlobalMixin';
import dateRangeDefaultPresetsMixin from '../../mixins/DateRangeDefaultPresetsMixin'
import Multiselect from "vue-multiselect";
import {Row, Col, Button, Checkbox} from "element-ui";
import FullscreenLoader from "../FullscreenLoader";

export default {
    name: "ReportsByArea",
    props: {
        projectId: {
            type: [Number, String],
            required: true
        },
        purpose: {
            type: [String],
            default: '',
            required: true
        }
    },
    data: () => ({
        apiSource: null,

        dateFilterOption: 'All',
        allAreas: [],
        selectedArea: [],
        isGenerating: false,
        startDate: '',
        endDate: '',
        isAllAreaSelected: false,
        startDatePast: '',
        endDatePast: '',
        dateRangePresets: []
    }),
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    components: {
        FullscreenLoader,
        Multiselect,
        [Row.name]: Row,
        [Col.name]: Col,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox
    },
    async mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        //Bus.$emit("projectype", vm.project);
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.startDatePast = firstDay.toLocaleDateString('en-US');
        this.endDatePast = lastDay.toLocaleDateString('en-US');
        this.dateRangePresets = this.getDateRangePresets();
        this.dateChanged([this.startDatePast, this.endDatePast]);
        await this.getAllAreas();
    },
    methods: {
        handleChange(values) {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.startDate = sDate;
            this.endDate = eDate;
        },
        selectAllAreas(isChecked) {
            if (isChecked) {
                for (let i = 0; i < this.allAreas.length; i++) {
                    this.selectedArea.push(this.allAreas[i]);
                }
            } else {
                for (let i = 0; i < this.allAreas.length; i++) {
                    this.selectedArea = [];
                }
            }


        },
        dateChanged(dates) {
            var sDate = this.changeDateFormat(dates[0], 'YYYY-MM-DD');
            var eDate = this.changeDateFormat(dates[1], 'YYYY-MM-DD');
            this.startDate = sDate;
            this.endDate = eDate;
        },
        async getAllAreas() {
            try {
                const {data} = await axios.get(
                    `/project/areas/${this.projectId}`
                );
                console.log("all Areas", data.response.data.areas);
                const response = data.response.data;
                // this.allAreas = [
                //     ...response.assigned_survey_areas,
                //     ...response.assigned_vaccination_areas
                // ];
                this.allAreas = response.areas;
            } catch (error) {
                console.error(error.response);
            }
        },
        async generatePDF() {

            const vm=this

            vm.isGenerating = true;

            const cancelToken = axios.CancelToken;
            vm.apiSource = cancelToken.source();

            vm.$refs.loader.show()

            const URL = `/pm/reports/generate/report/${this.projectId}`;

            if (this.dateFilterOption === 'Catch Date Range') {
                if (this.startDate === '' || this.endDate === '') {

                    this.startDate = this.formatDate(this.dateRangePresets[4].range[0]);
                    this.endDate = this.formatDate(this.dateRangePresets[4].range[1]);

                }
            } else if (this.dateFilterOption === 'All') {
                this.startDate = '';
                this.endDate = '';
            }

            const body = {
                report_type: this.purpose == 'survey' ? 34 : 3,
                area_ids: this.selectedArea.map(el => el.id),
                start_date: this.startDate,
                end_date: this.endDate,
            };
            try {
                const {data} = await axios.post(URL, body,{
                    cancelToken: vm.apiSource.token
                });
                const downloadURL = `/pm/reports/download/pdf/${data.response.data.report_id}`
                const pdfResponse = await axios.get(downloadURL)
                this.isGenerating = false;

                vm.$refs.loader.hide()

                console.log("pdf response", pdfResponse, data.response.data, pdfResponse.data.response.error.error_code, pdfResponse.data.response.data);
                if (pdfResponse.data.response.data === null) {
                    this.showAlert('Oops!', pdfResponse.data.response.error.msg, false)
                } else {
                    window.location.href = pdfResponse.data.response.data.src
                }
            } catch (error) {
                vm.$refs.loader.hide()
                this.isGenerating = false;
                console.error("pdf error", error.response);
            }
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect__input:disabled {
    cursor: not-allowed;
}

.set_font_title {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black !important;
    margin-left: -12px !important;
}

</style>
