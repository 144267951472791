<template>
    <component :is="baseComponent"
               :to="link.path ? link.path : '/'"
               :class="{active: isActive}"
               :target="link.target"
               :id="link.id"
               tag="li">
        <a v-if="isMenu"
           data-toggle="collapse"
           href="#"
           @click.prevent="collapseMenu">
            <i :class="link.icon"></i>
            <p>{{ link.name }}
                <b class="caret" :class="{rotated: !collapsed}"></b>
            </p>
        </a>
        <collapse-transition v-if="$slots.default || this.isMenu">
            <div v-show="!collapsed" class="collapse-menu">
                <ul class="nav">
                    <slot></slot>
                </ul>
            </div>
        </collapse-transition>
        <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
            <component
                    :to="{ path: link.path}"
                    replace
                    :is="elementType(link, false)"
                    :class="{active: isActive}"
                    @click.native.stop="onItemClick">
                <template v-if="addLink">
                    <span class="sidebar-mini-icon">{{ link.name.substring(0, 1) }}</span>
                    <span class="sidebar-normal">{{ link.name }}</span>
                </template>
                <template v-else>
                    <i :class="link.icon"></i>
                    <p>{{ link.name }}</p>
                </template>
            </component>
        </slot>
    </component>
</template>
<script>
import {CollapseTransition} from 'vue2-transitions';

export default {
    components: {
        CollapseTransition,
    },
    props: {
        menu: {
            type: Boolean,
            default: false,
            description: 'Whether item is a menu containing multiple items',
        },
        link: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    path: '',
                    children: [],
                    id: '',
                    parent: '',
                };
            },
            description: 'Link object',
        },
        parent: String,
        location: {
            type: Object,
            default: () => {
                return {
                    hash: '',
                    pathname: '',
                };
            },
        },

    },
    provide()
    {
        return {
            addLink: this.addChild,
            removeLink: this.removeChild,
        };
    },
    inject: {
        addLink: {default: null},
        removeLink: {default: null},
        autoClose: {default: false},
    },
    data()
    {
        return {
            children: [],
            collapsed: true,
        };
    },
    computed: {
        baseComponent()
        {
            return this.isMenu || this.link.isRoute ? 'li' : 'router-link';
        },
        isMenu()
        {
            return this.children.length > 0 || this.menu === true;
        },
        isActive()
        {
            //return false;
            let loc = this.location;
            return this.link.path === loc.hash;
        },
    },
    methods: {
        addChild(item)
        {
            const index = this.$slots.default.indexOf(item.$vnode);
            this.children.splice(index, 0, item);
        },
        removeChild(item)
        {
            const tabs = this.children;
            const index = tabs.indexOf(item);
            tabs.splice(index, 1);
        },
        elementType(link, isParent = true)
        {
            if (link.isRoute === false) {
                return isParent ? 'li' : 'router-link';
            } else {
                return 'router-link';
            }
        },
        collapseMenu()
        {
            this.collapsed = !this.collapsed;
        },
        onItemClick()
        {
            if (this.autoClose) {
                this.$sidebar.showSidebar = false;
            }

            if (screen.width <= 991) {
                this.$sidebar.toggleMinimize();
            }
        },
    },
    mounted()
    {
        if (this.addLink) {
            this.addLink(this);
        }
        if (this.link.collapsed !== undefined) {
            this.collapsed = this.link.collapsed;
        }
        if (this.isActive && this.isMenu) {
            this.collapsed = false;
        }
        /*Bus.$on('link.clicked', (path) => {
         console.log('link clicked', path);
         top.location.hash = '#' + path;
         this.location.hash = '#' + path;
         });*/
        Bus.$on(`expandMenu-${this.link.id}`, () => {
            this.collapsed = false;
        });
    },
    destroyed()
    {
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
        if (this.removeLink) {
            this.removeLink(this);
        }
    },
};
</script>
<style scoped>
.caret.rotated {
    transform: rotate(180deg);
}

.sidebar .nav li > a:hover,
.sidebar .nav li > a.router-link-active,
.sidebar .nav li > a.router-link-exact-active {
    color: #FFFFFF;
    display: block;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 1;
}

.sidebar .nav li > a {
    margin: 10px 15px 0;
    color: #FFFFFF;
    display: block;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 8px;
    opacity: .7;
}

</style>
