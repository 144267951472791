<template>

    <div>

        <div class="row">
            <div class="col-sm-12">
                <h3>Health Records</h3>
                <hr>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <h4>Summary</h4>
                <Summary ref="summary" :record-id="recordId"></Summary>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <h4>Record History</h4>
                <HistoryTable ref="historyTable" :record-id="recordId"></HistoryTable>
            </div>
        </div>

        <div v-if="!isReadOnly" class="row" >
            <div class="col-sm-12">

                <h4>Add Record</h4>
                <div class="card">
                    <div class="card-body">
                        <button type="button" @click="showExamModal" class="btn btn-primary"
                                v-bind:class="{'btn btn-success':examModalVisible}">Exam
                        </button>

                        <button type="button" @click="showSterlizationModal"
                                class="btn btn-primary" v-bind:class="{'btn btn-success':sterlizationModalVisible}">
                            Sterilization
                        </button>

                        <button type="button" @click="showTreatmentModal"
                                class="btn btn-primary" v-bind:class="{'btn btn-success':treatmentPostOpModalVisible}">
                            Treatment
                        </button>

                        <button type="button" @click="showSurgeryModal" class="btn btn-primary"
                                v-bind:class="{'btn btn-success':surgeryModalVisible}">
                            Surgery (Other)
                        </button>

                        <!--                        <button type="button" @click="showNoteModal" class="btn btn-primary"
                                                        v-bind:class="{'btn btn-success':noteModalVisible}">Note
                                                </button>-->

                        <button type="button" @click="showEuthModal"
                                class="btn btn-primary" v-bind:class="{'btn btn-success':euthanasiaModalVisible}">
                            Euthanasia
                        </button>

                        <button type="button" @click="showPostmortemModal"
                                class="btn btn-primary" v-bind:class="{'btn btn-success':postMortemModalVisible}">
                            Postmortem
                        </button>
                    </div>
                </div>

            </div>
        </div>

        <div class="row" ref="examModal" v-if="examModalVisible">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Exam</h5>
                        <hr>
                    </div>
                    <div class="card-body">
                        <Exam ref="examRef" :record-id="recordId"></Exam>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" ref="sterlizationModal" v-if="sterlizationModalVisible">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Sterlization</h5>
                        <hr>
                    </div>
                    <div class="card-body">
                        <Sterlization ref="sterilizationRef" :record-id="recordId"></Sterlization>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" ref="treatmentModal" v-if="treatmentPostOpModalVisible">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Treatment</h5>
                        <hr>
                    </div>
                    <div class="card-body">
                        <Treatment :record-id="recordId"></Treatment>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" ref="surgeryModal" v-if="surgeryModalVisible">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Surgery (Other)</h5>
                        <hr>
                    </div>
                    <div class="card-body">
                        <SurgeryOther :record-id="recordId"></SurgeryOther>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" ref="noteModal" v-if="noteModalVisible">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Note</h5>
                        <hr>
                    </div>
                    <div class="card-body">
                        <Note></Note>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" ref="euthModal" v-if="euthanasiaModalVisible">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Euthanasia</h5>
                        <hr>
                    </div>
                    <div class="card-body">
                        <Euthanasia :record-id="recordId"></Euthanasia>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" ref="postMortemModal" v-if="postMortemModalVisible">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Postmortem</h5>
                        <hr>
                    </div>
                    <div class="card-body">
                        <PostMortem :record-id="recordId"></PostMortem>
                    </div>
                </div>
            </div>
        </div>

        <div id="editExamDialog">
            <el-dialog width="80%" :title="dialogTitle" :visible.sync="editDialogVisible">
                <component v-if="editDialogVisible" :is="currentEditComponent"
                           v-bind="currentEditComponentProps"></component>
                <div class="d-flex justify-content-end">
                    <el-button
                        v-if="currentEditComponentProps && currentEditComponentProps.view !='Catching' && currentEditComponentProps.view !='Vaccination' && currentEditComponentProps.view !='Release'"
                        type="danger"
                        @click="deleteRecord()"
                    >
                        Delete
                    </el-button>
                </div>
            </el-dialog>
        </div>

    </div>

</template>

<script>

import Summary from "@/components/Template/Pm/Components/HealthRecord/Summary";
import HistoryTable from "@/components/Template/Pm/Components/HealthRecord/HistoryTable";

import Exam from "@/components/Template/Pm/Components/HealthRecord/Exam";
import Catching from "@/components/Template/Pm/Components/HealthRecord/Catching";
import Sterlization from "@/components/Template/Pm/Components/HealthRecord/Sterlization";
import Note from "@/components/Template/Pm/Components/HealthRecord/Note";
import SurgeryOther from "@/components/Template/Pm/Components/HealthRecord/SurgeryOther";
import Treatment from "@/components/Template/Pm/Components/HealthRecord/Treatment";
import Euthanasia from "@/components/Template/Pm/Components/HealthRecord/Euthanasia";
import PostMortem from "@/components/Template/Pm/Components/HealthRecord/PostMortem";
import VaccinationInfo from "@/components/Template/Pm/Components/HealthRecord/VaccinationInfo";

import {Dialog, Button} from 'element-ui';

export default {

    components: {
        Summary,
        Exam,
        Sterlization,
        Note,
        SurgeryOther,
        Treatment,
        Euthanasia,
        PostMortem,
        HistoryTable,
        [Dialog.name]: Dialog,
        Catching,
        VaccinationInfo,
        [Button.name]: Button,
    },
    data()
    {
        return {

            isReadOnly:false,

            dialogTitle: '',

            loading: true,

            currentEditComponent: null,
            currentEditComponentProps: null,

            editDialogVisible: false,

            examModalVisible: false,
            sterlizationModalVisible: false,
            treatmentPostOpModalVisible: false,
            treatmentOtherModalVisible: false,
            surgeryModalVisible: false,
            noteModalVisible: false,
            euthanasiaModalVisible: false,
            postMortemModalVisible: false,
        }
    },
    props: {
        recordId: {
            type: String,
        },
    },
    methods: {
        showExamModal()
        {
            let vm = this;

            if (!vm.$refs.summary.stillInCare) {
                alert('Outcome must be "Still In Care" to add new health record')
                return
            }

            vm.examModalVisible = !this.examModalVisible
            if (vm.examModalVisible) {
                setTimeout(function () {
                    vm.$refs.examModal.scrollIntoView({behavior: 'smooth'});
                    vm.$refs.examRef.isFemaleRecord = vm.$refs.summary.isFemaleRecord
                }, 200);
            }
        },
        showSterlizationModal()
        {
            let vm = this;

            if (!vm.$refs.summary.stillInCare) {
                alert('Outcome must me "Still In Care" to add new health record')
                return
            }

            if (vm.$refs.historyTable.isSterilizationDone) {
                vm.showAlert("Alert", "Already Sterilized, Only one record of this type is allowed per animal", false)
                return;
            }


            vm.sterlizationModalVisible = !this.sterlizationModalVisible
            if (vm.sterlizationModalVisible) {
                setTimeout(function () {
                    vm.$refs.sterlizationModal.scrollIntoView({behavior: 'smooth'});
                    vm.$refs.sterilizationRef.isFemaleRecord = vm.$refs.summary.isFemaleRecord
                }, 200);
            }
        },
        showTreatmentModal()
        {
            let vm = this;

            if (!vm.$refs.summary.stillInCare) {
                alert('Outcome must me "Still In Care" to add new health record')
                return
            }

            vm.treatmentPostOpModalVisible = !this.treatmentPostOpModalVisible
            if (vm.treatmentPostOpModalVisible) {
                setTimeout(function () {
                    vm.$refs.treatmentModal.scrollIntoView({behavior: 'smooth'});
                }, 200);
            }
        },
        showSurgeryModal()
        {
            let vm = this;

            if (!vm.$refs.summary.stillInCare) {
                alert('Outcome must me "Still In Care" to add new health record')
                return
            }

            vm.surgeryModalVisible = !this.surgeryModalVisible
            if (vm.surgeryModalVisible) {
                setTimeout(function () {
                    vm.$refs.surgeryModal.scrollIntoView({behavior: 'smooth'});
                }, 200);
            }
        },
        showNoteModal()
        {
            let vm = this;

            if (!vm.$refs.summary.stillInCare) {
                alert('Outcome must me "Still In Care" to add new health record')
                return
            }

            vm.noteModalVisible = !this.noteModalVisible
            if (vm.noteModalVisible) {
                setTimeout(function () {
                    vm.$refs.noteModal.scrollIntoView({behavior: 'smooth'});
                }, 200);
            }
        },
        showEuthModal()
        {
            let vm = this;

            if (!vm.$refs.summary.stillInCare) {
                alert('Outcome must me "Still In Care" to add new health record')
                return
            }

            if (vm.$refs.historyTable.isEuthanized) {
                vm.showAlert("Alert", "Only one record of this type is allowed per animal", false)
                return;
            }

            vm.euthanasiaModalVisible = !this.euthanasiaModalVisible
            if (vm.euthanasiaModalVisible) {
                setTimeout(function () {
                    vm.$refs.euthModal.scrollIntoView({behavior: 'smooth'});
                }, 200);
            }
        },
        showPostmortemModal()
        {
            let vm = this;

            if (!vm.$refs.summary.stillInCare) {
                alert('Outcome must me "Still In Care" to add new health record')
                return
            }

            if (vm.$refs.historyTable.isPostmortemDone) {
                vm.showAlert("Alert", "Only one record of this type is allowed per animal", false)
                return;
            }

            vm.postMortemModalVisible = !this.postMortemModalVisible
            if (vm.postMortemModalVisible) {
                setTimeout(function () {
                    vm.$refs.postMortemModal.scrollIntoView({behavior: 'smooth'});
                }, 200);
            }
        },
        showEditDialog(showFor, id)
        {
            let vm = this
            vm.currentEditComponentProps = {
                recordId: this.recordId,
                id: id,
                view: showFor
            };
            if (showFor == "Physical Examination") {
                vm.dialogTitle = "Physical Examination Edit Record"
                this.currentEditComponent = 'Exam'
            } else
                if (showFor == "Catching") {
                    vm.dialogTitle = "Catch-Release Details"
                    this.currentEditComponent = 'Catching'
                } else
                    if (showFor == "Release") {
                        vm.dialogTitle = "Catching Details"
                        this.currentEditComponent = 'Catching'
                    } else
                        if (showFor == "Sterilization") {
                            vm.dialogTitle = "Sterilization Details"
                            this.currentEditComponent = 'Sterlization'
                        } else
                            if (showFor == "Postmortem") {
                                vm.dialogTitle = "Postmortem Details"
                                this.currentEditComponent = 'PostMortem'
                            } else
                                if (showFor == "Euthanasia") {
                                    vm.dialogTitle = "Euthanasia Details"
                                    this.currentEditComponent = 'Euthanasia'
                                } else
                                    if (showFor == "Operation") {
                                        vm.dialogTitle = "Operation Details"
                                        this.currentEditComponent = 'SurgeryOther'
                                    } else
                                        if (showFor == "Treatment") {
                                            vm.dialogTitle = "Treatment Details"
                                            this.currentEditComponent = 'Treatment'
                                        } else
                                            if (showFor == "Release") {
                                                vm.dialogTitle = "Catch-Release Details"
                                                this.currentEditComponent = 'Catching'
                                            } else
                                                if (showFor == "Vaccination") {
                                                    vm.dialogTitle = "MV Vaccination Details"
                                                    this.currentEditComponent = 'VaccinationInfo'
                                                }
            this.editDialogVisible = true;
        },
        deleteRecord() {
            const vm = this;
            vm.editDialogVisible = false;
            vm.$refs.historyTable.handleDelete(null, vm.currentEditComponentProps);
        }
    },
    computed: {},
    mounted()
    {
        let vm = this
        vm.isReadOnly = false
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if(r.role_name == 'VIEWER'){
                vm.isReadOnly = true
            }
        })
        vm.$root.$on('healthRecordDeletedSuccessfully', () => {
            vm.$refs.summary.showStageAndOutcomeChangeDialog()
        })
    }

}

</script>

<style scoped>
    .pm-post-mortem, .pm-euthanasia, .pm-surgery-other, .pm-treatment, .pm-sterlization, .pm-exam{
        .col-sm-6, .col-sm-4{
        @media (max-width: 991px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 15px;
                padding-right: 15px ;
            }
        }
        .col-sm-4, .col-sm-6.text-right{
            @media (max-width: 991px) {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }
        .el-select{
            @media (max-width: 991px) {
                width: 100%;
            }
        }
    }
</style>
