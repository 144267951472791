<template>
    <div id="app">
        <maintenance-message></maintenance-message>
        <div :class="{'position-relative': maintenanceDetail.message_show}" v-if="isLoggedIn">
            <div v-if="isLoggedInAsAdmin">
                <router-view to="/admin"></router-view>
                <div>
                    <el-drawer
                        title="Notifications"
                        :visible.sync="drawer"
                        size="35%"
                        :direction="direction"
                        :before-close="handleClose">
                        <user-notifications></user-notifications>
                    </el-drawer>
                </div>
            </div>
            <div v-else>
                <router-view to="/platform"></router-view>
                <div>
                    <el-drawer
                        title="Notifications"
                        :visible.sync="drawer"
                        size="35%"
                        :direction="direction"
                        :before-close="handleClose">
                        <user-notifications></user-notifications>
                    </el-drawer>
                </div>
            </div>
        </div>
        <div :class="{'position-relative': maintenanceDetail.message_show}" v-else>
            <transition name="slide-fade" mode="out-in">
                <router-view to="/login"></router-view>
            </transition>
        </div>
        <span
            style="background-color:white; border-radius: 5px; padding: 2px; bottom: 0; right: 0; position: fixed; font-size: 12px; color: grey; z-index: 1999;">{{
                getAppVersion
            }}</span>

        <!-- The Modal -->
        <div id="image-modal" class="modal_full_img">
            <span class="close close-image-modal image-preview-close-btn">X</span>
            <img class="modal-content-full-img" id="image-modal-src">
        </div>

    </div>
</template>
<script>
import {Drawer, Button} from 'element-ui'
import UserNotifications from "@/components/Template/UserNotifications";
import MaintenanceMessage from "@/components/Template/Maintenance/MaintenanceMessage";
import {mapActions} from "vuex";


export default {
    name: 'App',
    data() {
        return {
            drawer: false,
            direction: 'rtl',
            isLoggedInAsAdmin: this.$store.getters.isSuperAdmin
        }
    },
    components: {
        UserNotifications,
        [Drawer.name]: Drawer,
        [Button.name]: Button,
        MaintenanceMessage
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        },
        getAppVersion() {
            return process.env.VUE_APP_VERSION;
        },
        maintenanceDetail() {
            if (!window.location.pathname.includes('maintenance') && this.$store.getters.getMaintenanceDetail.maintenance_mode) {
                this.$router.push('/maintenance');
            } else if (window.location.pathname.includes('maintenance') && !this.$store.getters.getMaintenanceDetail.maintenance_mode) {
                this.$router.push('/');
            }
            return this.$store.getters.getMaintenanceDetail
        }
    },
    methods: {
        ...mapActions(["getMaintenanceDetail"]),
        async logout() {

        },
        handleClose(done) {
            done();
        },
    },
    created() {
        window.Bus.$on('open-notification-panel', () => {
            this.drawer = true;
        });
        window.Bus.$on('api_error_422', (data) => {
            this.showAlert('Error', data, false);
        })
    }
}
</script>

<style>
.slide-fade-enter-active {
    transition: all .1s ease;
}

.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}

.image-preview-close-btn {
    height: 30px;
    width: 30px;
    opacity: 1;
    background-color: white;
    color: black;
    padding-top: 5px;
    padding-left: 8px;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 35px;
    cursor: pointer;
}
.position-relative {
    position: relative;
}
@media screen and (max-width: 991px) {
    .el-drawer.rtl{
        width: 80% !important;
        max-width: 500px;
    }
}
@media screen and (max-width: 450px) {
    .el-drawer.rtl{
        width: 90% !important;
    }
}

</style>
