<template>
    <div class="card"
         :data-background-color="color">

        <div class="card-header">
            <div class="" title="HSIApps" role="img" aria-label="HSIApps"
                 v-bind:style="{minWidth: '80px', height: '80px', backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat', backgroundImage : 'url('+getProjectIcon+')', backgroundSize: 'cover', borderRadius: '5px'}">
                <!--                <img :src="projectImage"/>-->
            </div>
            <div class="">
                <router-link v-if="userRole!='4' && userRole!='5' && userRole!='6' && userRole!='7' && userRole!='8'" :to="projectDetails" tag="p">
                    <p class="project-title">
                        {{ title }}
                    </p>
                </router-link>
                <a style="text-decoration: none; color: black;" href="https://play.google.com/store/apps/details?id=in.fusionbit.hsapps" target="_blank" v-else class="project-title">
                    {{ title }}
                    <br>
                    <br>
                </a>

                <p class="project-info-text">Created On: {{ createdOn }}</p>
                <p class="project-info-text">Role: {{ getUserRole }}</p>
                <p class="project-info-text">Last Active: {{ lastActive }}</p>
                <p class="project-info-text">Field Users: {{ fieldUsers }}</p>
            </div>
            <div class="project-properties">
<!--                <p style="text-align: center;" v-if="projectType=='MV'"><img :src="mvdlogo" class="logo_img"/></p>
                <p style="text-align: center;" v-if="projectType=='PM'"><img :src="dpmlogo" class="logo_img"/></p>-->
                <el-tooltip v-if="isOrgProject" :content="orgName"
                            :open-delay="300"
                            placement="right">
                    <p class="blue-tag" v-if="isOrgProject">ORG</p>
                </el-tooltip>
                <!--                <a href="#" class="green-tag">LIVE</a>-->
            </div>
        </div>

        <hr>
        <div class="project-card-footer">
            <p class="project-contributors">Animals Treated: {{ animalsTreated }}</p>
            <!--            <p class="project-contributors">Contributors: {{contributors}}</p>
                        <p class="project-viewers">Viewers: {{viewers}}</p>-->
        </div>
    </div>
</template>
<script>

import {Tooltip} from 'element-ui';
import dpmlogo from '../../../../assets/images/pm-logo.png';
import mvdlogo from '../../../../assets/images/mv-logo.png';

import mvLogo from '../../../../assets/images/mv_logo.png';
import pmLogo from '../../../../assets/images/pm_logo.png';
import hcLogo from '../../../../assets/images/hc_logo_med.png';

export default {
    components: {
        [Tooltip.name]: Tooltip
    },
    name: 'card',
    props: {
        projectId: {
            type: Number,
        },
        animalsTreated: {
            type: Number,
        },
        fieldUsers: {
            type: Number,
        },
        title: {
            type: String,
            description: 'Card title',
            default: 'Project Title'
        },
        createdOn: {
            type: String,
            default: 'N/A',
        },
        userRole: {
            default: '',
        },
        lastActive: {
            type: String,
            default: 'N/A',
        },
        contributors: {
            type: String,
            default: '0',
        },
        viewers: {
            type: String,
            default: '0',
        },
        color: {
            type: String,
            description: 'Card color (orange|yellow|red|green|blue|"")'
        },
        isOrgProject: {
            type: Boolean,
            default: false
        },
        orgName: {
            type: String,
        },
        projectImage: {
            type: String,
        },
        projectType: {
            type: String,
        },
        dpmlogo: {
            type: String,
            default: dpmlogo,
        },
        mvdlogo: {
            type: String,
            default: mvdlogo,
        },

    },
    computed: {
        getUserRole: function () {
            if (this.$props.userRole != 'Owner') {
                let rolesString = '';
                // for (let i = 0; i < this.$props.userRole.length; i++) {
                //     rolesString = rolesString + this.$props.userRole[i].role_name + ' | ';
                // }
                //return rolesString.substring(0, rolesString.length - 2);

                if(this.$props.userRole==1){
                    rolesString='Manager'
                }

                if(this.$props.userRole==2){
                    rolesString='Contributor'
                }

                if(this.$props.userRole==3){
                    rolesString='Viewer'
                }

                if(this.$props.userRole==4){
                    rolesString='Surveyor'
                }

                if(this.$props.userRole==5){
                    rolesString='Vaccination'
                }

                if(this.$props.userRole==6){
                    rolesString='Clinic'
                }

                if(this.$props.userRole==7){
                    rolesString='Catch-Release'
                }

                if(this.$props.userRole==8){
                    rolesString='Driver'
                }

                return rolesString
            }
            return this.$props.userRole;
        },
        projectDetails: function () {
            if (this.$props.projectType == 'MV') {
                return '/project/mv/' + this.projectId + '/overview';
            } else if (this.$props.projectType == 'PM') {
                return '/project/pm/' + this.projectId + '/overview';
            } else if (this.$props.projectType == 'HC') {
                return '/project/hc/' + this.projectId + '/overview';
            }
        },
        getProjectIcon: function () {

            if (this.$props.projectImage == null) {
                if (this.$props.projectType == 'MV') {
                    return mvLogo;
                } else
                    if (this.$props.projectType == 'PM') {
                        return pmLogo;
                    } else
                    if (this.$props.projectType == 'HC') {
                        return hcLogo;
                    } else {
                        return 'https://vuejs.org/images/logo.png';
                    }
            } else
                if (this.$props.projectImage == '') {
                    if (this.$props.projectType == 'MV') {
                        return mvLogo;
                    } else
                        if (this.$props.projectType == 'PM') {
                            return pmLogo;
                        }else
                        if (this.$props.projectType == 'HC') {
                            return hcLogo;
                        } else {
                            return 'https://vuejs.org/images/logo.png';
                        }
                } else {
                    if (this.$props.projectImage.includes("mv_logo.png")) {
                        return mvLogo;
                    } else
                        if (this.$props.projectImage.includes("pm_logo.png")) {
                            return pmLogo;
                        }else
                        if (this.$props.projectImage.includes("hc_logo_med.png")) {
                            return hcLogo;
                        } else {
                            return this.$props.projectImage;
                        }
                }


        }
    }
}
</script>


<style scoped>

p {
    cursor: default;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

.project-img {
    margin-right: 15px;
}

.project-details {
    margin-bottom: 15px;
}

.project-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    cursor: pointer;
}

.project-info-text {
    margin: 0;
}

.project-contributors {
    float: left;
}

.project-viewers {
    float: right;
}

.project-card-footer {
    margin-left: 15px;
    margin-right: 15px;
}

.project-properties {
    align-self: end;
}

/* Pradeep Start */
@media only screen  and (max-width: 320px) {
    .project-properties { /* css add for responsive image MVD and DPM  */
        margin-left: -15px;
    }
}

/* Pradeep End */
</style>
