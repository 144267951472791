<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row setMarginTop">
            <template v-if="$store.getters.getCurrentSelectedProject.banner_message!=null">
                <div v-if="$store.getters.getCurrentSelectedProject.banner_message!=''" class="col-sm-12">
                    <div class="card setShadow">
                        <div class="card-body" style="padding: 10px;">
                            <p style="margin-top: 15px; font-size: 15px; font-weight: bold;">{{
                                    $store.getters.getCurrentSelectedProject.banner_message
                                }}</p>
                        </div>
                    </div>
                </div>
            </template>
            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="card setShadow">
                    <div class="card-body" style="padding: 10px;">
                        <div
                            id="map"
                            style="height: 400px !important;width:100%;"
                        ></div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="card setShadow">
                    <div class="card-body" style="padding: 10px;">
                        <span>Location</span>
                        <h5>{{ projectOverview.project_location }}</h5>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Project Owner</p>
                                        <a :href="'mailto:' + projectOverview.ownerEmail + '?subject=' + projectOverview.name ">
                                            {{ projectOverview.project_owner }} </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Sterilized</p>
                                        {{ projectOverview.total_sterilized }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Avg. Sterilization/Day</p>
                                        {{ projectOverview.avg_sterilized_per_day }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Caught</p>
                                        {{ projectOverview.total_caught }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row setMarginTop">

            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Areas (Total/Assigned)</p>
                                        {{ projectOverview.total_areas }}/{{ projectOverview.total_assigned_areas }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                            <div class="card setShadow">
                                <div class="card-body">
                                    <div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="numbers">
                                                    <p>Areas Assigned</p>
                                                    {{ projectOverview.total_assigned_areas }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Active Catch-Release Users</p>
                                        {{ projectOverview.catch_release_assigned_users }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Non-Active Catch-Release Users</p>
                                        {{ projectOverview.catch_release_inactive_users }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Clinic Users</p>
                                        {{ projectOverview.total_clinic_users }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row setMarginTop">
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Male</p>
                                        {{ projectOverview.total_male_animals }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Female</p>
                                        {{ projectOverview.total_female_animals }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Young</p>
                                        {{ projectOverview.total_young_animals }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Avg Sterilization Time</p>
                                        <!-- 12M -->
                                        {{ projectOverview.avg_sterilization_time }} min
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Post op Complications</p>
                                        {{ projectOverview.post_op_complication_count }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Mortality Percentage</p>
                                        {{ projectOverview.mortality_percentage }}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row setMarginTop set_res_margin_top">
            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="row setTitleDiv">
                    <p></p>
                </div>
                <!-- Pradeep Start -->
                <div class="setMarginTop">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6">
                            <div class="card setShadow">
                                <div class="card-body" style="height: 435px;">
                                    <highcharts :options="PieChartOptions"></highcharts>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6">
                            <!--<line-mvd  charttitle='PM- Monthly Progress'></line-mvd>-->
                            <div class="card setShadow">
                                <div class="card-body" style="height: 435px;">
                                    <highcharts :options="LineChartOptions"></highcharts>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Pradeep End -->
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6"
                 v-if="StackedColumnChartOptions.series[0].data.length>0||
                                 StackedColumnChartOptions.series[1].data.length>0">
                <!--<line-mvd charttitle="Vaccinations by Month"></line-mvd>-->
                <div class="card setShadow">
                    <div
                        class="card-body"
                        style="height: 435px;"
                    >
                        <highcharts
                            :options="StackedColumnChartOptions"
                        ></highcharts>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row setTitleDiv">
                    <p>Field User Activity</p>
                </div>
            </div>
            <div class="col-sm-12">
                <dash-tables :columns="fieldUserActivityColumns"
                             :users="projectOverview.active_users_last_ten_days"></dash-tables>
            </div>
            <div class="col-sm-12" style="margin-top: 35px;">
                <hr>
                <p>*Days with no activity are not considered in this calculation</p>
            </div>
        </div>

    </div>
</template>

<script>
import DashboardCharts from "../../Template/Dashboardchart.vue";
import DashboardlineCharts from "../../Template/Dashboardlinechart.vue";
import DashboardpieCharts from "../../Template/DashboardpieCharts.vue";
import DashboardTab from "../../Template/Tables/DashboardTables";
import Mvd from "../../Template/Mvd.vue";
import mixin from '../../../mixins/GlobalMixin';
import VOffline from 'v-offline';

// Highcharts Start
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import exportingInitData from 'highcharts/modules/export-data';
import {API_KEY} from "../Maps/API_KEY";
import {MAP_STYLE} from "../Maps/MAP_STYLE";
import GoogleMapsLoader from "google-maps";
import {MarkerClusterer} from "@googlemaps/markerclusterer";

GoogleMapsLoader.KEY = API_KEY;

exportingInit(Highcharts);
exportingInitData(Highcharts);
// Highcharts End

export default {
    components: {
        'charts': DashboardCharts,
        'line-charts': DashboardlineCharts,
        'pie-charts': DashboardpieCharts,
        'dash-tables': DashboardTab,
        'line-mvd': Mvd,
        VOffline,
        highcharts: Chart
    },
    props: {
        projectId: {
            type: String
        },
    },
    mixins: [mixin],
    data() {
        return {
            map: null,
            center: '',
            project: {},
            errorMsg: null,
            callingApi: true,
            selectedUserRole: 1,
            //Pie Chart Start
            PieChartOptions: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    style: {
                        fontFamily: 'Montserrat,Helvetica Neue,Arial,sans-serif',
                        fontSize: "10px"
                    }
                },
                title: {
                    text: 'Caught Methods',
                    align: "left",
                    style: {
                        "color": "#252422",
                        "fontSize": "16px",
                        "fontWeight": "400",
                        "fontFamily": "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            style: {
                                fontWeight: "400"
                            }
                        }
                    }
                },
                series: [
                    {
                        name: "Caught Methods",
                        colorByPoint: true,
                        data: []
                    }
                ]
            },
            //Pie Chart End
            //Line Chart Start
            LineChartOptions: {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: 'Montserrat,Helvetica Neue,Arial,sans-serif',
                    }
                },
                title: {
                    text: 'Monthly Total Sterilizations',
                    align: "left",
                    style: {
                        "color": "#252422",
                        "fontSize": "16px",
                        "fontWeight": "400",
                        "fontFamily": "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                    labels: {
                        //rotation: -90,
                        formatter: function () {
                            const date = new Date();
                            const month = date.toLocaleString('default', {month: 'short'});
                            if (month == this.value) {
                                return '<span style="fill: red; font-size: 13px;">' + this.value + '</span>';
                            } else {
                                return this.value;
                            }
                        }
                    },
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: []
            },
            //Row Line Chart End,

            StackedColumnChartOptions: {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                title: {
                    text: 'Catching by Gender',
                    align: "left",
                    style: {
                        color: "#252422",
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                xAxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                    labels: {
                        //rotation: -90,
                        formatter: function () {
                            const date = new Date();
                            const month = date.toLocaleString('default', {month: 'short'});
                            if (month == this.value) {
                                return '<span style="fill: red; font-size: 13px;">' + this.value + '</span>';
                            } else {
                                return this.value;
                            }
                        }
                    },
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    floating: false,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || 'white',
                    shadow: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [],
            },

            projectOverview: {
                name: null,
                ownerEmail: null,
                project_location: '',
            },
            fieldUserActivityColumns: [
                {
                    prop: 'name',
                    label: 'Field User',
                    minWidth: 100,
                },
                {
                    prop: 'area',
                    label: 'Areas',
                    minWidth: 100,
                },
                {
                    prop: 'date',
                    label: 'Date',
                    minWidth: 100,
                },
            ],
            pmmarkers: [],

        }
    },
    methods: {

        mvPinpoint(location, map) {
            var vm = this;
            const marker = new google.maps.Marker({
                position: location,
            });

            vm.pmmarkers.push(marker);
            marker.setMap(map);
        },
        plotVaccinationPoints(map) {
            let vm = this;
            let pinPoints = vm.projectOverview.catching_pins;

            for (let i = 0; i < pinPoints.length; i++) {
                let latLng = pinPoints[i].split(",");
                latLng = {lat: +latLng[0], lng: +latLng[1]};
                const marker = new google.maps.Marker({
                    position: latLng,
                });
                vm.pmmarkers.push(marker);
                marker.setMap(map);
            }

            new MarkerClusterer({map: map, markers: vm.pmmarkers});


        },

        loadMap() {
            const vm = this;
            const KMLurls = vm.projectOverview.areas_kml;

            GoogleMapsLoader.load(google => {
                var map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 7,
                    center: vm.center,
                    styles: MAP_STYLE,
                    streetViewControl: false,
                    mapTypeControl: false
                });

                KMLurls.forEach(url => {
                    //console.log("url", url);
                    new google.maps.KmlLayer({
                        url: url,
                        suppressInfoWindows: true,
                        preserveViewport: true,
                        map: map,
                    });
                });


                vm.mvPinpoint(vm.center, map);
                vm.plotVaccinationPoints(map);

            });

        },

        setPieChart(data) {
            const pieData = [...data];
            // pieData.forEach(el => {
            //     el.color = '#' + el.color;
            // })
            this.PieChartOptions.series[0].data = pieData;
        },

        setStackedColumnChart(data) {
            this.StackedColumnChartOptions.series = [...data];
        },

        setLineChart(data) {
            this.LineChartOptions.series = [...data];
        },

        async getDashboardData() {
            let vm = this;
            try {
                await axios.get(
                    "/stats/pm/project/dashboard/" + vm.projectId
                ).then(response => {


                    vm.projectOverview = Object.assign(vm.projectOverview, response.data.response.data.stats);

                    if (vm.projectOverview.pie_chart_data.length > 0) {
                        vm.setPieChart(vm.projectOverview.pie_chart_data)
                    }
                    vm.setStackedColumnChart(vm.projectOverview.line_chart_data)

                    vm.setLineChart(vm.projectOverview.line_chart_data_2)

                    const latLngArr = vm.projectOverview.project_location_lat_lng.split(",");
                    vm.center = {lat: +latLngArr[0], lng: +latLngArr[1]};

                    setTimeout(function () {
                        vm.drawAreasFromKML()
                    }, 1000);

                }).catch(e => {
                    console.log(e)
                });
            } catch (error) {
                console.log(error)
            }
        }

    },
    mounted() {

        let vm = this;

        vm.getDashboardData().then(() => {
            vm.loadMap();
        })

        vm.$store.dispatch("setScreenName", "Overview");
        const {owner, name} = vm.$store.getters.getCurrentSelectedProject;
        vm.projectOverview = Object.assign(vm.projectOverview, {
            name,
            ownerEmail: owner.email
        });
    }
}
</script>

<style scoped>
td,
th {
    padding-right: 10px;
}

.card .numbers p {
    font-size: 14px !important;
    color: #9a9a9a !important;
}

.card .number {
    /* font-size: 14px !important; */
    color: #9a9a9a !important;
    font-size: 1.5em;
    text-align: right;
}

.card .number p {
    margin: 0;
    font-size: 13px;
}

.setShadow {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.card .card-body {
    text-align: center;
}

.containBytitile h4 {
    font-size: 1.2em;
    line-height: 1.45em;
}

.projectdetail-desktop {
    margin-left: 20px !important;
    cursor: pointer;
}

.Set-floatright {
    float: right;
}

.setTitleDiv p {
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.set_mvd_res_chart {
    height: 338px;
}

.img_marker_popup {
    /* max-width: 100% !important; */
    height: 70px;
    width: 70px;
}

.info_window_row {
    margin-right: -5px !important;
    margin-left: -5px !important;
}

@media only screen and (max-width: 640px) {
    .projectdetail-desktop {
        float: left;
        margin-top: -36px;
        margin-left: 247px;
        cursor: pointer;
    }
}

@media only screen and (max-device-width: 768px) {
    .set_mvd_res_chart {
        height: 50%;
    }
}

@media (min-width: 780px) {
    .mvd_details_class {
        height: 407px;
    }

    .set_res_activity_status {
        height: 600px;
    }
}

.set_b_font {
    font-size: 16px !important;
    color: #9a9a9a !important;
}

.set_b_font_card {
    font-size: 17px !important;
    color: #9a9a9a !important;
}

.text_left {
    text-align: left !important;
}

.set_numbers_div {
    padding: 5px 15px 10px;
    text-align: center;
}

.set_card_Operated {
    height: 124px;
}

.set_card_deaths {
    height: 150px;
}

@media (min-width: 780px) {
    .set_card_height_avg {
        height: 142px;
    }
}

.set_card_caught {
    margin-bottom: -27px;
}

.owner-name {
    font-size: 14px !important;
}
</style>
