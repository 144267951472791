<template>
    <div v-if="data" class="custom-multiselect">
        <b-form-input
            v-if="showSearchBar"
            placeholder="Search"
            v-model="searchQuery"
            @input="filterData"
        />
        <b-list-group-item>
            <div class="d-flex" style="justify-content: space-between !important">
                <b-form-checkbox v-model="checkAll" @change="toggleAll">
                    <strong> {{ title }} </strong>
                </b-form-checkbox>
                <span class="float-right"
                        ><strong>
                            {{ items.length }}/{{ data.length }}</strong
                        ></span
                    >
            </div>
        </b-list-group-item>
        <b-list-group class="multiselect-items" style="backgroundColor: #eee !important">
            <b-list-group-item
                v-for="(el, index) in dataClone"
                :key="index"
                class="mt-1 mb-1"
                :class="{ checked: checkIfChecked(el.id) }"
            >
                <b-form-checkbox
                    v-model="items"
                    :value="el.id"
                    @change="emitEvent"
                    :key="'checkbox' + el.id"
                >
                    {{ el.name }}
                </b-form-checkbox>
                <!-- If it bootstrap-vue component lags use default input tag because bootstrap-vue component also have his own event to fire -->
                <!-- <input type="checkbox" v-model="items" :value="el.id" @change="emitEvent"> {{ el.name }} -->
                <small> {{ el.email }} </small>
            </b-list-group-item>
            <template v-if="dataClone.length === 0 && showSearchBar">
                <b-list-group-item>
                    <div class="text-center">
                        <span> No records found</span>
                        <span> <b-icon-emoji-frown /> </span>
                    </div>
                </b-list-group-item>
            </template>
        </b-list-group>
    </div>
</template>

<script>
import {
    BFormInput,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BIconEmojiFrown
} from "bootstrap-vue";
export default {
    name: "HSAMultiSelect",
    components: {
        BFormInput,
        BListGroup,
        BListGroupItem,
        BFormCheckbox,
        BIconEmojiFrown
    },
    props: {
        value: {
            required: true
        },
        data: {
            required: true,
            type: Array
        },
        selectedDataIds: {
            type: Array
        },
        title: {
            required: true,
            type: String
        },
        checkedItemColor: {
            type: String,
            default: "lightblue"
        },
        showSearchBar: {
            type: Boolean,
            default: false
        },
        // height: {
        //     type: String,
        //     default: 300
        // }
    },
    model: {
        prop: "value",
        event: "change"
    },
    data() {
        return {
            items: [],
            searchQuery: "",
            dataClone: this.data,
            checkAll: null
        };
    },

    watch: {
        data(newVal, oldVal) {
            this.dataClone = newVal;
        },
        selectedDataIds(newVal, oldVal) {
            this.items = newVal;
            this.$emit("change", this.items);
        }
    },
    methods: {
        emitEvent(item) {
            this.$emit("change", this.items);
        },
        checkIfChecked(key) {
            return this.items.includes(key);
        },
        filterData() {
            const filterArray = this.data.filter(arr =>
                arr.name.toLowerCase().match(this.searchQuery.toLowerCase())
            );
            this.dataClone = filterArray;
        },
        toggleAll() {
            if (this.checkAll) {
                const temp = this.data.map(el => el.id);
                this.items = temp
            } else {
                this.items = []
            }
            this.$emit("change", this.items);
        }
    }
};
</script>

<style lang="scss" scoped>
.checked {
    background-color: lightblue;
}
.multiselect-items {
    max-height: 300px;
    overflow-y: auto;
}
</style>
