const routes = [
    {
        path: '/admin-login',
        name: 'adminLogin',
        component: require('./components/Template/SuperAdmin/SuperAdminLogin.vue').default,
        meta: {guest: true},
    },
    {
        path: '/admin',
        name: 'admin',
        component: require('./components/Template/SuperAdmin/SuperAdminApp.vue').default,
        meta: {requiresAuth: true},
        redirect: '/admin/dashboard',
        children: [
            {
                path: '/admin/dashboard',
                component: require('./components/Template/SuperAdmin/SuperAdminDashboard.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: '/admin/application',
                component: require('./components/Template/SuperAdmin/ApplicationTables.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: '/admin/account',
                component: require('./components/Template/SuperAdmin/AccountTables.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: '/admin/profile',
                component: require('./components/Template/SuperAdmin/SuperAdminProfile.vue').default,
                meta: {requiresAuth: true},
            }
        ]
    },
    {
        path: '/',
        redirect: '/login',
        component: require('./App.vue').default,
    },
    {
        path: '/login',
        name: 'login',
        component: require('./components/Template/AppLogin.vue').default,
        meta: {guest: true},
    },
    {
        path: '/logintojoin',
        name: 'loginToJoin',
        props: route => ({
            invitationCode: route.params.invitationCode,
            invitationEmail: route.params.invitationEmail,
            invitationType: route.params.invitationType
        }),
        component: require('./components/Template/AppLogin.vue').default,
        meta: {guest: true},
    },
    {
        path: '/user/verify/:confirmationCode',
        component: require('./components/Template/VerifyUser.vue').default,
        props: route => ({confirmationCode: route.params.confirmationCode}),
        meta: {guest: true},
    },
    {
        path: '/forgot',
        component: require('./components/Template/ForgotPassword.vue').default,
        props: route => ({confirmationCode: route.params.confirmationCode}),
        meta: {guest: true},
    },
    {
        path: '/delete/account',
        component: require('./components/Template/DeleteAccount.vue').default,
        meta: {guest: true},
    },
    {
        path: '/reset/:verificationCode',
        component: require('./components/Template/ResetPassword.vue').default,
        props: route => ({verificationCode: route.params.verificationCode}),
        meta: {guest: true},
    },
    {
        path: '/signup',
        name: 'signup',
        component: require('./components/Template/AppSignup.vue').default,
        meta: {guest: true},
    },
    {
        path: '/register',
        name: 'registerUser',
        component: require('./components/Template/AppSignup.vue').default,
        props: route => ({
            invitationCode: route.params.invitationCode,
            invitationEmail: route.params.invitationEmail,
            invitationType: route.params.invitationType
        }),
        meta: {guest: true},
    },
    {
        path: '/platform',
        name: 'platform',
        component: require('./components/Template/Platform.vue').default,
        meta: {requiresAuth: true},
        redirect: '/platform/projects',
        children: [
            {
                path: 'projects',
                component: require('./components/Template/Projects.vue').default,
                props: route => ({query: route.query}),
                meta: {requiresAuth: true},
            },
            {
                path: 'fieldteams',
                component: require('./components/Template/FieldTeams.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'inviteft',
                component: require('./components/Template/NewFieldTeam.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'newproject',
                component: require('./components/Template/NewProject.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'notifications',
                component: require('./components/Template/UserNotifications.vue').default,
                meta: {requiresAuth: true},
                name: 'userNotifications'
            },
            {
                path: 'user/profile',
                component: require('./components/Template/UserProfile.vue').default,
                meta: {requiresAuth: true},
                name: 'userProfile'
            },
            {
                path: 'user/organisation',
                component: require('./components/Template/CreateOrganisation.vue').default,
                meta: {requiresAuth: true},
                name: 'organisationSettings'
            },
        ]
    },
    {
        path: '/project/join/:invitationCode/:email',
        component: require('./components/Template/InvitationJoiningScreen.vue').default,
        props: route => ({
            invitationType: 'project',
            invitationCode: route.params.invitationCode,
            email: route.params.email
        }),
        meta: {requiresAuth: true},
    },
    {
        path: '/field_team/join/:invitationCode/:email',
        component: require('./components/Template/InvitationJoiningScreen.vue').default,
        props: route => ({
            invitationType: 'field_team',
            invitationCode: route.params.invitationCode,
            email: route.params.email
        }),
        meta: {requiresAuth: false},
    },
    {
        path: '/organisation/join/:invitationCode/:email',
        component: require('./components/Template/InvitationJoiningScreen.vue').default,
        props: route => ({
            invitationType: 'organisation',
            invitationCode: route.params.invitationCode,
            email: route.params.email
        }),
        meta: {requiresAuth: true},
    },
    {
        path: '/project/mv/:projectId',
        component: require('./components/Template/UIComponents/SidebarPlugin/MvSideBar.vue').default,
        props: route => ({projectId: route.params.projectId}),
        meta: {requiresAuth: true},
        children: [
            {
                path: 'overview',
                component: require('./components/Template/MvProjectOverview.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'areas',
                component: require('./components/Template/AddVaccinationArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'assignments',
                component: require('./components/Template/AssignAreaUsers.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'addfieldteam',
                component: require('./components/Template/NewFieldTeamFromProject.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'inviteftuser',
                component: require('./components/Template/InviteFieldTeamUser.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination',
                component: require('./components/Template/VaccinationRecord.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'survey',
                component: require('./components/Template/Surveys.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination_area/upload',
                name: 'uploadAreaKml',
                component: require('./components/Template/UploadVaccinationArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'area/details/:areaId',
                name: 'viewAreaDetails',
                component: require('./components/Template/AreaDetail.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/edit/:areaId',
                name: 'editArea',
                component: require('./components/Template/EditArea.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/vaccination/list/:areaId',
                name: 'areaVaccinationList',
                component: require('./components/Template/RecordDetailList.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/vaccination/mapview/:areaId',
                name: 'areaVaccinationMapview',
                component: require('./components/Template/ViewOnMap.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination/list',
                name: 'projectVaccinationList',
                component: require('./components/Template/RecordDetailList.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination/mapview',
                name: 'projectVaccinationMapview',
                props: route => ({projectLevel: true}),
                component: require('./components/Template/ViewOnMap.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination/outer',
                name: 'projectOuterVaccinations',
                component: require('./components/Template/OuterVaccinations.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination/outer/move/:areaId',
                name: 'projectMoveOuterVaccinations',
                component: require('./components/Template/MoveOuterVaccinationArea.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'archived',
                name: 'projectArchivedVaccinations',
                component: require('./components/Template/ArchivedRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/timeperiod',
                name: 'reportByTimeperiod',
                component: require('./components/Template/ReportByDates.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/location',
                name: 'reportByLocation',
                component: require('./components/Template/ReportByCity.vue').default,
                props: route => ({purpose: 'vaccination'}),
                meta: {requiresAuth: true},
            },
            {
                path: 'report/area',
                name: 'reportByLocation',
                component: require('./components/Template/ReportsByArea.vue').default,
                props: route => ({purpose: 'vaccination'}),
                meta: {requiresAuth: true},
            },
            {
                path: 'report/surveyLocation',
                name: 'reportByLocation',
                component: require('./components/Template/ReportByCity.vue').default,
                props: route => ({purpose: 'survey'}),
                meta: {requiresAuth: true},
            },
            {
                path: 'report/surveyArea',
                name: 'reportByLocation',
                component: require('./components/Template/ReportsByArea.vue').default,
                props: route => ({purpose: 'survey'}),
                meta: {requiresAuth: true},
            },
            {
                path: 'report/survey',
                name: 'reportBySurvey',
                component: require('./components/Template/ReportBySurvey.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/lactating',
                name: 'reportByLactating',
                component: require('./components/Template/ReportByLactating.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/young',
                name: 'reportByYoung',
                component: require('./components/Template/ReportByYoung.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'settings',
                name: 'projectSettings',
                component: require('./components/Template/ProjectSettings.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'area/survey/list/:areaId',
                name: 'areaSurveyList',
                component: require('./components/Template/SurveyRecords.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/survey/mapview/:areaId',
                name: 'areaSurveyMapview',
                component: require('./components/Template/SurveysViewOnMap.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'survey/list',
                name: 'projectSurveyList',
                component: require('./components/Template/SurveyRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'survey/mapview',
                name: 'projectSurveyMapview',
                component: require('./components/Template/SurveysViewOnMap.vue').default,
                props: route => ({projectLevel: true}),
                meta: {requiresAuth: true},
            },
        ]
    },
    {
        path: '/project/pm/:projectId',
        component: require('./components/Template/UIComponents/SidebarPlugin/PmSideBar.vue').default,
        props: route => ({projectId: route.params.projectId}),
        meta: {requiresAuth: true},
        children: [
            {
                path: 'overview',
                component: require('./components/Template/Pm/PmProjectOverview.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'areas',
                component: require('./components/Template/Pm/AddPmArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'assignments',
                component: require('./components/Template/Pm/UserAssignments.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'inviteftuser',
                component: require('./components/Template/Pm/PmInviteFieldTeamUser.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'area/upload',
                name: 'uploadAreaKmlPm',
                component: require('./components/Template/Pm/UploadAreaKml.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'area/details/:areaId',
                name: 'viewAreaDetailsPm',
                component: require('./components/Template/Pm/AreaDetailsPm.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/edit/:areaId',
                name: 'editAreaPm',
                component: require('./components/Template/Pm/EditAreaPm.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'operated',
                component: require('./components/Template/Pm/Operated.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'clear-for-sterilization',
                component: require('./components/Template/Pm/ClearForSterilization.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'awaitingEvaluation',
                component: require('./components/Template/Pm/AwaitingEvaluation.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'preOpHold',
                component: require('./components/Template/Pm/PreOpHoldRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'postOpHold',
                component: require('./components/Template/Pm/PostOpHoldRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'treatmentstatus',
                component: require('./components/Template/Pm/TreatmentStatus.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'readyforrelease',
                component: require('./components/Template/Pm/ReadyForReleaseRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'released',
                component: require('./components/Template/Pm/Released.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'all',
                component: require('./components/Template/Pm/ViewAllRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'fullprogramdata',
                component: require('./components/Template/Pm/FullProgramData.vue').default,
                meta: {requiresAuth: true},
            },
            // {
            //     path: 'archived',
            //     component: require('./components/Template/Pm/PmArchivedRecords.vue').default,
            //     meta: {requiresAuth: true},
            // },
            {
                path: 'archived',
                component: require('./components/Template/Pm/ArchivedRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'death',
                component: require('./components/Template/Pm/ViewDeathRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/city',
                name: 'reportByLocality',
                component: require('./components/Template/Pm/Reports/PmReportByCity.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/area',
                name: 'reportByLocality',
                component: require('./components/Template/Pm/Reports/PmAreaReport.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/map',
                name: 'reportByMap',
                component: require('./components/Template/Pm/Reports/DpmReportMap.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/microchip',
                name: 'reportByMicrochip',
                component: require('./components/Template/Pm/Reports/Microchip.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'settings',
                name: 'projectSettingsPm',
                component: require('./components/Template/Pm/ProjectSettings.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'record/details/:recordId',
                name: 'HealthRecordDetailsPm',
                component: require('./components/Template/Pm/HealthRecordDetailsPm.vue').default,
                props: route => ({recordId: route.params.recordId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'edit-bulk-records',
                name: 'EditBulkRecords',
                component: require('./components/Template/Pm/EditBulkRecords.vue').default,
                props: route => ({query: route.query}),
                meta: {requiresAuth: true},
            }
        ]
    },
    {
        path: '/project/hc/:projectId',
        component: require('./components/Template/UIComponents/SidebarPlugin/HcSideBar.vue').default,
        props: route => ({projectId: route.params.projectId}),
        meta: {requiresAuth: true},
        children: [
            {
                path: 'overview',
                component: require('./components/Template/HealthClinic/HCDashboard.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'users',
                component: require('./components/Template/HealthClinic/Users.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'records',
                component: require('./components/Template/HealthClinic/HcRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/users',
                name: 'hcReportByUsers',
                component: require('./components/Template/HealthClinic/Reports/HCUsersReport.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/map',
                name: 'hcReportByMap',
                component: require('./components/Template/HealthClinic/Reports/HCMapReport.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'settings',
                name: 'HcProjectSettings',
                component: require('./components/Template/HealthClinic/HCProjectSettings.vue').default,
                meta: {requiresAuth: true},
            }
        ]
    },
    {
        path: '/validate',
        name: 'validate',
        component: require('./components/Template/Pages/ValidateAndVerifyUrl.vue').default,
        props: route => ({query: route.query}),
    },
    {
        path: '/test-page',
        component: require('./components/Template/Pm/DatatablesTestPage.vue').default,
        meta: {guest: true},
    },
    {
        path: '/maintenance',
        component: require('./components/Template/Maintenance/Maintenance.vue').default,
    },

    {
        path: '/mv/project/:projectId/area/:areaId/add/vaccination',
        name: 'addVaccinationData',
        component: require('./components/Template/Mv/AddVaccinationData.vue').default,
        props: route => ({areaId: route.params.areaId, projectId: route.params.projectId}),
        meta: {requiresAuth: true},
    },

];
export default routes;

