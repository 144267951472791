import axios from 'axios';

class ApiService {
    archivePmRecords(payload) {
        return axios.post('archive/pm/catching/records', payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }
}

export default new ApiService();