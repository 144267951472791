<template>

    <div>
        <div class="wrapper wrapper-full-page">
            <div class="full-page login-page section-image">
                <div class="container">

                    <br>

                    <div class="col-lg-4 col-md-6 ml-auto mr-auto">

                        <card type="login" footer-line>
                            <h4 class="header text-center">HSIApps</h4>
                            <p>Delete your Account and its Data</p>
                            <hr>
                            <div class="form-group">
                                <fg-input type="email"
                                          required
                                          label="Email"
                                          name="email"
                                          v-validate="modelValidations.email"
                                          :error="getError('email')"
                                          v-model="model.email">
                                </fg-input>
                                <fg-input type="password"
                                          required
                                          label="Password"
                                          name="password"
                                          v-validate="modelValidations.password"
                                          :error="getError('password')"
                                          v-model="model.password">
                                </fg-input>
                            </div>
                            <div slot="footer">
                                <button v-on:click="requestAccountDeletion"
                                        class="btn mb-3 btn-round btn-block btn-info">
                                    Delete Account
                                </button>
                            </div>

                        </card>
                    </div>
                </div>
                <div class="full-page-background"></div>
            </div>
        </div>
    </div>

</template>

<script>

import {Button, Card} from "./UIComponents";
import Swal from "sweetalert2";

export default {
    components: {
        Card,
        [Button.name]: Button,
    },
    data() {
        return {
            model: {
                email: '',
                password: '',
            },
            modelValidations: {
                email: {
                    required: true,
                    email: true,
                },
                password: {
                    required: true,
                    min: 6,
                },
            },

            reset_email: '',
            form: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                acceptTerms: false,
                isEmailDisabled: false,
            },
            formValidations: {
                firstName: {
                    alpha_spaces: true,
                },
                lastName: {
                    alpha_spaces: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                acceptTerms: {
                    required: true
                },
                password: {
                    required: true,
                    min: 6,
                },
                phone: {
                    required: true,
                    min: 10,
                    max: 15,
                },
            }

        }
    },
    methods: {

        requestAccountDeletion() {

            let vm = this;

            vm.$validator.validateAll().then((result) => {
                if (result) {

                    new Swal({
                        title: 'Are you sure you want to delete you account and its data?',
                        text: `You won't be able to revert this!`,
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        cancelButtonClass: 'btn btn-danger btn-fill',
                        confirmButtonText: 'Delete Account',
                        buttonsStyling: false,
                    }).then(function (r) {
                        if (r.value) {

                            window.axios.post('user/request/account/delete', {
                                email: vm.model.email,
                                password: vm.model.password
                            })
                                .then(response => {
                                    if (response.data.response.error.error_code == 0) {
                                        vm.showAlert('Success', response.data.response.error.msg, true, function (confirm) {
                                            vm.$router.push({name: 'login'});
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                    vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                                })
                        }

                    });


                }
                return false;
            }).catch(() => {
                return false
            });

        }

    },


}

</script>

<style scoped>

</style>
