<template>
  <div class="project-sharing">
    <div class="d-flex justify-content-between align-items-center title-btn-center">
      <b>Project Sharing (Administrators)</b>
      <button
        type="button"
        class="btn btn-default"
        style="background-color: #2a9055 !important;"
        @click="openInviteModal"
      >
        Invite user
      </button>
    </div>
    <div v-if="projects.length">
      <el-collapse accordion>
        <el-collapse-item
          v-for="(project, index) of projects"
          v-bind:key="project.id"
          :title="project.name"
          :name="index"
          class="mb-2"
        >
          <div v-if="project.assigned_users.length" class="p-3 tab-scorlling">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">First name</th>
                  <th scope="col">Last name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Note</th>
                  <th scope="col">Role</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(assigned_user, index) of project.assigned_users"
                  v-bind:key="index"
                >
                  <td>{{ assigned_user.first_name }}</td>
                  <td>{{ assigned_user.last_name }}</td>
                  <td>{{ assigned_user.email }}</td>
                  <td>{{ assigned_user.note }}</td>
                  <td>
                    {{ assigned_user.role }}
                    (<i
                      class="el-icon-close hover" style="color: red;"

                      @click="removeUserConfirmation(assigned_user, project)"
                    ></i
                    >)
                  </td>
                  <td>{{ assigned_user.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="!project.assigned_users.length" class="p-3">
            No assigned users
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-else>No projects</div>

      <div class="d-flex justify-content-between align-items-center title-btn-center">
          <b>Organisation Sharing</b>
          <button
              type="button"
              class="btn btn-default"
              style="background-color: #2a9055 !important;"
              @click="openOrgInviteModal"
          >
              Invite user
          </button>
      </div>

    <el-dialog
      title="Invite user"
      :visible.sync="inviteDialogVisible"
      class="invite-user-dialog"
      width="60%"
    >
      <div>
        <el-form
          ref="inviteUserForm"
          :model="inviteUserModel"
          label-width="120px"
          label-position="top"
        >
          <div class="row">
            <div class="col">
              <el-form-item
                label="First name"
                prop="first_name"
                :rules="[{ required: true, message: 'First name is required' }]"
              >
                <el-input v-model="inviteUserModel.first_name"> </el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item
                label="Last name"
                prop="last_name"
                :rules="[{ required: true, message: 'Last name is required' }]"
              >
                <el-input v-model="inviteUserModel.last_name"> </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-form-item
                label="Email"
                prop="email"
                :rules="[{ required: true, message: 'Email name is required' }]"
              >
                <el-input v-model="inviteUserModel.email" @blur="checkEmail()">
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <el-form-item
                label="Select project"
                prop="project_id"
                :rules="[{ required: true, message: 'Please select project' }]"
              >
                <el-select
                  v-model="inviteUserModel.project_id"
                  placeholder="Select project"
                  filterable
                >
                  <el-option
                    v-for="project in inviteProjectNames"
                    :key="project.id"
                    :label="project.name"
                    :value="project.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-7">
              <el-form-item
                label="Select role"
                prop="user_role"
                :rules="[{ required: true, message: 'Please select role' }]"
              >
                <el-radio-group v-model="inviteUserModel.user_role">
                  <el-radio
                    v-for="role in roles"
                    :key="role.id"
                    :label="role.id"
                    >{{ role.role_name }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>
          </div>

          <el-form-item label="Note" prop="note">
            <el-input v-model="inviteUserModel.note"> </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="inviteDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submitInviteUser">Confirm</el-button>
      </span>
    </el-dialog>

      <el-dialog
          title="Invite user on Organisation"
          :visible.sync="inviteOrgDialogVisible"
          class="invite-user-dialog"
          width="40%"
      >
          <div>
              <el-form
                  ref="inviteUserForm"
                  :model="inviteUserOrgModel"
                  label-width="120px"
                  label-position="top"
              >
                  <div class="row">
                      <div class="col">
                          <el-form-item
                              label="First name"
                              prop="first_name"
                              :rules="[{ required: true, message: 'First name is required' }]"
                          >
                              <el-input v-model="inviteUserOrgModel.first_name"> </el-input>
                          </el-form-item>
                      </div>
                      <div class="col">
                          <el-form-item
                              label="Last name"
                              prop="last_name"
                              :rules="[{ required: true, message: 'Last name is required' }]"
                          >
                              <el-input v-model="inviteUserOrgModel.last_name"> </el-input>
                          </el-form-item>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col">
                          <el-form-item
                              label="Email"
                              prop="email"
                              :rules="[{ required: true, message: 'Email name is required' }]"
                          >
                              <el-input v-model="inviteUserOrgModel.email" @blur="checkEmail()">
                              </el-input>
                          </el-form-item>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12">
                          <el-form-item
                              label="Select role"
                              prop="user_role"
                              :rules="[{ required: true, message: 'Please select role' }]"
                          >
                              <el-radio-group v-model="inviteUserOrgModel.user_role">
                                  <el-radio
                                      v-for="role in roles"
                                      :key="role.id"
                                      :label="role.id"
                                  >{{ role.role_name }}</el-radio
                                  >
                              </el-radio-group>
                          </el-form-item>
                      </div>
                      <div class="col-12">
                          <p v-if="inviteUserOrgModel.user_role==1">Can manage and modify all projects under your organisation and generate reports.</p>
                          <p v-if="inviteUserOrgModel.user_role==3">Can only view your Organisations dashboard and generate reports. Cannot see individual projects inside Organisations. This role is recommended to share details with Donors</p>
                      </div>
                  </div>

              </el-form>



          </div>
          <span slot="footer" class="dialog-footer">
        <el-button @click="inviteOrgDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="">Confirm</el-button>
      </span>
      </el-dialog>


  </div>
</template>

<script>
import {
  Collapse,
  CollapseItem,
  Dialog,
  Button,
  Select,
  Option,
  Input,
  Form,
  FormItem,
  Radio,
  RadioGroup,
} from "element-ui";
import { mapGetters } from "vuex";
import Swal from "sweetalert2"

export default {
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  data() {
    return {
      projects: [],
      inviteProjectNames: [],
      roles: [],
      inviteDialogVisible: false,
        inviteOrgDialogVisible: false,
      inviteUserModel: {
        organisation_id: "",
        first_name: "",
        last_name: "",
        email: "",
        user_id: null,
        project_id: "",
        user_role: "",
        note: "",
      },
        inviteUserOrgModel: {
            organisation_id: "",
            first_name: "",
            last_name: "",
            email: "",
            user_id: null,
            user_role: "",
        },
    };
  },
  methods: {
    getProjectList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axios.get("/organisation/shared/project/list").then(
        (response) => {
          this.projects = response.data.response.data.projects;
          loading.close();
        },
        (error) => {
          loading.close();
        }
      );
    },
    openInviteModal() {
      this.resetInviteUserModel();
      this.inviteUserModel.organisation_id = this.organisationId;
      this.inviteDialogVisible = true;
      if (!this.inviteProjectNames.length) {
        this.getProjectNames();
      }

      if (!this.roles.length) {
        this.getUserRoles();
      }
    },

      openOrgInviteModal() {
          this.resetInviteUserOrgModel();
          this.inviteUserOrgModel.organisation_id = this.organisationId;
          this.inviteOrgDialogVisible = true;

          if (!this.roles.length) {
              this.getUserRoles();
          }
      },
    getProjectNames() {
      axios.get("/project/names").then((response) => {
        this.inviteProjectNames = response.data.response.data.project_names;
      });
    },
    getUserRoles() {
      axios.get("/user/roles").then((response) => {
        if (response && response.data.response.error.error_code === 0) {
          const roles = response.data.response.data.roles
            .map((role) => {
              if (role.type === "Administrative") {
                return role;
              }
            })
            .filter(Boolean);
          this.roles = roles;
        }
      });
    },
    checkEmail() {
      if (this.inviteUserModel.email) {
        axios
          .get(
            `/user/find/email?email=${encodeURIComponent(
              this.inviteUserModel.email
            )}`
          )
          .then((response) => {
            if (response && response.data.response.data) {
              this.inviteUserModel.user_id =
                response.data.response.data.user.id;
            }
          });
      }
    },
    submitInviteUser() {
      const vm = this;
      vm.$refs["inviteUserForm"].validate((valid) => {
        if (valid) {
          const loading = vm.$loading({
            lock: true,
            text: "Inviting user...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          axios
            .put("/organisation/share/project", vm.inviteUserModel)
            .then((response) => {
              if (response.data.response.error.error_code == 0) {
                loading.close();
                vm.inviteDialogVisible = false;
                vm.showAlert("Success", response.data.response.error.msg, true);
                vm.getProjectList();
              }
            })
            .catch((error) => {
              loading.close();
              vm.inviteDialogVisible = false;
              vm.showAlert(
                "Error",
                error.response.data.response.error.error_msg,
                false
              );
            });
        }
      });
    },
    resetInviteUserModel() {
      this.inviteUserModel = {
        organisation_id: "",
        first_name: "",
        last_name: "",
        email: "",
        user_id: null,
        project_id: "",
        user_role: "",
        note: "",
      };
    },
      resetInviteUserOrgModel() {
          this.inviteUserModel = {
              organisation_id: "",
              first_name: "",
              last_name: "",
              email: "",
              user_id: null,
              user_role: "",
          };
      },
    removeUserConfirmation(user, project) {
      const body = {
        email: user.email,
        organisation_id: project.organisation_id,
        project_id: project.id,
        user_role: user.role_id,
      };
      let apiUrl = "/organisation/project/remove/user";
      new Swal({
        title: "Are you sure?",
        text: `You want to remove ${user.email}`,
        type: "warning",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger btn-fill",
        cancelButtonClass: "btn btn-success btn-fill",
        confirmButtonText: "yes",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (user.type === "invited_user") {
            body["role_id"] = user.role_id;
            apiUrl = "/organisation/project/invitation/delete";
          }
          this.removeUser(body, apiUrl);
        }
      });
    },
    removeUser(body, apiUrl) {
      const vm = this;
      const loading = vm.$loading({
        lock: true,
        text: "Removing user...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axios
        .delete(apiUrl, { data: { ...body } })
        .then((response) => {
          loading.close();
          if (response.data.response.error.error_code === 0) {
            vm.showAlert("Success", response.data.response.error.msg, true);
            vm.getProjectList();
          } else {
            vm.showAlert(
              "Error",
              response.data.response.error.error_msg,
              false
            );
          }
        })
        .catch((error) => {
          loading.close();
          vm.inviteDialogVisible = false;
          vm.showAlert(
            "Error",
            error.response.data.response.error.error_msg,
            false
          );
        });
    },
  },
  computed: {
    ...mapGetters("user", ["organisation"]),
  },
  created() {
    this.organisationId = this.organisation.id;
    this.getProjectList();
  },
};
</script>
<style lang="scss" scoped>
.el-icon-close:hover {
  cursor: pointer;
}
@media (max-width: 767px) {
   .tab-scorlling{
    overflow-x: auto;
   }
}
@media (max-width: 450px) {
  .title-btn-center{
    flex-flow: wrap;
    justify-content: center !important;
  }
  .title-btn-center b{margin-right: 10px;}
}
</style>
