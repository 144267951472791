<template>

    <div v-loading="isLoadingData" class="pm-euthanasia">
        <el-form ref="euthanasiaForm" :model="euthModal" label-width="80px" label-position="top">
            <div class="row">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-6">
                            <el-form-item
                                label="Select Date Time"
                                prop="dateTime"
                                :rules="[
                                    { required: true, message: 'Please select date time', trigger: 'change' }
                                ]">
                                <el-date-picker
                                    v-model="euthModal.dateTime"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="Select date and time">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="col-lg-6">
                            <el-form-item
                                label="Select Veterinarian"
                                prop="vet"
                                :rules="[
                                    { required: true, message: 'Please select veterinarian', trigger: 'change' }
                                ]">
                                <el-select v-model="euthModal.vet" placeholder="Select">
                                    <el-option
                                        v-for="item in list.vets"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item
                        label="Reason"
                        prop="reason"
                        :rules="[
                            { required: true, message: 'Please select reason', trigger: 'change' }
                        ]">
                        <el-select v-model="euthModal.reason" placeholder="Select">
                            <el-option
                                v-for="item in list.reasons"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <template v-if="euthModal.treatmentType==='Other'">
                        <el-form-item label="Treatment Note" prop="reasonOtherNote">
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="Please input"
                                v-model="euthModal.reasonOtherNote">
                            </el-input>
                        </el-form-item>
                    </template>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-6">
                            <el-form-item
                                label="Method"
                                prop="method"
                                :rules="[
                                    { required: true, message: 'Method is required', trigger: 'change' }
                                ]">
                                <el-input
                                    type="text"
                                    placeholder="Please input"
                                    v-model="euthModal.method">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-6">
                            <el-form-item
                                label="Drugs Given"
                                prop="drugsGiven"
                                :rules="[
                                    { required: true, message: 'Please select drug given', trigger: 'change' }
                                ]">
                                <el-select v-model="euthModal.drugsGiven" multiple placeholder="Multi Select">
                                    <el-option
                                        v-for="item in list.drugs"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item label="Note" prop="note">
                        <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="Please input"
                                v-model="euthModal.note">
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-right">
                    <button type="button" class="btn btn-success" :disabled="isSavingData||isLoadingData"
                            @click="saveOrUpdateEuthanasia">{{ getButtonLabel }}
                    </button>
                </div>
            </div>
        </el-form>
    </div>

</template>

<script>

import {DatePicker, Select, Option, Input, Form, FormItem} from 'element-ui';


export default {
    components: {
        [DatePicker.name]: DatePicker,
        [Select.name]: Select,
        [Option.name]: Option,
        [Input.name]: Input,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    data()
    {
        return {

            isLoadingData: false,
            isSavingData: false,

            euthModal: {
                dateTime: '',
                vet: '',
                reason: '',
                reasonOtherNote: '',
                method: '',
                drugsGiven: [],
                note: '',
            },

            list: {
                vets: [
                    {
                        label: 'Shrikant',
                        value: 'Shrikant',
                    },
                ],

                reasons: [
                    {
                        label: 'Rabies Suspected',
                        value: 'Rabies Suspected',
                    },
                    {
                        label: 'Rabies Suspected & Sample Taken',
                        value: 'Rabies Suspected & Sample Taken',
                    }
                ],

                drugs: [
                    {
                        label: 'Meloxicam',
                        value: 'Meloxicam',
                    },
                    {
                        label: 'Prednisolone',
                        value: 'Prednisolone',
                    },
                ]
            }

        }
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        recordId: {
            type: String,
            required: true
        }
    },
    methods: {
        init()
        {
            if (this.id != null) {
                this.getEuthanasiaDetails();
            }
            this.getVets();
        },
        getEuthanasiaDetails()
        {
            let vm = this;

            vm.isLoadingData = true

            axios.get('/euthanasia/details/' + vm.id)
                .then(response => {
                    vm.isLoadingData = false
                    if (response.data.response.error.error_code == 0) {
                        let record = response.data.response.data.euthanasia;
                        vm.euthModal.dateTime = record.date_time
                        vm.euthModal.vet = record.vet_name
                        vm.euthModal.reason = record.reason
                        vm.euthModal.note = record.note
                        vm.euthModal.method = record.method
                        vm.euthModal.drugsGiven = JSON.parse(record.drugs_given)
                        vm.euthModal.reasonOtherNote = record.reason_other_note
                    }
                })
                .catch(e => {
                    vm.isLoadingData = false
                    console.log(e)
                })
        },
        saveOrUpdateEuthanasia()
        {
            this.$refs['euthanasiaForm'].validate((valid) => {
                if (valid) {
                    let vm = this;
        
                    vm.isSavingData = true
        
                    let url = "";
        
                    if (vm.id != null) {
                        if (vm.id > 0) {
                            url = '/euthanasia/update/' + vm.recordId + '/' + vm.id;
                        } else {
                            url = '/euthanasia/add/' + vm.recordId;
                        }
                    } else {
                        url = '/euthanasia/add/' + vm.recordId;
                    }
        
                    axios.post(url, {
                        date_time: vm.euthModal.dateTime,
                        vet: vm.euthModal.vet,
                        reason: vm.euthModal.reason,
                        note: vm.euthModal.note,
                        method_used: vm.euthModal.method,
                        drugs_given: vm.euthModal.drugsGiven,
                        reason_other_note: vm.euthModal.reasonOtherNote,
                    }).then(response => {
                        vm.isSavingData = false
                        if (response.data.response.error.error_code == 0) {
        
                            this.$root.$emit('HistoryTableUpdateRecords') //like this
                            if (vm.id > 0) {
                                vm.$message({
                                    message: 'Euthanasia Details Updated Successfully.',
                                    type: 'success'
                                });
                            } else {
                                vm.clearModalData()
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        }
                    }).catch(e => {
                        vm.isSavingData = false
                        console.log(e);
                    });
                } else {
                    return false;
                }
            });
        },
        clearModalData()
        {
            this.euthModal.dateTime = ''
            this.euthModal.vet = ''
            this.euthModal.reason = ''
            this.euthModal.note = ''
            this.euthModal.method = ''
            this.euthModal.drugsGiven = []
            this.euthModal.reasonOtherNote = ''
            this.$refs['euthanasiaForm'].resetFields();
        },
        getVets()
        {
            var vm = this;
            axios.get("/organisation/get/vets", {
                params: {
                    organisation_id: vm.$store.getters.getCurrentSelectedProject.organisation_id,
                },
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.list.vets = []
                    var v = response.data.response.data.vets;
                    for (let i = 0; i < v.length; i++) {
                        vm.list.vets.push({
                            value: v[i].first_name + ' ' + v[i].last_name,
                            label: v[i].first_name + ' ' + v[i].last_name
                        });
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        },
    },
    computed: {
        getButtonLabel: function () {
            if (this.id != null) {
                if (this.id > 0) {
                    return "UPDATE"
                } else {
                    return "SAVE"
                }
            } else {
                return "SAVE"
            }
        }
    },
    mounted()
    {
        this.init()
    }

}

</script>

<style scoped>

</style>
