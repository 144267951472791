const staticData = {
    dehydrationLevels: [
        {
            value: 'Normal',
            label: 'Normal'
        }, {
            value: 'Mid',
            label: 'Mid'
        },
        {
            value: 'Moderate',
            label: 'Moderate'
        }, {
            value: 'Severe',
            label: 'Severe'
        },
    ],
    bodyConditions: [
        {
            value: 'BCS1',
            label: 'BCS1'
        }, {
            value: 'BCS2',
            label: 'BCS2'
        },
        {
            value: 'BCS3',
            label: 'BCS3'
        },
        {
            value: 'BCS4',
            label: 'BCS4'
        },
        {
            value: 'BCS5',
            label: 'BCS5'
        },
    ],
    mucusMembraneList: [
        {
            value: 'Moist and Pink',
            label: 'Moist and Pink'
        },
        {
            value: 'Dry and Pale',
            label: 'Dry and Pale'
        },
        {
            value: 'Sticky and Pale',
            label: 'Sticky and Pale'
        },
    ],
    mentationList: [
        {
            value: 'Bright, Alert and Responsive',
            label: 'Bright, Alert and Responsive'
        },
        {
            value: 'Depressed',
            label: 'Depressed'
        },
        {
            value: 'Stuporous',
            label: 'Stuporous'
        },
        {
            value: 'Comatose',
            label: 'Comatose'
        }
    ],
    reproductiveStatusList: [
        {
            value: 'In Heat',
            label: 'In Heat'
        },
        {
            value: 'Lactating',
            label: 'Lactating'
        },
    ],
    conditionsList: [
        {
            value: 'TVT',
            label: 'TVT'
        },
        {
            value: 'Mange',
            label: 'Mange'
        },
        {
            value: 'Maggot Wound',
            label: 'Maggot Wound'
        },
        {
            value: 'Accident Wound',
            label: 'Accident Wound'
        },
        {
            value: 'Fracture',
            label: 'Fracture'
        },
        {
            value: 'Eye Infection',
            label: 'Eye Infection'
        },
        {
            value: 'Ear Infection',
            label: 'Ear Infection'
        },
        {
            value: 'Ectoparasites',
            label: 'Ectoparasites'
        },
        {
            value: 'Wound',
            label: 'Wound'
        },
        {
            value: 'Diarrhea ',
            label: 'Diarrhea '
        },
        {
            value: -1,
            label: 'Other'
        },
    ],
    topUps: [
        {label: '0', value: '0'}, {label: '1', value: '1'}, {label: '2', value: '2'}, {
            label: '3',
            value: '3'
        }, {
            label: '4',
            value: '4'
        }, {label: '5', value: '5'}, {label: '6', value: '6'}, {label: '7', value: '7'}, {
            label: '8',
            value: '8'
        }, {label: '9', value: '9'}, {label: '10', value: '10'}, {label: '11', value: '11'}, {
            label: '12',
            value: '12'
        }, {label: '13', value: '13'}, {label: '14', value: '14'}, {label: '15', value: '15'},
    ],
    pregnancyStages: [
        {
            label: 'Early',
            value: 'Early'
        },
        {
            label: 'Mid',
            value: 'Mid'
        },
        {
            label: 'Advance',
            value: 'Advance'
        },
    ],
    sterlizationConditions: [
        {
            value: 'Pyometra',
            label: 'Pyometra'
        },
        {
            value: 'Hydrometra',
            label: 'Hydrometra'
        },
        {
            value: 'Ascites',
            label: 'Ascites'
        },
        {
            value: 'Cystic Ovaries',
            label: 'Cystic Ovaries'
        },
        {
            value: 'Hernia',
            label: 'Hernia'
        },
        {
            value: 'Endometriosis',
            label: 'Endometriosis'
        }
    ],
    complications: [
        {
            label: 'Bleeding',
            value: 'Bleeding'
        },
        {
            label: 'Increased Incision',
            value: 'Increased Incision'
        },
        {
            label: 'Other',
            value: 'Other'
        },
    ],
    sterlizationResults: [
        {
            label: 'Sterilization Successful',
            value: 'Sterilization Successful'
        },
        {
            label: 'Already Sterilized',
            value: 'Already Sterilized'
        },
        {
            label: 'Sterilization Aborted',
            value: 'Sterilization Aborted'
        },
    ],
    nextStepList: [
        {
            value: 'Cleared for Sterilization',
            label: 'Cleared for Sterilization'
        },
        {
            value: 'Put on Pre-op hold',
            label: 'Put on Pre-op hold'
        },
        {
            value: 'Put on Post-op hold',
            label: 'Put on Post-op hold'
        },
        {
            value: 'Sterilization Aborted',
            label: 'Sterilization Aborted'
        },
        {
            value: 'Cleared for Release',
            label: 'Cleared for Release'
        },
    ]
}

export default staticData;
