<template>
    <div class="card img-left card_pos">
        <div class="card-header row">

            <div class="col-xs-12 col-sm-12 col-md-12">

                <div class="area-details">
                    <div class="row">
                        <span class="area-title col-sm-12">
                            {{ title }}
                        </span>
                    </div>
                    <hr>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Created On:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ formatDate(createdOn) }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Country:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ country }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>State/Province:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ areaState }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>City/District/Village:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ locality }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Animals Caught:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ animalsCaught }}</span>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Sterilized:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ animalsSterilized }}</span>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Cleared For Release:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ clearedForRelease }}</span>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-sm-6 text-right">
                            <span>Released:</span>
                        </div>
                        <div class="col-sm-6">
                            <span>{{ animalsReleased }}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    name: "card",
    props: {
        areaId: {
            type: Number
        },
        title: {
            type: String,
            description: "Card title",
            default: "area Title"
        },
        createdOn: {
            type: String,
            default: "N/A"
        },
        country: {
            type: String,
            default: "N/A"
        },
        areaState: {
            type: String,
            default: "N/A"
        },
        locality: {
            type: String,
            default: "0"
        },
        fieldUsers: {
            type: [String, Number],
            default: "0"
        },
        submitter_name: {
            type: String,
            default: ""
        },
        areaStatus: {
            type: Number,
            default: 0
        },
        statusColor: {
            type: String,
            default: "black"
        },
        surveyStatus: {
            type: Object
        },
        vaccinationStatus: {
            type: Object
        },
        animalsCaught: {
            type: Number,
            default: 0
        },
        animalsSterilized: {
            type: Number,
            default: 0
        },
        clearedForRelease: {
            type: Number,
            default: 0
        },
        animalsReleased: {
            type: Number,
            default: 0
        }
    },
    methods: {
        formatDate(date)
        {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
    },
    computed: {
        areaDetails: function () {
            return "area/details/" + this.areaId;
        }
    }
};
</script>

<style scoped>

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.area-img {
    margin-right: 15px;
    width: 80px;
}

.area-details {
    margin-bottom: 15px;
}

.area-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    color: #51cbce;
}

.area-info-text {
    margin: 0;
}

.area-contributors {
    text-align: right;
}

.a_title {
    text-align: center;
    color: #51cbce;
}

.set_row {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
}

.area-card-footer {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    padding: 5px;
    background: ghostwhite;
}

.footer_tags {
    margin-bottom: 0 !important;
}

.footer_div {
    text-align: center !important;
}

</style>
