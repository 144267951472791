<template>

    <div>


    </div>

</template>

<script>

export default {

    props: {
        confirmationCode: {
            type: String,
        },
    },
    data()
    {
        return {}
    },
    methods: {

        verifyUser()
        {
            let vm = this;
            axios.get("/register/verify/" + vm.confirmationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Your account is verified successfully, Now you can login to HSIApps", true, function (confirm) {
                            vm.$router.replace({name: 'login'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'login'})
                    })
                });
        },

    },
    created()
    {
        this.verifyUser()
    }


}

</script>

<style scoped>

</style>
