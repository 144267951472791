  export default [
  {
    'id': 1,
    'uniqueid': 'HC1',
    'microChipNo'  : '0',
    'photo': 'http://www.hsi.org/assets/images/logos/hsi_global.png',
    'datecatchattend': '12-2-2019',
    'datearv': '12-2-2019',
    'daterelease': '12-2-2019',
    'caughtlocality': 'Flacq District',
    'colour': 'Sable',
    'systemid': '',
    'gender': 'Male',
    'weight': '14',
    'lactating': '',
  },
  {
    'id': 2,
    'uniqueid': 'HC2',
    'microChipNo'  : '0',
    'photo': 'https://s3.amazonaws.com/hsi-demo/1553688215.jpg',
    'datecatchattend': '12-2-2019',
    'datearv': '12-2-2019',
    'daterelease': '12-2-2019',
    'caughtlocality': 'Flacq District',
    'colour': 'Sable',
    'systemid': '',
    'gender': 'Male',
    'weight': '14',
    'lactating': '',
  },
  {
    'id': 3,
    'uniqueid': 'HC2',
    'microChipNo'  : '0',
    'photo': 'http://www.hsi.org/assets/images/logos/hsi_global.png',
    'datecatchattend': '12-2-2019',
    'datearv': '12-2-2019',
    'daterelease': '12-2-2019',
    'caughtlocality': 'Flacq District',
    'colour': 'Sable',
    'systemid': '',
    'gender': 'Male',
    'weight': '14',
    'lactating': '',
  },
  {
    'id': 4,
    'uniqueid': 'HC2',
    'microChipNo'  : '0',
    'photo': 'http://www.hsi.org/assets/images/logos/hsi_global.png',
    'datecatchattend': '12-2-2019',
    'datearv': '12-2-2019',
    'daterelease': '12-2-2019',
    'caughtlocality': 'Flacq District',
    'colour': 'Sable',
    'systemid': '',
    'gender': 'Male',
    'weight': '14',
    'lactating': '',
  },
  {
    'id': 5,
    'uniqueid': 'HC2',
    'microChipNo'  : '990000002180195',
    'photo': 'http://www.hsi.org/assets/images/logos/hsi_global.png',
    'datecatchattend': '12-2-2019',
    'datearv': '12-2-2019',
    'daterelease': '12-2-2019',
    'caughtlocality': 'Centre de Flacq',
    'colour': 'Brown',
    'systemid': '',
    'gender': 'Female',
    'weight': '11',
    'lactating': '',
  },
  {
    'id': 6,
    'uniqueid': 'HC6',
    'microChipNo'  : '0',
    'photo': 'http://www.hsi.org/assets/images/logos/hsi_global.png',
    'datecatchattend': '12-2-2019',
    'datearv': '12-2-2019',
    'daterelease': '12-2-2019',
    'caughtlocality': 'Flacq District',
    'colour': 'Sable',
    'systemid': '',
    'gender': 'Male',
    'weight': '14',
    'lactating': '',
  }
]
