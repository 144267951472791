<template>
    <div>
        <navbar v-model="showNavbar">
            <div class="navbar-wrapper">
                <div class="logo">
                    <router-link to="/platform/projects">
                        <a class="simple-text logo-mini">
                            <div class="logo-img">
                                <img :src="logo" alt="">
                            </div>
                        </a>
                    </router-link>
                </div>
            </div>
            <template slot="navbar-menu">
                <ul class="navbar-nav navbar-all-nav">
                    <li>
                        <div class="notification-icon-wrapper">
                            <div v-if="unreadNotificationCount" class="notification-red-badge">{{
                                    unreadNotificationCount
                                }}
                            </div>
                            <a class="nav-link ti-bell" title="Notifications" style="font-size: 20px; cursor: pointer"
                               @click="userNotificationsPage"></a>
                        </div>
                    </li>
                    <li>
                        <a class="nav-link ti-help" title="Need help?" style="font-size: 20px" href="#"
                           @click="showHelpModal()"></a>
                    </li>

                    <drop-down icon="nc-icon nc-bell-55" tag="li" position="right" direction="none"
                               class="nav-item btn-rotate dropdown">
                        <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle dropdown-toggle-name"
                           aria-haspopup="true" :aria-expanded="isOpen">
                            <p class="user-name" :title="username">HSI Super Admin</p>
                        </a>

                        <router-link to="" tag="a">
                            <a class="dropdown-item" @click="logout()">
                                <span class="sidebar-normal">Logout</span>
                            </a>
                        </router-link>

                    </drop-down>

                </ul>

            </template>
            <help-modal ref="helpModal2"></help-modal>
        </navbar>
        <div style="background: white; padding: 20px;">
            <router-link to="/admin/dashboard" style="margin-right: 20px;">
                Dashboard
            </router-link>

            <router-link to="/admin/application" style="margin-right: 20px;">
                Organisation Applications
            </router-link>

            <router-link to="/admin/account" style="margin-right: 20px;">
                Users
            </router-link>

            <router-link to="/admin/profile" style="margin-right: 20px;">
                Profile
            </router-link>

        </div>
    </div>
</template>
<script>
import {Navbar} from '../UIComponents';

//import logo from '../../../assets/images/hsapps-green-small.png';
import logo from '../../../assets/images/HSIApps-Trnprnt-green.png';

import HelpModal from "../Layout/HelpModal";

export default {
    components: {
        Navbar,
        "help-modal": HelpModal
    },
    data()
    {
        return {
            activeNotifications: false,
            showNavbar: false,
            userImage: '',
            username: '',
            helpLinkUrl: 'URL',
            unreadNotificationCount: null,
            notificationCheckTimer: null,
        };
    },
    props: {
        usertype: {
            type: String,
        },
        logo: {
            type: String,
            default: logo,
        },
    },
    methods: {
        userNotificationsPage()
        {
            //this.$router.push({'name': 'userNotifications',});
            window.Bus.$emit('open-notification-panel')
        },
        capitalizeFirstLetter(string)
        {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown()
        {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown()
        {
            this.activeNotifications = false;
        },
        toggleSidebar()
        {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar()
        {
            this.$sidebar.displaySidebar(false);
        },
        minimizeSidebar()
        {
            this.$sidebar.toggleMinimize();
        },
        toggleNavbar()
        {
            this.showNavbar = !this.showNavbar;
        },
        showModal()
        {
            //    Bus.$emit("showmodal",true);
            var pageUrl = location.href;
            var res = pageUrl.split("/");
            var pageName = res[3];
            var faqpage = "https://hsappsinternal.wiki.zoho.com/FAQ.hlp#" + pageName;
            if (pageName == "projects" || pageName == "fieldteams") {
                window.open(faqpage, 'popUpWindow', 'height=900,width=1000,left=100,top=70,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
            }
        },
        async logout()
        {
            await this.$store.dispatch('LogOut')
            this.$router.push('/login')
        },
        notifications()
        {
            let vm = this;
            axios.get('user/notifications').then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.unreadNotificationCount = 0;
                    var notifications = response.data.response.data.notifications;
                    for (var i = 1; i < notifications.length; i++) {
                        var read = notifications[i].read;
                        if (read == 0) {
                            if (vm.unreadNotificationCount == null) {
                                vm.unreadNotificationCount = 0;
                            }
                            vm.unreadNotificationCount++;
                        }
                    }
                    if (vm.unreadNotificationCount > 99) {
                        vm.unreadNotificationCount = '99+';
                    }
                }
            }).catch(error => {
                console.log(error)
            });
        },
        showHelpModal()
        {
            this.$refs.helpModal2.loadUrl(window.USER_MANUAL_URL);
        }
    },
    computed: {
        appName()
        {
            return window.AppName;
        },
        title()
        {
            return window.appConfig.appName;
        },
    },
    mounted()
    {
        this.helpLinkUrl = window.USER_MANUAL_URL;
        var vm = this;
        vm.notifications();

        vm.notificationCheckTimer = setInterval(() => {
            vm.notifications();
        }, 1000 * 60)

        this.$root.$on('userDataSet', () => {
            const userData = JSON.parse(localStorage.getItem('userdata'));
            vm.username = userData.userName;
            const user_image = userData.userImage;
            if (user_image == null || user_image == '') {
                vm.userImage = window.DefaultUserImage;
            } else {
                vm.userImage = user_image;
            }
        });
    },
    beforeDestroy()
    {
        clearInterval(this.notificationCheckTimer)
    }
}

</script>
<!--pradeep -->
<style>
.setnavbarbgcolor {
    background-color: white !important;
}

#show-modal {
    cursor: pointer;
}

.remove-color:hover {
    color: #66615B !important;
}

.badge {
    position: absolute;
    top: -14px;
    right: -6px;
    padding: 3px 7px 5px 7px;
    border-radius: 50%;
    background-color: red;
    color: white;
}

.user-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
}

.dropdown-toggle-name::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    top: 18px;
}

@media (max-width: 800px) {
    .navbar-all-nav {
        flex-direction: row;
        margin-top: 30px;
    }

    .dropdown-icon {
        margin-top: 0px !important;
    }
}

.notification-red-badge {
    position: absolute;
    right: 5px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: red;
    text-align: center;
    margin: 0;
    font-size: 12px;
    color: white;
    font-weight: bold;
    border-radius: 30px;
    height: 19px;
    min-width: 19px;
}

.notification-icon-wrapper {
    position: relative;
}

</style>
<!--End-->
