<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ibox ">
                                <div class="ibox-content">
                                    <div class="panel-heading">
                                        <h2 class="set_font_title">Edit Vaccination Area</h2>
                                        <p></p>
                                    </div>
                                    <div class="card setShadow" style="padding: 10px;">
                                        <div class="row">
                                            <div class="col col-sm-12">
                                                <p>
                                                    To edit a Vaccination Area (geofence) simply re-draw the new
                                                    geofence and press Save. The old geofence will be replaced with the
                                                    new one. Alternatively, upload a new one.
                                                </p>
                                                <p>
                                                    <b>Note</b>: Any out-of-area records will remain out-of-area even if now
                                                    within the new geofence border unless they are moved in.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="panel-options col-md-8">
                                                <p>
                                                    Enter a search term or zoom/position the map to a particular area,
                                                    then draw a polygon.<br>
                                                    To add more vaccination areas(polygons), Click on the polygon icon.
                                                    <span><img width="25"
                                                            src="@/assets/images/polygon-icon.png"/></span>
                                                </p>
                                            </div>
                                            <div class="col col-md-4 text-right up_kml_btn">
                                                <button v-bind:disabled="!onLine" @click="uploadVaccinationArea"
                                                        class="btn btn-warning"
                                                        type="button" onclick="">
                                                    UPLOAD GEOFENCE (KML)
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                        </div>
                                        <div class="panel-body set_map">
                                            <form @submit.prevent="submit" class="form-horizontal">
                                                <input type="hidden" name="no_of_fence" id="no_of_fence" value="">
                                                <div class="pac-card" id="pac-card">
                                                    <div>
                                                    </div>
                                                    <div id="pac-container">
                                                        <input id="pac-input" type="text"
                                                               placeholder="Search by location">
                                                    </div>
                                                </div>
                                                <div id="map"></div>
                                                <div id="vaccination-areas" style="padding: 5px;">
                                                    <input type="hidden" value="" name="kml_data_1" id="kml_data_1">
                                                </div>
                                                <div class="form-group" id="vaccination-areas-btn">
                                                    <div class="col-sm-12 text-right">
                                                        <button class="btn btn-white" type="button"
                                                                @click="goBack">Cancel
                                                        </button>
                                                        <button v-if="onLine" type="submit"
                                                                :disabled="isUploadingKMLData"
                                                                class="btn btn-primary">Save
                                                        </button>
                                                        <button v-else v-bind:disabled="true" type="submit"
                                                                class="btn btn-primary">Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {API_KEY} from './Maps/API_KEY';
import {MAP_STYLE} from './Maps/MAP_STYLE';
import GoogleMapsLoader from 'google-maps';

GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['drawing', 'places'];

import mixin from '../../mixins/GlobalMixin';
import {Select, Option,} from 'element-ui';
import Swal from 'sweetalert2';
import VOffline from 'v-offline';

export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline
    },
    props: {
        areaId: {
            type: Number,
        }
    },
    data()
    {
        return {
            all_overlays: [],
            all_shapes: [],
            selectedShape: null,
            polygonstyles: [],
            selectedCountry: [],
            selectedDistrict: [],
            selectedState: [],
            placeholderVal: "",
            fenceCount: 0,
            project: null,
            isUploadingKMLData: false,
            uploadedCount: 0,
            areaDetails: '',
            latitude: '',
            longitude: '',
            polygon: [],
        }
    },
    mixins: [mixin],
    methods: {
        submit()
        {
            let vm = this;
            console.log('submit clicked')
            vm.isUploadingKMLData = true;
            vm.generate_kml_data();
            console.log('After kmldata')
            let areaId = vm.areaDetails.id;
            let requestPayload = {
                name: $('#settlement_name').val(),
                country: $('#country').val(),
                google_admin_area_level1: $('#google_admin_area_level1').val(),
                google_locality: $('#google_locality').val(),
            }

            if (vm.fenceCount) {
                Object.assign(requestPayload, {kml_data: $('#kml_data_' + 1).val()});
            }
            console.log('request payload', requestPayload)
            //    for (let i = 1; i <= vm.fenceCount; i++) {
            axios.put('/area/update/' + areaId, requestPayload).then(response => {
                if (response.data.response.error.error_code == 0) {
                    //Success
                    this.showAlert('Success', response.data.response.error.msg, true);
                    //    $('#fence-field-msg' + i).text(response.data.response.error.msg);
                    //    vm.uploadedCount++;
                    //    if (vm.uploadedCount == vm.fenceCount) {
                    vm.isUploadingKMLData = false;
                    //    }
                    vm.getAreaDetails(areaId, 1);
                    window.location.reload();
                } else {
                    //    $('#fence-field-msg' + i).text(response.data.response.error.error_msg);
                    this.showAlert('Error', error.response.data.response.error.error_msg, false);
                }
            }).catch(error => {
                this.showAlert('Error', error.response.data.response.error.error_msg, false);
                //$('#fence-field-msg' + i).text(error.response.data.response.error.error_msg);
            });
            //    }
        },
        initMap(google)
        {
            let vm = this;
            let latLng = vm.project.project_location_lat_lng.split(',');
            const myLatlng = new google.maps.LatLng(latLng[0], latLng[1]);

            const map = new google.maps.Map(document.getElementById('map'), {
                center: myLatlng,
                zoom: 7,
                styles: MAP_STYLE,
            });
            var kmlUrl = this.areaDetails.file_url;
            var ctaLayer = new google.maps.KmlLayer({
                url: kmlUrl,
                map: map
            });

            let card = document.getElementById('pac-card');
            let input = document.getElementById('pac-input');
            let types = document.getElementById('type-selector');
            let strictBounds = document.getElementById('strict-bounds-selector');
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

            let autocomplete = new google.maps.places.Autocomplete(input);

            // Bind the map's bounds (viewport) property to the autocomplete object,
            // so that the autocomplete requests use the current map bounds for the
            // bounds option in the request.
            autocomplete.bindTo('bounds', map);

            autocomplete.addListener('place_changed', function () {

                let place = autocomplete.getPlace();
                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }
                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);  // Why 17? Because it looks good.
                }

            });

            let drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        google.maps.drawing.OverlayType.POLYGON
                    ]
                },
                polygonOptions: {
                    clickable: true,
                    draggable: true,
                    strokeColor: '#9c27b0',
                    editable: true,
                    strokeOpacity: 1,
                    strokeWeight: 4,
                    fillOpacity: 0
                },
            });

            function clearSelection()
            {
                if (vm.selectedShape) {
                    vm.selectedShape.setEditable(false);
                    vm.selectedShape = null;
                }
            }

            function setSelection(shape)
            {
                clearSelection();
                vm.selectedShape = shape;
                shape.setEditable(true);
                $("#vaccination-areas").show();
                for (let i = 1; i <= vm.fenceCount; i++) {
                    $('#fence-fields-' + i).css('background-color', '');
                }
            }

            function deleteSelectedShape()
            {
                if (vm.selectedShape) {
                    $('#fence-fields-' + vm.selectedShape.id).remove();
                    vm.fenceCount = vm.fenceCount - 1;
                    vm.selectedShape.setMap(null);
                }
            }

            function deleteAllShape()
            {
                for (let i = 0; i < vm.all_overlays.length; i++) {
                    vm.all_overlays[i].overlay.setMap(null);
                }
                vm.all_overlays = [];
            }

            function CenterControl(controlDiv, map)
            {
                // Set CSS for the control border.
                let controlUI = document.createElement('div');
                controlUI.style.backgroundColor = '#fff';
                controlUI.style.border = '2px solid #fff';
                controlUI.style.borderRadius = '3px';
                controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
                controlUI.style.cursor = 'pointer';
                controlUI.style.marginBottom = '22px';
                controlUI.style.textAlign = 'center';
                controlUI.title = 'Select to delete the shape';
                controlDiv.appendChild(controlUI);
                // Set CSS for the control interior.
                let controlText = document.createElement('div');
                controlText.style.color = 'rgb(25,25,25)';
                controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
                controlText.style.fontSize = '16px';
                controlText.style.lineHeight = '38px';
                controlText.style.paddingLeft = '5px';
                controlText.style.paddingRight = '5px';
                controlText.innerHTML = 'Delete Selected Area';
                controlUI.appendChild(controlText);
                // Setup the click event listeners: simply set the map to Chicago.
                controlUI.addEventListener('click', function () {
                    deleteSelectedShape();
                });
            }

            drawingManager.setMap(map);

            google.maps.event.addListener(drawingManager, 'polygoncomplete', function (event) {
                google.maps.event.addListener(event.getPath(), 'insert_at', function () {
                    let len = event.getPath().getLength();
                    for (let i = 0; i < len; i++) {
                        //console.log(event.getPath().getAt(i).toUrlValue(5));
                        //alert(event.getPath().getAt(i).toUrlValue(5));
                    }
                });
                google.maps.event.addListener(event.getPath(), 'set_at', function () {
                    let len = event.getPath().getLength();
                    for (let i = 0; i < len; i++) {
                        //console.log(event.getPath().getAt(i).toUrlValue(5));
                    }
                });
            });

            google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
                if (event.type == google.maps.drawing.OverlayType.POLYGON) {
                    //    vm.latitude = event.overlay.getPath().getArray()[0].lat();
                    //    vm.longitude = event.overlay.getPath().getArray()[0].lng();
                    event.overlay.getPath().getArray().forEach(function (position) {
                        var obj = {};
                        obj["x"] = position.lat();
                        obj["y"] = position.lng();
                        vm.polygon.push(obj);
                    });

                    Region.prototype.area = function () {
                        var area = 0,
                            i,
                            j,
                            point1,
                            point2;

                        for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
                            point1 = this.points[i];
                            point2 = this.points[j];
                            area += point1.x * point2.y;
                            area -= point1.y * point2.x;
                        }
                        area /= 2;

                        return area;
                    };

                    Region.prototype.centroid = function () {
                        var x = 0,
                            y = 0,
                            i,
                            j,
                            f,
                            point1,
                            point2;

                        for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
                            point1 = this.points[i];
                            point2 = this.points[j];
                            f = point1.x * point2.y - point2.x * point1.y;
                            x += (point1.x + point2.x) * f;
                            y += (point1.y + point2.y) * f;
                        }

                        f = this.area() * 6;

                        return new Point(x / f, y / f);
                    };
                    var region = new Region(vm.polygon);
                    vm.latitude = region.centroid().x;
                    vm.longitude = region.centroid().y;
                    // console.log(vm.latitude);
                    // console.log(vm.longitude);
                    // console.log(region.centroid());
                }
                vm.fenceCount = vm.fenceCount + 1;
                console.log('fencecount', vm.fenceCount)
                vm.appendNewInputFields(1);
                $("#no_of_fence").val(vm.fenceCount);
                vm.all_overlays.push(event);

                if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                    drawingManager.setDrawingMode(null);
                    //Write code to select the newly selected object.
                    let newShape = event.overlay;
                    newShape.type = event.type;
                    newShape.id = vm.fenceCount;
                    vm.all_shapes[vm.fenceCount] = newShape;
                    google.maps.event.addListener(newShape, 'click', function () {
                        setSelection(newShape);
                    });
                    setSelection(newShape);
                }
            });

            function Point(x, y)
            {
                this.x = x;
                this.y = y;
            }

            function Region(points)
            {
                this.points = points || [];
                this.length = points.length;
            }

            let centerControlDiv = document.createElement('div');
            let centerControl = new CenterControl(centerControlDiv, map);
            centerControlDiv.index = 1;
            map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);
            vm.createpolygonstyleobject();
            $("#pac-input").focus();
        },
        appendNewInputFields(parms)
        {
            let vm = this;
            if (parms == 0) {
                $("#vaccination-areas").append(vm.getInputFields());
            } else {
                var geocoder = new google.maps.Geocoder;
                var latlng = {lat: parseFloat(vm.latitude), lng: parseFloat(vm.longitude)};
                geocoder.geocode({'location': latlng}, function (results, status) {
                    if (status === 'OK') {
                        if (results[0]) {
                            var address_components = results[0].address_components;
                            for (var i = 1; i < address_components.length; i++) {
                                var singleAdd = address_components[i];
                                var type = singleAdd.types[0];
                                if (type == "locality") {
                                    $("#google_locality").val(singleAdd.long_name);
                                }
                                if (type == "administrative_area_level_1") {
                                    $("#google_admin_area_level1").val(singleAdd.long_name);
                                }
                                if (type == "country") {
                                    $("#country").val(singleAdd.long_name);
                                }
                            }
                        }
                    }
                });
            }
        },
        getInputFields()
        {
            let vm = this;
            vm.isUploadingKMLData = false;
            return '<input type="hidden" value="" name="kml_data_' + vm.fenceCount + '" id="kml_data_' + vm.fenceCount + '">' +
                '<div class="row fence-fields" id="fence-fields-' + vm.fenceCount + '">'
                + '<div class="col-sm-12 col-md-3"><label>Vaccination Area Name <span class="error">*</span></label><input class="form-control settlement_name" name="settlement_name' + vm.fenceCount + '" id="settlement_name" value="' + vm.areaDetails.name + '"required></div>'
                + '<div class="col-sm-12 col-md-3"><label>Country <span class="error">*</span></label><input name="country' + vm.fenceCount + '" class="form-control" id="country" value="' + vm.areaDetails.google_country + '" required>'
                + '</div>'
                + '<div class="col-sm-12 col-md-3"><label>State/Province<span class="error">*</span></label><input name="state' + vm.fenceCount + '" class="form-control " id="google_admin_area_level1" value="' + vm.areaDetails.google_admin_area_level1 + '" required>'
                + '</div>'
                + '<div class="col-sm-12 col-md-3"><label>City/District/Village <span class="error">*</span></label><input name="locality' + vm.fenceCount + '" class="form-control" id="google_locality" value="' + vm.areaDetails.google_locality + '" required>'
                + '</div>'
                + '<span class="col-sm-12" id="fence-field-msg' + vm.fenceCount + '"></span>';
        },
        generate_kml_data()
        {
            let vm = this;
            for (let i = 1; i <= vm.fenceCount; i++) {
                vm.getPolygonCoords(vm.all_shapes[i]);
            }
        },
        getPolygonCoords(newShape)
        {
            let vm = this;
            let len = newShape.getPath().getLength();
            for (let i = 0; i < len; i++) {
                let latLng = newShape.getPath().getAt(i).toUrlValue(6).split(',');
            }
            vm.logCode2(newShape);
        },
        logCode2(newShape)
        {
            let vm = this;
            let code = "";
            let kmltext1 = '<Placemark><name>' + 'name of geofence' + '</name>\n' +
                '<description>' + 'Description' + '</description>\n' +
                '<styleUrl>#' + 'poly-01579B-6-0-nodesc' + '</styleUrl>\n' +
                '<Polygon>\n<tessellate>' + '1' + '</tessellate>\n' +
                '<outerBoundaryIs><LinearRing><coordinates>\n';

            let len = newShape.getPath().getLength();
            for (let i = 0; i < len; i++) {
                let latLng = newShape.getPath().getAt(i).toUrlValue(6).split(',');
                code += latLng[1] + ',' + latLng[0] + ',0.0 \n';
            }
            let kmltext2 = '</coordinates></LinearRing></outerBoundaryIs>\n</Polygon>\n</Placemark>\n';
            let generatedKml = vm.kmlheading() + kmltext1 + code + kmltext2 + vm.kmlend();
            document.getElementById("kml_data_" + newShape.id).value = generatedKml;
        },
        kmlheading()
        {
            let vm = this;
            let heading = "";
            let styleforpolygon = "";
            let styleforrectangle = "";
            let styleforpolyline = "";
            let styleformarker = "";
            let i;
            heading = '<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n' +
                '<kml xmlns="http://www.opengis.net/kml/2.2">\n' +
                '<Document><name>' + 'Untitled layer' + '</name>\n' +
                '<description>' + 'layer description' + '</description>\n';

            for (i = 0; i < vm.selectedShape.getPath().getLength(); i++) {
                styleforpolygon += '<Style id="' + vm.polygonstyles[0].name + '">\n' +
                    '<LineStyle><color>' + vm.polygonstyles[0].kmlcolor + '</color><width>' + vm.polygonstyles[0].width + '</width></LineStyle>\n' +
                    '<PolyStyle><color>' + vm.polygonstyles[0].kmlfill + '</color></PolyStyle>\n' +
                    '</Style>\n';
            }
            return heading + styleforpolygon;
        },
        kmlend()
        {
            let ending;
            return ending = '</Document>\n</kml>';
        },
        createpolygonstyleobject()
        {
            let vm = this;
            let polygonstyle = new vm.Polystyle();
            vm.polygonstyles.push(polygonstyle);
        },
        Polystyle()
        {
            this.name = "Lump";
            this.kmlcolor = "CD0000FF";
            this.kmlfill = "9AFF0000";
            this.color = "#FF0000";
            this.fill = "#0000FF";
            this.width = 2;
            this.lineopac = 0.8;
            this.fillopac = 0.6;
        },
        uploadVaccinationArea()
        {
            //window.location = '/project/vaccination_area/upload/' + this.areaId;
            this.$router.push({name: 'uploadAreaKml', params: {areaId: this.areaId}})
        },
        getAreaDetails(areaId, parms)
        {
            axios.get("/area/details/" + areaId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        let vm = this;
                        vm.areaDetails = response.data.response.data.area;
                        if (parms == 0) {
                            vm.appendNewInputFields(0);
                        }
                        GoogleMapsLoader.load(google => {
                            vm.initMap(google)
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data.response.error) {

                    }
                });
        },
        goBack()
        {
            window.history.back();
        }
    },
    mounted()
    {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;

        //Bus.$emit("projectype", vm.project);
        let url = location.href;
        let res = url.split("/");
        let areaId = res[res.length - 1];
        vm.getAreaDetails(areaId, 0);
    }
}

</script>
<style scoped>
#map {
    height: 500px;
    width: 100%;
    margin-bottom: 10px;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}

#pac-container {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls label {
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

.setTitleDiv p {
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.el_select_width {
    width: 100% !important;
}

.btn-color {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.text-field {
    width: 75% !important;
}

.no_rec_msg {
    font-size: 20px;
    height: 220px;
}

.set_font_title {
    font-size: 15px;
    font-weight: 600;
    color: black;
    margin-left: -12px !important;
}

.set_map {
    margin-top: -17px;
    margin-bottom: -10px;
}

.up_kml_btn {
    margin-top: -10px;
    margin-bottom: 20px;
}

.fence-fields {
    padding-bottom: 0px !important;
    padding-top: 5px;
}

.set_cursor {
    cursor: pointer;
}
</style>
