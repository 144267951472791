<template>
  <div class="card" v-if="stats">
    <div class="card-body">
      <div class="d-flex flex-wrap">
          <span
            class="shape-icon"
            v-for="shape in stats.shapes"
            :key="shape.shape_code"
          >
            <template v-if="shape.shape_code === 0">
              <b-icon-circle-fill /> {{ shape.description }}
            </template>
            <template v-else>
              <b-icon-triangle-fill /> {{ shape.description}}
            </template>
          </span>

          <span
            class="legend-icon"
            v-for="(leg, index) in stats.legend"
            :key="'legend' + index"
          >
          <template>
              <b-icon-circle-fill :style="{ color: leg.color_code }" />
              <b-icon-triangle-fill :style="{ color: leg.color_code }" />
              {{ leg.text }}
          </template>
          </span>

      </div>
    </div>
  </div>
</template>

<script>
import { BIconTriangleFill, BIconCircleFill } from "bootstrap-vue";
export default {
  name: "HSIstats",
  components: {
    BIconTriangleFill,
    BIconCircleFill,
  },
  data() {
    return {
      stats: undefined,
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    async getStats() {
      const response = await axios.get("/area/status/color_legend");
      const { data: stats } = response.data.response;
      const { shape_info: shapes, legend } = stats;
      this.stats = { shapes, legend };
    },
  },
};
</script>

<style lang="scss" scoped>
// .shape-icon {
//   width: 50%;
// }
// .legend {
//     width: 33.33%
// }
.card-body {
    span {
        margin: 5px 25px;
    }
}
</style>