<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12">
                <h5>Move Out-of-Area Vaccination Records</h5>
                <p>Moving an out-of-area record into an area will count that record as inside the chosen area.</p>
                <hr>
            </div>
        </div>

        <div class="row">

            <div class="col-sm-6">
                <div class="form-group">
                    <template>
                        <label>Move to Area</label>
                        <el-select v-model="selectedArea" placeholder="Select Area"
                                   @change="">
                            <el-option
                                    v-for="area in areas"
                                    :key="area.id"
                                    :label="area.name"
                                    :value="area.id">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>

            <div class="col-sm-6">
                <br>
                <button v-bind:disabled="!onLine" type="button"
                        @click="moveSelectedRecords()" name="generate"
                        class="btn btn-round btn-default">
                    Move
                </button>
            </div>

        </div>

        <br>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <table id="example" class="display table-striped table-bordered select" cellspacing="0"
                               width="100%">
                            <thead>
                            <tr>
                                <th><input type="checkbox" id="allCheckbox" @click="inputCheckBox"></th>
                                <th>Ownership</th>
                                <th>Age Category</th>
                                <th>Reproductive Status</th>
                                <!--                                <th>Vaccine/Drug</th>-->
                                <th>Vaccinated Date</th>
                                <th>Vaccinated By</th>
                                <th>Note</th>
                            </tr>
                            </thead>
                        </table>
                        <div class="row">
                            <div class="col-sm-6 text-left">
                                <div style="margin-top: 20px">
                                    <el-button v-bind:disabled="!onLine" @click="clearSelection()">Clear
                                        selection
                                    </el-button>
                                    <el-button :disabled="!(multipleSelection.length === 1)" :loading="isArchiveLoading"
                                               @click="archiveSingleRecord">Archive
                                    </el-button>
                                </div>
                            </div>
                            <div class="col-sm-6 text-right">
                                <div style="margin-top: 20px">
                                    <el-button v-bind:disabled="!onLine" @click="confirmMove()">
                                        Move All
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import mixin from '../../mixins/GlobalMixin';
import {Select, Option, Table, TableColumn, Button} from 'element-ui';
import VOffline from 'v-offline';
import Swal from 'sweetalert2';

export default {

    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
        areaId: {
            type: Number,
        }
    },
    mixins: [mixin],
    data()
    {
        return {
            selectedProject: null,
            selectedArea: null,
            projects: [],
            areas: [],
            tableData: [],
            multipleSelection: [],
            table: null,
            recordLen: 0,
            isArchiveLoading: false
        }
    },
    methods: {
        init()
        {
            this.getProjectNames();
            this.getOuterVaccinationRecords();
        },
        getProjectNames()
        {
            let vm = this;
            axios.get('project/names')
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.projects = response.data.response.data.project_names;
                        for (let i = 0; i < vm.projects.length; i++) {
                            if (vm.projects[i].id == vm.projectId) {
                                vm.projects[i].name = vm.projects[i].name + " (Current)";
                                vm.selectedProject = vm.projectId;
                                vm.getProjectAreas();
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        inputCheckBox()
        {
            var vm = this;
            vm.multipleSelection = [];
            if (document.getElementById('allCheckbox').checked) {
                document.querySelectorAll('.check_input').forEach(function (element) {
                    element.checked = true;
                    vm.multipleSelection.push(element.value);
                    element.classList.add("active");
                });
            } else {
                vm.multipleSelection = [];
                document.querySelectorAll('.check_input').forEach(function (element) {
                    element.checked = false;
                    element.classList.remove("active");
                });
            }
        },
        getProjectAreas()
        {
            let vm = this;
            axios.get('area', {
                params: {
                    project_id: vm.selectedProject
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.areas = response.data.response.data;
                    for (let i = 0; i < vm.areas.length; i++) {
                        if (vm.areas[i].id == vm.areaId) {
                            vm.areas[i].name = vm.areas[i].name + " (Current)";
                            vm.selectedArea = vm.areas[i].id;
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
        confirmMove()
        {
            let vm = this;
            if (vm.selectedProject == null || vm.selectedArea == null) {
                vm.showAlert('Error', 'You must select project and area', false);
                return
            }
            new Swal({
                title: 'Are you sure you want to move all records?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Move Records',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.post('vaccination/move/outer_records/' + vm.projectId + '/' + vm.areaId, {
                        move_to_area_id: vm.selectedArea,
                    }).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            vm.getOuterVaccinationRecords();
                            vm.showAlert('Success', response.data.response.error.msg, true);
                        }
                    }).catch(error => {
                        console.log(error);
                        vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                    })
                }
            });
        },
        getOuterVaccinationRecords()
        {
            let vm = this;
            vm.tableData = [];
            vm.multipleSelection = [];
            axios.get('vaccination/outer/records/' + vm.projectId + '/' + vm.areaId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.recordLen = response.data.response.data.outer_vaccination_records.length;
                        for (let i = 0; i < response.data.response.data.outer_vaccination_records.length; i++) {
                            var record = response.data.response.data.outer_vaccination_records[i];
                            let data = {
                                "": '<input type="checkbox" class="check_input" name="id[]" value="' + record.id + '">',
                                ownership: record.ownership,
                                age_category: record.age_category,
                                reproductive_status: record.reproductive_status,
                                vaccination_date: record.vaccination_date,
                                vaccination_by: record.vaccinated_by.first_name,
                                note: record.note,
                            };
                            vm.tableData.push(data);
                        }
                        if (vm.table != null) {
                            vm.table.destroy();
                        }
                        try {
                            vm.table = $('#example').DataTable({
                                "scrollX": true,
                                responsive: true,
                                dom: 'lBfrtip',
                                "bInfo": false,
                                data: vm.tableData,
                                columns: [
                                    {data: ''},
                                    {data: 'ownership'},
                                    {data: 'age_category'},
                                    {data: 'reproductive_status'},
                                    //{data: 'vaccination_drug_name'},
                                    {data: 'vaccination_date'},
                                    {data: 'vaccination_by'},
                                    {data: 'note'},
                                ],
                                buttons: [
                                    {
                                        extend: 'csv',
                                        title: 'Outer Vaccinations',
                                        messageTop: 'Report',
                                        messageBottom: 'Human Society International HSIAPPS',
                                        className: 'btn btn-default',
                                        exportOptions: {
                                            columns: [1, 2, 3, 4, 5, 6],
                                        }
                                    },
                                ],
                                lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                                pageLength: 15,
                            });
                            vm.CheckUncheckRecord();
                        } catch (e) {
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        handleSelectionChange(val)
        {
            this.multipleSelection = val;
        },
        moveSelectedRecords()
        {
            let vm = this;
            if (vm.multipleSelection.length == 0) {
                vm.showAlert('Alert', 'Please select records to be moved', false);
                return;
            }
            new Swal({
                title: 'Are you sure you want to move records to selected area?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Move Records',
                buttonsStyling: false,
            }).then(function (result) {
                axios.post('vaccination/move/outer/records', {
                    record_ids: vm.multipleSelection,
                }).then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.getOuterVaccinationRecords();
                        vm.showAlert('Success', response.data.response.error.msg, true);
                    }
                }).catch(error => {
                    console.log(error);
                });
            });
        },
        clearSelection()
        {
            //this.$refs.multipleTable.clearSelection();
            var vm = this;
            vm.multipleSelection = [];
            document.querySelectorAll('.check_input').forEach(function (element) {
                element.checked = false;
                element.classList.remove("active");
            });
            document.getElementById('allCheckbox').checked = false;
        },
        CheckUncheckRecord()
        {
            var vm = this;
            document.querySelectorAll('.check_input').forEach(function (ele) {
                ele.addEventListener('click', function (element) {
                    if (element.target.checked) {
                        vm.multipleSelection.push(element.target.value);
                        ele.classList.add("active");
                    } else {
                        var idx = vm.multipleSelection.indexOf(element.target.value);
                        vm.multipleSelection.splice(idx, 1);
                        ele.classList.remove("active");
                    }
                    var selectCheckBoxLen = document.querySelectorAll('.check_input.active').length;
                    if (vm.recordLen == selectCheckBoxLen) {
                        document.getElementById('allCheckbox').checked = true;
                    } else {
                        document.getElementById('allCheckbox').checked = false;
                    }
                }, false);
            });
        },
        async archiveSingleRecord()
        {
            const vm = this
            if (!(vm.multipleSelection.length === 1)) {
                return
            }
            const URL = `vaccination/archive/${this.multipleSelection[0]}`

            new Swal({
                title: 'Are you sure you want to archive record?',
                text: ``,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Archive',
                buttonsStyling: false,
            }).then(function (result) {
                // console.log('result', result)
                if (result.value) {
                    vm.isArchiveLoading = true
                    axios.post(URL).then(response => {
                        vm.isArchiveLoading = false
                        vm.multipleSelection = [];
                        if (response.data.response.error.error_code == 0) {
                            vm.getOuterVaccinationRecords();
                            vm.showAlert('Success', response.data.response.error.msg, true);
                        }
                    }).catch(error => {
                        vm.multipleSelection = [];
                        vm.isArchiveLoading = false
                        console.log(error);
                    });
                }
            });
        }
    },
    computed: {},
    mounted()
    {
        this.init();
        let vm = this;
        //Bus.$emit("projectype", vm.project);
    }
}

</script>

<style lang="scss">
.dataTables_wrapper {
  &.form-inline {
    display: block !important;
    text-align: center;
  }
}

.btn-group .btn + .btn {
  margin-left: 5px !important;
}

.el-table .cell {
  text-overflow: unset !important;
}

.el-table th > .cell {
  text-overflow: unset !important;
}

.el-table__row .cell {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.el-button--default {
  background: #f58220 !important;
  color: white;
}
</style>
