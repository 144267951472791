<template>
    <div v-if="maintenanceDetail.message_show" class="maintenance-message">
        {{ maintenanceDetail.message_text }}
    </div>
</template>
<script>
import {mapActions} from "vuex";


export default {
    name: 'MaintenanceMessage',
    computed: {
        maintenanceDetail() {
            return this.$store.getters.getMaintenanceDetail
        }
    },
    methods: {
        ...mapActions(["getMaintenanceDetail"])
    },
    async created() {
        const loading = this.$loading({
            lock: true,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        await this.getMaintenanceDetail();
        loading.close();
    }
}
</script>

<style>
.maintenance-message {
    position: sticky;
    top: 0;
    z-index: 9999;
    text-align: center;
    font-size: 1.5rem;
    background-color: #f3efde !important;
}
</style>
