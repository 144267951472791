<template>
    <div>
        <template slot="header">
            <h4 class="card-title">Views</h4>
            <p class="category">Bar Chart</p>
        </template>
        <bar-chart :labels="viewsChart.labels"
                   :height="200"
                   :datasets="viewsChart.datasets">
        </bar-chart>
    </div>
</template>
<script>
    import {Card} from './UIComponents';
    import LineChart from './UIComponents/Charts/LineChart';
    import BarChart from './UIComponents/Charts/BarChart';
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

    const tooltipOptions = {
        tooltipFillColor: 'rgba(0,0,0,0.5)',
        tooltipFontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
        tooltipFontSize: 14,
        tooltipFontStyle: 'normal',
        tooltipFontColor: '#fff',
        tooltipTitleFontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: 'bold',
        tooltipTitleFontColor: '#fff',
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10,
    };
    export default {
        components: {
            Card,
            LineChart,
            BarChart,

        },
        data() {
            return {
                viewsChart: {
                    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    datasets: [
                        {
                            label: 'Data',
                            borderColor: '#4cbdd7',
                            fill: true,
                            backgroundColor: '#4cbdd7',
                            hoverBorderColor: '#4cbdd7',
                            borderWidth: 8,
                            data: [100, 120, 80, 100, 90, 130, 110, 100, 80, 110, 130, 140, 130, 120, 130, 80, 100, 90, 120, 130],
                            datalabels: {
                                display: false
                            },
                        },
                        {
                            label: 'Data',
                            borderColor: '#eb5e28',
                            fill: true,
                            backgroundColor: '#eb5e28',
                            hoverBorderColor: '#eb5e28',
                            borderWidth: 8,
                            data: [80, 140, 50, 120, 50, 150, 60, 130, 50, 130, 150, 100, 110, 80, 140, 50, 140, 50, 110, 150],
                            datalabels: {
                                display: false
                            },
                        },
                    ],

                },

            };
        },
    };
</script>
<style>
</style>
