<template>
  <div class="card setShadow mvd_details_class">
   	<div class="card-header">
    <div class="containBytitile">  
    <h4 class="card-title">{{ charttitle }}</h4>
     </div>
      <p class="card-category"></p>
      </div>
      <div class="card-body set_mvd_res_chart">
    <!-- Pradeep Start -->
    <line-chart :labels="stockChart.labels"
                :height="140"
                :color="stockChart.color"
                :extra-options="stockChart.options"
                :datasets="stockChart.datasets">
     </line-chart>
     <!-- Pradeep Start -->
    </div>    
 </div>
</template>
<script>
   
    import {Card} from './UIComponents';
    import ChartCard from './UIComponents/Cards/ChartCard';
    import LineChart from './UIComponents/Charts/LineChart';
    import BarChart from './UIComponents/Charts/BarChart';
    import PieChart from './UIComponents/Charts/PieChart';

    const tooltipOptions = {
        tooltipFillColor: 'rgba(0,0,0,0.5)',
        tooltipFontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
        tooltipFontSize: 14,
        tooltipFontStyle: 'normal',
        tooltipFontColor: '#fff',
        tooltipTitleFontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: 'bold',
        tooltipTitleFontColor: '#fff',
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10,
    };
    export default {
        components: {
            Card,
            ChartCard,
            LineChart
        },
         props: {
            charttitle: {
                type: String,
                default: 'TEST USER',
            },
        },
        data() {
            return {
                stockChart: {
                    labels: ['6pm', '9pm', '11pm', '2am', '4am', '6am', '8am','9am'],
                    datasets: [
                        {
                            label: 'Active Users',
                            borderColor: '#f17e5d',
                            pointBackgroundColor: '#f17e5d',
                            pointRadius: 3,
                            pointHoverRadius: 3,
                            lineTension: 0,
                            fill: false,
                            borderWidth: 3,
                            data: [1,10,2,10,3,10,4,10],
                        }],
                    color: '#f17e5d',
                    options: {
                        plugins: {
                            datalabels: {
                                display: false
                            }
                        },
                        tooltips: tooltipOptions,
                        scales: {
                            yAxes: [
                                {

                                    ticks: {
                                        fontColor: '#9f9f9f',
                                        beginAtZero: false,
                                        maxTicksLimit: 5,
                                    },
                                    gridLines: {
                                        drawBorder: false,
                                        borderDash: [8, 5],
                                        zeroLineColor: 'transparent',
                                        color: '#9f9f9f',
                                    },

                                }],

                            xAxes: [
                                {
                                    barPercentage: 1.6,
                                    gridLines: {
                                        drawBorder: false,
                                        borderDash: [8, 5],
                                        color: '#9f9f9f',
                                        zeroLineColor: 'transparent',
                                    },
                                    ticks: {
                                        padding: 20,
                                        fontColor: '#9f9f9f',
                                    },
                                }],
                        },
                    },
                }
            };
        },
    };
</script>
<style>

</style>
