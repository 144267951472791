import * as types from '../mutation-types';

const state = {

    organisation: null,

    isFetchingUserProjects: false,

    ownProjects: [],

    assignedProjects: [],

    userRoles: [],

    userOrganisations: [],

    projectCount: null,

    userData: null,

};

const getters = {

    ownProjects: state => state.ownProjects,
    assignedProjects: state => state.assignedProjects,
    isFetchingUserProjects: state => state.isFetchingUserProjects,
    userRoles: state => state.userRoles,
    organisation: state => state.organisation,
    userOrganisations: state => state.userOrganisations,
    getVetsInOrganisation: state => orgId => {
        let vets = [];
        if (orgId) {
            if (orgId > 0) {
                let i = state.userOrganisations.find(item => item.organisation.id === orgId)
                vets = i.organisation.vets
                console.log(vets)
                return vets
            } else {
                console.log(vets)
                return vets;
            }
        } else {
            console.log(vets)
            return vets;
        }
    },
    userProjectCount: state => state.projectCount,
    userData: state => state.userData

};

const mutations = {

    [types.SET_USER_PROJECTS](state, data, type)
    {
        state.ownProjects = data.projects;
    },

    [types.ADD_PROJECT](state, data)
    {
        state.assignedProjects.push(data.project);
    },

    [types.SET_USER_ROLES](state, data)
    {
        state.userRoles = data.roles;
    },

    [types.SET_ORGANISATION](state, data)
    {
        state.organisation = data.organisation;
    },

    [types.SET_USER_ORGANISATIONS](state, data)
    {
        if (data.own_organisation != null) {
            state.userOrganisations = [];
            state.userOrganisations.push({
                organisation: {
                    id: data.own_organisation.id,
                    //name: data.own_organisation.name + ' (Director)',
                    name: data.own_organisation.name,
                    vets: data.own_organisation.vets
                },
            });
        }
        //state.userOrganisations = [...state.userOrganisations, ...data.joined_organisations];
        state.userOrganisations = [...state.userOrganisations];
        state.organisation = data.own_organisation;
    },
    [types.SET_USER_PROJECT_COUNT](state, data) {
        state.projectCount = data
    },
    [types.SET_USER_DATA](state, data) {
        state.userData = data;
    }
};

const actions = {

    getOrganisation({commit})
    {
        return axios.get('/organisation').then(r => {
            commit(types.SET_ORGANISATION, r.data.response.data);
        });
    },

    getUserOrganisations({commit})
    {
        return axios.get('user/organisations').then(r => {
            commit(types.SET_USER_ORGANISATIONS, r.data.response.data);
        });
    },

    getProjects({commit})
    {
        return axios.get('/user/projects').then(r => {
            commit(types.SET_USER_PROJECTS, r.data.response.data);
        }).catch(function (error) {
            //location.href = '/logout';
        });
    },

    getProjectsByType({commit}, type) {
        return axios.get(`/user/projects/type/${type}`).then(r => {
            commit(types.SET_USER_PROJECTS, r.data.response.data, type);
        }).catch(function (error) {
            //location.href = '/logout';
        });
    },

    createProject({commit}, projectDetails)
    {
        return axios.post('/project/create', projectDetails).then(r => {
            if (r.data.response.error.error_code == 0) {
                commit(types.ADD_PROJECT, r.data.response.data);
                return true;
            } else {
                console.log(r);
                return false;
            }
        }).catch(function (error) {
            let errors = [];
            for (let key in error.response.data.response.data) {
                console.log("Key: " + key);
                console.log("Value: " + error.response.data.response.data[key]);
                errors.push(error.response.data.response.data[key]);
            }
            console.log(error);
            return errors;
        });
    },

    getUserRoles({commit})
    {
        return axios.get('/user/roles').then(r => {
            if (r.data.response.error.error_code == 0) {
                commit(types.SET_USER_ROLES, r.data.response.data);
                return true;
            } else {
                console.log(r);
                return false;
            }
        }).catch(function (error) {
            let errors = [];
            for (let key in error.response.data.response.data) {
                console.log("Key: " + key);
                console.log("Value: " + error.response.data.response.data[key]);
                errors.push(error.response.data.response.data[key]);
            }
            console.log(error);
            return errors;
        });
    },

    setUserProjectCount({commit}, data) {
        commit(types.SET_USER_PROJECT_COUNT, data);
    },

    getUser({commit}) {
        return axios.get("/user").then((response) => {
            const userData = response.data.response.data;
            if (response.data.response.error.error_code == 0) {
                commit(types.SET_USER_DATA, userData);
                const counts = {
                    organisation: userData.user.organisation_project_count, shared: userData.user.shared_project_count, private: userData.user.private_project_count
                };
                commit(types.SET_USER_PROJECT_COUNT, counts);
            }
            return userData;
        }, (error) => {
            return errors;
        });
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
