<template>

    <div>
        <div class="login-page">
            <div class="wrapper wrapper-full-page">
                <div class="full-page login-page section-image">
                    <div class="register-page">
                        <div class="wrapper wrapper-full-page ">
                            <div class="full-page register-page section-image" filter-color="black">
                                <div class="content">
                                    <h4 class="hsapps_title"></h4>
                                    <div class="container">
                                        <div class="row desktop">
                                            <div class="col-md-6 col-sm-12">
                                                <info-section class="mt-5"
                                                              type="primary"
                                                              title="HSIApps Signup"
                                                              description="Signup for HSIApps to start streamlining your animal welfare operations. HSIApps is FREE, sponsored by Humane Society International."
                                                              icon="">

                                                </info-section>
                                            </div>
                                            <div class="col-md-6 col-sm-12">

                                                <card v-if="invitationEmail.length>0" color="green">
                                                    <span class="text-white">Please Login if you already have an account or Create an account to join this invitation</span><br>
                                                </card>

                                                <form id="signupForm" method="post" action="/signup">

                                                    <input type="hidden" name="invitation_code"
                                                           :value="invitationCode">

                                                    <input type="hidden" name="invitation_email"
                                                           :value="invitationEmail">

                                                    <card type="signup">
                                                        <template slot="header">
                                                            <h4 class="card-title text-center">HSIApps Signup</h4>
                                                            <hr>
                                                        </template>

                                                        <fg-input required name="email" v-model="form.email"
                                                                  :readonly="form.isEmailDisabled"
                                                                  :error="getError('email')"
                                                                  v-validate="formValidations.email"
                                                                  addon-left-icon="nc-icon nc-email-85"
                                                                  placeholder="Email"></fg-input>

                                                        <fg-input v-model="form.password"
                                                                  addon-left-icon="nc-icon nc-key-25"
                                                                  name="password"
                                                                  type="password"
                                                                  ref="password"
                                                                  v-validate="formValidations.password"
                                                                  :error="getError('password')"
                                                                  placeholder="Password"></fg-input>
                                                        <fg-input v-model="form.password_confirmation"
                                                                  type="password"
                                                                  addon-left-icon="nc-icon nc-key-25"
                                                                  name="password_confirmation"
                                                                  v-validate="'required|confirmed:password'"
                                                                  :error="getError('password_confirmation')"
                                                                  data-vv-as="password"
                                                                  placeholder="Confirm Password"></fg-input>

                                                        <fg-input v-model="form.firstName"
                                                                  addon-left-icon="nc-icon nc-single-02"
                                                                  name="firstName"
                                                                  v-validate="formValidations.firstName"
                                                                  :error="getError('firstName')"
                                                                  placeholder="First Name"></fg-input>
                                                        <fg-input v-model="form.lastName"
                                                                  addon-left-icon="nc-icon nc-circle-10"
                                                                  name="lastName"
                                                                  v-validate="formValidations.lastName"
                                                                  :error="getError('lastName')"
                                                                  placeholder="Last Name"></fg-input>
                                                        <fg-input name="phone" v-model="form.phone"
                                                                  addon-left-icon="nc-icon nc-mobile"
                                                                  placeholder="Phone"></fg-input>

                                                        <fg-input name="country" v-model="form.country"
                                                                  addon-left-icon="nc-icon nc-globe-2"
                                                                  placeholder="Country"></fg-input>

                                                        <fg-input name="city" v-model="form.city"
                                                                  addon-left-icon="nc-icon nc-pin-3"
                                                                  placeholder="City"></fg-input>


<!--                                                        <p-checkbox name="acceptTerms" v-model="form.acceptTerms"-->
<!--                                                                    v-validate="'required:true'"-->
<!--                                                                    :error="getErrorAcceptTerms()"-->
<!--                                                                    class="text-left">-->
<!--                                                            I agree to the-->
<!--                                                            <a href="https://hsapps.org/HSIAppsTerms12April2022.pdf"-->
<!--                                                               target="_blank">terms and conditions</a>.-->
<!--                                                        </p-checkbox>-->
                                                        <div id="error"
                                                             style="display:none; color:#ef8157; font-size:80%">
                                                            please
                                                            check to accept the terms and conditions
                                                        </div>


                                                        <div slot="footer" class="signupBtnAlign">
                                                            <p-button v-on:click="validateSignUpForm" slot="footer"
                                                                      type="info" round class="signup_btn_width">
                                                                Sign-Up
                                                            </p-button>
                                                            <br>
                                                            <br>
                                                            <p-button v-on:click="$router.go(-1)" slot="footer"
                                                                      type="danger" round class="signup_btn_width">
                                                                Login
                                                            </p-button>
                                                        </div>
                                                    </card>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="full-page-background"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import {Button, Card, Checkbox, InfoSection} from "./UIComponents";
import PCheckbox from "./UIComponents/Inputs/Checkbox";
import TopNavbar from "./Layout/TopNavbarNew";

export default {

    components: {
        Card,
        [Checkbox.name]: Checkbox,
        [Button.name]: Button,
        InfoSection,
        PCheckbox,
        'top-navbar': TopNavbar
    },
    props: {
        invitationCode: {
            type: String,
            default: ''
        },
        invitationEmail: {
            type: String,
            default: ''
        },
        invitationType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {

            model: {
                email: '',
                password: '',
                rememberMeChecked: false,
                isLoggingIn: false,
            },
            modelValidations: {
                email: {
                    required: true,
                    email: true,
                },
                password: {
                    required: true,
                    min: 6,
                },
            },

            reset_email: '',
            form: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                country: '',
                city: '',
                password: '',
                password_confirmation: '',
                acceptTerms: true,
                isEmailDisabled: false,
            },
            formValidations: {
                firstName: {
                    alpha_spaces: true,
                },
                lastName: {
                    alpha_spaces: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                acceptTerms: {
                    required: false
                },
                password: {
                    required: true,
                    min: 6,
                },
            }
        }
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },


        validateSignUpForm(e) {
            let vm = this;
            //var acceptTerms = document.querySelector("input[name=acceptTerms]").checked;
            var acceptTerms = true
            if (!acceptTerms) {
                $("#error").show();
            } else {
                $("#error").hide();
            }
            e.preventDefault();
            this.$validator.validateAll().then((result) => {

                let data=null

                if(vm.$props.invitationCode!=''&&vm.$props.invitationType!=''){
                    data={
                        'email': vm.form.email,
                        'password': vm.form.password,
                        'password_confirmation': vm.form.password_confirmation,
                        'first_name': vm.form.firstName,
                        'last_name': vm.form.lastName,
                        'phone': vm.form.phone,
                        'country': vm.form.country,
                        'city': vm.form.city,
                        'invitation_code': vm.$props.invitationCode,
                        'invitation_type': vm.$props.invitationType,
                        'acceptTerms': vm.form.acceptTerms
                    }
                }else{
                    data={
                        'email': vm.form.email,
                        'password': vm.form.password,
                        'password_confirmation': vm.form.password_confirmation,
                        'first_name': vm.form.firstName,
                        'last_name': vm.form.lastName,
                        'phone': vm.form.phone,
                        'country': vm.form.country,
                        'city': vm.form.city,
                        'acceptTerms': vm.form.acceptTerms
                    }
                }


                if (result) {
                    axios.post('user/register', data).then(response => {
                        if (response.data.response.error.error_code === 0) {

                            if(response.data.response.data){
                                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.response.data.token

                                vm.showAlert("success", response.data.response.error.msg, true, function (confirm) {
                                    vm.$router.replace("/platform/projects");
                                })
                            }else{
                                vm.showAlert("success", response.data.response.error.msg, true, function (confirm) {
                                    vm.$router.push({name: 'login'});
                                })
                            }

                            // if (vm.$props.invitationType === 'field_team') {
                            //
                            //     // axios.get("/field_team/join/" + vm.$props.invitationCode)
                            //     //     .then(response2 => {
                            //     //         if (response2.data.response.error.error_code === 0) {
                            //     //             vm.showAlert("success", "Field team joined successfully", true, function (confirm) {
                            //     //                 vm.$router.replace("/platform/projects");
                            //     //             })
                            //     //         }
                            //     //     })
                            //     //     .catch(error => {
                            //     //         vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                            //     //             vm.$router.replace({name: 'platform'})
                            //     //         })
                            //     //     });
                            //
                            // } else {
                            //     vm.showAlert('Success', response.data.response.error.msg, true, function () {
                            //         if (vm.invitationType !== '' && vm.invitationCode !== '' && vm.invitationEmail !== '') {
                            //             if (response.data.response.data.token) {
                            //                 vm.$router.replace("/platform/projects");
                            //             }
                            //         } else {
                            //             vm.$router.push({name: 'login'});
                            //         }
                            //     });
                            // }


                        } else {
                            vm.showAlert('Error', response.data.response.error.error_msg, false);
                        }
                    })
                        .catch(error => {
                            console.log(error);
                            vm.showAlert('Error', error.data.response.error.error_msg, false);
                        });
                }
                return false
            }).catch(() => {
                return false
            });
        },
        getErrorAcceptTerms() {
            setTimeout(function () {
                var acceptTerms = document.querySelector("input[name=acceptTerms]").checked;
                if (acceptTerms) {
                    $("#error").hide();
                } else {
                    $("#error").show();
                }
            }, 200);
        },
        validateNumber(e) {
            let val = e.target.value;
            let rex = /^[0-9+]+$/;
            if (rex.test(val)) {
                return true;
            } else {
                e.target.value = val.slice(0, -1);
            }
        },
        getInvitationInfo() {
            const vm = this
            axios.get('/field_team/invitation/details/' + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        const invitation = response.data.response.data.invitation
                        vm.form.firstName = invitation.first_name
                        vm.form.lastName = invitation.last_name
                    } else {
                        vm.showAlert('Error', response.data.response.error.errror_msg, false)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    mounted() {
        if (window.localStorage.getItem('hsapps-expired') !== null) {
            window.localStorage.removeItem('hsapps-expired');

            toastr.error('Your session has expired.');
        }
        setTimeout(function () {
            $("#error").hide();
        }, 201);
    },
    created() {
        if (this.invitationEmail.length > 0) {
            let invitationEmail = this.invitationEmail;
            this.form.email = invitationEmail;
            if (invitationEmail !== '') {
                this.form.isEmailDisabled = true;
                this.getInvitationInfo()
            }
        }

    }

}

</script>

<style>
.form-group {
    margin-bottom: 10px !important;
}

.form-control {
    margin-bottom: 0 !important;
}

</style>

<style type="text/css">
.buttonright {
    position: absolute;
    width: 113px;
    right: 0;
    margin-right: 20px;
    top: 18px;
    z-index: 9;
}

.setformheight {
    height: 65px;
}

/* Pradeep */
.signupBtnAlign {
    margin-left: 20px;
    margin-right: 20px;
}

.card-signup .card-footer {
    margin-top: 0px;
}

.signup_btn_width {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

.loginBtn--google {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0.2em;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    box-shadow: 1px 1px 6px grey;
    padding: 5px;
}

.input-group .form-control:last-child,
.input-group .form-group .el-input__inner:last-child,
.form-group .input-group .el-input__inner:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
    border-left: 0 none;
}

.navbar-toggler {
    display: none;
}

.settextboxwidth {
    flex-grow: 1;
}

@media screen and (max-width: 767px) {
    .signupfrmresponsive {
        width: 80%;
    }
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
    .settextboxwidth {
        width: 84%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
    .settextboxwidth {
        width: 84%;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (orientation: portrait) {
    .settextboxwidth {
        width: 87%;
    }
}


@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
    .settitleresponsive {
        margin-top: 210px;
    }

    .buttonright {
        top: 6px;
    }
}

@media only screen and (max-device-width: 320px) {
    .settitleresponsive {
        margin-top: 218px;
    }

    .buttonright {
        top: 6px;
    }

    .registerform_responsive {
        margin-top: 10px !important;
    }
}

@media only screen and (max-device-width: 375px) {
    .settitleresponsive {
        margin-top: 218px;
    }

    .buttonright {
        top: 6px;
    }

    .registerform_responsive {
        margin-top: 10px !important;;
    }


}

@media only screen and (max-device-width: 768px) {
    .settitleresponsive {
        margin-top: 224px;
    }

    .buttonright {
        top: 6px;
    }

}

@media only screen and (max-device-width: 425px) {
    .registerform_responsive {
        margin-top: 10px !important;;
    }
}

@media (max-width: 768px) {
    .registerform_responsive {
        margin-top: 225px;
    }

}

/* End */

</style>
