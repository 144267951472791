<template>

    <div>


    </div>

</template>

<script>

import Swal from "sweetalert2"

export default {

    props: {
        invitationCode: {
            type: String,
        },
        email: {
            type: String,
        },
        invitationType: {
            type: String,
        }
    },
    data()
    {
        return {}
    },
    methods: {

        joinProject()
        {
            let vm = this;
            axios.get("/project/join/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Project joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        },

        joinFieldTeam()
        {
            let vm = this;
            axios.get("/field_team/join/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Field team joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        },

        joinOrganisation()
        {
            let vm = this;
            axios.get("/organisation/join/member/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Organisation joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        }

    },
    created()
    {

        const vm = this

        if (vm.$store.getters.isAuthenticated) {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + vm.$store.getters.getJwt;

            if (vm.invitationType === 'project') {
                vm.joinProject()
            } else
                if (vm.invitationType === 'field_team') {
                    vm.joinFieldTeam()
                } else
                    if (vm.invitationType === 'organisation') {
                        vm.joinOrganisation()
                    }

        } else {


            axios.get('/user/check_email/' + vm.email)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        //user found

                        new Swal({
                            title: 'Alert!',
                            text: 'Please login to join this invitation',
                            type: 'info',
                        }).then(function () {

                            vm.$router.replace({
                                name: '/loginToJoin',
                                params: {
                                    invitationType: vm.invitationType,
                                    invitationCode: vm.invitationCode,
                                    invitationEmail: vm.email
                                }
                            })

                        });

                    } else {
                        //user not found redirect to sign up page with params
                        new Swal({
                            title: 'Alert!',
                            text: 'You need to register with HSIApps to join this invitation',
                            type: 'warning',
                        }).then(function () {

                            vm.$router.replace({
                                name: 'registerUser',
                                params: {
                                    invitationType: vm.invitationType,
                                    invitationCode: vm.invitationCode,
                                    invitationEmail: vm.email
                                }
                            })


                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }


    }


}

</script>

<style scoped>

</style>
