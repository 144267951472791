<template>
    <div class="pm-exam">
        <el-container v-loading="isLoadingData">
            <el-form ref="examForm" :model="examModal" label-width="80px" label-position="top">
                <div class="row">
                    <div class="col-lg-3">
                        <el-form-item
                            label="Select Date"
                            prop="dateTime"
                            :rules="[
                                { required: true, message: 'Please select date time', trigger: 'change' }
                            ]">
                            <el-date-picker
                                v-model="examModal.dateTime"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="Select date and time">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item
                            label="Select Veterinarian"
                            prop="vet"
                            :rules="[
                                { required: true, message: 'Please select veterinarian', trigger: 'change' }
                            ]">
                            <el-select v-model="examModal.vet" placeholder="Select">
                                <el-option
                                    v-for="item in vets"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Weight" prop="weight">
                            <el-input type="number" placeholder="" v-model="examModal.weight">
                                <template slot="append">Kg</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="Temperature" prop="temp">
                            <el-input type="number" placeholder="" v-model="examModal.temp">
                                <template slot="append">℉</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-lg-3">
                        <el-form-item
                            label="Heart Rate"
                            prop="heartRate"
                            :rules="[
                                { validator: minMaxValidatorPerMin}
                            ]">
                            <el-input type="number" placeholder="" v-model="examModal.heartRate">
                                <template slot="append">beats/min</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="Respiratory Rate"
                            prop="breathRate"
                            :rules="[
                                { validator: minMaxValidatorPerMin}
                            ]">
                            <el-input type="number" placeholder="" v-model="examModal.breathRate">
                                <template slot="append">breaths/min</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="Dehydration Level" prop="dehydrationLevel">
                            <el-select v-model="examModal.dehydrationLevel" placeholder="Select">
                                <el-option
                                    v-for="item in dehydrationLevels"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Body Condition Score" prop="bodyConditionScore">
                            <span slot="label"> Body Condition Score <small>(BCS1-5 ranges from very thin to very obese)</small></span>
                            <el-select v-model="examModal.bodyConditionScore" placeholder="Select">
                                <el-option
                                    v-for="item in bodyConditions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-lg-3">
                        <el-form-item label="Mucus Membrane" prop="mucusMembrane">
                            <el-select v-model="examModal.mucusMembrane" placeholder="Select">
                                <el-option
                                    v-for="item in mucusMembraneList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Mentation" prop="mentation">
                            <el-select v-model="examModal.mentation" placeholder="Select">
                                <el-option
                                    v-for="item in mentationList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="isFemaleRecord" label="Reproductive Status" prop="reproductiveStatus">
                            <el-select v-model="examModal.reproductiveStatus" placeholder="Select" clearable>
                                <el-option
                                    v-for="item in reproductiveStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Select Conditions (found during Exam)" prop="conditions">
                            <el-select v-model="examModal.conditions" multiple placeholder="Multi Select">
                                <el-option
                                    v-for="item in conditionsList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-lg-3">
                        <el-form-item label="Note" prop="note">
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="Please input"
                                v-model="examModal.note">
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="Next Step"
                            prop="nextStep"
                            :rules="[
                                { required: true, message: 'Please select next step', trigger: 'change' }
                            ]">
                            <el-select v-model="examModal.nextStep" placeholder="Select">
                                <el-option
                                    v-for="item in nextStepList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </el-container>
        <div class="row text-right">
            <div class="col-sm-12">
                <button type="button" :disabled="isSavingData||isLoadingData" class="btn btn-success"
                        @click="saveOrUpdateExamination">
                    {{ getButtonLabel }}
                </button>
            </div>
        </div>

    </div>

</template>

<script>

import {Select, Option, DatePicker, Input, Container, Form, FormItem} from 'element-ui';

export default {

    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [DatePicker.name]: DatePicker,
        [Input.name]: Input,
        [Container.name]: Container,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    data()
    {
        return {

            isFemaleRecord:false,

            isSavingData: false,

            isLoadingData: false,

            examModal: {
                dateTime: null,
                vet: '',
                weight: '',
                temp: '',
                heartRate: '',
                breathRate: '',
                dehydrationLevel: '',
                bodyConditionScore: '',
                mucusMembrane: '',
                mentation: '',
                reproductiveStatus: '',
                conditions: [],
                nextStep: '',
                note: ''
            },

            pickerOptions: {
                shortcuts: [
                    {
                        text: 'Today',
                        onClick(picker)
                        {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: 'Yesterday',
                        onClick(picker)
                        {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: 'A week ago',
                        onClick(picker)
                        {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }
                ]
            },
            vets: [
                {
                    value: 'Shrikant',
                    label: 'Shrikant'
                }, {
                    value: 'Amit',
                    label: 'Amit'
                },
            ],
            dehydrationLevels: [
                {
                    value: 'Normal',
                    label: 'Normal'
                }, {
                    value: 'Mid',
                    label: 'Mid'
                },
                {
                    value: 'Moderate',
                    label: 'Moderate'
                }, {
                    value: 'Severe',
                    label: 'Severe'
                },
            ],
            bodyConditions: [
                {
                    value: 'BCS1',
                    label: 'BCS1'
                }, {
                    value: 'BCS2',
                    label: 'BCS2'
                },
                {
                    value: 'BCS3',
                    label: 'BCS3'
                },
                {
                    value: 'BCS4',
                    label: 'BCS4'
                },
                {
                    value: 'BCS5',
                    label: 'BCS5'
                },
            ],
            mucusMembraneList: [
                {
                    value: 'Moist and Pink',
                    label: 'Moist and Pink'
                },
                {
                    value: 'Dry and Pale',
                    label: 'Dry and Pale'
                },
                {
                    value: 'Sticky and Pale',
                    label: 'Sticky and Pale'
                },
            ],
            mentationList: [
                {
                    value: 'Bright, Alert and Responsive',
                    label: 'Bright, Alert and Responsive'
                },
                {
                    value: 'Depressed',
                    label: 'Depressed'
                },
                {
                    value: 'Stuporous',
                    label: 'Stuporous'
                },
                {
                    value: 'Comatose',
                    label: 'Comatose'
                }
            ],
            reproductiveStatusList: [
                {
                    value: 'In Heat',
                    label: 'In Heat'
                },
                {
                    value: 'Lactating',
                    label: 'Lactating'
                },
            ],
            conditionsList: [
                {
                    value: 'TVT',
                    label: 'TVT'
                },
                {
                    value: 'Mange',
                    label: 'Mange'
                },
                {
                    value: 'Maggot Wound',
                    label: 'Maggot Wound'
                },
                {
                    value: 'Accident Wound',
                    label: 'Accident Wound'
                },
                {
                    value: 'Fracture',
                    label: 'Fracture'
                },
                {
                    value: 'Eye Infection',
                    label: 'Eye Infection'
                },
                {
                    value: 'Ear Infection',
                    label: 'Ear Infection'
                },
                {
                    value: 'Ectoparasites',
                    label: 'Ectoparasites'
                },
                {
                    value: 'Wound',
                    label: 'Wound'
                },
                {
                    value: 'Diarrhea ',
                    label: 'Diarrhea '
                },
                {
                    value: -1,
                    label: 'Other'
                },
            ],
            nextStepList: [
                {
                    value: 'Cleared for Sterilization',
                    label: 'Cleared for Sterilization'
                },
                {
                    value: 'Put on Pre-op hold',
                    label: 'Put on Pre-op hold'
                },
                {
                    value: 'Put on Post-op hold',
                    label: 'Put on Post-op hold'
                },
                {
                    value: 'Sterilization Aborted',
                    label: 'Sterilization Aborted'
                },
                {
                    value: 'Cleared for Release',
                    label: 'Cleared for Release'
                },
            ]
        }
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        recordId: {
            type: String,
            required: true
        }
    },
    methods: {
        saveOrUpdateExamination()
        {
            let vm = this;

            this.$refs['examForm'].validate((valid) => {
                if (valid) {
                    vm.isSavingData = true

                    let url = "";

                    if (vm.id != null) {
                        if (vm.id > 0) {
                            url = '/physical_examination/update/physicalExamination/' + vm.recordId + '/' + vm.id;
                        } else {
                            url = '/physical_examination/add/physicalExamination/' + vm.recordId;
                        }
                    } else {
                        url = '/physical_examination/add/physicalExamination/' + vm.recordId;
                    }

                    axios.post(url, {
                        timestamp: vm.examModal.dateTime,
                        vet_name: vm.examModal.vet,
                        weight: vm.examModal.weight,
                        temperature: vm.examModal.temp,
                        heart_rate: vm.examModal.heartRate,
                        resp_rate: vm.examModal.breathRate,
                        dehydration_level: vm.examModal.dehydrationLevel,
                        body_condition_score: vm.examModal.bodyConditionScore,
                        mucus_membrane: vm.examModal.mucusMembrane,
                        mentation: vm.examModal.mentation,
                        reproductive_status: vm.examModal.reproductiveStatus,
                        condition: vm.examModal.conditions,
                        note: vm.examModal.note,
                        next_step: vm.examModal.nextStep,
                    }).then(response => {
                        vm.isSavingData = false
                        if (response.data.response.error.error_code == 0) {

                            this.$root.$emit('HistoryTableUpdateRecords') //like this
                            if (vm.id > 0) {
                                vm.$message({
                                    message: 'Physical Examination Details Updated Successfully.',
                                    type: 'success'
                                });
                            } else {
                                vm.clearModalData()
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        }
                    })
                    .catch(e => {
                        vm.isSavingData = false
                        console.log(e);
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getPhysicalExaminationDetails()
        {
            let vm = this;

            vm.isLoadingData = true

            axios.get('/physical_examination/physicalExamination/' + vm.id)
                .then(response => {
                    vm.isLoadingData = false
                    if (response.data.response.error.error_code == 0) {
                        let record = response.data.response.data.physical_examination;
                        vm.examModal.dateTime = record.created_at;
                        vm.examModal.vet = record.vet_name;
                        vm.examModal.weight = record.weight;
                        vm.examModal.temp = record.temperature;
                        vm.examModal.heartRate = record.heart_rate;
                        vm.examModal.breathRate = record.respiratory_rate;
                        vm.examModal.dehydrationLevel = record.dehydration_level;
                        vm.examModal.bodyConditionScore = record.body_condition_score;
                        vm.examModal.mucusMembrane = record.mucus_membrane;
                        vm.examModal.mentation = record.mentation;
                        vm.examModal.reproductiveStatus = record.reproductive_status;
                        vm.examModal.conditions = JSON.parse(record.condition);
                        vm.examModal.note = record.comment;
                        vm.examModal.nextStep = record.next_step;
                    }
                })
                .catch(e => {
                    vm.isLoadingData = false
                    console.log(e);
                })

        },
        init()
        {
            if (this.id != null) {
                this.getPhysicalExaminationDetails();
            }
            this.getVets();
        },
        getVets()
        {
            var vm = this;
            axios.get("/organisation/get/vets", {
                params: {
                    organisation_id: vm.$store.getters.getCurrentSelectedProject.organisation_id,
                },
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.vets = []
                    var v = response.data.response.data.vets;
                    for (let i = 0; i < v.length; i++) {
                        vm.vets.push({
                            value: v[i].first_name + ' ' + v[i].last_name,
                            label: v[i].first_name + ' ' + v[i].last_name
                        });
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        },
        clearModalData()
        {
            this.dateTime = null
            this.examModal.vet = ''
            this.examModal.weight = ''
            this.examModal.temp = ''
            this.examModal.heartRate = ''
            this.examModal.breathRate = ''
            this.examModal.dehydrationLevel = ''
            this.examModal.bodyConditionScore = ''
            this.examModal.mucusMembrane = ''
            this.examModal.mentation = ''
            this.examModal.reproductiveStatus = ''
            this.examModal.conditions = []
            this.examModal.nextStep = ''
            this.examModal.note = ''
            this.$refs['examForm'].resetFields();
        },
        minMaxValidatorPerMin(rule, value, callback) {
            if (value < 0 || value > 999) {
                callback(new Error('must be between 0 to 999'));
            } else {
                callback();
            }
        }
    },
    computed: {
        getButtonLabel: function () {
            if (this.id != null) {
                if (this.id > 0) {
                    return "UPDATE"
                } else {
                    return "SAVE"
                }
            } else {
                return "SAVE"
            }

        }
    },
    mounted()
    {
        this.init();
    }

}

</script>

<style scoped lang="scss">
.pm-exam {
    .el-form-item__label {
        padding: 0 !important;
        margin-bottom: 5px;
        line-height: 10px;
    }
}
</style>
